import { handleCheckSelectedFiles, readableFileSize } from 'rapidfab/utils/fileUtils';
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  ButtonToolbar,
  Form,
  Card,
  ProgressBar,
} from 'react-bootstrap';
import Feature from 'rapidfab/components/Feature';
import {
  FEATURES, MAX_FILE_SIZE,
  UPLOAD_LINE_ITEMS_MAX_LIMIT,
} from 'rapidfab/constants';
import Dropzone from 'react-dropzone';
import Alert from 'rapidfab/utils/alert';
import ModelLibraryInput from 'rapidfab/components/records/order/edit/ModelLibraryInput';
import ModelLibraryModal from 'rapidfab/components/modals/ModelLibraryModal';
import NewLineItemsModal from 'rapidfab/components/records/order/line_item_dialog/NewLineItemsModal';
import getValidExtensionsToUploadModel from 'rapidfab/utils/getValidExtensionsToUploadModel';
import { faSpinner, faTimes, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { pluralWord } from 'rapidfab/utils/stringUtils';
import ConfirmationModal from './ConfirmationModal';
// we are adding the below extensions to be valid
//  '.x_t', '.sat', '.step', '.igs', '.iges',
// '.sldprt', '.ipt', '.prt', '.CATPart',
// '.jt', '.3dm', '.obj', '.stl', '.iam', '.dae',

const AddProduct = ({
  onDesignFileSubmit,
  onNoModelUploadSubmit,
  onFromModelLibrarySubmit,
  uploadModel,
  restrictedUploadModelLibraryOnlyFeature,
  isUserRestricted,
  isCADToSTLConversionFeatureEnabled,
  lineItemsLength,
  isPowderWorkflowFeatureEnabled,
}) => {
  const intl = useIntl();
  const addProductRef = useRef();

  const VALID_EXTENSIONS = getValidExtensionsToUploadModel(isCADToSTLConversionFeatureEnabled);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [noModelUpload, setNoModelUpload] = useState(!!isPowderWorkflowFeatureEnabled);
  const [selectedFile, setSelectedFile] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [isModelLibraryModalVisible, setIsModelLibraryModalVisible] = useState(false);
  const [selectedModelLibrary, setSelectedModelLibrary] = useState(null);
  const [maxFileSizeWarning, setMaxFileSizeWarning] = useState(null);

  const showLineItemDialog = selectedFiles?.length > 1 && uploadedFiles?.length > 0;

  const isSubmittingButtonDisabled =
    isSubmitting || (!noModelUpload &&
    !selectedFiles &&
    !selectedModelLibrary);
  const disableFormControls = isSubmitting;

  const getFileExtension = file => {
    try {
      return file.name.toLowerCase().split('.').pop();
    } catch {
      return null;
    }
  };

  const onDropValidate = file => {
    const fileExtension = getFileExtension(file);

    if (fileExtension && !VALID_EXTENSIONS.includes(fileExtension)) {
      // Dropzone expects object to be returned in case of error
      // (the result is used in onDropRejected)
      return {
        message: `File must be one of extension: ${VALID_EXTENSIONS.join(', ')}`,
      };
    }
    // Dropzone expects null when everything is fine
    return null;
  };

  const onDropAccepted = async files => {
    const file = files[0];
    const validFiles = handleCheckSelectedFiles(files);

    setSelectedModelLibrary(null);
    setSelectedFile(file);
    setSelectedFiles(validFiles);

    if (validFiles.length > 1 && !selectedModelLibrary) {
      addProductRef?.current.click();
    }
  };
  /**
   * See Dropzone docs for more details
   * @param filesErrors {Object[]}
   * @param filesErrors[].errors {Object[]}
   * @param filesErrors[].errors[].message {string}
   * @param filesErrors[].file {Object}
   */
  const onDropRejected = filesErrors => {
    filesErrors.forEach(({ errors }) => {
      errors.forEach(({ message }) => {
        Alert.error(message);
      });
    });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (selectedFiles.length + lineItemsLength > UPLOAD_LINE_ITEMS_MAX_LIMIT) {
      Alert.error(`
      Your version of the app can support only ${UPLOAD_LINE_ITEMS_MAX_LIMIT} line-items per order. Please select at max ${Math.abs(lineItemsLength - UPLOAD_LINE_ITEMS_MAX_LIMIT)} items and try again.`);
      return;
    }
    setIsSubmitting(true);

    try {
      if (noModelUpload) {
        await onNoModelUploadSubmit();
      } else if (selectedFiles && !selectedModelLibrary) {
        if (!maxFileSizeWarning) {
          const tooLargeFiles = selectedFiles.filter(file => file?.size > MAX_FILE_SIZE);
          if (tooLargeFiles.length) {
            const maxFileSize = readableFileSize(MAX_FILE_SIZE);
            const fileSize = readableFileSize(tooLargeFiles[0].size);
            setMaxFileSizeWarning(
              `Allowed max file ${maxFileSize} size exceeded (received file size is ${fileSize}). Would you like to upload the file anyway?`,
            );
            setIsSubmitting(false);
            return;
          }
          const zippedFiles = selectedFiles.filter(file => file?.size === 0);
          if (zippedFiles.length) {
            Alert.error('The file you uploaded is empty. Please select a valid file and try again.');
            setIsSubmitting(false);
            return;
          }
        }

        setMaxFileSizeWarning(null);

        const promises = selectedFiles.map(file => onDesignFileSubmit(file, getFileExtension(file)));
        const fileResponces = await Promise.all(promises);
        setUploadedFiles(fileResponces.map(result => result.json));
      } else if (selectedModelLibrary) {
        await onFromModelLibrarySubmit(selectedModelLibrary.uri);
      }
      Alert.success(intl.formatMessage(
        { id: 'toaster.addProduct.createdSuccessfully', defaultMessage: 'Product{showPluralApostrophe} created successfully.' },
        { showPluralApostrophe: pluralWord('', selectedFiles) },
      ));
      if (selectedFiles?.length === 1) {
        setSelectedFiles([]);
      }
      // TODO: Scroll to Product panel (once it is implemented)
    } catch {
      // In case of validation error - show no success toastr, but clear the form
      // (user will be able to use Product form to proceed)
    }
    setIsSubmitting(false);
    setSelectedFile(null);
    setSelectedModelLibrary(null);
    setNoModelUpload(!!isPowderWorkflowFeatureEnabled);
  };

  const onModelLibrarySelect = modelLibrary => {
    setSelectedModelLibrary(modelLibrary);
    setIsModelLibraryModalVisible(false);
  };

  const onNoModelChange = () => {
    setSelectedModelLibrary(null);
    setNoModelUpload(!noModelUpload);
  };

  const clearSelectedFileOrModelLibrary = () => {
    setSelectedModelLibrary(null);
    setSelectedFile(null);
    setSelectedFiles(null);
  };

  const removeFile = file => {
    setSelectedFiles(selectedFiles.filter(item => item.name !== file.name));
  };

  const uploadPercentage = uploadModel ?
    Number.parseInt(uploadModel.percent, 10) :
    0;

  const modelName = `Model Library: ${selectedModelLibrary?.name}`;

  // File Upload might be disabled for restricted users
  // when `restricted-user-upload-from-model-library-only` feature is enabled
  const fileUploadProhibited = restrictedUploadModelLibraryOnlyFeature && isUserRestricted;
  const isFileUploadAllowed = !fileUploadProhibited &&
    !noModelUpload && (!selectedFiles || selectedFiles.length === 0) &&
    !selectedModelLibrary;

  const isModelLibrarySelectAllowed = !noModelUpload &&
    (!selectedFiles || selectedFiles.length === 0) &&
    !selectedModelLibrary;

  const isNoModelUploadAllowed = !selectedFile && !selectedModelLibrary;

  return lineItemsLength >= UPLOAD_LINE_ITEMS_MAX_LIMIT ? (
    <div className="jumbotron">
      <p>
        You have reached the limit of 25 line items in one order.
        If you need to submit more line items, please create another order.
        More information can be found in <a href="https://authentise.zendesk.com/hc/en-us/articles/115001760463-What-are-your-usage-limits-">this article</a>.
        Please contact <a href="mailto:support@authentise.com">support@authentise.com</a> if you have any questions.
      </p>
    </div>
  ) : (
    <Card bg="dark">
      <Card.Header className="pd-exp inverse">
        {/* TODO add plural translations */}
        <FormattedMessage id="addProduct" defaultMessage="Add Product" />
      </Card.Header>
      <div className="card-body-wrapper">
        <Card.Body>
          <Form horizontal onSubmit={handleSubmit}>
            {
              // TODO: support restricted-model-upload feature
              //  (only Model Library button is available for restricted users in this case)
              // TODO: Model Library button
            }
            {isNoModelUploadAllowed && !isPowderWorkflowFeatureEnabled && (
              <Feature featureName={FEATURES.NO_MODEL_UPLOAD}>
                <div className="checkbox m-b-md">
                  <Form.Check
                    inline
                    name="noModelUpload"
                    checked={noModelUpload}
                    onChange={onNoModelChange}
                    type="checkbox"
                    label={(
                      <strong>
                        <FormattedMessage
                          id="record.uploadWithoutDesign"
                          defaultMessage="Upload Without Design"
                        />
                      </strong>
                    )}
                  />
                </div>
              </Feature>
            )}
            {isModelLibrarySelectAllowed && (
              <ModelLibraryInput
                onClick={() => setIsModelLibraryModalVisible(true)}
              />
            )}
            {isFileUploadAllowed && !isPowderWorkflowFeatureEnabled && (
              <div className="jumbotron">
                <Dropzone
                  onDropAccepted={onDropAccepted}
                  validator={onDropValidate}
                  onDropRejected={onDropRejected}
                  maxFiles={20}
                  accept={VALID_EXTENSIONS.map(extension => `.${extension}`)}
                  multiple
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps({ role: 'button' })}>
                      <input {...getInputProps()} />
                      <h1 className="text-center">Upload Products</h1>
                      <div className="text-center">
                        <FontAwesomeIcon icon={faUpload} size="5x" />
                      </div>
                      <p className="text-center mt15">
                        Drag files here or click to browse
                      </p>
                    </div>
                  )}
                </Dropzone>
              </div>
            )}
            {selectedFiles && (
              <div className="m-b-md">
                {selectedFiles.map(file =>
                  (
                    <React.Fragment key={`selectd-file-${file.name}`}>
                      {file.name}
                      <button
                        className="spacer-left btn-xs btn-danger mr15"
                        type="button"
                        onClick={() => removeFile(file)}
                        disabled={disableFormControls}
                        tabIndex={0}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </button>
                    </React.Fragment>
                  ),
                )}
              </div>
            )}
            {selectedModelLibrary && (
              <div className="m-b-md">
                {modelName}
                <button
                  className="spacer-left btn-xs btn-danger"
                  type="button"
                  onClick={clearSelectedFileOrModelLibrary}
                  disabled={disableFormControls}
                  tabIndex={0}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
            )}
            {(isSubmitting) && (
              <ProgressBar striped now={uploadPercentage} />
            )}
            <ButtonToolbar className="clearfix">
              <Button
                ref={addProductRef}
                disabled={isSubmittingButtonDisabled}
                type="submit"
                variant="success"
                className="flexed-pull-right mt15"
                data-cy="add-product-submit-button"
              >
                {' '}
                {isSubmitting ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  <FormattedMessage
                    id="addProduct"
                    defaultMessage="Add Product"
                  />
                )}
              </Button>
            </ButtonToolbar>
            {!!maxFileSizeWarning && (
              <ConfirmationModal
                handleCancel={() => setMaxFileSizeWarning(null)}
                handleConfirm={handleSubmit}
                confirmButtonContent="Yes"
                message={maxFileSizeWarning}
              />
            )}

          </Form>
          {isModelLibraryModalVisible && (
            <ModelLibraryModal
              onHide={() => setIsModelLibraryModalVisible(false)}
              handleSelect={onModelLibrarySelect}
            />
          )}
          {showLineItemDialog && (
            <NewLineItemsModal
              files={uploadedFiles}
              updateProcessingFiles={setUploadedFiles}
              updateSelectedFiles={setSelectedFiles}
            />
          )}
        </Card.Body>
      </div>
    </Card>
  );
};
AddProduct.defaultProps = {
  isCADToSTLConversionFeatureEnabled: false,
};

AddProduct.propTypes = {
  onNoModelUploadSubmit: PropTypes.func.isRequired,
  lineItemsLength: PropTypes.number.isRequired,
  onFromModelLibrarySubmit: PropTypes.func.isRequired,
  onDesignFileSubmit: PropTypes.func.isRequired,
  uploadModel: PropTypes.shape({
    name: PropTypes.string,
    percent: PropTypes.number,
  }).isRequired,
  restrictedUploadModelLibraryOnlyFeature: PropTypes.bool.isRequired,
  isUserRestricted: PropTypes.bool.isRequired,
  isPowderWorkflowFeatureEnabled: PropTypes.bool.isRequired,
  isCADToSTLConversionFeatureEnabled: PropTypes.bool,
};

export default AddProduct;
