import AssetTypesTabs from 'rapidfab/components/assets/AssetTypesTabs';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';
import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { Badge, Button, Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import DisabledFor from 'rapidfab/components/DisabledFor';
import DisabledIconWithTooltip from 'rapidfab/components/DisabledIconWithTooltip';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { ROUTES } from 'rapidfab/constants';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import Table from 'rapidfab/components/Tables/Table';
import _isEmpty from 'lodash/isEmpty';
import { faPlus, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { getIsDebugModeEnabled } from 'rapidfab/selectors';
import { Link } from 'react-router-dom';

const PostProcessorTypesGrid = ({ postProcessorTypes, isFetching, uuid }) => {
  const columns = [
    {
      type: 'custom',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      resource: 'post-processor-type',
      Cell: ({ row: { original: data } }) => (
        <Link
          to={getRouteURI(ROUTES.POST_PROCESSOR_TYPES, null, { uuid: data.uuid }, true)}
        >
          {getShortUUID(data.uri)}
        </Link>
      ),
    },
    {
      type: 'custom',
      uid: 'field.name',
      accessor: 'name',
      defaultMessage: 'Name',
      isSortable: true,
      Cell: ({ row: { original } }) => (
        <div>
          {original.name}
          {original.archived && (
            <Badge bg="default" className="badge-sm ms-2">
              <FormattedMessage id="field.archived" defaultMessage="Archived" />
            </Badge>
          )}
          {!original.active_post_processors?.length && (
            <OverlayTrigger
              placement="top"
              overlay={(
                <Tooltip>
                  <FormattedMessage
                    id="tooltip.PPTHasNoActivePostProcessors"
                    defaultMessage="Post Processor Type has no active Post Processors"
                  />
                </Tooltip>
              )}
            >
              <FontAwesomeIcon icon={faTriangleExclamation} className="spacer-left" color="#ffd500" />
            </OverlayTrigger>
          )}
        </div>
      ),
    },
  ];
  return (
    <Table
      tableID="postProcessorTypes"
      data={postProcessorTypes}
      columns={columns}
      isFilteringEnabled
      withDefaultPagination
      initialSortedColumn="name"
      initialSortedDesc={false}
      isManualSoringEnabled={false}
      isUpdatedColumnShown={false}
      isFetching={!uuid && isFetching}
    />
  );
};

PostProcessorTypesGrid.defaultProps = {
  uuid: null,
};

PostProcessorTypesGrid.propTypes = {
  postProcessorTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      archived: PropTypes.string,
      active_post_processors: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
  uuid: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
};

const PostProcessorTypes = ({
  isGroupQualificationsFeatureEnabled,
  extraFilters,
  openCreateModal,
  ...componentProps
}) => {
  const isDegbugModeEnabled = useSelector(getIsDebugModeEnabled);

  return (
    <Container fluid>
      <BreadcrumbNav breadcrumbs={['assets', 'postProcessorTypes']} />

      <Row>
        <Col xs={12} className="mb8">
          <AssetTypesTabs activeKey="post-processor-types" />
        </Col>
        <Col xs={12}>
          <div className="pull-right">
            <DisabledFor
              nonManager
              /*
              Disable for Non Managers only when `group-qualifications` feature is enabled.
              No limitations otherwise
              */
              alwaysEnabled={!isGroupQualificationsFeatureEnabled}
              disabledPrefix={
                isDegbugModeEnabled ? (
                  <DisabledIconWithTooltip
                    id="limitedFunctionalityDebugMode"
                    defaultMessage="This functionality usage is limited. Contact your Bureau Manager if you have any questions. Debug Info: Locked due to configuration of User Groups and Group Qualifications. See docs for more details."
                    placement="bottom"
                  />
                )
                  : <DisabledIconWithTooltip placement="bottom" />
              }
            >
              {({ disabled }) => (
                <Button
                  variant="primary"
                  size="sm"
                  onClick={openCreateModal}
                  disabled={disabled}
                >
                  <FontAwesomeIcon icon={faPlus} />{' '}
                  <FormattedMessage id="record.postProcessorType.add" defaultMessage="Add Post Processor Type" />
                </Button>
              )}
            </DisabledFor>
          </div>
        </Col>
      </Row>

      <hr />

      <Row>
        <Col xs={12}>
          {!_isEmpty(extraFilters) && (
            <Row className="mb8">
              <Col className="col-lg-10 col-12 offset-lg-1">
                <div className="filters-label ">Filters:</div>
                {extraFilters}
              </Col>
            </Row>
          )}
          <PostProcessorTypesGrid {...componentProps} />
        </Col>
      </Row>
    </Container>
  );
};

PostProcessorTypes.defaultProps = {
  extraFilters: [],
};

PostProcessorTypes.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  postProcessorTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isGroupQualificationsFeatureEnabled: PropTypes.bool.isRequired,
  extraFilters: PropTypes.arrayOf(PropTypes.shape({})),
  openCreateModal: PropTypes.func.isRequired,
};

export default PostProcessorTypes;
