import React from 'react';
import { Route, Routes } from 'react-router-dom';
import InventoryManufacturers from 'rapidfab/containers/organize/manufacturers';
import InventoryLocations from 'rapidfab/containers/organize/locations';
import InventoryServiceProvidersContainer from 'rapidfab/containers/organize/ServiceProvidersContainer';
import InventoryShipping from 'rapidfab/containers/plan/shipping';
import LibrariesContainer from 'rapidfab/containers/organize/LibrariesContainer';
import PropTypes from 'prop-types';
import SlicerConfigurationsContainer from 'rapidfab/containers/inventory/SlicerConfigurationsContainer';
import ManageConversions from '../containers/organize/conversions';
import PrivateRoute from './PrivateRoute';
import { FEATURES } from '../constants';
import { ROUTER_PERMISSION } from '../constantsRouter';
import PrepTasksContainer from '../containers/organize/PrepTasksContainer';
import NotFound from '../components/404';

const OrganizeRoutes = ({ session }) => (
  <Routes>
    <Route
      path="/conversions"
      element={(
        <PrivateRoute
          session={session}
          component={ManageConversions}
          permission={ROUTER_PERMISSION.BUREAU_UNRESTRICTED}
          features={
            [FEATURES.MANUFACTURING_EXECUTION_SYSTEM]
          }
        />
      )}
    />
    <Route
      path="/model-library"
      element={(
        <PrivateRoute
          session={session}
          component={LibrariesContainer}
          isRestrictedRoute
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            {
              [FEATURES.HAWKING_DEPLOYMENT]: false,
              [FEATURES.MODEL_LIBRARY]: true,
              [FEATURES.DIGITAL_DESIGN_WAREHOUSE]: false,
            }
          }
        />
      )}
    />
    <Route
      path="/builds-library"
      element={(
        <PrivateRoute
          session={session}
          component={LibrariesContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            {
              [FEATURES.CERTIFIED_BUILDS]: true,
            }
          }
        />
      )}
    />
    <Route
      path="/manufacturers"
      element={(
        <PrivateRoute
          session={session}
          component={InventoryManufacturers}
          isRestrictedRoute
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            {
              [FEATURES.HAWKING_DEPLOYMENT]: false,
            }
          }
        />
      )}
    />
    <Route
      path="/locations"
      element={(
        <PrivateRoute
          session={session}
          component={InventoryLocations}
          isRestrictedRoute
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            {
              [FEATURES.HAWKING_DEPLOYMENT]: false,
            }
          }
        />
      )}
    />
    <Route
      path="/service-providers"
      element={(
        <PrivateRoute
          session={session}
          isRestrictedRoute
          component={() => <InventoryServiceProvidersContainer type="work" />}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            [FEATURES.SERVICE_PROVIDERS]
          }
        />
      )}
    />
    <Route
      path="/vendor-service-providers"
      element={(
        <PrivateRoute
          session={session}
          isRestrictedRoute
          component={() => <InventoryServiceProvidersContainer type="vendor" />}
          permission={ROUTER_PERMISSION.MANAGER}
          features={
            [FEATURES.VENDOR_SERVICE_PROVIDER]
          }
        />
      )}
    />
    <Route
      path="/shipping-partners"
      element={(
        <PrivateRoute
          session={session}
          component={InventoryShipping}
          isRestrictedRoute
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            [FEATURES.MANUFACTURING_EXECUTION_SYSTEM]
          }
        />
      )}
    />
    <Route
      path="/prep-tasks"
      element={(
        <PrivateRoute
          session={session}
          component={PrepTasksContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            [FEATURES.PREP_WORKFLOW]
          }
        />
      )}
    />
    <Route
      path="/slicers"
      element={(
        <PrivateRoute
          session={session}
          component={SlicerConfigurationsContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={[FEATURES.AUTO_CREATE_SLICE]}
        />
      )}
    />
    <Route
      path="*"
      element={<NotFound />}
    />
  </Routes>
);

OrganizeRoutes.propTypes = {
  session: PropTypes.shape({}).isRequired,
};

export default OrganizeRoutes;
