// !!!ATTENTION!!! This constant is not supposed to be used separately. Use API_RESOURCES constant instead
export const ENTITY_API_RESOURCES = {
  ASSIGNMENT: 'assignment',
  BUILD: 'build',
  BUILD_FILE: 'build-file',
  BUILD_FILE_LIBRARY: 'build-file-library',
  BUREAU_SETTINGS: 'bureau-settings',
  BUREAU_INTAKE_SETTINGS: 'bureau-intake-settings',
  BUREAU: 'bureau',
  BUREAU_BRANDING: 'bureau-branding',
  CAPTURE: 'capture',
  CAPTURE_TYPE: 'capture-type',
  CASTOR_COSTING: 'castor-costing',
  CASTOR_COSTING_CONFIG: 'castor-costing-config',
  CASTOR_INTEGRATION: 'integration',
  COMMENT: 'comment',
  COMMENT_ACTION: 'comment-action',
  CONTACT_US: 'contact-us',
  CURRENCY_CONVERSION: 'currency-conversion',
  CUSTOM_FIELD: 'custom_field',
  DESIGN_FILE: 'design-file',
  DOCUMENT: 'document',
  DOWNTIME: 'downtime',
  EVENT: 'event',
  FEATURE: 'feature',
  GROUPS: 'groups',
  INFILL_STRATEGY: 'infill_strategy',
  LABEL: 'label',
  LABEL_RELATIONSHIP: 'label-relationship',
  LAYOUT: 'layout',
  LINE_ITEM_NETFABB: 'line-item-netfabb',
  LINE_ITEM: 'line-item',
  LINE_ITEMS_BY_RUN: 'line-items-by-run',
  LINE_ITEM_ACTUALS: 'line-item-actuals',
  LINE_ITEM_QUOTE: 'line-item-quote',
  LABOR: 'labor',
  LOCATION: 'location',
  LIBRARY: 'library',
  SUB_LOCATION: 'sub-location',
  LOCATION_SETTINGS: 'location-settings',
  MANUFACTURER: 'manufacturer',
  MATERIAL: 'material',
  MATERIAL_BATCH: 'material-batch',
  MATERIAL_BATCH_ACTION: 'material-batch-action',
  MATERIAL_CONTAINER_ACTION: 'material-container-action',
  MATERIAL_BATCH_TRACEABILITY_REPORT: 'material-batch-traceability-report',
  MATERIAL_CONTAINER: 'material-container',
  MATERIAL_LOT: 'material-lot',
  MATERIAL_LOT_ACTION: 'material-lot-action',
  MATERIAL_PURCHASE_ORDER: 'material-purchase-order',
  MEMBERSHIP_BUREAU: 'membership-bureau',
  MEMBERSHIPS: 'memberships',
  MODEL_LIBRARY: 'model-library',
  MODEL: 'model',
  MODELER: 'modeler',
  MODELER_TYPE: 'modeler-type',
  MODELER_COMMAND: 'modeler-command',
  ORDER_QUOTE: 'order-quote',
  ORDER_PACKING_SLIP: 'order-packing-slip',
  SHIPMENT_PACKING_SLIP: 'shipment-packing-slip',
  ORDER: 'order',
  PASSWORD_RESETS: 'password_resets',
  PERMISSIONS: 'permissions',
  PIECE: 'piece',
  PIECE_ACTUALS: 'piece-actuals',
  POST_PROCESSOR_TYPE: 'post-processor-type',
  POST_PROCESSOR: 'post-processor',
  PREP_WORKFLOW: 'prep-workflow',
  PREP_WORKFLOW_RECORD: 'prep-workflow-record',
  PREP_TASK: 'prep-task',
  PREP_TASK_RECORD: 'prep-task-record',
  PRINT: 'print',
  PIECES_BY_RUN: 'pieces-by-run',
  PRINTER_TYPE: 'printer-type',
  PRINTER: 'printer',
  PROCESS_PROVIDER: 'process-provider',
  PROCESS_STEP: 'process-step',
  PRODUCT: 'product',
  PROSPER_PRINTER_MATERIAL: 'prosper_printer_material',
  PROSPER: 'prosper',
  CUSTOM_GROUP: 'custom-group',
  ROLE: 'role',
  CREATE_RUNS: 'create-runs',
  RUN_TRANSFORMATION: 'run-transformation',
  RUN_QUEUE: 'run-queue',
  RUN: 'run',
  RUN_ACTUALS: 'run-actuals',
  USER_SSO: 'user_sso',
  SCHEDULE_RUNS: 'schedule_runs',
  SCHEDULE_BUILD_FILE_LIBRARY: 'build-file-library-produce',
  SERVICE_PROVIDER: 'service-provider',
  SERVICE_PROVIDER_JOB: 'service-provider-job',
  SESSIONS: 'sessions',
  SHIPMENT: 'shipment',
  SHIPPING: 'shipping',
  SPECIMEN: 'specimen',
  STOCK: 'stock',
  SUPPORT_STRATEGY: 'support_strategy',
  TOOLING_LINK: 'tool-link',
  TOOLING_STOCK: 'tooling-stock',
  TOOLING_STOCK_TYPE: 'tooling-type',
  TOOLING_STOCK_ACTION: 'tooling-stock-action',
  TRACEABILITY_REPORT: 'traceability-report',
  USERS: 'users',
  WORKFLOW: 'workflow',
  WORKSTATION: 'workstation',
  WORK_CHECKLIST_LINKING: 'work-checklist-linking',
  WORK_INSTRUCTION: 'work-instruction',
  WORK_INSTRUCTION_REPORT: 'work-instruction-report',
  NEBUMIND_ANALYSIS: 'nebumind-analysis',
  ZVERSE_CONVERSION: 'zverse-conversion', // Used for 3d party API for Model conversions on the backend
  EXPERT_CONVERSION: 'expert-conversion', // Used for Model conversions by expert on the backend
  // Temporary resource. Will removed once functionality is no longer needed
  ANATOMICAL_MODEL: 'anatomical-model',
  ANATOMICAL_MODEL_ASSEMBLY: 'anatomical-model-assembly',
  ANATOMICAL_MODEL_PART: 'anatomical-model-part',
  WORK_SCHEDULE: 'work-schedule',
  LINE_ITEM_WORKSTEP_ESTIMATE: 'line-item-workstep-estimate',
  LINE_ITEM_WORKSTEP_COST_ESTIMATE: 'line-item-workstep-cost-estimate',
  SAMPLE: 'sample',
  RUN_MATERIAL: 'run-material',
  STOCK_SUMMARY: 'stock-summary/sub-location',
  SHOPPING_CART: 'shopping-cart',
  SHOPPING_CART_ITEM: 'shopping-cart-item',
  CAROUSEL: 'carousel',
  SENSOR: 'sensor',
  SENSOR_DATA: 'sensor-data',
  PAYMENT: 'payment',
  MATERIAL_BATCH_SENSOR_DATA: 'material-batch-sensor-data',
  COMMAND: 'command',
  JENI_CLUSTER: 'jeni-cluster',
  JENI_COMMAND: 'jeni-command',
  SLICER_CONFIGURATION_RAW: 'slicer-config/raw',
  SLICED_FILE: 'sliced-file',
  MACHINE_JOB_FILE: 'machine-job-file',
  SLICE_TESTING: 'slice',
  SECURE_FILE_CHECKOUT: 'secure-file-checkout',
  SECURE_CHECKOUT_DIRECTORY: 'secure-checkout-directory',
  INTEGRATION: 'integration',
  MODELER_PRINTING_PRESET: 'modeler-printing-preset',
  ZIPFILE: 'zipfile',
  ZIPFILE_REQUEST: 'zipfile-request',
  THREADS_MESSAGES: 'threads-messages',
  BUREAU_NOTIFICATION: 'bureau-notification',
};

// !!!ATTENTION!!! This constant is not supposed to be used separately. Use API_RESOURCES constant instead
// Custom non-entity endpoints should not be included into Entity types list
export const NON_ENTITY_API_RESOURCES = {
  // Material-genealogy is not a real resources, but more a `statistical` one used for Genealogy Tree
  MATERIAL_GENEALOGY: 'material-genealogy',

  WORK_CHECKLISTS_FOR: 'work-checklists-for',

  WORK_CHECKLISTS_FOR_RESOURCE: 'work-checklists-for-resource',

  // Service Provider Roles uses custom endpoints to get line items and orders data
  SERVICE_PROVIDER_V1_LINE_ITEM: 'service-provider/v1/line-item',
  SERVICE_PROVIDER_V1_ORDER: 'service-provider/v1/order',

  OAUTH_TOKEN_AUTODESK_FORGE: 'oauth/token/autodesk-forge',

  // Used to get list of Line Items with prints available for Run creation on Run New page
  LINE_ITEM_WITH_AVAILABLE_FOR_RUN_PRINTS:
    'line-item-with-available-for-run-prints',

  // Permissions system check data
  // (based on Custom Group, but with extended bureau permissions check, see BE implementation for more details)
  ACCESS_INFO_FOR_RESOURCE: 'access-info-for-resource',
  CSRF_TOKEN: 'csrf-token',
  BULK_DELETE_PRODUCT: 'bulk-delete-product',
};

export const GUIDELINE_API_RESOURCES = {
  NAMED_EXPRESSION: 'guideline/named-expression',
  FOR_WORK_INSTRUCTION: 'guideline/for-work-instruction',
  FOR_PROCESS_STEP: 'guideline/for-process-step',
  FOR_LINE_ITEM: 'guideline/for-line-item',
  EXPRESSION: 'guideline/expression',
  COMPARATOR: 'guideline/comparator',
};

export const MATERIAL_TEST_API_RESOURCES = {
  MATERIAL_TEST_OPERATION: 'material-test/operation',
  MATERIAL_TEST_OPERATION_LINKING: 'material-test/operation-linking',
  MATERIAL_TEST_OPERATION_WORK_CHECKLISTS_FOR_MATERIAL:
    'material-test/work-checklists-for/material',
  MATERIAL_TEST_OPERATION_WORK_CHECKLISTS_FOR_PANEL:
    'material-test/work-checklists-for/material-test-panel',
  MATERIAL_TEST_INSTRUCTION: 'material-test/instruction',
  MATERIAL_TEST_INSTRUCTION_REPORT: 'material-test/instruction-report',
  MATERIAL_TEST_UNIT: 'material-test/unit',
  MATERIAL_TEST_PANEL: 'material-test/panel',
};

export const MSNAV_API_RESOURCES = {
  MSNAV_IMPORT_FILE: 'msnav-import/file',
  MSNAV_IMPORT_WORKFLOW: 'msnav-import/workflow',
  MSNAV_IMPORT_MSNAV_WORKFLOW_TO_WORKFLOW:
    'msnav-import/msnav-workflow-to-workflow',
  MSNAV_IMPORT_FILE_TO_ORDER: 'msnav-import/file-to-order',
};

export const API_RESOURCES = {
  ...ENTITY_API_RESOURCES,
  ...NON_ENTITY_API_RESOURCES,
  ...MATERIAL_TEST_API_RESOURCES,
  ...GUIDELINE_API_RESOURCES,
  ...MSNAV_API_RESOURCES,
};

export const ENTITY_TYPES = {
  // All Entity-related API resources are Entity types by default
  ...ENTITY_API_RESOURCES,

  // Used for all resources returned from server which are not `real` entities
  NONE: 'none',
};

export const BUILD_PACKER_TYPES = {
  PACK2D: 'pack2d',
  PACK3D: 'pack3d',
  PACK3D_PREMIUM: 'pack3d_premium',
  USER_MANAGED: 'user_managed',
};

export const PRIORITIES = {
  LOW: 0,
  NORMAL: 65,
  HIGH: 75,
  CRITICAL: 95,
};

export const MATERIAL_BATCH_ACTION_TYPES = {
  MACHINE_LOAD: 'machine_load',
  BLEND_BATCHES: 'blend_batches',
  MACHINE_UNLOAD_CHAMBER: 'machine_unload_chamber',
  MACHINE_UNLOAD_HOPPER: 'machine_unload_hopper',
  SPLIT_BATCH: 'split_batch',
  ADD_CONTAINER: 'add_container',
  SCRAP_BATCH: 'scrap_batch',
  SIEVE: 'sieve',
  TEST: 'test',
  CONTAINERIZE_BATCH: 'containerize_batch',
  MACHINE_TOP_OFF: 'machine_top_off',
  CREATE_INITIAL_BATCH: 'create_initial_batch',
  EDIT_BATCH_QUANTITY: 'edit_batch_quantity',
  RELOCATE: 'relocate',
};

export const TOOLING_STOCK_ACTION_TYPES = {
  RELOCATE: 'relocate',
  RECORD_TESTS: 'record_tests',
  MAKE_READY_FOR_USE: 'make_ready_for_use',
  PUT_INTO_USE: 'put_into_use',
  COMPLETE_USE: 'complete_use',
  NEEDS_REFURBISH: 'mark_needs_refurbish',
  SENT_TO_REFURBISH: 'send_to_refurbish',
  COMPLETE_REFURBISH: 'complete_refurbish',
  DISCARD: 'discard',
};

export const TOOLING_STOCK_STATUSES = {
  NEW: 'new',
  IDLE: 'idle',
  IN_USE: 'in-use',
  NEEDS_REFURBISHMENT: 'needs-refurbishment',
  DOING_REFURBISHMENT: 'doing-refurbishment',
  REFURBISHED: 'refurbished',
  ARCHIVED: 'archived',
};

const TOOLING_STOCK_ALWAYS_ALLOWED_ACTIONS = [
  TOOLING_STOCK_ACTION_TYPES.RELOCATE,
  TOOLING_STOCK_ACTION_TYPES.RECORD_TESTS,
  TOOLING_STOCK_ACTION_TYPES.DISCARD,
];

export const TOOLING_STOCK_VENDOR_DISALLOWED_ACTIONS = [
  TOOLING_STOCK_ACTION_TYPES.RELOCATE,
  TOOLING_STOCK_ACTION_TYPES.MAKE_READY_FOR_USE,
  TOOLING_STOCK_ACTION_TYPES.PUT_INTO_USE,
  TOOLING_STOCK_ACTION_TYPES.COMPLETE_USE,
  TOOLING_STOCK_ACTION_TYPES.NEEDS_REFURBISH,
  TOOLING_STOCK_ACTION_TYPES.SENT_TO_REFURBISH,
];

export const TOOLING_STOCK_ALLOWED_ACTIONS = {
  [TOOLING_STOCK_STATUSES.NEW]: [
    TOOLING_STOCK_ACTION_TYPES.MAKE_READY_FOR_USE,
    ...TOOLING_STOCK_ALWAYS_ALLOWED_ACTIONS,
  ],
  [TOOLING_STOCK_STATUSES.IDLE]: [
    // PUT_INTO_USE disabled for rapidfab
    TOOLING_STOCK_ACTION_TYPES.NEEDS_REFURBISH,
    ...TOOLING_STOCK_ALWAYS_ALLOWED_ACTIONS,
  ],
  [TOOLING_STOCK_STATUSES.IN_USE]: [
    TOOLING_STOCK_ACTION_TYPES.COMPLETE_USE,
    ...TOOLING_STOCK_ALWAYS_ALLOWED_ACTIONS,
  ],
  [TOOLING_STOCK_STATUSES.NEEDS_REFURBISHMENT]: [
    TOOLING_STOCK_ACTION_TYPES.SENT_TO_REFURBISH,
    ...TOOLING_STOCK_ALWAYS_ALLOWED_ACTIONS,
  ],
  [TOOLING_STOCK_STATUSES.DOING_REFURBISHMENT]: [
    TOOLING_STOCK_ACTION_TYPES.COMPLETE_REFURBISH,
    ...TOOLING_STOCK_ALWAYS_ALLOWED_ACTIONS,
  ],
  [TOOLING_STOCK_STATUSES.REFURBISHED]: [
    TOOLING_STOCK_ACTION_TYPES.MAKE_READY_FOR_USE,
    ...TOOLING_STOCK_ALWAYS_ALLOWED_ACTIONS,
  ],
  [TOOLING_STOCK_STATUSES.ARCHIVED]: [],
};

export const PERMANENT_CONTAINER_ACTION_TYPES = {
  LOAD_MATERIAL: 'load_container',
  TOP_OFF: 'top_off_container',
  TRANSFER_MATERIAL: 'transfer_container',
  UNLOAD_MATERIAL: 'unload_container',
  RELOCATE: 'relocate_container',
};

export const MATERIAL_LOT_ACTION_TYPES = {
  ADD_CONTAINERS: 'add_containers',
  EDIT_CONTAINERS_QUANTITY: 'edit_containers_quantity',
  DELETE_CONTAINERS: 'delete_containers',
  RECEIVE_LOT: 'receive_lot',
};

export const RECORD_SOURCES = {
  // event is loaded via List/Get API request, or created/updated via Post/Put request.
  API: 'api',
  // Event is fetched by `*.*.created` event-stream event
  EVENT_STREAM: 'event-stream',
};

export const ACCESS_INFO_ACTION_TYPES = {
  EDIT: 'edit', // PUT request
  DELETE: 'delete', // DELETE request
  USE: 'use', // using an entity URI in other entity field. E.g. `process_step.workstation_type`, etc.
  // Edge-case actions
  REDO: 'redo', // `redo` action is a Run transformation on some/all of Run.Prints provided for a Run resource
  REMANUFACTURE: 'remanufacture', // `remanufacture` is a Run transformation action, provided on a piece resource
  CHANGE_WORKFLOW: 'change_workflow', // `change workflow` is a Run transformation action, provided on a piece resource
};

// Resources for the Threads backend
export const THREADS_API_RESOURCES = {
  AUTH_LOGIN: 'auth/login',
  THREADS: 'threads',
  THREAD: 'thread',
};

export const EVENT_STREAM_SELECTOR_RESOURCES = {
  MSNAV: 'msnav',
  PAYMENT: 'payment',
  SLICER: 'slice',
  BUILD_FILE: 'build-file',
};
