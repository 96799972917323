import { faClipboard, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Card, Row, Button } from 'react-bootstrap';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';
import { MATERIAL_BATCH_STATUSES, ROUTES } from 'rapidfab/constants';
import { MATERIAL_BATCH_STATUS_MAP, MATERIAL_LOT_STATUS_MAP } from 'rapidfab/mappings';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import Table from 'rapidfab/components/Tables/Table';
import _keyBy from 'lodash/keyBy';
import { DebugModeBadge } from 'rapidfab/components/DebugMode/DebugModeComponents';

const MaterialBatchAndLotUIContainer = ({
  containers,
  locations,
  subLocations,
  currentType,
  isDebugModeEnabled,
}) => {
  const routeData = currentType.toLowerCase() === 'batch' ? ROUTES.MATERIAL_BATCH : ROUTES.MATERIAL_LOT;
  const currentRoute = currentUUID =>
    getRouteURI(routeData, { uuid: containers.find(currentContainer => currentContainer.uuid === currentUUID).uuid, container: '' }, {}, true);
  const currentItem = currentUUID => {
    if (currentType === 'lot') {
      return containers.find(item => item.uuid === currentUUID);
    }
    return null;
  };

  const getDebugModeRowVisibility = batch => (
    !batch.at_machine
    && batch.quantity > 0
    && batch.status !== MATERIAL_BATCH_STATUSES.DONE);

  const columns = [
    {
      type: 'custom',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      Cell: ({ value }) => {
        const href = currentRoute(value);
        const item = currentItem(value);
        const currentID = value && getShortUUID(value);
        const title = item ? `${item?.name} (${currentID})` : currentID;

        return (
          <div className="d-flex">
            <Link
              to={href}
              className="pull-right"
            > {title}
            </Link>
          </div>
        );
      },
    },
    {
      type: 'translatable',
      uid: 'field.status',
      accessor: 'status',
      defaultMessage: 'Status',
      mapping: currentType === 'lot' ? MATERIAL_LOT_STATUS_MAP : MATERIAL_BATCH_STATUS_MAP,
      isSortable: true,
    },
    {
      type: 'suffixed',
      uid: 'field.originalQuantity',
      accessor: 'full_quantity',
      defaultMessage: 'Original Quantity',
      suffix: 'units',
      isSortable: true,
    },
    {
      type: 'suffixed',
      uid: 'field.currentQuantity',
      accessor: 'quantity',
      defaultMessage: 'Current Quantity',
      suffix: 'units',
      isSortable: true,
    },
    currentType === 'lot' ?
      {
        type: 'time',
        uid: 'field.actualDeliveryDate',
        accessor: 'actual_delivery_date',
        defaultMessage: 'Delivered',
        isSortable: true,
      } :
      {
        type: 'time',
        uid: 'created',
        accessor: 'created',
        defaultMessage: 'Created',
        isSortable: true,
        isManuallySorted: true,
      },
  ];

  const debugModeColumns = [
    {
      debugMode: true,
      type: 'custom',
      uid: 'is_visible',
      accessor: 'is_visible',
      defaultMessage: 'Is Visible',
      Header: () => ([
        <DebugModeBadge />,
        <span>Is Visible</span>,
      ]),
      Cell: ({ row: { original: resource } }) => (getDebugModeRowVisibility(resource) ? 'Yes' : 'No'),
    },
    currentType === 'batch' && {
      debugMode: true,
      type: 'bool',
      uid: 'at_machine',
      accessor: 'at_machine',
      defaultMessage: 'At Machine',
    },
    {
      debugMode: true,
      type: 'resource',
      uid: 'field.location',
      accessor: 'location',
      defaultMessage: 'Location',
      resource: 'location',
      resources: _keyBy(locations, 'uri'),
      isSortable: true,
    },
    {
      debugMode: true,
      type: 'resource',
      uid: 'field.sub_location',
      accessor: 'sub_location',
      defaultMessage: 'Sub-Location',
      resource: 'sub-location',
      resources: _keyBy(subLocations, 'uri'),
      isSortable: true,
    },
  ];

  if (isDebugModeEnabled) {
    debugModeColumns.forEach(debugModeCol => columns.push(debugModeCol));
  }

  let panelType = currentType[0].toUpperCase() + currentType.slice(1, currentType.length);

  if (currentType === 'batch') {
    panelType += 'es';
  } else {
    panelType += 's';
  }

  if (!containers?.length) {
    return null;
  }

  return (
    <Col>
      <Card bg="dark" className="mt30">
        <Card.Header className="pd-exp inverse">
          <div className="d-flex align-items-center justify-content-between">
            <p className="mb0">{`Active Material ${panelType}`}</p>
            {
              currentType === 'lot' && (
                <div>
                  <Button
                    variant="info"
                    size="sm"
                    href={getRouteURI(
                      ROUTES.MATERIAL_LOT_NEW, null, { isOrderMaterialLot: true },
                    )}
                    className="pull-right spacer-right"
                  >
                    <FontAwesomeIcon icon={faClipboard} />{' '}
                    Order Material Lot
                  </Button>
                  <Button
                    variant="primary"
                    size="sm"
                    href={getRouteURI(ROUTES.MATERIAL_LOT_NEW)}
                    className="pull-right spacer-right"
                  >
                    <FontAwesomeIcon icon={faPlus} />{' '}
                    <FormattedMessage id="materialLot.add" defaultMessage="Add Material Lot" />
                  </Button>
                </div>
              )
            }
          </div>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col xs={{ span: 12 }}>
              <Table
                tableID="materialBatchAndLotUIContainer"
                data={containers}
                columns={columns}
                isFilteringEnabled={false}
                withDefaultPagination={false}
                isManualSoringEnabled={false}
                isUpdatedColumnShown={false}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
};

MaterialBatchAndLotUIContainer.defaultProps = {
  containers: [],
};

MaterialBatchAndLotUIContainer.propTypes = {
  containers: PropTypes.instanceOf(Array),
  currentType: PropTypes.string.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  value: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isDebugModeEnabled: PropTypes.bool.isRequired,
  subLocations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default MaterialBatchAndLotUIContainer;
