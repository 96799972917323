import dayjs from 'dayjs';
import _find from 'lodash/find';
import _has from 'lodash/has';
import _map from 'lodash/map';
import _mapValues from 'lodash/mapValues';
import _toNumber from 'lodash/toNumber';
import Actions from 'rapidfab/actions';
import SelectMultiple from 'rapidfab/components/forms/SelectMultiple';
import Runs from 'rapidfab/components/plan/runs';
import { API_RESOURCES } from 'rapidfab/constants';
import 'rapidfab/containers/plan/runs.scss';
import { FormattedMessage } from 'rapidfab/i18n';
import { RUN_OPERATION_MAP, RUN_STATUS_MAP } from 'rapidfab/mappings';
import * as Selectors from 'rapidfab/selectors';
import { classifyFilterURIs, getUpdatedFilters } from 'rapidfab/utils/filterUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormLabel } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Alert from 'rapidfab/utils/alert';
import { pluralWord } from 'rapidfab/utils/stringUtils';
import withRecordsListHandling from '../hocs/withRecordsListHandling';

// Define a mapping of resource keys below to their respective filter buckets
const WORKSTATION_NAME_RESOURCE_MAPPING = {
  [API_RESOURCES.PRINTER]: 'printer',
  [API_RESOURCES.POST_PROCESSOR]: 'post_processor',
};

const WORKSTATION_TYPE_RESOURCE_MAPPING = {
  [API_RESOURCES.PRINTER_TYPE]: 'printer_type',
  [API_RESOURCES.POST_PROCESSOR_TYPE]: 'post_processor_type',
  [API_RESOURCES.SHIPPING]: 'shipping',
};

/** Small hook to store filtered data and update on deps change. */
const useFilteredTabularData = initialData => {
  const [filteredData, setFilteredData] = useState(initialData);
  useEffect(() => setFilteredData(initialData), [initialData]);
  return { filteredData, setFilteredData };
};

const RunsContainer = (
  /** componentProps are sent to this component via withRecordsListHandling() */
  componentProps,
) => {
  const shippings = useSelector(Selectors.getShippings);
  const printerTypes = useSelector(Selectors.getPrinterTypes);
  const postProcessorTypes = useSelector(Selectors.getPostProcessorTypes);
  const runUris = useMemo(() => _map(componentProps.data, 'uri'), [componentProps.data]);
  const scheduledRunsByRunUri = useSelector(state => Selectors.getScheduledRunsByRunUri(state, runUris));

  const printers = useSelector(Selectors.getPrinters);
  const postProcessors = useSelector(Selectors.getPostProcessors);
  const isDebugModeEnabled = useSelector(Selectors.getIsDebugModeEnabled);

  const fetchingRuns = useSelector(state => state.ui.nautilus[API_RESOURCES.RUN].list.fetching);

  const workstationsByUri = useSelector(Selectors.getWorkstationsByUri);
  const workstations = [
    ...printers,
    ...postProcessors,
  ];

  const workstationTypes = [
    ...shippings,
    ...printerTypes,
    ...postProcessorTypes,
  ];

  const dispatch = useDispatch();

  const [selectedRunsForEdit, setSelectedRunsForEdit] = useState([]); // Marked/ticked runs for bulk-edit.
  const { onCustomFilterUpdate, onGroupingUpdate } = componentProps;
  const { filteredData, setFilteredData } = useFilteredTabularData(componentProps.data);

  const onInitialize = () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER_TYPE].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.SHIPPING].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].list());
  };
  useEffect(() => onInitialize(), []);

  const fetchScheduleRuns = () => {
    if (!fetchingRuns && runUris.length > 0) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.SCHEDULE_RUNS].list({
        run: runUris,
      }));
    }
  };
  useEffect(() => {
    fetchScheduleRuns();
  }, [fetchingRuns]);

  const onStatusChange = selectedStatuses => {
    const { onFilterUpdate, filters } = componentProps;
    const changedFilters = { ...filters };

    if (selectedStatuses.length) {
      changedFilters.status = _map(selectedStatuses, 'status');
    } else {
      delete changedFilters.status;
    }
    onFilterUpdate(changedFilters);
  };

  const onOperationChange = selectedOperations => {
    const { onFilterUpdate, filters } = componentProps;
    const changedFilters = { ...filters };

    if (selectedOperations.length) {
      changedFilters.operation = _map(selectedOperations, 'operation');
    } else {
      delete changedFilters.operation;
    }
    onFilterUpdate(changedFilters);
  };

  const onWorkstationTypeChange = selectedWorkstationTypes => {
    const { onFilterUpdate, filters } = componentProps;

    // Classify each URI into the correct filter bucket
    const filterBuckets = classifyFilterURIs(selectedWorkstationTypes, WORKSTATION_TYPE_RESOURCE_MAPPING);

    // Get the updated filters based on the bucket contents
    const updatedFilters = getUpdatedFilters(filterBuckets, filters);

    onFilterUpdate(updatedFilters);
  };

  const onWorkstationNameChange = selectedWorkstationNames => {
    const { onFilterUpdate, filters } = componentProps;

    const filterBuckets = classifyFilterURIs(selectedWorkstationNames, WORKSTATION_NAME_RESOURCE_MAPPING);
    const updatedFilters = getUpdatedFilters(filterBuckets, filters);

    onFilterUpdate(updatedFilters);
  };

  const convertTimeframeToDate = ({ value }) => {
    switch (value) {
      case 'nextWeek':
        return dayjs().add(7, 'day').endOf('day');
      case 'next2Weeks':
        return dayjs().add(14, 'day').endOf('day');
      case 'nextMonth':
        return dayjs().add(1, 'month').endOf('day');
      case 'nextDay':
        return dayjs().add(1, 'day').endOf('day');
        // Default corresponds to next day.
      default:
        return dayjs().add(1, 'day').endOf('day');
    }
  };

  const onTimeframeFilter = (runs, selectedTimeframe) => runs.filter(run => {
    const scheduledStart = dayjs(run?.estimate?.estimates?.start);
    const scheduledEnd = dayjs(run?.estimate?.estimates?.end);
    const now = dayjs();

    // Returned predicate: Scheduled start and scheduled end are within selected timeframe.
    return (
      scheduledStart.isAfter(now) && scheduledStart.isBefore(convertTimeframeToDate(selectedTimeframe)) &&
        scheduledEnd.isAfter(now) && scheduledEnd.isBefore(convertTimeframeToDate(selectedTimeframe))
    );
  });

  const onInlineExtraFilterChange = useCallback((fieldId, fieldValue) => {
    onCustomFilterUpdate(fieldId, fieldValue);
    // `value` will be the key in the list of resources (/runs/) that we wish to group by.
    if (fieldId === 'grouping') {
      onGroupingUpdate(fieldValue?.value?.length ? fieldValue : null);
    }
    if (fieldId === 'timeframe') {
      if (!fieldValue?.value) {
        // Value is null, reset the filtered data to initial.
        setFilteredData(componentProps.data);
      } else {
        const updatedFilteredData = onTimeframeFilter(componentProps.data, fieldValue);
        setFilteredData(updatedFilteredData ?? null);
      }
    }
  }, [filteredData, componentProps.data]);

  /** Submit method for values are edited in multi-edit modal. */
  const onUpdateRun = async values => {
    // eslint-disable-next-line no-restricted-syntax
    for await (const selectedRun of selectedRunsForEdit) {
      // User has submitted new values for run schedule.
      if (_has(values, 'start') || _has(values, 'end')) {
        const payload = {
          estimates: {
            start: dayjs(values.start).toISOString(),
            end: dayjs(values.end).toISOString(),
          },
        };
        dispatch(Actions.Api.nautilus[API_RESOURCES.SCHEDULE_RUNS]
          .put(extractUuid(scheduledRunsByRunUri[selectedRun.uri].uri), payload));
      }
      const payload = {
        workstation_uri: values.workstation_uri,
        workstation_queue_position: _toNumber(values.workstation_queue_position),
      };
      await dispatch(Actions.Api.nautilus[API_RESOURCES.RUN].put(extractUuid(selectedRun.uri), payload));
    }
    Alert.success(`Successfully updated ${pluralWord('run', selectedRunsForEdit)}.`);
  };

  // Prepare options list based on run statuses for SelectMultiple
  const availableStatuses = _mapValues(RUN_STATUS_MAP, (statusOption, status) => ({
    label: statusOption.defaultMessage,
    status,
  }));

  // Prepare options list based on run statuses for SelectMultiple
  const availableOperations = _mapValues(RUN_OPERATION_MAP, (operationOption, operation) => ({
    label: operationOption.defaultMessage,
    operation,
  }));

  // Prepare options list based on Workstation Names for SelectMultiple
  const availableWorkstationNames = _map(workstations, workstation => ({
    label: workstation.name,
    value: workstation.uri,
  }));

  // Prepare options list based on Workstation Types for SelectMultiple
  const availableWorkstationTypes = _map(workstationTypes, workstation => ({
    label: workstation.name,
    value: workstation.uri,
  }));

  const {
    status: statuses = [],
    operation: operations = [],
    printer: printerNames = [],
    post_processor: postProcessorNames = [],
    printer_type: printerTypesFilters = [],
    post_processor_type: postProcessorTypesFilters = [],
    shipping: shippingFilters = [],
  } = componentProps.filters;

  const allWorkstationNames = [...printerNames, ...postProcessorNames];
  const allWorkstationTypes = [...shippingFilters, ...printerTypesFilters, ...postProcessorTypesFilters];

  // Converting array of string to options list for SelectMultiple to work properly
  const selectedStatuses = _map(statuses, status => availableStatuses[status]);
  const selectedOperations = _map(operations, operation => availableOperations[operation]);
  const selectedWorkstationNames = _map(allWorkstationNames, dataUri =>
    _find(availableWorkstationNames, { value: dataUri }),
  );
  const selectedWorkstationTypes = _map(allWorkstationTypes, dataUri =>
    _find(availableWorkstationTypes, { value: dataUri }),
  );

  const GROUPING_OPTIONS = [
    { value: null, groupDisplayLabelField: null, label: 'All', selected: [] },
    { value: 'workstation_uri', groupDisplayLabelField: 'name', label: 'Workstation Queue', selected: workstationsByUri },
  ];

  // Omitted for release (SEPT-2024)
  // :tech-debt: Refactor withRecordsListHandling filter to B/E.
  // const TIMEFRAME_OPTIONS = [
  //   { value: null, label: 'All' },
  //   { value: 'nextDay', label: 'Next Day' },
  //   { value: 'nextWeek', label: 'Next Week' },
  //   { value: 'next2Weeks', label: 'Next 2 Weeks' },
  //   { value: 'nextMonth', label: 'Next Month' },
  // ];
  // End of omit.

  const inlineExtraFilters = [
    {
      options: GROUPING_OPTIONS,
      fieldId: 'grouping',
      label: 'Group by',
    },
    // Omitted for release (SEPT-2024)
    // :tech-debt: Refactor withRecordsListHandling filter to B/E.
    // {
    //   options: TIMEFRAME_OPTIONS,
    //   fieldId: 'timeframe',
    //   label: 'Timeframe',
    //   defaultValue: 'nextDay',
    // },
    // End of omit.
  ];

  const selected = {
    selectedRunsForEdit,
  };

  const dispatched = {
    setSelectedRunsForEdit,
    onUpdateRun,
  };

  return (
    <Runs
      {...componentProps}
      {...selected}
      {...dispatched}
      data={filteredData}
      scheduledRunsByRunUri={scheduledRunsByRunUri}
      isDebugModeEnabled={isDebugModeEnabled}
      inlineExtraFilters={inlineExtraFilters}
      onInlineExtraFilterChange={onInlineExtraFilterChange}
      extraFilters={(
        <div className="form-inline" style={{ lineHeight: '40px' }}>
          <div className="form-group mr15">
            <FormLabel htmlFor="statusFilter">
              Status:
            </FormLabel>
            <div className="spacer-left form-control inline-picky-wrapper">
              <SelectMultiple
                title="Status"
                className="run-status__selector"
                data={Object.values(availableStatuses)}
                labelKey="label"
                valueKey="status"
                selectedData={selectedStatuses}
                handleOnClose={onStatusChange}
              />
            </div>
          </div>

          <div className="form-group mr15">
            <FormLabel htmlFor="operation" className="mb0">
              <FormattedMessage id="field.operation" defaultMessage="Operation" />:
            </FormLabel>
            <div className="spacer-left form-control inline-picky-wrapper">
              <SelectMultiple
                name="operation"
                title="Operation"
                className="operation__selector"
                data={Object.values(availableOperations)}
                labelKey="label"
                valueKey="operation"
                selectedData={selectedOperations}
                handleOnClose={onOperationChange}
              />
            </div>
          </div>

          <div className="form-group mr15">
            <FormLabel htmlFor="workstation_type" className="mb0">
              <FormattedMessage id="workstation.type" defaultMessage="Workstation Type" />:
            </FormLabel>
            <div className="spacer-left form-control inline-picky-wrapper-full">
              <SelectMultiple
                name="workstation_type"
                title="All"
                className="workstation-type__selector"
                data={Object.values(availableWorkstationTypes)}
                labelKey="label"
                valueKey="value"
                selectedData={selectedWorkstationTypes}
                handleOnClose={onWorkstationTypeChange}
              />
            </div>
          </div>

          <div className="form-group mr15">
            <FormLabel htmlFor="workstation_name" className="mb0">
              <FormattedMessage id="workstation.name" defaultMessage="Workstation Name" />:
            </FormLabel>
            <div className="spacer-left form-control inline-picky-wrapper-full">
              <SelectMultiple
                name="workstation_name"
                title="All"
                className="workstation-name__selector"
                data={Object.values(availableWorkstationNames)}
                labelKey="label"
                valueKey="value"
                selectedData={selectedWorkstationNames}
                handleOnClose={onWorkstationNameChange}
              />
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default withRecordsListHandling(
  RunsContainer,
  Selectors.getRuns,
  ['run'],
);
