import MaterialTypeModalContainer from 'rapidfab/containers/modals/MaterialTypeModalContainer';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Actions from 'rapidfab/actions';
import { API_RESOURCES, FEATURES } from 'rapidfab/constants';
import isFetchingInitial from 'rapidfab/utils/isFetchingInitial';
import * as Selectors from 'rapidfab/selectors';

import MaterialsComponent from 'rapidfab/components/inventory/materials';
import { useSearchParams } from 'react-router-dom';

const MaterialsContainer = props => {
  const [searchParams] = useSearchParams();
  const uuid = searchParams.get('uuid');
  const bureau = useSelector(Selectors.getBureauUri);
  const fetching = useSelector(state =>
    isFetchingInitial(
      state.ui.nautilus[API_RESOURCES.MANUFACTURER].list,
      state.ui.nautilus[API_RESOURCES.MATERIAL].list,
    ));
  const materials = useSelector(Selectors.getMaterials);
  const manufacturers = useSelector(Selectors.getManufacturers);
  const externalMaterialDatabaseFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.EXTERNAL_MATERIAL_DB));

  const [isCreateMaterialTypeModalShown, setIsCreateMaterialTypeModalShown] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const apiParams = [
      {}, // page
      {}, // searchParams
      {}, // queryParams
      true, // forced
    ];
    dispatch(Actions.Api.nautilus[API_RESOURCES.MANUFACTURER].list({}, ...apiParams));
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].list({ bureau }, ...apiParams));
  }, []);

  return (
    <>
      <MaterialsComponent
        {...props}
        fetching={fetching}
        materials={materials}
        manufacturers={manufacturers}
        externalMaterialDbFeatureEnabled={externalMaterialDatabaseFeatureEnabled}
        setIsCreateMaterialTypeModalShown={setIsCreateMaterialTypeModalShown}
      />
      {(isCreateMaterialTypeModalShown || uuid) && (
        <MaterialTypeModalContainer
          isVisible={isCreateMaterialTypeModalShown}
          hideModal={() => setIsCreateMaterialTypeModalShown(false)}
        />
      )}
    </>

  );
};

export default MaterialsContainer;
