import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  FormGroup,
  FormLabel,
  Button,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'rapidfab/i18n';
import Loading from 'rapidfab/components/Loading';
import ResourceReadOnlyView from 'rapidfab/components/ResourceReadOnlyView/ResourceReadOnlyView';
import ResourceReadOnlyViewRow, { RESOURCE_READ_ONLY_VIEW_FIELD_TYPES } from 'rapidfab/components/ResourceReadOnlyView/ResourceReadOnlyViewRow';
import LabelsInput from 'rapidfab/components/records/model_library/LabelsInput';
import { FormControlTextArea } from 'rapidfab/components/formTools';
import { finalFormInputTypes, modelLibraryType, modelType } from 'rapidfab/types';
import { getUUIDResource, isFeatureEnabled } from 'rapidfab/selectors';
import { MODEL_STATUS_MAP } from 'rapidfab/mappings';
import { extractUuid } from 'rapidfab/reducers/makeApiReducers';
import { FEATURES, XEROX_MODEL_LIBRARY_CUSTOM_FIELDS } from 'rapidfab/constants';
import _find from 'lodash/find';
import CustomFieldComponent from 'rapidfab/components/forms/CustomFieldComponent';

import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { MODEL_LIBRARY_DETAILS_PANEL } from 'rapidfab/constants/forms';
import TableCellLabels from 'rapidfab/components/TableCellLabels';
import { faEdit, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import Feature from 'rapidfab/components/Feature';

const ModelLibraryDetailsPanel = ({
  modelLibrary,
  labelsByUri,
  onUpdate,
  model,
  isHawkingUser,
  initialFormValues,
  findCustomFieldReference,
  customFieldValues,
  onCustomFieldChange,
  isModelDataEditable,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const onCancelEdit = () => {
    setIsEditing(false);
  };

  const partNumberFieldReference =
    findCustomFieldReference(XEROX_MODEL_LIBRARY_CUSTOM_FIELDS.PART_NUMBER);

  const partNumberFieldFieldValue = _find(
    customFieldValues,
    ({ custom_field }) => partNumberFieldReference && custom_field === partNumberFieldReference.uri,
  );

  const onSubmit = async formValues => {
    setIsSaving(true);
    try {
      // eslint-disable-next-line no-param-reassign
      formValues.custom_field_values = customFieldValues;
      await onUpdate(formValues);
      setIsEditing(false);
      setIsSaving(false);
    } catch {
      setIsSaving(false);
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialFormValues}
      render={({ handleSubmit, form }) => (
        <form id="details" onSubmit={handleSubmit}>
          <Card className="m-b" bg="light" data-cy="model-details">
            <Card.Header className="pd-exp">
              <div className="d-flex justify-content-between align-items-center">
                {isHawkingUser ? (
                  <FormattedMessage
                    id="record.modelLibrary.productDetails"
                    defaultMessage="Product Details"
                  />
                ) : (
                  <FormattedMessage
                    id="record.modelLibrary.modelDetails"
                    defaultMessage="Model Details"
                  />
                )}
                {isSaving ? <Loading /> : (isEditing
                  ? (
                    <div>
                      <Button

                        size="xs"
                        className="btn-default mr15"
                        onClick={() => {
                          form.reset();
                          onCancelEdit();
                        }}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </Button>
                      <Button form="details" size="xs" className="btn-primary" type="submit">
                        <FontAwesomeIcon icon={faSave} />
                      </Button>
                    </div>
                  )
                  : (
                    isModelDataEditable && (
                      <Button data-cy="model-details-edit-btn" size="xs" className="btn-default" onClick={() => setIsEditing(true)}>
                        <FontAwesomeIcon icon={faEdit} />
                      </Button>
                    )
                  ))}
              </div>
            </Card.Header>
            <Card.Body>
              {isEditing
                ? (
                  <div>
                    {partNumberFieldReference && (
                      <FormGroup>
                        <CustomFieldComponent
                          reference={partNumberFieldReference}
                          value={partNumberFieldFieldValue && partNumberFieldFieldValue.value}
                          onChange={onCustomFieldChange}
                          variant={CustomFieldComponent.variants.row}
                        />
                      </FormGroup>
                    )}
                    <FormGroup controlId="mlNotes">
                      <FormLabel>
                        <FormattedMessage
                          id={isHawkingUser ? 'field.description' : 'field.notes'}
                          defaultMessage={isHawkingUser ? 'Description' : 'Notes'}
                        />:
                      </FormLabel>
                      <FormControlTextArea name="notes" initialValue={initialFormValues?.notes} />
                    </FormGroup>
                    <FormGroup controlId="mlLabels">
                      <FormLabel>
                        <FormattedMessage id="field.labels" defaultMessage="Labels" />:
                      </FormLabel>
                      <Field
                        name="labels"
                        render={props => (
                          <>
                            <Feature featureName="experiment-alpha">
                              <LabelsInput.New
                                field={{
                                  ...props.meta,
                                  ...props.input,
                                }}
                              />
                            </Feature>
                            <Feature featureName="experiment-alpha" isInverted>
                              <LabelsInput.Old
                                field={{
                                  ...props.meta,
                                  ...props.input,
                                }}
                              />
                            </Feature>
                          </>
                        )}
                      />
                    </FormGroup>
                  </div>
                )
                : (
                  <ResourceReadOnlyView entity={modelLibrary}>
                    {partNumberFieldReference && (
                      <ResourceReadOnlyViewRow
                        name="part_number"
                        type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                        customValue={(
                          partNumberFieldFieldValue?.value
                            ? (
                              <span>{partNumberFieldFieldValue.value}</span>
                            ) : (
                              <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                            )
                        )}
                      />
                    )}
                    <ResourceReadOnlyViewRow
                      name="notes"
                      label={(
                        <FormattedMessage
                          id={isHawkingUser ? 'field.description' : 'field.notes'}
                          defaultMessage={isHawkingUser ? 'Description' : 'Notes'}
                        />
                      )}
                    />
                    <ResourceReadOnlyViewRow
                      name="labels"
                      type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                      customValue={(
                        <TableCellLabels
                          labelUris={modelLibrary.labels}
                          labelsByUri={labelsByUri}
                        />
                      )}
                    />
                    {
                      !isHawkingUser && (
                        <ResourceReadOnlyViewRow
                          name="status"
                          type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                          customValue={(
                            model
                              ? (
                                <FormattedMessage
                                  {...MODEL_STATUS_MAP[model.status]}
                                />
                              ) : (
                                <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                              )
                          )}
                        />
                      )
                    }
                  </ResourceReadOnlyView>
                )}
            </Card.Body>
          </Card>
        </form>
      )}
    />
  );
};
ModelLibraryDetailsPanel.defaultProps = {
  model: null,
  customFieldValues: [],
};
ModelLibraryDetailsPanel.propTypes = {
  modelLibrary: modelLibraryType.isRequired,
  labelsByUri: PropTypes.objectOf(PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string,
  })).isRequired,
  onUpdate: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  model: modelType,
  input: finalFormInputTypes.isRequired,
  meta: PropTypes.shape({}).isRequired,
  isHawkingUser: PropTypes.bool.isRequired,
  initialFormValues: PropTypes.shape({
    notes: PropTypes.string,
  }).isRequired,
  findCustomFieldReference: PropTypes.func.isRequired,
  customFieldValues: PropTypes.arrayOf(PropTypes.shape({})),
  onCustomFieldChange: PropTypes.func.isRequired,
  isModelDataEditable: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { modelLibrary } = ownProps;
  const model = getUUIDResource(state, extractUuid(modelLibrary.additive.model));
  const isHawkingUser = isFeatureEnabled(state, FEATURES.HAWKING_DEPLOYMENT) ||
    isFeatureEnabled(state, FEATURES.AUTHENTISE_PDM);

  const isStanleyXUser = isFeatureEnabled(state, FEATURES.STANLEY_X_DEPLOYMENT);

  const initialFormValues = {};
  Object
    .keys(modelLibrary)
    .filter(key => MODEL_LIBRARY_DETAILS_PANEL.FIELDS.includes(key))
    .forEach(key => {
      initialFormValues[key] = modelLibrary[key];
    });

  return {
    initialFormValues,
    model,
    isHawkingUser,
    isStanleyXUser,
  };
};

export default connect(mapStateToProps)(ModelLibraryDetailsPanel);
