import { faPlus, faBoxArchive } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from 'rapidfab/components/Loading';
import TableWithSearching from 'rapidfab/components/Tables/TableWithSearching';
import React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { TOOLING_STOCK_STATUSES_MAP } from 'rapidfab/mappings';
import { Link } from 'react-router-dom';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { ROUTES } from 'rapidfab/constants';

const NavbarLinks = ({ isVendorUser, showModal, showArchived, setShowArchived }) => (
  <div className="pull-right d-flex">
    <Button
      variant="primary"
      size="sm"
      className="pull-right spacer-right"
      onClick={setShowArchived}
    >
      <FontAwesomeIcon icon={faBoxArchive} />{' '}
      <FormattedMessage
        id={
          showArchived
            ? 'record.tooling_stock.hide_archived'
            : 'record.tooling_stock.show_archived'
        }
        defaultMessage={
          showArchived ? 'Hide Archived Tools' : 'Show Archived Tools'
        }
      />
    </Button>
    {!isVendorUser && (
      <Button
        variant="primary"
        size="sm"
        className="pull-right spacer-right"
        onClick={showModal}
      >
        <FontAwesomeIcon icon={faPlus} />{' '}
        <FormattedMessage
          id="record.tooling_stock.create"
          defaultMessage="Create Tool"
        />
      </Button>
    )}
  </div>
);

NavbarLinks.propTypes = {
  showModal: PropTypes.func.isRequired,
  showArchived: PropTypes.bool.isRequired,
  setShowArchived: PropTypes.func.isRequired,
  isVendorUser: PropTypes.bool.isRequired,
};

const ToolingStocks = props => {
  const {
    fetching,
    toolingStocks,
    stockTypes,
    locations,
    onSort,
    showModal,
    isVendorUser,
    filters,
    onFilterUpdate,
  } = props;
  const breadcrumbs = ['inventory', 'toolingStocks'];

  const columns = [
    {
      type: 'uuid',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      route: isVendorUser
        ? ROUTES.VENDOR_TOOLING_STOCK
        : ROUTES.TOOLING_STOCK_DETAIL,
    },
    {
      type: 'text',
      uid: 'field.name',
      accessor: 'name',
      defaultMessage: 'Name',
    },
    {
      type: 'custom',
      uid: 'field.type',
      accessor: 'type',
      defaultMessage: 'Type',
      isSortable: true,
      Cell: ({ row: { original: data } }) => {
        const stockType = stockTypes?.[data.type];
        return (
          <Link
            to={getRouteURI(
              isVendorUser
                ? ROUTES.VENDOR_TOOLING_STOCK_TYPES
                : ROUTES.TOOLING_STOCK_TYPES,
              null,
              { uuid: stockType?.uuid },
              true,
            )}
            replace
          >
            {stockType?.name ?? 'N/A'}
          </Link>
        );
      },
    },
    ...(isVendorUser
      ? []
      : [
        {
          type: 'resource',
          uid: 'field.location',
          accessor: 'location',
          defaultMessage: 'Location',
          resource: 'location',
          resources: locations,
          isSortable: true,
        },
      ]),
    {
      type: 'caps',
      uid: 'field.status',
      accessor: 'status',
      defaultMessage: 'Status',
      mapping: TOOLING_STOCK_STATUSES_MAP,
      isSortable: true,
    },
  ];

  return (
    <>
      {fetching ? (
        <Loading />
      ) : (
        <TableWithSearching
          {...props}
          isFetching={fetching}
          withBreadcrumbs
          showLocationsFilter={!isVendorUser}
          locations={Object.values(locations)}
          breadcrumbs={breadcrumbs}
          navbar={(
            <NavbarLinks
              isVendorUser={isVendorUser}
              showModal={showModal}
              showArchived={filters.include_archived}
              setShowArchived={() => (
                // Bug in endpoint where any value passed as filter param is read as true
                // So we need to pass empty filter params to work around that
                onFilterUpdate(
                  filters.include_archived ? {} : {
                    include_archived: true,
                  },
                )
              )}
            />
          )}
          data={toolingStocks}
          columns={columns}
          isFilteringEnabled={false}
          withDefaultPagination={false}
          isManualSoringEnabled
          manualSortingFunc={onSort}
          isUpdatedColumnShown={false}
          initialSortedDesc
        />
      )}
    </>
  );
};

ToolingStocks.propTypes = {
  fetching: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  stockTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  toolingStocks: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSort: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      type: PropTypes.string,
    }),
  }).isRequired,
  isVendorUser: PropTypes.bool.isRequired,
  filters: PropTypes.shape({
    include_archived: PropTypes.bool,
  }).isRequired,
  onFilterUpdate: PropTypes.func.isRequired,
};

export default ToolingStocks;
