import PropTypes from 'prop-types';
import SelectSingle from 'rapidfab/components/forms/SelectSingle';
import * as Selectors from 'rapidfab/selectors';
import { pluralWord } from 'rapidfab/utils/stringUtils';
import React from 'react';
import { Button, FormControl, FormGroup, FormLabel, Modal } from 'react-bootstrap';
import { Field, Form as ReactFinalForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import _map from 'lodash/map';
import { API_RESOURCES } from 'rapidfab/constants';
import { RUN_OPERATION_TO_TYPE_KEY_MAP } from 'rapidfab/mappings';
import { DateInput } from '../forms/DateInput';

/**
 * Modal UI displayed when user clicks to edit multiple run records.
 */
const RunMultiEditModal = ({ show, onClose, onConfirm, selectedRunsForEdit }) => {
  // Returns `post_processor_type`, `printer_type`, or `shipping`.
  const runAggregateWorkstationType = RUN_OPERATION_TO_TYPE_KEY_MAP[selectedRunsForEdit?.[0].operation];
  // Get first WS-type as all should be the same; validated in enclosing code.
  const workstationTypeURI = selectedRunsForEdit?.length && selectedRunsForEdit[0][runAggregateWorkstationType];
  const workstations = useSelector(state =>
    Selectors.getWorkstationsByWorkstationType(state, workstationTypeURI));
  const savingRuns = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.RUN].put.fetching ||
    state.ui.nautilus[API_RESOURCES.SCHEDULE_RUNS].put.fetching,
  );

  const renderSelectedRunsForEditAsList = () => {
    const max = 6;
    if (selectedRunsForEdit.length > max) {
      return (
        <ul>
          {_map(selectedRunsForEdit.slice(0, max), run => (
            <li key={run.uri}>{run.name}</li>
          ))}
          <li>and <strong>{selectedRunsForEdit.length - max}</strong> more...</li>
        </ul>
      );
    }
    return (
      <ul>
        {_map(selectedRunsForEdit, run => (
          <li key={run.uri}>{run.name}</li>
        ))}
      </ul>
    );
  };

  const validate = values => {
    const errors = {};
    if (values.start && !values.end) {
      errors.startEndTime = 'You must provide both a start and an end date if one of either field is filled.';
    }
    if (values.end && !values.start) {
      errors.startEndTime = 'You must provide both a start and an end date if one of either field is filled.';
    }
    return errors;
  };

  return (
    <ReactFinalForm
      onSubmit={onConfirm}
      validate={validate}
      render={({ handleSubmit, errors, invalid }) => (
        <Modal show={show} onHide={onClose}>
          <Modal.Header closeButton>Edit Runs</Modal.Header>
          <Modal.Body>
            <div className="m-b">
              The following changes will be applied to the following {pluralWord('run', selectedRunsForEdit)}:
              {selectedRunsForEdit && renderSelectedRunsForEditAsList()}
            </div>
            <div id="form-controls-container" style={{ gap: 15 }} className="d-flex flex-column">
              <Field
                name="workstation_uri"
                render={({ input }) => (
                  <FormGroup>
                    <FormLabel>
                      Workstation
                    </FormLabel>
                    <SelectSingle
                      name="workstation_uri"
                      data={workstations}
                      value={input.value}
                      handleOnChange={input.onChange}
                      imitateOnChangeEvent
                    />
                  </FormGroup>
                )}
              />
              {selectedRunsForEdit?.length === 1 && (
                <Field
                  name="workstation_queue_position"
                  render={({ input }) => (
                    <FormGroup>
                      <FormLabel>
                        Queue Position
                      </FormLabel>
                      <FormControl
                        type="number"
                        onChange={input.onChange}
                      />
                    </FormGroup>
                  )}
                />
              )}
              {selectedRunsForEdit?.length === 1 && (
                <div style={{ gap: 5 }} className="d-flex">
                  <Field
                    name="start"
                    render={({ input }) => (
                      <FormGroup
                        controlId="startDate"
                      >
                        <FormLabel>New Start Date</FormLabel>
                        <DateInput
                          name="startDate"
                          value={input.value}
                          onChange={input.onChange}
                          required
                        />
                      </FormGroup>
                    )}
                  />
                  <Field
                    name="end"
                    render={({ input }) => (
                      <FormGroup
                        controlId="endDate"
                      >
                        <FormLabel>New End Date</FormLabel>
                        <DateInput
                          name="endDate"
                          value={input.value}
                          onChange={input.onChange}
                          required
                        />
                      </FormGroup>
                    )}
                  />
                </div>
              )}
              {(errors.startEndTime) && (<span className="text-danger">{errors.startEndTime}</span>)}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" type="submit" onClick={onClose}>
              Cancel
            </Button>
            <Button disabled={savingRuns || invalid} type="submit" onClick={handleSubmit}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    />
  );
};

export default RunMultiEditModal;

RunMultiEditModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  selectedRunsForEdit: PropTypes.arrayOf(PropTypes.shape({
    post_processor_type: PropTypes.string,
    operation: PropTypes.string,
  })).isRequired,
};
