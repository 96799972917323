import BureauBarcodeIcon from 'rapidfab/components/BureauBarcodeIcon';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Col,
  FormControl,
  Form,
  Dropdown,
  Row,
  SplitButton,
  ProgressBar,
  Button,
  OverlayTrigger,
  InputGroup,
  Modal,
  Card,
} from 'react-bootstrap';

import Tooltip from 'rapidfab/components/Tooltip';

import Config from 'rapidfab/config';
import {
  FEATURES,
  RUN_MODEL_FILE_EXTENSIONS,
  ROUTES,
  RUN_STATUSES, RUN_OPERATIONS,
  ACCESS_INFO_ACTION_TYPES,
  PRIORITIES, ALLOWED_MODEL_EXTENSIONS, FILE_EXTENSIONS,
} from 'rapidfab/constants';
import { extractUuid, getShortUUID } from 'rapidfab/utils/uuidUtils';
import { FormattedDateTime, FormattedMessage } from 'rapidfab/i18n';
import { RUN_STATUS_MAP } from 'rapidfab/mappings';
import { RUN_STATUS_TRANSFORMATIONS } from 'rapidfab/transformations';
import FileInput from 'rapidfab/components/records/order/edit/FileInput';
import PriorityLabel from 'rapidfab/components/records/run/PriorityLabel';
import Feature from 'rapidfab/components/Feature';
import SaveButtonTitle from 'rapidfab/components/SaveButtonTitle';
import SelectSingle from 'rapidfab/components/forms/SelectSingle';
import { buildFileResourceType, secureCheckoutDirectoryResourceType } from 'rapidfab/types';
import DisabledByAccessInfoCheck from 'rapidfab/components/DisabledByAccessInfoCheck';
import Loading from 'rapidfab/components/Loading';
import { Link } from 'react-router-dom';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import RunModelDownload from 'rapidfab/components/records/run/RunModelDownload';
import Alert from 'rapidfab/utils/alert';
import { faBan, faCheck, faExternalLink, faPlus, faQuestionCircle, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import SlicedModelDownload from 'rapidfab/components/records/run/SlicedModelDownload';
import PressToPrintView from 'rapidfab/components/records/run/PressToPrintView';
import CustomFieldList from 'rapidfab/components/forms/CustomFieldList';
import getVisibleCustomFieldReferencesWithOptions from 'rapidfab/utils/getVisibleCustomFieldReferencesWithOptions';

const styles = {
  spacingBottom: { marginBottom: '15px' },
};

const AddCertifiedBuildToLibraryConfirmationModal = ({
  show,
  closeModal,
  onConfirm,
  printCount,
  runData,
}) => {
  const [enteredCertifiedBuildName, setEnteredCertifiedBuildName] = useState('');
  const validateEnteredCertifiedBuildName = name => {
    if (!name || name.length === 0) {
      Alert.error(
        <FormattedMessage
          id="toaster.error.certifiedBuilds.blankName"
          defaultMessage="Certified build name cannot be blank."
        />);
      return false;
    }

    if (name.length > 50) {
      Alert.error(
        <FormattedMessage
          id="toaster.error.certifiedBuilds.nameTooLong"
          defaultMessage="Certified build name is too long."
        />);
      return false;
    }

    return true;
  };

  return (
    <Modal show={show} onHide={closeModal} backdrop="static">
      <Modal.Header>Add to Library - &lsquo;{runData?.name}&rsquo;</Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to add this template run to the certified
          builds library?
        </p>
        <InputGroup
          className="my-2"
        >
          <Form.Control
            required
            placeholder="Certified build name"
            aria-label="certified-build-name"
            onChange={event => setEnteredCertifiedBuildName(event.target.value)}
          />
        </InputGroup>
        <div className="spacer-top">
          <Card bg="light">
            <Card.Header><strong>Pieces Included ({printCount})</strong></Card.Header>
          </Card>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={closeModal}>
          Close
        </Button>
        <Button
          variant="success"
          onClick={() => {
            if (validateEnteredCertifiedBuildName(enteredCertifiedBuildName)) {
              onConfirm(enteredCertifiedBuildName);
            }
          }}
        >
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AddCertifiedBuildToLibraryConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  printCount: PropTypes.number.isRequired,
  runData: PropTypes.shape({
    name: PropTypes.string,
    prints: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

const InfoRow = ({ children, id, defaultMessage, defaultSubtitleMessage, inputId, labelSuffix }) => (
  <Row style={styles.spacingBottom}>
    <Col xs={3}>
      <label className="control-label--text-left control-label" htmlFor={inputId}>
        <span>
          <FormattedMessage id={id} defaultMessage={defaultMessage} />:
          {labelSuffix && (
            <span>
            &nbsp;
              {labelSuffix}
            </span>
          )}
        </span>
        <p>{defaultSubtitleMessage}</p>

      </label>
    </Col>
    <Col xs={9}>{children}</Col>
  </Row>
);

InfoRow.defaultProps = {
  inputId: null,
  labelSuffix: null,
  defaultSubtitleMessage: PropTypes.string,
};

InfoRow.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string.isRequired,
  inputId: PropTypes.string,
  labelSuffix: PropTypes.node,
  defaultSubtitleMessage: PropTypes.string,
};

const RunRecordForm = ({
  created,
  operation,
  handleDelete,
  handleInputChange,
  handleSubmit,
  isFetching,
  isSaving,
  name,
  notes,
  pieces_locked,
  secureRunCheckout,
  buildFiles,
  postProcessor,
  postProcessors,
  printer,
  printers,
  printerType,
  printerTypeName,
  priority,
  printCount,
  postProcessorType,
  postProcessorTypeName,
  status,
  uuid,
  modelUpload,
  uploadModel,
  materialBatch,
  handleFileRemove,
  handleFileChange,
  isModelUploading,
  errorMessage,
  initialStatus,
  runURI,
  isGeneralMFGLanguageEnabled,
  shipping,
  postProcessorData,
  printerData,
  shippingData,
  addCertifiedBuildToLibraryConfirmationModalState,
  setAddCertifiedBuildToLibraryConfirmationModalState,
  prints,
  handleAddBuildFileToCertifiedBuildsLibrary,
  buildFileDoesExistInBuildLibrary,
  existingBuildFiles,
  isCertifiedBuildsFeatureEnabled,
  isUserManagedPrinterType,
  manuallySlicedBuildFile,
  manuallySlicedBuildFileState,
  isPressToPrintFeatureEnabled,
  isSlicingFeatureEnabled,
  lastSecureCheckoutDirectory,
  secureCheckoutDirectories,
  isDisabledForm,
  customFieldValues,
  customRunFieldReferences,
  handleCustomInputChange,
}) => {
  /* If we are creating the run and while the status is "Calculating" -> we will see the Spinner.
  Once the status changed to anything else -> if we have buildFiles in the Array -> we will see them,
   If no - we will get null as it was initially there. */
  const buildFileStatus = status === 'calculating' ? <Loading /> : null;

  const maxInProgressPostProcessors = postProcessors[0]?.in_progress_max;
  const maxInProgressPrinters = printers[0]?.in_progress_max;
  const maxInProgressShipping = shippingData?.in_progress_max;

  const InProgressPostProcessors = postProcessorData?.in_progress.length;
  const InProgressPrinters = printerData?.in_progress?.length;
  const InProgressShipping = shippingData?.in_progress.length;

  const {
    manuallySlicedBuildFileUpload,
    onManuallySlicedBuildFileChange,
    handleRemoveManuallySlicedBuildFile,
  } = manuallySlicedBuildFileState;

  const visibleRunCustomFieldReferencesWithOptions = getVisibleCustomFieldReferencesWithOptions({
    fieldReferences: customRunFieldReferences,
    fieldValues: customFieldValues,
  });

  return (
    <>
      <AddCertifiedBuildToLibraryConfirmationModal
        runData={{
          name,
          prints,
        }}
        printCount={printCount}
        show={addCertifiedBuildToLibraryConfirmationModalState.show}
        closeModal={() => {
          setAddCertifiedBuildToLibraryConfirmationModalState({
            ...addCertifiedBuildToLibraryConfirmationModalState,
            show: false,
          });
        }}
        onConfirm={certifiedBuildName => {
          setAddCertifiedBuildToLibraryConfirmationModalState({
            ...addCertifiedBuildToLibraryConfirmationModalState,
            show: false,
          });
          handleAddBuildFileToCertifiedBuildsLibrary(certifiedBuildName);
        }}
      />
      <div>
        <div className="clearfix">
          <div className="pull-right mb15 d-flex align-items-center">
            { isCertifiedBuildsFeatureEnabled && (
              buildFileDoesExistInBuildLibrary ? (
                <Tooltip
                  placement="top"
                  trigger={(
                    <Button
                      className="spacer-right btn-sm"
                      variant="primary"
                      disabled={isDisabledForm}
                    >
                      <FontAwesomeIcon icon={faCheck} className="spacer-right" />
                      Certified Build ({getShortUUID(existingBuildFiles[0]?.uri ?? '')})
                    </Button>
                  )}
                >
                  Find Certified Build ({getShortUUID(existingBuildFiles[0]?.uri ?? <i>unknown</i>)})&nbsp;
                  in the Builds Library.
                </Tooltip>
              )
                : (
                  <Button
                    disabled={
                      isFetching
                      || isModelUploading
                      || isSaving
                      || status === 'calculating'
                      || isUserManagedPrinterType
                      || isDisabledForm
                    }
                    className="spacer-right"
                    variant="primary"
                    size="sm"
                    onClick={() => setAddCertifiedBuildToLibraryConfirmationModalState({ show: true })}
                  >
                    <FontAwesomeIcon icon={faPlus} className="spacer-right" />
                    Add Certified Build to Library
                  </Button>
                )
            )}
            <DisabledByAccessInfoCheck
              resourceUri={runURI}
              actionType={ACCESS_INFO_ACTION_TYPES.EDIT}
              renderDisabledPrefix
            >
              {({ disabled }) => {
                if (operation === RUN_OPERATIONS.PRINTING && status === RUN_STATUSES.COMPLETE) {
                  return (
                    <Button
                      className="spacer-right"
                      variant="success"
                      size="sm"
                      disabled={isFetching || isSaving || disabled || isDisabledForm}
                      onClick={handleSubmit}
                    >
                      {isSaving ? <FontAwesomeIcon spin icon={faSpinner} /> : <SaveButtonTitle />}
                    </Button>
                  );
                }
                return (
                  <SplitButton
                    id="uxSaveDropdown"
                    variant="success"
                    size="sm"
                    disabled={isFetching || isSaving || disabled || isDisabledForm}
                    title={isSaving ? <FontAwesomeIcon spin icon={faSpinner} /> : <SaveButtonTitle />}
                    onClick={handleSubmit}
                  >
                    <Dropdown.Item disabled={isFetching} eventKey={1} onClick={handleDelete}>
                      <FontAwesomeIcon icon={faBan} />{' '}
                      <FormattedMessage id="button.delete" defaultMessage="Delete" />
                    </Dropdown.Item>
                  </SplitButton>
                );
              }}
            </DisabledByAccessInfoCheck>
          </div>
        </div>

        <InfoRow id="field.runName" defaultMessage="Run Name" inputId="name">
          <FormControl
            placeholder={name}
            disabled={isFetching || isDisabledForm}
            name="name"
            id="name"
            value={name}
            onChange={handleInputChange}
          />
        </InfoRow>

        {created ? (
          <InfoRow id="created" defaultMessage="Created">
            <FormattedDateTime value={created} />
          </InfoRow>
        ) : null}

        {status && RUN_STATUS_TRANSFORMATIONS[initialStatus] ? (
          <InfoRow id="field.status" defaultMessage="Status" inputId="status">
            <FormControl
              as="select"
              id="status"
              name="status"
              onChange={handleInputChange}
              type="select"
              value={status}
              disabled={isDisabledForm}
            >
              {RUN_STATUS_TRANSFORMATIONS[initialStatus].map(statusOption => (
                <FormattedMessage
                  key={RUN_STATUS_MAP[statusOption].id}
                  id={RUN_STATUS_MAP[statusOption].id}
                  defaultMessage={RUN_STATUS_MAP[statusOption].defaultMessage}
                >
                  {text =>
                    <option key={statusOption} value={statusOption}>{text}</option>}
                </FormattedMessage>
              ))}
            </FormControl>
            {errorMessage && <span>{errorMessage}</span>}
          </InfoRow>
        ) : null}

        <Feature featureName={FEATURES.SECURE_FILE_CHECKOUT}>
          <InfoRow id="field.secureRunCheckout" defaultMessage="Secure Run Checkout">
            <FormControl
              as="select"
              id="secureRunCheckout"
              name="secureRunCheckout"
              onChange={handleInputChange}
              type="select"
              value={secureRunCheckout}
              disabled={isDisabledForm}
            >
              {!lastSecureCheckoutDirectory?.uri && (
                <FormattedMessage
                  id="noCurrentlyCheckedOut"
                  defaultMessage="No Currently Checked Out"
                >
                  {text => <option value={null}>{text}</option>}
                </FormattedMessage>
              )}
              {secureCheckoutDirectories.map(
                item => <option key={item.uri} value={item.uri}>{item.common_name}</option>,
              )}
            </FormControl>
          </InfoRow>
        </Feature>

        <Feature featureName={FEATURES.PRIORITY}>
          <InfoRow id="field.priority" defaultMessage="Priority">
            <PriorityLabel value={priority} containerClassName="justify-content-start" />
          </InfoRow>
        </Feature>

        <InfoRow id="field.pieces_locked" defaultMessage="Locked" inputId="pieces_locked">
          <Form.Check
            disabled={isFetching || isDisabledForm}
            name="pieces_locked"
            id="pieces_locked"
            checked={pieces_locked}
            onChange={handleInputChange}
            type="checkbox"
          />
        </InfoRow>

        <Feature featureName={FEATURES.QR_PRINTS_TRAVELER}>
          <InfoRow id="field.traveler" defaultMessage="Traveler">
            <a
              href={`${Config.HOST.QR}/traveler/run/${uuid}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <BureauBarcodeIcon className="spacer-right" />
              Print all travelers
            </a>
            <br />
            <small>See the prints table below to print individual travelers.</small>
          </InfoRow>
        </Feature>

        {buildFiles.length ? (
          <InfoRow id="packedBuildPlateModel" defaultMessage="Packed Build Plate Model">
            <RunModelDownload
              buildFiles={buildFiles}
              runName={name}
              runUUID={uuid}
            />
          </InfoRow>
        ) : buildFileStatus}

        {isSlicingFeatureEnabled && manuallySlicedBuildFile && (
          <InfoRow id="slicedBuildPlateModel" defaultMessage="Sliced Build Plate Model">
            <SlicedModelDownload
              slicedBuildFile={manuallySlicedBuildFile}
            />
          </InfoRow>
        )}

        {isSlicingFeatureEnabled && manuallySlicedBuildFile && (
          <InfoRow id="manuallySlicedBuildFile" defaultMessage="Manually Sliced Build File">
            {manuallySlicedBuildFileUpload ? (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {manuallySlicedBuildFileUpload.name}
                {' '}
                <Button
                  size="xs"
                  variant="danger"
                  onClick={handleRemoveManuallySlicedBuildFile}
                  disabled={isDisabledForm}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
              </div>
            ) : (
              <FileInput
                onlyFileInput
                acceptedExtensions={[...ALLOWED_MODEL_EXTENSIONS, FILE_EXTENSIONS.ZIP]}
                customContainerStyles="d-flex"
                fileType={FileInput.fileTypes.model}
                handleFileChange={onManuallySlicedBuildFileChange}
                disabled={isDisabledForm}
              />
            )}
          </InfoRow>
        )}

        {buildFiles.length ? (
          <InfoRow id="field.replaceModel" defaultMessage="Replace Model">
            {
              !isModelUploading && (
                modelUpload ? (
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {modelUpload.name}
                    {' '}
                    <Button
                      size="xs"
                      variant="danger"
                      onClick={handleFileRemove}
                      disabled={isDisabledForm}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>
                  </div>
                ) : (
                  <FileInput
                    name="model"
                    acceptedExtensions={RUN_MODEL_FILE_EXTENSIONS}
                    fileType={FileInput.fileTypes.model}
                    required
                    onlyFileInput
                    handleFileChange={handleFileChange}
                    disabled={isDisabledForm}
                  />
                )
              )
            }
            {isModelUploading && (
              <ProgressBar
                striped
                className="input-height"
                now={Number.parseInt(uploadModel.percent, 10)}
                active
              />
            )}
          </InfoRow>
        ) : buildFileStatus}

        {shipping && shippingData ? (
          <InfoRow
            id="shipping"
            defaultMessage="Shipping"
            defaultSubtitleMessage={`(${InProgressShipping} of ${maxInProgressShipping} in progress)`}
          >
            <InputGroup>
              <div className="d-flex align-items-center w-100">
                <SelectSingle
                  name="shipping"
                  data={[shippingData]}
                  value={shipping}
                  handleOnChange={handleInputChange}
                  disabled
                  required
                  imitateOnChangeEvent
                />
                <InputGroup.Text className="spacer-left">
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to={getRouteURI(ROUTES.SHIPPING_EDIT, { uuid: extractUuid(shipping) }, {}, true)}
                  >
                    Go <FontAwesomeIcon icon={faExternalLink} />
                  </Link>
                </InputGroup.Text>
              </div>
            </InputGroup>
          </InfoRow>
        ) : null}

        {printerType ? (
          <InfoRow
            id={isGeneralMFGLanguageEnabled ? 'mfg.printerType.productionDeviceType' : 'field.printerType'}
            defaultMessage={isGeneralMFGLanguageEnabled ? 'Production Device Type' : 'Printer Type'}
          >
            <Form.Text>
              <Link to={getRouteURI(ROUTES.PRINTER_TYPES, null, { uuid: extractUuid(printerType) }, true)}>
                {printerTypeName || printerType}
              </Link>
            </Form.Text>
          </InfoRow>
        ) : null}

        {printers.length > 0 ? (
          <InfoRow
            id={isGeneralMFGLanguageEnabled ? 'mfg.printer.productionDevice' : 'field.printer'}
            defaultMessage={isGeneralMFGLanguageEnabled ? 'Production Device' : 'Printer'}
            defaultSubtitleMessage={`(${InProgressPrinters} of ${maxInProgressPrinters} in progress)`}
          >
            <InputGroup>
              <div className="d-flex w-full">
                <SelectSingle
                  name="printer"
                  data={printers}
                  value={printer}
                  handleOnChange={handleInputChange}
                  disabled={isDisabledForm}
                  required
                  imitateOnChangeEvent
                />
                <InputGroup.Text>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to={getRouteURI(ROUTES.PRINTER_EDIT, { uuid: extractUuid(printer) }, {}, true)}
                  >
                    Go <FontAwesomeIcon icon={faExternalLink} />
                  </Link>
                </InputGroup.Text>
              </div>
            </InputGroup>
          </InfoRow>
        ) : null}

        {postProcessorType ? (
          <InfoRow id="field.postProcessorType" defaultMessage="Post Processor Type">
            <Form.Text>
              <Link to={getRouteURI(ROUTES.POST_PROCESSOR_TYPES,
                null, { uuid: extractUuid(postProcessorType) }, true)}
              >
                {postProcessorTypeName || postProcessor}
              </Link>
            </Form.Text>
          </InfoRow>
        ) : null}

        {postProcessor ? (
          <InfoRow id="field.postProcessor" defaultMessage="Post-Processor" defaultSubtitleMessage={`(${InProgressPostProcessors} of ${maxInProgressPostProcessors} in progress)`}>
            <InputGroup>
              <div className="d-flex w-full">
                <SelectSingle
                  name="postProcessor"
                  data={postProcessors}
                  value={postProcessor}
                  handleOnChange={handleInputChange}
                  disabled={isDisabledForm}
                  required
                  imitateOnChangeEvent
                />
                <InputGroup.Text>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to={getRouteURI(ROUTES.POST_PROCESSOR_EDIT, { uuid: extractUuid(postProcessor) }, {}, true)}
                  >
                    Go <FontAwesomeIcon icon={faExternalLink} />
                  </Link>
                </InputGroup.Text>
              </div>
            </InputGroup>
          </InfoRow>
        ) : null}

        {materialBatch && (
          <Feature featureName={FEATURES.MATERIAL_MANAGEMENT}>
            <InfoRow
              id="materialBatch"
              defaultMessage="Material Batch"
              labelSuffix={(
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(
                    <Tooltip>
                      <FormattedMessage
                        id="tooltip.error.materialbatch"
                        values={{ space: <br /> }}
                        defaultMessage="Is something wrong with this batch? {space} Email support@authentise.com if you need assistance with changing the recorded batch."
                      />
                    </Tooltip>
                  )}
                >
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </OverlayTrigger>
              )}
            >
              <Form.Text>
                <Link to={getRouteURI(ROUTES.MATERIAL_BATCH, { uuid: extractUuid(materialBatch.uri) }, {}, true)}>
                  {/* TODO: Change when UI for multiple lots is ready {materialBatch.material_name} */}
                  {materialBatch.materials?.map(material => material.name).join(', ')} - Batch {getShortUUID(materialBatch.uri)}
                </Link>
              </Form.Text>
            </InfoRow>
          </Feature>
        )}
        <InfoRow id="field.notes" defaultMessage="Notes">
          <FormControl
            as="textarea"
            disabled={isFetching || isDisabledForm}
            name="notes"
            value={notes || ''}
            onChange={handleInputChange}
          />
        </InfoRow>
        <CustomFieldList
          customFieldReferences={visibleRunCustomFieldReferencesWithOptions}
          customFieldValues={Object.values(customFieldValues)}
          onChange={handleCustomInputChange}
        />

        {isPressToPrintFeatureEnabled && operation === RUN_OPERATIONS.PRINTING && (
          <PressToPrintView
            runURI={runURI}
            printerData={printerData}
          />
        )}
      </div>
    </>
  );
};

RunRecordForm.defaultProps = {
  created: null,
  notes: null,
  pieces_locked: null,
  name: null,
  postProcessor: null,
  printerType: null,
  printerTypeName: null,
  priority: PRIORITIES.NORMAL,
  postProcessorType: null,
  postProcessorTypeName: null,
  printer: null,
  materialBatch: null,
  status: null,
  uuid: null,
  isModelUploading: false,
  modelUpload: null,
  uploadModel: null,
  errorMessage: null,
  runURI: null,
  lastSecureCheckoutDirectory: null,
  secureRunCheckout: null,
};

RunRecordForm.propTypes = {
  created: PropTypes.string,
  notes: PropTypes.string,
  pieces_locked: PropTypes.bool,
  handleDelete: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  buildFiles: PropTypes.arrayOf(buildFileResourceType).isRequired,
  name: PropTypes.string,
  postProcessor: PropTypes.string,
  postProcessors: PropTypes.arrayOf(PropTypes.shape({
    in_progress_max: PropTypes.number,
    in_progress: PropTypes.number,
  })).isRequired,
  printer: PropTypes.string,
  printers: PropTypes.arrayOf(PropTypes.shape({
    in_progress_max: PropTypes.number,
    in_progress: PropTypes.number,
  })).isRequired,
  printerType: PropTypes.string,
  operation: PropTypes.string.isRequired,
  printerTypeName: PropTypes.string,
  priority: PropTypes.number,
  postProcessorType: PropTypes.string,
  postProcessorTypeName: PropTypes.string,
  status: PropTypes.string,
  uuid: PropTypes.string,
  initialStatus: PropTypes.string.isRequired,
  isModelUploading: PropTypes.bool,
  modelUpload: PropTypes.shape({ name: PropTypes.string }),
  uploadModel: PropTypes.shape({
    name: PropTypes.string,
    percent: PropTypes.number,
  }),
  materialBatch: PropTypes.shape({
    // material_name: PropTypes.string,
    materials: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
    })),
    uri: PropTypes.string,
  }),
  handleFileChange: PropTypes.func.isRequired,
  handleFileRemove: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  runURI: PropTypes.string,
  isGeneralMFGLanguageEnabled: PropTypes.bool.isRequired,
  shipping: PropTypes.shape({}).isRequired,
  postProcessorData: PropTypes.shape({
    in_progress: PropTypes.arrayOf(
      PropTypes.number.isRequired,
    ),
    in_progress_max: PropTypes.number.isRequired,
  }).isRequired,
  printerData: PropTypes.shape({
    in_progress: PropTypes.arrayOf(
      PropTypes.number.isRequired,
    ),
    in_progress_max: PropTypes.number.isRequired,
  }).isRequired,
  shippingData: PropTypes.shape({
    in_progress: PropTypes.arrayOf(
      PropTypes.number.isRequired,
    ),
    in_progress_max: PropTypes.number.isRequired,
  }).isRequired,
  addCertifiedBuildToLibraryConfirmationModalState: PropTypes.func.isRequired,
  setAddCertifiedBuildToLibraryConfirmationModalState: PropTypes.shape({}).isRequired,
  prints: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  printCount: PropTypes.number.isRequired,
  certifiedBuildsState: PropTypes.shape({
    setIsAddedToCertifiedBuilds: PropTypes.func,
    isAddedToCertifiedBuilds: PropTypes.bool,
  }).isRequired,
  handleAddBuildFileToCertifiedBuildsLibrary: PropTypes.func.isRequired,
  buildFileDoesExistInBuildLibrary: PropTypes.bool.isRequired,
  existingBuildFiles: PropTypes.arrayOf(PropTypes.shape({
    uri: PropTypes.string,
  })).isRequired,
  isCertifiedBuildsFeatureEnabled: PropTypes.bool.isRequired,
  isUserManagedPrinterType: PropTypes.bool.isRequired,
  manuallySlicedBuildFile: PropTypes.shape({}).isRequired,
  manuallySlicedBuildFileState: PropTypes.shape({
    manuallySlicedBuildFileUpload: PropTypes.shape({
      name: PropTypes.string,
    }),
    onManuallySlicedBuildFileChange: PropTypes.func,
    handleRemoveManuallySlicedBuildFile: PropTypes.func,
  }).isRequired,
  isPressToPrintFeatureEnabled: PropTypes.bool.isRequired,
  isSlicingFeatureEnabled: PropTypes.bool.isRequired,
  secureCheckoutDirectories: PropTypes.arrayOf(secureCheckoutDirectoryResourceType).isRequired,
  lastSecureCheckoutDirectory: secureCheckoutDirectoryResourceType,
  secureRunCheckout: PropTypes.string,
  isDisabledForm: PropTypes.bool.isRequired,
  customFieldValues: PropTypes.arrayOf({}).isRequired,
  customRunFieldReferences: PropTypes.arrayOf({}).isRequired,
  handleCustomInputChange: PropTypes.func.isRequired,
};

export default RunRecordForm;
