import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import AddProduct from 'rapidfab/components/AddProduct';
import Actions from 'rapidfab/actions';
import { API_RESOURCES, DESIGN_FILE_TYPES, FEATURES, MIME_TYPES } from 'rapidfab/constants';
import * as Selectors from 'rapidfab/selectors';

const AddProductContainer = props => {
  const uploadModel = useSelector(Selectors.getUploadModel);
  const restrictedUploadModelLibraryOnlyFeature = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.RESTRICTED_USER_UPLOAD_FROM_MODEL_LIBRARY_ONLY));
  const isUserRestricted = useSelector(Selectors.isCurrentUserRestricted);
  const isCADToSTLConversionFeatureEnabled =
    useSelector(state => Selectors.isFeatureEnabled(state, FEATURES.NATIVE_CAD_TO_STL_CONVERSION));
  const isPowderWorkflowFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.POWDER_WORKFLOW));

  const selected = { uploadModel,
    restrictedUploadModelLibraryOnlyFeature,
    isUserRestricted,
    isCADToSTLConversionFeatureEnabled,
    isPowderWorkflowFeatureEnabled };
  const dispatch = useDispatch();

  const submitProduct = extraPayload =>
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRODUCT].post({
      order: props.orderUri,
      ...extraPayload,
    }));
  const onNoModelUploadSubmit = () =>
    submitProduct({ no_model_upload: true });

  const onFromModelLibrarySubmit = modelLibraryUri =>
    submitProduct({ model_library: modelLibraryUri });

  const onDesignFileSubmit = async (file, extension) => {
    const productResponse = await submitProduct();

    const { location: productUri } = productResponse.headers;
    const designFileResponse = await dispatch(
      Actions.Api.nautilus[API_RESOURCES.DESIGN_FILE].post({
        name: file.name,
        product: productUri,
        type: DESIGN_FILE_TYPES.SINGLE_MESH,
        format: extension,
      }),
    );
    const { uploadLocation, location: designFileUri } = designFileResponse.headers;
    if (designFileUri) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL].list({ design_file: designFileUri }, {}, {}, {}, true));
    }
    const isFileSizeMoreThan5MB = file.size > 5_000_000;
    dispatch(Actions.UploadModel.uploadProgress(0));
    dispatch(Actions.UploadModel.upload(uploadLocation, file, MIME_TYPES.OCTET_STREAM, isFileSizeMoreThan5MB));
    return designFileResponse;
  };

  const dispatched = {
    onNoModelUploadSubmit,
    onFromModelLibrarySubmit,
    onDesignFileSubmit,
  };

  return (
    <AddProduct
      {...props}
      {...selected}
      {...dispatched}
    />
  );
};

AddProductContainer.propTypes = {
  orderUri: PropTypes.string.isRequired,
  lineItemsLength: PropTypes.number.isRequired,
};

export default AddProductContainer;
