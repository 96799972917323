import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Modal,
} from 'react-bootstrap';

const ConfirmationModal = ({
  handleConfirm,
  handleCancel,
  message,
  title,
  confirmButtonContent,
  cancelButtonContent,
  isHawkingUser,
  customConfirmVariant,
  customCancelVariant,
  swapButtons,
  isDarken,
}) => {
  const confirmButtonVariant = (customConfirmVariant || (!isHawkingUser && 'danger'));
  const cancelButtonVariant = (customCancelVariant || (!isHawkingUser && 'primary'));

  const ConfirmButton = (
    <Button
      variant={confirmButtonVariant}
      className={isHawkingUser && 'hawking-primary'}
      onClick={handleConfirm}
    >
      {confirmButtonContent}
    </Button>
  );

  const CancelButton = handleCancel && (
    <Button
      variant={cancelButtonVariant}
      className={isHawkingUser && 'hawking-secondary'}
      onClick={handleCancel}
    >
      {cancelButtonContent}
    </Button>
  );

  return (
    <Modal show onHide={handleCancel} backdrop="static" dialogClassName={isDarken ? 'custom-darken-modal' : undefined}>
      {title && (
        <Modal.Header style={{ position: 'relative' }}>
          <Modal.Title>
            {title}
          </Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>
        {message}
      </Modal.Body>
      <Modal.Footer>
        {swapButtons ? (
          <>
            {handleCancel && CancelButton}
            {ConfirmButton}
          </>
        ) : (
          <>
            {ConfirmButton}
            {handleCancel && CancelButton}
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  message: PropTypes.node.isRequired,
  title: PropTypes.node,
  handleConfirm: PropTypes.func.isRequired,
  handleCancel: PropTypes.func,
  confirmButtonContent: PropTypes.node, // Making it a `node` to allow <FormattedMessage ...>
  cancelButtonContent: PropTypes.node, // Making it a `node` to allow <FormattedMessage ...>
  isHawkingUser: PropTypes.bool,
  customConfirmVariant: PropTypes.string,
  customCancelVariant: PropTypes.string,
  swapButtons: PropTypes.bool,
  isDarken: PropTypes.bool,
};

ConfirmationModal.defaultProps = {
  title: null,
  confirmButtonContent: <FormattedMessage id="button.ok" defaultMessage="OK" />,
  cancelButtonContent: <FormattedMessage id="button.cancel" defaultMessage="Cancel" />,
  isHawkingUser: false,
  handleCancel: false,
  customConfirmVariant: null,
  customCancelVariant: null,
  swapButtons: false,
  isDarken: false,
};

export default ConfirmationModal;
