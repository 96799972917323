import React from 'react';
import PropTypes from 'prop-types';
import { STATUS_COLOR_CODE_TYPES } from 'rapidfab/constants';
import { SERVICE_PROVIDER_JOB_MAP } from 'rapidfab/mappings';

import TableWithSearching from 'rapidfab/components/Tables/TableWithSearching';

const ServiceProviderJobs = componentProps => {
  const { data, serviceProvidersByUri, fetching, onSort } = componentProps;
  const breadcrumbs = ['plan', 'serviceProviderJobs'];

  const columns = [
    {
      type: 'text',
      uid: 'record.lineItem',
      accessor: 'line_item_name',
      defaultMessage: 'Line Item',
      isSortable: true,
    },
    {
      type: 'date',
      uid: 'field.bid_requested_date',
      accessor: 'created',
      defaultMessage: 'Bid Requested Date',
      isSortable: true,
    },
    {
      type: 'record',
      uid: 'orderName',
      accessor: 'order_name',
      defaultMessage: 'Order Name',
      resource: 'order',
      uri: 'order',
      isSortable: true,
    },
    {
      type: 'cost',
      uid: 'field.cost',
      accessor: 'cost',
      defaultMessage: 'Cost',
    },
    {
      type: 'resource',
      uid: 'record.serviceProvider',
      accessor: 'service_provider',
      defaultMessage: 'Service Provider',
      slug: 'service-provider',
      resource: 'service_provider',
      resources: serviceProvidersByUri,
      isSortable: true,
    },
    {
      type: 'translatable',
      uid: 'field.status',
      accessor: 'status',
      defaultMessage: 'Status',
      mapping: SERVICE_PROVIDER_JOB_MAP,
      coloured: true,
      colorScheme: STATUS_COLOR_CODE_TYPES.SERVICE_PROVIDER_JOB,
      isSortable: true,
    },
    {
      type: 'date',
      uid: 'field.dueDate',
      accessor: 'due_at',
      defaultMessage: 'Due Date',
      isSortable: true,
    },
  ];

  return (
    <TableWithSearching
      {...componentProps}
      data={data}
      columns={columns}
      withBreadcrumbs
      breadcrumbs={breadcrumbs}
      isFetching={fetching}
      isManualSoringEnabled
      manualSortingFunc={onSort}
      initialSortedDesc
      initialSortedColumn="updated"
    />
  );
};
ServiceProviderJobs.propTypes = {
  serviceProvidersByUri: PropTypes.objectOf(PropTypes.shape({
    uri: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
};

export default ServiceProviderJobs;
