import React from 'react';
import { FormLabel } from 'react-bootstrap';
import Select from 'react-select';
import _map from 'lodash/map';
import { selectInputStyles } from 'rapidfab/constants/styles';
import PropTypes from 'prop-types';

/**
 * Inline mapped filters list component for TableWithSearching.jsx.
 */
const TableInlineFilters = ({ onChange, filters }) => (
  <div className="form-inline" style={{ lineHeight: '40px' }}>
    {
      _map(filters, filterItem => (
        <div key={filterItem.fieldId} className="form-group mr15">
          <FormLabel htmlFor={filterItem.fieldId}>
            {filterItem.label}:
          </FormLabel>
          <div className="spacer-left form-control inline-picky-wrapper">
            <Select
              name={filterItem.fieldId}
              defaultValue={filterItem.defaultValue}
              styles={selectInputStyles}
              options={filterItem.options}
              onChange={value => onChange(filterItem.fieldId, value)}
            />
          </div>
        </div>
      ))
    }
  </div>
);

export default TableInlineFilters;

TableInlineFilters.propTypes = {
  onChange: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape({
    fieldId: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string.isRequired,
    })).isRequired,
  })).isRequired,
};
