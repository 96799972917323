import _flatMap from 'lodash/flatMap';
import _map from 'lodash/map';
import { loadAdditionalRunResourcesByRunUris } from 'rapidfab/dispatchers/run';
import usePrevious from 'rapidfab/hooks';
import usePagination from 'rapidfab/hooks/usePagination';
import {
  getLocationFilter,
  getNonServicePostProcessors,
  getRunActualsKeyedByRunUri,
  getRunEstimatesByRunUri,
  getSubLocationFilter,
} from 'rapidfab/selectors';
import {
  findCurrentRunStartDate,
  findScheduledRunsForResource,
  getPercentageOfTheCurrentScheduledRun,
  isRunActive,
} from 'rapidfab/utils/getRunName';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';

import PostProcessorsComponent from 'rapidfab/components/assets/postProcessors';
import {
  API_RESOURCES,
  FEATURES, PAGINATION_IGNORE_DEFAULT_LIMIT,
  VIEW_MODE_OPTIONS,
} from 'rapidfab/constants';
import PropTypes from 'prop-types';

const PostProcessors = props => {
  const postProcessors = useSelector(getNonServicePostProcessors);
  const [viewMode, setViewMode] = useState(props.viewMode || VIEW_MODE_OPTIONS.CARDS);
  const locations = useSelector(Selectors.getLocations);
  const locationsByUri = useSelector(Selectors.getLocationsByUri);
  const subLocationsByUri = useSelector(Selectors.getSubLocationsByUri);
  const postProcessorTypes = useSelector(Selectors.getPostProcessorTypesByUri);
  const isGroupQualificationsFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.GROUP_QUALIFICATIONS));
  const isDebugModeEnabled = useSelector(Selectors.getIsDebugModeEnabled);
  const groupedPostProcessors = useSelector(Selectors.getPostProcessorsGroupedByLocationAndSubLocation);
  const runs = useSelector(Selectors.getRunsByPostProcessorUri);
  const runEstimatesKeyedByRunUri = useSelector(getRunEstimatesByRunUri);
  const runActuals = useSelector(getRunActualsKeyedByRunUri);
  const locationFilter = useSelector(getLocationFilter);
  const subLocationFilter = useSelector(getSubLocationFilter);
  const isWorkScheduleFeatureEnabled = useSelector(state => Selectors.isFeatureEnabled(state, FEATURES.WORK_SCHEDULE));
  const workSchedulesByPostProcessorType = useSelector(Selectors.getWorkSchedulesByPostProcessorType);

  const fetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.LOCATION].list.fetching ||
    state.ui.nautilus[API_RESOURCES.POST_PROCESSOR].list.fetching ||
    state.ui.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].list.fetching);

  const runsFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.SCHEDULE_RUNS].list.fetching ||
    state.ui.nautilus[API_RESOURCES.RUN_ACTUALS].list.fetching ||
    state.ui.nautilus[API_RESOURCES.RUN].list.fetching);

  const isWorkScheduleFetching = useSelector(state =>
    (isWorkScheduleFeatureEnabled ?
      state.ui.nautilus[API_RESOURCES.WORK_SCHEDULE].list.fetching : false));

  const isCardsView = viewMode === VIEW_MODE_OPTIONS.CARDS;
  const [additionalResourcesFetched, setAdditionalResourcesFetched] = useState(false);
  const [globalFilter, setGlobalFilter] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [hideArchived, setHideArchived] = useState(false);

  const {
    paginationState,
    setTotalItems,
    setPage,
    nextPage,
    prevPage,
    totalPaginatedPages,
  } = usePagination(15);

  const selected = useMemo(() => ({
    fetching,
    data: postProcessors,
    hideArchived,
    isCardsView,
    locationsByUri,
    locations,
    groupedPostProcessors,
    runsFetching,
    postProcessorTypes,
    subLocationsByUri,
    isGroupQualificationsFeatureEnabled,
    isDebugModeEnabled,
    workSchedulesByPostProcessorType,
    isWorkScheduleFetching,
  }), [
    fetching,
    postProcessors,
    hideArchived,
    locationsByUri,
    locations,
    isCardsView,
    groupedPostProcessors,
    runsFetching,
    postProcessorTypes,
    subLocationsByUri,
    isGroupQualificationsFeatureEnabled,
    isDebugModeEnabled,
    workSchedulesByPostProcessorType,
    isWorkScheduleFetching,
  ]);

  const dispatch = useDispatch();
  const onInitialize = () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list({}, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
    dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].list({}, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
  };

  const getWorkSchedule = postProcessorTypesUris => {
    if (isWorkScheduleFeatureEnabled && postProcessorTypesUris.length) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.WORK_SCHEDULE].list({
        post_processor_type: postProcessorTypesUris,
      }));
    }
  };

  const loadPostProcessors = () => {
    const findBy = {
      ...(locationFilter && { location: locationFilter }),
      ...(subLocationFilter && { sub_location: subLocationFilter }),
      ...(hideArchived && { archived: null }),
    };
    const filter = {
      sort: 'name',
    };

    if (globalFilter) {
      filter.multicolumn_search = globalFilter;
    }

    // Clear all the resources if we are going through the pagination
    dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR].clear('list'));
    dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].clear('list'));
    dispatch(Actions.Api.nautilus[API_RESOURCES.RUN].clear('list'));
    dispatch(Actions.Api.nautilus[API_RESOURCES.SCHEDULE_RUNS].clear('list'));

    dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR].list({ ...findBy },
      { limit: paginationState.pageLimit,
        offset: paginationState.offset > 0 ? paginationState.offset : 0 }, {}, { ...filter }, true))
      .then(postProcessorsResponse => {
        setTotalItems(postProcessorsResponse.json?.meta?.count);

        const postProcessors = postProcessorsResponse.json?.resources;
        const postProcessorTypeUris = _map(postProcessors, 'post_processor_type');

        if (postProcessorTypeUris.length) {
          // Get corresponding post-processor types
          dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE]
            .list({ uri: postProcessorTypeUris }, {}, {}, {}, true));
        }

        // Load additional resources only if we are in the cards view
        if (postProcessors?.length && isCardsView) {
          const runUris = _flatMap(postProcessors, 'queue');
          loadAdditionalRunResourcesByRunUris(runUris, dispatch);
          setAdditionalResourcesFetched(true);
          // Set "false" only if we had resources, to prevent additional API calls.
        } else if (postProcessors.length) {
          // Reset if we are not in the cards view
          setAdditionalResourcesFetched(false);
        }
      });
  };

  const previousPage = usePrevious(paginationState.activePage);
  const previousLocationFilter = usePrevious(locationFilter);
  const previousSubLocationFilter = usePrevious(subLocationFilter);
  const previousGlobalFilter = usePrevious(globalFilter);
  const previousHideArchived = usePrevious(hideArchived);

  useEffect(() => {
    /*
      We need to send API calls only if:
      1. Initial loading in the LIST view, sending the base Post Processor API calls
         without additional resources.
      2. Switching between the LIST and CARDS view but only ONCE, to prevent
         sending the same API calls multiple times.
      3. Pagination changes: In LIST view only Post Processor's data, in CARD view
         also all the additional resources.
      4. Global filter changes.
      5. There should not be any duplicated API calls.
    */

    if ((!additionalResourcesFetched && isCardsView)
      || previousPage !== paginationState.activePage
      || globalFilter !== previousGlobalFilter
      || hideArchived !== previousHideArchived
      || (previousLocationFilter !== locationFilter
        || previousSubLocationFilter !== subLocationFilter)) {
      loadPostProcessors();
    }
  }, [
    paginationState.pageLimit,
    paginationState.offset,
    paginationState.activePage,
    locationFilter,
    subLocationFilter,
    globalFilter,
    hideArchived,
    isCardsView,
    additionalResourcesFetched,
  ]);

  useEffect(() => onInitialize(), []);

  useEffect(() => {
    getWorkSchedule(_map(postProcessorTypes, 'uri'));
  }, [isWorkScheduleFeatureEnabled, JSON.stringify(postProcessorTypes)]);

  const findScheduledRuns = useCallback(postProcessor =>
    findScheduledRunsForResource(postProcessor, runs, runEstimatesKeyedByRunUri),
  [runEstimatesKeyedByRunUri, runs]);

  const getCurrentPercentageOfTheCurrentScheduledRun = useCallback(run =>
    getPercentageOfTheCurrentScheduledRun(run, runEstimatesKeyedByRunUri, runActuals),
  [runEstimatesKeyedByRunUri]);

  const findRunStartDate = useCallback(run => {
    findCurrentRunStartDate(run, runEstimatesKeyedByRunUri);
  }, [runEstimatesKeyedByRunUri]);

  const onArchivedCheckboxChange = event => setHideArchived(event.target.checked);

  const dispatched = {
    isRunActive,
    findScheduledRuns,
    onArchivedCheckboxChange,
    scheduledStartDate: findRunStartDate,
    getPercentage: getCurrentPercentageOfTheCurrentScheduledRun,
  };

  return (
    <PostProcessorsComponent
      {...selected}
      {...dispatched}
      {...props}
      viewModeProps={{
        viewMode,
        setViewMode,
      }}
      filters={{
        globalFilter,
        setGlobalFilter,
      }}
      filterValues={{
        filterValue,
        setFilterValue,
      }}
      pagination={{
        ...paginationState,
        nextPage,
        prevPage,
        setPage,
        totalPaginatedPages,
      }}
    />
  );
};

PostProcessors.propTypes = {
  onFilterUpdate: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  viewMode: PropTypes.string,
};

PostProcessors.defaultProps = {
  viewMode: VIEW_MODE_OPTIONS.CARDS,
};

export default PostProcessors;
