import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import Loading from 'rapidfab/components/Loading';
import BuildPlateLineItem from 'rapidfab/components/records/run/BuildPlateLineItem';
import BuildPlateModeToggle from 'rapidfab/components/records/run/BuildPlateModeToggle';
import ChangeInformationText from 'rapidfab/components/records/run/ChangeInformationText';
import { PRODUCTION_CHANGE_OPTIONS, PRODUCTION_CHANGE_TYPES_VALUES } from 'rapidfab/constants';
import { FormattedMessage } from 'rapidfab/i18n';
import Icon, { ICON_NAMES } from 'rapidfab/icons';
import { getLineItemWorkflowTypeObjectKey } from 'rapidfab/utils/lineItemUtils';
import React from 'react';
import { Button, Col, Container, Form, FormControl, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';

const BuildPlateLineItemsMode = ({
  buildPlateToggleProps,
  lineItemsProps,
  productionChangeProps,
  selectAllProps,
  fetching,
  lineItems,
  modelsByUri,
  runTransformations,
  run,
  orders,
  workflowsByUri,
  isWorkflowFetching,
}) => {
  const {
    onBuildPlateModeToggle,
    isLineItemsModeEnabled,
  } = buildPlateToggleProps;

  const {
    lineItemSummaryData,
    selectedLineItems,
    setSelectedLineItems,
  } = lineItemsProps;

  const {
    productionChange,
    setProductionChange,
    isCheckboxDisabled,
    chooseInformationText,
    shouldShowReadMore,
    shouldShowMoreInfo,
    setIsChangeInformationExpanded,
    onSelectPiecesModalOpen,
  } = productionChangeProps;

  const {
    selectAllChecked,
    checkboxDisableValues,
    handleSelectAllCheckChange,
  } = selectAllProps;

  const isScrapChange = productionChange === PRODUCTION_CHANGE_TYPES_VALUES.SCRAP;

  const firstSelectedWorkflow = selectedLineItems.length ? selectedLineItems[0].workflow : null;

  const hasDifferentWorkflows = selectedLineItems.length
    ? lineItems.some(lineItem => lineItem.workflow !== firstSelectedWorkflow)
    : lineItems.length && !lineItems.every(lineItem => lineItem.workflow === lineItems[0].workflow);

  const isNoProductionChangeSelected = !productionChange;
  const isNoPrintsSelected = !selectedLineItems.length;

  if (fetching) {
    return <Loading />;
  }

  return (
    <Container fluid>
      <Row>
        <Col xs={12}>
          <Row>
            <Col sm={{ span: 12 }} lg={{ span: 12 }} className="mb20">
              <BuildPlateModeToggle
                lineItemsMode
                hasSelectedItems={!_isEmpty(selectedLineItems)}
                onBuildPlateModeToggle={onBuildPlateModeToggle}
                isLineItemsModeEnabled={isLineItemsModeEnabled}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={{ span: 12 }} lg={{ span: 12 }}>
              {!lineItems.length
                ? (
                  <h1 className="text-center mt-2">
                    <FormattedMessage
                      id="record.run.noLineItems"
                      defaultMessage="No Line Items Available"
                    />
                  </h1>
                )
                : lineItems.map(lineItem => (
                  <BuildPlateLineItem
                    key={lineItem.uri}
                    lineItem={lineItem}
                    run={run}
                    ordersByUri={orders}
                    isWorkflowFetching={isWorkflowFetching}
                    runTransformations={runTransformations}
                    productionChange={productionChange}
                    lineItemSummaryData={lineItemSummaryData}
                    model={modelsByUri[lineItem[getLineItemWorkflowTypeObjectKey(lineItem)]?.model]}
                    workflow={workflowsByUri[lineItem.workflow]}
                    selectedLineItems={selectedLineItems}
                    setSelectedLineItems={setSelectedLineItems}
                    checkboxDisableValues={checkboxDisableValues}
                    isNoProductionChangeSelected={isNoProductionChangeSelected}
                  />
                ))}
            </Col>
            {!!lineItems.length && (
              <Col sm={{ span: 12 }} lg={{ span: 12 }}>
                <div className="d-flex justify-content-end align-items-start">
                  {productionChange && (!isScrapChange && hasDifferentWorkflows) && (
                    <OverlayTrigger
                      className="ml15"
                      placement="left"
                      overlay={(
                        <Tooltip>
                          <FormattedMessage
                            id="record.run.workflowMismatchTooltipSelectAllLineItemsWarning"
                            defaultMessage="Select All Production Change is only available for Line Items with the same workflow."
                          />
                        </Tooltip>
                      )}
                    >
                      <FontAwesomeIcon className="mt3" icon={faQuestionCircle} />
                    </OverlayTrigger>
                  )}
                  <Form.Check
                    className="spacer-left"
                    checked={selectAllChecked}
                    disabled={isCheckboxDisabled || (!isScrapChange && hasDifferentWorkflows)}
                    onChange={handleSelectAllCheckChange}
                    label={(
                      <>
                        <p className="mb0">Select All Production Change</p>
                      </>
                    )}
                  />
                </div>

                <div className="d-flex justify-content-end align-items-center">
                  <div className="d-flex justify-content-end mr30">
                    <ChangeInformationText>
                      <div className="d-flex align-items-center">
                        <Icon name={ICON_NAMES.INFO} size="large" />
                        <span>
                          <FormattedMessage
                            id="record.run.productionChangeType"
                            defaultMessage="Production Change Information"
                          />
                        </span>
                      </div>
                    </ChangeInformationText>
                    <ChangeInformationText>
                      <span>
                        <FormattedMessage
                          id={chooseInformationText()}
                          defaultMessage="Select a type of Production Change to alter the manufacturing process for a Piece"
                        />
                      </span>
                        &nbsp;
                      {shouldShowReadMore && (
                        <span
                          tabIndex={0}
                          role="button"
                          className="change-info-expander"
                          onClick={() => setIsChangeInformationExpanded(true)}
                        >
                          read more...
                        </span>
                      )}
                        &nbsp;
                      {shouldShowMoreInfo && (
                        <a
                          target="_blank"
                          href="https://authentise.zendesk.com/hc/en-us/articles/360055743712"
                          rel="noreferrer"
                          className="change-info-more-link"
                        >
                          More info
                        </a>
                      )}
                    </ChangeInformationText>
                  </div>
                  <FormControl
                    className="wauto ml5"
                    as="select"
                    onChange={event => setProductionChange(event.target.value)}
                    value={productionChange}
                  >
                    {PRODUCTION_CHANGE_OPTIONS.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </FormControl>
                  <Button
                    onClick={onSelectPiecesModalOpen}
                    bsSize="small"
                    bsStyle="success"
                    className="spacer-left"
                    disabled={isNoProductionChangeSelected || isNoPrintsSelected}
                  >
                    Begin
                  </Button>
                </div>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

BuildPlateLineItemsMode.propTypes = {
  buildPlateToggleProps: PropTypes.shape({
    onBuildPlateModeToggle: PropTypes.func.isRequired,
    isLineItemsModeEnabled: PropTypes.bool.isRequired,
  }).isRequired,
  lineItemsProps: PropTypes.shape({
    lineItemSummaryData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    selectedLineItems: PropTypes.arrayOf(PropTypes.shape({
      uri: PropTypes.string,
      workflow: PropTypes.string,
    })).isRequired,
    setSelectedLineItems: PropTypes.func.isRequired,
    checkboxDisableValues: PropTypes.func.isRequired,
  }).isRequired,
  fetching: PropTypes.bool.isRequired,
  lineItems: PropTypes.arrayOf(PropTypes.shape({
    uri: PropTypes.string,
    workflow: PropTypes.string,
  })).isRequired,
  modelsByUri: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  workflowsByUri: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  productionChangeProps: PropTypes.shape({
    productionChange: PropTypes.string.isRequired,
    setProductionChange: PropTypes.func.isRequired,
    isCheckboxDisabled: PropTypes.bool.isRequired,
    chooseInformationText: PropTypes.func.isRequired,
    shouldShowReadMore: PropTypes.bool.isRequired,
    shouldShowMoreInfo: PropTypes.bool.isRequired,
    setIsChangeInformationExpanded: PropTypes.func.isRequired,
    onClickProductionChange: PropTypes.func.isRequired,
    onSelectPiecesModalOpen: PropTypes.func.isRequired,
  }).isRequired,
  selectAllProps: PropTypes.shape({
    selectAllChecked: PropTypes.bool.isRequired,
    checkboxDisableValues: PropTypes.func.isRequired,
    handleSelectAllCheckChange: PropTypes.func.isRequired,
  }).isRequired,
  run: PropTypes.shape({
    uri: PropTypes.string,
  }).isRequired,
  runTransformations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isWorkflowFetching: PropTypes.bool.isRequired,
  orders: PropTypes.objectOf(PropTypes.shape({})).isRequired,
};

export default BuildPlateLineItemsMode;
