import {
  faArchive,
  faBarcode,
  faBoxOpen, faExternalLink,
  faPencil,
  faStore,
  faTrashAlt, faWaveSquare,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import Loading from 'rapidfab/components/Loading';
import { ROUTES } from 'rapidfab/constants';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';
import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const SubLocationCard = ({
  subLocation,
  lotsCount,
  batchesCount,
  setEditSubLocationModal,
  materialResourcesFetching,
  hasRelatedResources,
  setShowConfirmationModal,
  subLocationDeleting,
  handleSetLocationFilters,
  locationUri,
  selectedSubLocationSensorData,
  isMaterialManagementFeatureEnabled,
}) => {
  if (!subLocation) return null;

  const { serial_number, frequency } = selectedSubLocationSensorData ?? {};

  const renderArchiveButtonIconsState = () => {
    if (subLocationDeleting) {
      return <Loading inline />;
    }

    if (hasRelatedResources) {
      return <FontAwesomeIcon icon={faArchive} />;
    }
    return <FontAwesomeIcon icon={faTrashAlt} />;
  };

  return (
    <div className="sub-location-card">
      <div className="sub-location-card-icon">
        <FontAwesomeIcon icon={faStore} />
      </div>
      <div className="w-100">
        <div className="sub-location-card-body-item">
          <div className="sub-location-card-header-content-item">
            <p className="sub-location-card-header-name">{subLocation.name}</p>
            <p className="sub-location-card-header-uuid">{getShortUUID(subLocation.uuid)}</p>
          </div>
          <div className="sub-location-card-header-content-item">
            <Button
              size="sm"
              variant="link"
              className="action-icon-button"
              name="sub_location_name"
              onClick={() => setEditSubLocationModal(true)}
            >
              <FontAwesomeIcon icon={faPencil} />
            </Button>
            {
              materialResourcesFetching ? (
                <Loading inline className="spacer-left" />
              ) : (
                <Button
                  size="sm"
                  disabled={subLocation?.is_default}
                  variant="link"
                  className="action-icon-button"
                  name="archive_sub_location"
                  onClick={() => setShowConfirmationModal(true)}
                >
                  {renderArchiveButtonIconsState()}
                </Button>
              )
            }
          </div>

        </div>
        <div className="sub-location-card-body-item">

          {isMaterialManagementFeatureEnabled && (
            <div className="sub-location-card-body-resources">
              <div className="sub-location-card-body-resources-item">
                <FontAwesomeIcon icon={faBoxOpen} className="spacer-right" />
                {materialResourcesFetching ? <Loading inline /> : (
                  <>
                    {lotsCount}
                    <Link
                      onClick={() => handleSetLocationFilters(locationUri, subLocation.uri)}
                      rel="noopener noreferrer"
                      className="sub-location-card-body-resources-item-link"
                      to={{
                        pathname: getRouteURI(ROUTES.MATERIAL_LOTS,
                          { uuid: subLocation.uuid },
                          {}, true),
                      }}
                    >
                      <FontAwesomeIcon icon={faExternalLink} />
                    </Link>
                  </>
                )}
              </div>
              <div className="sub-location-card-body-resources-item">
                <FontAwesomeIcon icon={faArchive} className="spacer-right" />
                {materialResourcesFetching ? <Loading inline /> : (
                  <>
                    {batchesCount}
                    <Link
                      onClick={() => handleSetLocationFilters(locationUri, subLocation.uri)}
                      rel="noopener noreferrer"
                      className="sub-location-card-body-resources-item-link"
                      to={{
                        pathname: getRouteURI(ROUTES.MATERIAL_BATCHES,
                          { uuid: subLocation.uuid },
                          {}, true),
                      }}
                    >
                      <FontAwesomeIcon icon={faExternalLink} />
                    </Link>
                  </>
                )}
              </div>
            </div>
          )}

          <div className="sub-location-card-body-rest-info">
            {frequency && (
              <div className="sub-location-card-body-rest-info-item">
                <FontAwesomeIcon icon={faWaveSquare} className="spacer-right" />
                <span>{frequency} min</span>
              </div>
            )}

            {serial_number && (
              <div className="sub-location-card-body-rest-info-item">
                <FontAwesomeIcon icon={faBarcode} className="spacer-right" />
                <span>{serial_number}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

SubLocationCard.propTypes = {
  subLocation: PropTypes.shape({
    name: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
    is_default: PropTypes.bool,
  }).isRequired,
  lotsCount: PropTypes.number.isRequired,
  batchesCount: PropTypes.number.isRequired,
  setEditSubLocationModal: PropTypes.func.isRequired,
  materialResourcesFetching: PropTypes.bool.isRequired,
  hasRelatedResources: PropTypes.bool.isRequired,
  setShowConfirmationModal: PropTypes.func.isRequired,
  subLocationDeleting: PropTypes.bool.isRequired,
  handleSetLocationFilters: PropTypes.func.isRequired,
  locationUri: PropTypes.string.isRequired,
  selectedSubLocationSensorData: PropTypes.shape({
    serial_number: PropTypes.string,
    frequency: PropTypes.number,
  }).isRequired,
  isMaterialManagementFeatureEnabled: PropTypes.bool.isRequired,
};

export default SubLocationCard;
