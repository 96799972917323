import { WORKFLOW_TYPES } from 'rapidfab/constants';

export const getLineItemWorkflowTypeObjectKey = lineItem => {
  switch (lineItem?.workflow_type) {
    case WORKFLOW_TYPES.POWDER_MANUFACTURING:
      return 'powder';
    default:
      return 'additive';
  }
};

export const getLineItemEstimatesTypeObjectKey = lineItem => {
  switch (lineItem?.workflow_type) {
    case WORKFLOW_TYPES.POWDER_MANUFACTURING:
      return 'estimates_powder';
    default:
      return 'estimates_additive';
  }
};

/**
 * Get required custom fields with default values if they are missing in the current values.
 *
 * @param {Array} customFieldReferences - Array of custom field references.
 * @param {Array} customFieldValues - Array of current custom field values.
 * @returns {Array} - Array of required custom fields with default values if not already provided.
 */
export const getRequiredDefaultCustomFields = (customFieldReferences, customFieldValues) => {
  if (!customFieldReferences) return [];

  // Get required custom fields
  const requiredCustomFields = customFieldReferences.filter(field => field.required);

  // If we do not have any required fields, skip the rest of the logic
  if (!requiredCustomFields.length) return [];

  // Create a set of URIs of custom fields that already have values
  const existingCustomFieldUris = new Set(customFieldValues.map(field => field.custom_field));

  // Map through required custom fields to add default values if not already present
  return requiredCustomFields
    .map(customField => {
      if (!existingCustomFieldUris.has(customField.uri)) {
        return {
          custom_field: customField.uri,
          value: customField.default_value,
        };
      }
      return null;
    })
    .filter(Boolean); // Filter out null values
};

export default getLineItemWorkflowTypeObjectKey;
