import PropTypes from 'prop-types';
import PrinterTypeModal from 'rapidfab/components/modals/PrinterTypeModal';
import { PRINTER_TYPE_SECTION_FIELDS_LIST } from 'rapidfab/mappings';
import { someFeaturesEnabled } from 'rapidfab/selectors';
import {
  handleGetMissingRequiredFieldsForSection,
  validateNestedField,
  validateRequiredField,
} from 'rapidfab/utils/formHelpers';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Actions from 'rapidfab/actions';
import {
  API_RESOURCES, BUILD_VOLUME_XYZ_MAXIMUM_VALUE,
  FEATURES,
  PAGINATION_IGNORE_DEFAULT_LIMIT,
  ROUTES,
  WORK_SCHEDULE_TYPES,
  MATERIAL_MODES, MODAL_TEMPLATES, manufacturerDefaultPayload, PRINTER_TYPE_REQUIRED_FIELD_TITLES,
} from 'rapidfab/constants';
import * as Selectors from 'rapidfab/selectors';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import Alert from 'rapidfab/utils/alert';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _isEmpty from 'lodash/isEmpty';
import {
  getDurationSecondsFromHoursAndMinutes,
  getHoursAndMinutesFromDurationSecs,
} from 'rapidfab/utils/timeUtils';
import { PRINTER_TYPE_CONTAINER } from 'rapidfab/constants/forms';
import dayjs from 'dayjs';
import _capitalize from 'lodash/capitalize';
import _clone from 'lodash/clone';
import _omit from 'lodash/omit';
import objectSupport from 'dayjs/plugin/objectSupport';
import { weekDays } from 'rapidfab/constants/dates';
import { FormattedMessage } from 'react-intl';
import { useModal } from 'rapidfab/hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PRINTER_TYPE_BUILD_PLATE_TYPES from 'rapidfab/constants/printerTypeBuildPlates';

function redirect() {
  window.location.hash = getRouteURI(ROUTES.PRINTER_TYPES);
}

const initialGroupedSectionsVisibilityState = {
  general: true,
  buildPacking: true,
  costPricing: true,
  advanced: true,
};

const PrinterTypeModalContainer = props => {
  const prosperFeature = FEATURES.EXTERNAL_PRODUCTION_ESTIMATE;
  const [searchParams] = useSearchParams();
  const uuid = searchParams.get('uuid');
  const printerType = useSelector(state => Selectors.getUUIDResource(state, uuid));
  const defaultCurrency = useSelector(Selectors.getBureauDefaultCurrency);
  const laborByPrinterType = useSelector(Selectors.getLaborByPrinterType);
  const [openWarningModal, WarningModal] = useModal();
  const navigate = useNavigate();

  const laborData = laborByPrinterType[printerType?.uri];

  const initialValues = {
    description: '',
    constant_overhead: 0,
    ...printerType,
  };

  const fetching = useSelector(state => state.ui.nautilus[API_RESOURCES.PRINTER_TYPE].get.fetching
    || state.ui.nautilus[API_RESOURCES.MATERIAL].list.fetching
    || state.ui.nautilus[API_RESOURCES.CUSTOM_GROUP].list.fetching
    || state.ui.nautilus[API_RESOURCES.PROSPER].list.fetching
    || state.ui.nautilus[API_RESOURCES.INFILL_STRATEGY].list.fetching
    || state.ui.nautilus[API_RESOURCES.SUPPORT_STRATEGY].list.fetching
    || state.ui.nautilus[API_RESOURCES.PROSPER_PRINTER_MATERIAL].list.fetching);

  const deleting = useSelector(state => state.ui.nautilus[API_RESOURCES.PRINTER_TYPE].delete.fetching);

  const isManufacturerFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.MANUFACTURER].post.fetching);

  const submitting = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.PRINTER_TYPE].put.fetching
  || state.ui.nautilus[API_RESOURCES.PRINTER_TYPE].post.fetching);

  const someFeaturesAdvancedSectionEnabled = useSelector(
    state => someFeaturesEnabled(state, [FEATURES.WORK_SCHEDULE, FEATURES.JENI_CLUSTER]),
  );

  const initialFormValues = {};
  Object
    .keys(initialValues)
    .filter(key => PRINTER_TYPE_CONTAINER.FIELDS.includes(key))
    .forEach(key => {
      initialFormValues[key] = initialValues[key];
    });

  PRINTER_TYPE_CONTAINER.NULL_FIELDS.forEach(fieldName => {
    if (initialFormValues[fieldName] === null) {
      initialFormValues[fieldName] = '';
    }
  });

  initialFormValues.duration = getHoursAndMinutesFromDurationSecs(laborData?.duration) || '00:00';
  initialFormValues.labor_enhanced_workflow_enabled = !_isEmpty(laborData);
  initialFormValues.integrated = initialFormValues.integrated || false;

  if (props.createRecordOnTheFlyProps) {
    const { name } = props.createRecordOnTheFlyProps;
    initialFormValues.name = name;
  }

  const bureau = useSelector(Selectors.getBureau).uri;
  const manufacturers = useSelector(Selectors.getSortedManufacturersByName);
  const customGroups = useSelector(Selectors.getCustomGroups);
  const materials = useSelector(Selectors.getMaterials);
  const materialsByUri = useSelector(Selectors.getMaterialsByUri);
  const infillStrategies = useSelector(Selectors.getInfillStrategies);
  const prospers = useSelector(Selectors.getProspers);
  const supportStrategies = useSelector(Selectors.getSupportStrategies);
  const isProsperFeatureEnabled = useSelector(state => Selectors.isFeatureEnabled(state, prosperFeature));
  const isGroupQualificationsFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.GROUP_QUALIFICATIONS));
  const prosperPrinterMaterials = useSelector(Selectors.getProsperPrinterMaterials);
  const fromTemplate = printerType && printerType.base_template !== null;
  const use100Infill = useSelector(state => Selectors.isFeatureEnabled(state, FEATURES.USE_100_INFILL));

  const isWorkScheduleFeatureEnabled = useSelector(state => Selectors.isFeatureEnabled(state, FEATURES.WORK_SCHEDULE));
  const isPack3dPremiumFeatureEnabled = useSelector(state => Selectors.isFeatureEnabled(
    state,
    FEATURES.PACK3D_PREMIUM,
  ));

  const workSchedule = useSelector(state => (isWorkScheduleFeatureEnabled ?
    Selectors.getWorkSchedulesForPrinterType(state, printerType?.uri) : null),
  );
  const [workScheduleEnabled, setWorkScheduleEnabled] = useState(workSchedule);
  const [isLaborWorkflowEnabled, setIsLaborWorkflowEnabled] = useState(
    initialFormValues.laborType !== undefined,
  );

  const [multiMaterialIsActive, setMultiMaterialIsActive] =
    useState(initialFormValues?.material_mode === MATERIAL_MODES.MULTI_MATERIAL);

  const [activePlateType, setActivePlateType] =
    useState(initialFormValues?.build_plate_shape || PRINTER_TYPE_BUILD_PLATE_TYPES.RECTANGULAR);

  const [groupedSectionsVisibilityState, setGroupedSectionsVisibilityState] =
    useState(initialGroupedSectionsVisibilityState);

  const [sectionsWithMissingFields, setSectionsWithMissingFields] = useState({});

  const handleCloseModal = () => {
    if (props.isVisible) {
      return props.hideModal();
    }
    return navigate(getRouteURI(ROUTES.PRINTER_TYPES, null, null, true), { replace: true });
  };

  const [isDurationInvalid, setIsDurationInvalid] = useState(false);
  const [workDays, setWorkDays] = useState([]);
  const weekDaysTimeMapping = {};
  if (isWorkScheduleFeatureEnabled) {
    dayjs.extend(objectSupport);
    weekDays.forEach(day => {
      weekDaysTimeMapping[day] = {
        start: dayjs({
          h: 9,
          m: 0,
        }).format('HH:mm'),
        finish: dayjs({
          h: 17,
          m: 0,
        }).format('HH:mm'),
      };
    });
  }

  useEffect(() => {
    setIsLaborWorkflowEnabled(initialFormValues.laborType !== undefined);
  }, [initialFormValues.laborType]);

  useEffect(() => {
    setMultiMaterialIsActive(initialFormValues?.material_mode === MATERIAL_MODES.MULTI_MATERIAL);
  }, [initialFormValues.material_mode]);

  useEffect(() => {
    if (uuid) {
      setGroupedSectionsVisibilityState({
        general: true,
        buildPacking: false,
        costPricing: false,
        advanced: false,
      });
    } else {
      setGroupedSectionsVisibilityState(initialGroupedSectionsVisibilityState);
    }
  }, [uuid]);

  const [laborScheduleTime, setLaborScheduleTime] = useState(weekDaysTimeMapping);
  const [laborNonStopEnabled, setLaborNonStopEnabled] = useState(false);
  const [UTC_TimeZone, setUTC_TimeZone] = useState(workSchedule?.utc_offset || 0);

  const selected = {
    bureau,
    manufacturers,
    customGroups,
    materials,
    materialsByUri,
    infillStrategies,
    prospers,
    supportStrategies,
    isProsperFeatureEnabled,
    isGroupQualificationsFeatureEnabled,
    prosperPrinterMaterials,
    initialFormValues,
    uuid,
    printerType,
    fromTemplate,
    defaultCurrency,
    use100Infill,
    fetching,
    submitting,
    isManufacturerFetching,
    laborScheduleTime,
    laborNonStopEnabled,
    workScheduleEnabled,
    workDays,
    UTC_TimeZone,
    multiMaterialIsActive,
    activePlateType,
    setActivePlateType,
    groupedSectionsVisibilityState,
    someFeaturesAdvancedSectionEnabled,
    deleting,
    sectionsWithMissingFields,
  };

  const dispatch = useDispatch();

  const onInitialize = (currentBureau, currentUUID, prosperFeatureEnabled) => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].list({ bureau: currentBureau }));
    dispatch(Actions.Api.nautilus[API_RESOURCES.CUSTOM_GROUP].list({}, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
    dispatch(Actions.Api.nautilus[API_RESOURCES.MANUFACTURER].list());

    if (currentUUID) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER_TYPE].get(currentUUID));
    }

    if (prosperFeatureEnabled) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.PROSPER].list());
      dispatch(Actions.Api.nautilus[API_RESOURCES.INFILL_STRATEGY].list());
      dispatch(Actions.Api.nautilus[API_RESOURCES.SUPPORT_STRATEGY].list());
      if (printerType?.uri) {
        dispatch(Actions.Api.nautilus[API_RESOURCES.PROSPER_PRINTER_MATERIAL].list({
          printer_type: printerType.uri,
        }));
      }
    }
    if (isWorkScheduleFeatureEnabled && printerType?.uri) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.WORK_SCHEDULE].list({
        printer_type: printerType?.uri,
      }));
    }
  };

  const onInitializeLaborData = () => printerType && isWorkScheduleFeatureEnabled &&
    dispatch(Actions.Api.nautilus[API_RESOURCES.LABOR].list({
      printer_type: printerType.uri,
    }));

  const handleSetSectionVisibilityState = section => {
    if (!section) return;
    setGroupedSectionsVisibilityState(previous => ({
      ...previous,
      [section]: !previous[section],
    }));
  };

  const handleParseLaborPayload = printerTypeURI => {
    const week = Object.entries(laborScheduleTime).map(([day, time]) => {
      const { start, finish } = time;
      if (!workDays.includes(day)) {
        return null;
      }
      return {
        [day.toLowerCase()]: {
          start: {
            h: Number(start.split(':')[0]),
            m: Number(start.split(':')[1]),
          },
          finish: {
            h: Number(finish.split(':')[0]),
            m: Number(finish.split(':')[1]),
          },
        },
      };
    });

    const payload = {
      type: WORK_SCHEDULE_TYPES.CALENDAR,
      printer_type: printerTypeURI,
      utc_offset: +UTC_TimeZone,
    };

    if (laborNonStopEnabled) {
      payload.type = WORK_SCHEDULE_TYPES.NON_STOP;
    } else {
      payload.week = Object.assign({}, ...week);
    }

    return payload;
  };

  const handleCreateLaborScheduleTime = printerTypeUri => {
    if (!isWorkScheduleFeatureEnabled || !printerTypeUri) {
      // No need to do any actions, we either do not have URi or the feature is disabled
      return;
    }

    if (workSchedule && !workScheduleEnabled) {
      /* This means we have some data of work schedule but the customer manually
         disabled this component which means we should remove all the work-schedule data
         related to this Printer Type (DELETE) */
      dispatch(Actions.Api.nautilus[API_RESOURCES.WORK_SCHEDULE].delete(extractUuid(workSchedule.uri)));
    }

    if (workScheduleEnabled) {
      // In this case the customer manually set the "Labor Available Schedule" as Enabled (clicked on checkbox)
      const payload = handleParseLaborPayload(printerTypeUri);

      if (workSchedule) {
        // This means the customer had already created the schedule and we need to update the values on the BE (PUT)
        dispatch(Actions.Api.nautilus[API_RESOURCES.WORK_SCHEDULE].put(extractUuid(workSchedule.uri), payload));
      } else {
        /* No schedule was created and the customer would like to create the new one
           (previously set some date time or left default values) (POST) */
        dispatch(Actions.Api.nautilus[API_RESOURCES.WORK_SCHEDULE].post(payload));
      }
    }
  };

  const handleCreateLaborData = (printerTypeUri, formValues) => {
    const laborPayload = {
      type: 'labor_to_start',
      duration: getDurationSecondsFromHoursAndMinutes(formValues.duration),
      printer_type: printerTypeUri,
    };

    if (!_isEmpty(laborData)) {
      /* Labor already exists, PUT onto existing */
      dispatch(Actions.Api.nautilus[API_RESOURCES.LABOR]
        .put(extractUuid(laborData.uri), laborPayload));
    } else {
      /* Labor doesn't exist, POST to create new */
      dispatch(Actions.Api.nautilus[API_RESOURCES.LABOR].post(laborPayload));
    }
  };

  const onFormSubmit = payload => {
    const formValues = _clone(payload);
    const updatedPayload = _omit(
      formValues,
      ['duration', 'labor_enhanced_workflow_enabled'],
    );
    updatedPayload.build_plate_shape = activePlateType;

    updatedPayload.material_mode =
      multiMaterialIsActive ? MATERIAL_MODES.MULTI_MATERIAL : MATERIAL_MODES.SINGLE_MATERIAL;

    if (workScheduleEnabled && (!workDays.length && !laborNonStopEnabled)) {
      Alert.error(
        <FormattedMessage
          id="toaster.error.labor.requireAtLeastOneDay"
          defaultMessage="Labor settings requires that at least one day is selected"
        />);
      return;
    }

    // delete readonly fields
    delete updatedPayload.flow_time;
    delete updatedPayload.flow_time_queued;

    // clears form of d value if rectangular build plate is used
    if (updatedPayload.build_plate_shape === PRINTER_TYPE_BUILD_PLATE_TYPES.RECTANGULAR) {
      delete updatedPayload.build_volume.d;
    }

    // clears form of x and y values if circular build plate is used
    if (updatedPayload.build_plate_shape === PRINTER_TYPE_BUILD_PLATE_TYPES.CIRCULAR) {
      delete updatedPayload.build_volume.x;
      delete updatedPayload.build_volume.y;
    }

    PRINTER_TYPE_CONTAINER.FLOAT_FIELDS.forEach(fieldName => {
      const field = _get(updatedPayload, fieldName);
      if (field) {
        _set(updatedPayload, fieldName, Number.parseFloat(field));
      }
    });

    if (!payload.edit_group) {
      _set(updatedPayload, 'edit_group', null);
    }

    PRINTER_TYPE_CONTAINER.INTEGER_FIELDS.forEach(fieldName => {
      const field = _get(updatedPayload, fieldName);
      if (field) {
        _set(updatedPayload, fieldName, Number(field));
      }
    });

    PRINTER_TYPE_CONTAINER.NULL_FIELDS.forEach(fieldName => {
      if (updatedPayload[fieldName] === '') {
        updatedPayload[fieldName] = null;
      }
    });

    const buildVolumeValues = _get(updatedPayload, 'build_volume', {});

    const totalBuildVolumeValue = Object.values(buildVolumeValues)
      // eslint-disable-next-line unicorn/no-array-reduce
      .reduce((total, value) => total + value);

    if (totalBuildVolumeValue > BUILD_VOLUME_XYZ_MAXIMUM_VALUE) {
      Alert.error(<FormattedMessage
        id="toaster.printerType.buildVolumeTotalValue"
        defaultMessage="The Build Volume values X, Y, and Z in total should not exceed 1 km"
      />);
      return;
    }

    /* Handle case: If labor already exists but user unchecks labor workflow */
    if (laborData && formValues.labor_enhanced_workflow_enabled === false) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.LABOR].delete(extractUuid(laborData.uri)));
    }

    if (updatedPayload.uuid) {
      dispatch(
        Actions.Api.nautilus[API_RESOURCES.PRINTER_TYPE].put(updatedPayload.uuid, updatedPayload),
      )
        .then(() => handleCreateLaborScheduleTime(printerType?.uri))
        .then(() => formValues.labor_enhanced_workflow_enabled &&
          handleCreateLaborData(printerType?.uri, formValues))
        .then(() => Alert.success(<FormattedMessage
          id="toaster.printerType.updated"
          defaultMessage="Printer Type successfully updated."
        />));
    } else {
      dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER_TYPE].post(updatedPayload)).then(
        printerTypeResponse => {
          const { uri } = printerTypeResponse.payload;
          handleCreateLaborScheduleTime(uri);
          if (formValues.labor_enhanced_workflow_enabled) {
            handleCreateLaborData(uri, formValues);
          }
          Alert.success(<FormattedMessage
            id="toaster.printerType.created"
            defaultMessage="Printer Type successfully created"
          />);

          if (props.createRecordOnTheFlyProps) {
            const { confirmOnTheFlyCreation } = props.createRecordOnTheFlyProps;
            confirmOnTheFlyCreation(uri);
            dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER_TYPE].list());
            handleCloseModal();
          } else {
            navigate(getRouteURI(ROUTES.PRINTER_TYPES, null, { uuid: extractUuid(uri) }, true), { replace: true });
            openWarningModal({
              modalTemplate: MODAL_TEMPLATES.YES_OR_NO,
              title: '',
              bodyText: <FormattedMessage
                id="message.mustHavePrinterToUseInRun"
                defaultMessage="To use this printer type in a run you must have a printer of this type, do you want to create one now?"
              />,
              onConfirm: () => {
                navigate(
                  getRouteURI(
                    ROUTES.PRINTER_NEW,
                    {},
                    { printerType: uri },
                    true,
                  ),
                );
              },
            });
          }
        });
    }
  };
  const onDelete = currentUUID => {
    if (currentUUID) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER_TYPE].delete(currentUUID))
        .then(() => Alert.success(
          <FormattedMessage
            id="toaster.printerType.deleted"
            defaultMessage="Printer Type {uuid} successfully deleted."
            values={{ uuid: currentUUID }}
          />,
        ))
        .finally(redirect);
    }
  };

  useEffect(() => onInitialize(bureau, uuid, isProsperFeatureEnabled), [uuid, printerType?.uri]);
  useEffect(() => onInitializeLaborData, [printerType]);

  const handleChangeLaborTime = (event, day, type) => {
    const { value } = event.target;

    const parsedValue = dayjs({
      hour: value.split(':')[0],
      minute: value.split(':')[1],
    }).format('HH:mm');

    setLaborScheduleTime(previous => ({
      ...previous,
      [day]: {
        ...previous[day],
        [type]: parsedValue,
      },
    }));
    return true;
  };
  const handleTransformLaborPayload = payload => {
    if (payload.type === WORK_SCHEDULE_TYPES.NON_STOP) {
      return setLaborNonStopEnabled(true);
    }

    // Transform the API data first
    const weekPayloadData = Object.assign({},
      ...Object.entries(payload.week).map(([day, time]) => {
        const { start, finish } = time;

        return {
          [_capitalize(day)]: {
            start: dayjs({
              h: start.h,
              m: start.m,
            }).format('HH:mm'),
            finish: dayjs({
              h: finish.h,
              m: finish.m,
            }).format('HH:mm'),
          },
        };
      }));

    /* Taking into account the BE data could have only particular days and not the whole
       list of the weekdays, we should fill in the rest of the list with the default data in order
       to get all the weekdays and display the API data time and default time so the user could change it. */

    const defaultWeekData = Object.assign({},
      ...Object.entries(weekDaysTimeMapping).map(([day, time]) => {
        const { start, finish } = time;
        if (!Object.keys(weekPayloadData).includes(day)) {
          return {
            [day]: {
              start,
              finish,
            },
          };
        }
        return null;
      }).filter(Boolean));

    /* Combine API days and if it is not all the list of the weekdays
        -> fill in the rest of it by the default data contains of days. */
    const week = { ...weekPayloadData, ...defaultWeekData };

    // Set the work days checked as "[x] Workday" from API.
    setWorkDays(Object.keys(weekPayloadData));
    // Fill in the labor schedule time with all the data (API + default).
    return setLaborScheduleTime(week);
  };
  const handleSetWorkday = day => {
    if (workDays.includes(day)) {
      setWorkDays(previous => previous.filter(d => d !== day));
    } else {
      setWorkDays(previous => [...previous, day]);
    }
  };

  useEffect(() => {
    if (workSchedule) {
      /* If we have work-schedule data on BE, we should transform its values
         to access them in our component. */
      handleTransformLaborPayload(workSchedule);
      setUTC_TimeZone(workSchedule?.utc_offset);
      setWorkScheduleEnabled(true);
    } else {
      setWorkScheduleEnabled(false);
      setUTC_TimeZone(0);
      setWorkDays([]);
      setLaborScheduleTime(weekDaysTimeMapping);
    }
  }, [workSchedule]);

  const handleSetLaborNonStopTime = () => setLaborNonStopEnabled(previous => !previous);

  const handleTriggerWorkSchedule = () => setWorkScheduleEnabled(previous => !previous);

  const handleMaterialModeSetting = () => setMultiMaterialIsActive(previous => !previous);

  const handleUTC_TimezoneSetting = value => setUTC_TimeZone(value);

  const handleSubmitManufacturer = async name => {
    if (!name) {
      return null;
    }

    const payload = { name, ...manufacturerDefaultPayload };
    const manufacturerResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.MANUFACTURER].post(payload));
    const manufacturerUri = manufacturerResponse?.headers?.location;

    if (manufacturerUri) {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.MANUFACTURER].list());
      Alert.success(
        <FormattedMessage
          id="toaster.manufacturer.created"
          defaultMessage="Manufacturer successfully created."
        />,
      );
      return manufacturerUri;
    }

    return null;
  };

  const validateMissingFormFields = ({
    name,
    description,
    materials,
    time_before_print,
    time_after_print,
    manufacturer,
    min_packing_distance,
    running_cost_per_hour,
    build_volume = {},
  }) => {
    const errors = {
      name: validateRequiredField(name, PRINTER_TYPE_CONTAINER.FIELDS.name),
      description: validateRequiredField(description, PRINTER_TYPE_CONTAINER.FIELDS.description),
      materials: validateRequiredField(materials, PRINTER_TYPE_CONTAINER.FIELDS.materials),
      time_before_print: validateRequiredField(time_before_print, PRINTER_TYPE_CONTAINER.FIELDS.time_before_print),
      time_after_print: validateRequiredField(time_after_print, PRINTER_TYPE_CONTAINER.FIELDS.time_after_print),
      manufacturer: validateRequiredField(manufacturer, PRINTER_TYPE_CONTAINER.FIELDS.manufacturer),
      min_packing_distance: validateRequiredField(min_packing_distance,
        PRINTER_TYPE_CONTAINER.FIELDS.min_packing_distance),
      running_cost_per_hour: validateRequiredField(running_cost_per_hour,
        PRINTER_TYPE_CONTAINER.FIELDS.running_cost_per_hour),
    };

    const buildVolumeXError = validateNestedField(build_volume.x, 'build_volume.x');
    const buildVolumeYError = validateNestedField(build_volume.y, 'build_volume.y');
    const buildVolumeZError = validateNestedField(build_volume.z, 'build_volume.z');
    const buildVolumeDError = validateNestedField(build_volume.d, 'build_volume.d');

    if (activePlateType === PRINTER_TYPE_BUILD_PLATE_TYPES.RECTANGULAR
      && (buildVolumeXError || buildVolumeYError || buildVolumeZError)) {
      errors.build_volume = {
        ...buildVolumeXError,
        ...buildVolumeYError,
        ...buildVolumeZError,
      };
    }

    if (activePlateType === PRINTER_TYPE_BUILD_PLATE_TYPES.CIRCULAR
      && (buildVolumeDError || buildVolumeZError)) {
      errors.build_volume = {
        ...buildVolumeDError,
        ...buildVolumeZError,
      };
    }

    return {
      someFieldsNotValidated: Object.values(errors).some(Boolean),
      errors,
    };
  };
  const handleValidationErrors = errors => {
    const { missingRequiredFieldsBySections, sectionsToExpand } =
      handleGetMissingRequiredFieldsForSection(
        errors,
        PRINTER_TYPE_SECTION_FIELDS_LIST,
        PRINTER_TYPE_REQUIRED_FIELD_TITLES,
        'general',
      );

    // Update the state with sections that have missing fields
    setSectionsWithMissingFields(missingRequiredFieldsBySections);

    // Update the state to expand the sections that have required fields empty
    setGroupedSectionsVisibilityState(prevState => ({
      ...prevState,
      ...sectionsToExpand,
    }));
  };

  const handleSubmit = values => {
    const formValidationResults = validateMissingFormFields(values);
    if (!formValidationResults.someFieldsNotValidated) {
      onFormSubmit(values);
      setSectionsWithMissingFields({});
    } else {
      handleValidationErrors(formValidationResults.errors);
    }
  };

  return (
    <><WarningModal id="printerWarningModal" />
      <PrinterTypeModal
        {...props}
        {...selected}
        handleSubmitManufacturer={handleSubmitManufacturer}
        onFormSubmit={handleSubmit}
        onDelete={onDelete}
        laborWorkstationEnable={() => { setIsLaborWorkflowEnabled(!isLaborWorkflowEnabled); }}
        isLaborWorkflowEnabled={isLaborWorkflowEnabled}
        handleChangeLaborTime={handleChangeLaborTime}
        handleSetLaborNonStopTime={handleSetLaborNonStopTime}
        handleTriggerWorkSchedule={handleTriggerWorkSchedule}
        isWorkScheduleFeatureEnabled={isWorkScheduleFeatureEnabled}
        isPack3dPremiumFeatureEnabled={isPack3dPremiumFeatureEnabled}
        handleSetWorkday={handleSetWorkday}
        durationState={{
          isDurationInvalid,
          setIsDurationInvalid,
        }}
        handleSetSectionVisibilityState={handleSetSectionVisibilityState}
        handleUTC_TimezoneSetting={handleUTC_TimezoneSetting}
        handleMaterialModeSetting={handleMaterialModeSetting}
        handleCloseModal={handleCloseModal}

      />
    </>
  );
};

PrinterTypeModalContainer.defaultProps = {
  createRecordOnTheFlyProps: null,
};

PrinterTypeModalContainer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  createRecordOnTheFlyProps: PropTypes.oneOf([PropTypes.shape({
    name: PropTypes.string,
    confirmOnTheFlyCreation: PropTypes.func,
  }), null]),
};

export default PrinterTypeModalContainer;
