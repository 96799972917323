import { faClose, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _map from 'lodash/map';
import _some from 'lodash/some';
import PropTypes from 'prop-types';
import UseNonMfgLanguageFeature from 'rapidfab/components/generalMfgLanguage/UseNonMfgLanguageFeature';
import TableWithSearching from 'rapidfab/components/Tables/TableWithSearching';
import { ROUTES, RUN_STATUSES } from 'rapidfab/constants';
import { useModal } from 'rapidfab/hooks';
import { FormattedDateTime } from 'rapidfab/i18n';
import { RUN_OPERATION_MAP, RUN_OPERATION_TO_TYPE_KEY_MAP, RUN_STATUS_MAP } from 'rapidfab/mappings';
import Alert from 'rapidfab/utils/alert';
import { getRunFill } from 'rapidfab/utils/getRunName';
import { getPriorityLabel, pluralWord } from 'rapidfab/utils/stringUtils';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import React, { useCallback, useMemo } from 'react';
import { Button, Card, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import WrappedRunMultiEditModal from './RunMultiEditModal';

const Runs = componentProps => {
  const {
    data,
    fetching,
    onSort,
    scheduledRunsByRunUri,
    selectedRunsForEdit,
    setSelectedRunsForEdit,
    onUpdateRun,
    grouping,
  } = componentProps;
  const runsWithLockedState = _map(data, oldRun => ({
    ...oldRun,
    prints_fill: getRunFill(oldRun),
  }));

  const [openMultiEditRunModal, RunMultiEditModal, closeMultiEditRunModal] = useModal(WrappedRunMultiEditModal);
  const handleOpenMultiEditRunModal = () => {
    // Guard: check WS-types are the same before opening modal.
    if (selectedRunsForEdit.every(run => {
      const runAggregateWorkstationType = RUN_OPERATION_TO_TYPE_KEY_MAP[run?.operation];
      return run?.[runAggregateWorkstationType] !== selectedRunsForEdit[0]?.[runAggregateWorkstationType];
    })) {
      Alert.error("There is a discrepancy between selected runs' workstation types, please check and try again.");
      return;
    }

    openMultiEditRunModal({
      onConfirm: async values => {
        await onUpdateRun(values);
        setSelectedRunsForEdit([]);
        closeMultiEditRunModal();
      },
      onClose: closeMultiEditRunModal,
      selectedRunsForEdit,
    });
  };

  const toggleSetSelectedRunsForEdit = selectedRun => {
    /** List of runs marked to edit contains this selected run. */
    const selectedRunsDoesContainsSelectedRecord = _some(selectedRunsForEdit, run => _isEqual(run, selectedRun));
    setSelectedRunsForEdit(previous => (selectedRunsDoesContainsSelectedRecord ?
      selectedRunsForEdit.filter(run => !_isEqual(run, selectedRun)) : [...previous, selectedRun]));
  };

  /** Render UI to allow multi-editing of run records based on checked/marked. */
  const renderMultiRecordSelectorView = useCallback(() => {
    if (selectedRunsForEdit?.length) {
      return (
        <Card bg="dark">
          <Card.Header className="pd-exp inverse">
            <div className="d-flex justify-content-between align-items-center">
              Edit Runs
              <div style={{ gap: 5 }} className="d-flex justify-content-between align-items-center">
                <Button variant="danger" size="xs" onClick={() => setSelectedRunsForEdit([])}>
                  <FontAwesomeIcon icon={faClose} />
                </Button>
                <Button size="xs" onClick={handleOpenMultiEditRunModal}>
                  Edit ({selectedRunsForEdit.length}) {pluralWord('Record', selectedRunsForEdit)}
                </Button>
              </div>
            </div>
          </Card.Header>
        </Card>
      );
    }
    return null;
  }, [selectedRunsForEdit]);

  const regularColumns = [
    {
      type: 'uuid',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      resource: 'run',
    },
    {
      type: 'translatable',
      uid: 'field.status',
      accessor: 'status',
      defaultMessage: 'Status',
      mapping: RUN_STATUS_MAP,
      isSortable: true,
    },
    {
      type: 'time',
      uid: 'created',
      accessor: 'created',
      defaultMessage: 'Created',
      isSortable: true,
    },
    {
      type: 'record',
      uid: 'field.runName',
      accessor: 'name',
      defaultMessage: 'Run Name',
      resource: 'run',
      uri: 'uri',
      isSortable: true,
    },
    {
      type: 'translatable',
      uid: 'field.operation',
      accessor: 'operation',
      defaultMessage: 'Operation',
      mapping: RUN_OPERATION_MAP,
      isSortable: true,
    },
    {
      type: 'text',
      uid: 'workstation.type',
      accessor: 'workstation_type_name',
      defaultMessage: 'Workstation Type',
      isSortable: true,
    },
    {
      type: 'text',
      uid: 'workstation.name',
      accessor: 'workstation_name',
      defaultMessage: 'Workstation Name',
      isSortable: true,
    },
  ];

  /** Rendered only when a grouping is selected. */
  const groupingColumns = useMemo(() => [
    {
      type: 'text',
      uid: 'field.workstationQueuePosition',
      accessor: 'workstation_queue_position',
      defaultMessage: 'Queue Position',
      resource: 'run',
    },
    {
      type: 'translatable',
      uid: 'field.status',
      accessor: 'status',
      defaultMessage: 'Status',
      mapping: RUN_STATUS_MAP,
      isSortable: true,
    },
    {
      type: 'custom',
      uid: 'field.priority',
      accessor: 'priority',
      defaultMessage: 'Priority Level',
      resource: 'run',
      Cell: cellData => {
        const { row: { original: data } } = cellData;
        return <span>{getPriorityLabel(data.priority)}</span>;
      },
    },
    {
      type: 'record',
      uid: 'field.runName',
      accessor: 'name',
      defaultMessage: 'Run Name',
      resource: 'run',
      uri: 'uri',
      isSortable: true,
    },
    {
      type: 'custom',
      uid: 'field.scheduledStart',
      accessor: 'scheduled_start',
      defaultMessage: 'Scheduled Start',
      Cell: cellData => {
        const { row: { original: data } } = cellData;
        return <FormattedDateTime value={scheduledRunsByRunUri[data?.uri]?.estimates?.start} />;
      },
    },
    {
      type: 'custom',
      uid: 'field.scheduledEnd',
      accessor: 'scheduled_end',
      defaultMessage: 'Scheduled End',
      Cell: cellData => {
        const { row: { original: data } } = cellData;
        return <FormattedDateTime value={scheduledRunsByRunUri[data?.uri]?.estimates?.end} />;
      },
    },
    {
      type: 'custom',
      uid: 'field.edit',
      accessor: 'edit',
      defaultMessage: 'Edit',
      Cell: cellData => {
        const { row: { original: data } } = cellData;
        // Dynamically determine whether post-processor-type, printer-type, or shipping.
        const runAggregateWorkstationType = RUN_OPERATION_TO_TYPE_KEY_MAP[data?.operation];

        const isButtonDisabled = useMemo(() => {
          // Run's status cannot be selectable if its status is in-progress or complete.
          if ([RUN_STATUSES.IN_PROGRESS, RUN_STATUSES.COMPLETE].includes(data?.status)) {
            return true;
          }

          // Post-processor-types/printer_types/shipping cannot be null or falsy.
          if (_isEmpty(data?.[runAggregateWorkstationType])) {
            return true;
          }

          // Only allow selection for runs of the same post-processor-type
          if (selectedRunsForEdit.length > 0 &&
            selectedRunsForEdit?.[0][runAggregateWorkstationType] !== data[runAggregateWorkstationType]) {
            return true;
          }

          return false;
        }, [data?.status]);

        return isButtonDisabled ? (
          <OverlayTrigger
            overlay={(
              <Tooltip>
                Runs must be of the same post-processor-type and not in-progress or complete.
              </Tooltip>
            )}
          >
            <Form.Check checked={false} onChange={event => event.preventDefault()} />
          </OverlayTrigger>
        ) : (
          <Form.Check
            disabled={isButtonDisabled}
            checked={_some(selectedRunsForEdit, run => _isEqual(run, data))}
            onChange={() => toggleSetSelectedRunsForEdit(data)}
          />
        );
      },
    },
  ], [scheduledRunsByRunUri, selectedRunsForEdit]);

  const columns = grouping ? groupingColumns : regularColumns;

  const NavbarLinks = (
    <Button
      variant="primary"
      size="sm"
      href={getRouteURI(ROUTES.RUN_CREATE)}
      className="pull-right"
    >
      <FontAwesomeIcon icon={faPlus} />{' '}
      <UseNonMfgLanguageFeature
        mfgLanguageComponent={
          <FormattedMessage id="record.run.add" defaultMessage="Add Run" />
        }
        nonMfgLanguageComponent={(
          <FormattedMessage
            id="mfg.addRun.Schedule"
            defaultMessage="Schedule"
          />
        )}
      />
    </Button>
  );

  return (
    <>
      <RunMultiEditModal id="runMultiEditModal" />
      <TableWithSearching
        {...componentProps}
        data={runsWithLockedState}
        columns={columns}
        isFetching={fetching}
        withBreadcrumbs
        breadcrumbs={['plan', 'runs']}
        navbar={NavbarLinks}
        isManualSoringEnabled
        manualSortingFunc={onSort}
        initialSortedDesc
        initialSortedColumn="updated"
        isUpdatedColumnShown={false}
        tableHeadComponent={renderMultiRecordSelectorView()}
      />
    </>
  );
};

Runs.defaultProps = {
  sort: '',
  search: '',
};

Runs.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  onLimitChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onFilterUpdate: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  sort: PropTypes.string,
  search: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  recordListItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  filters: PropTypes.shape({}).isRequired,
  recordListStore: PropTypes.shape({}).isRequired,
  offset: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
};

export default Runs;
