import { faCommenting } from '@fortawesome/free-regular-svg-icons';
import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ROUTES } from 'rapidfab/constants';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import UseNonMfgLanguageFeature from 'rapidfab/components/generalMfgLanguage/UseNonMfgLanguageFeature';
import {
  faArchive,
  faArrowDownShortWide, faBarsProgress,
  faBarsStaggered,
  faBox,
  faBoxesPacking, faBoxesStacked, faBoxOpen,
  faBriefcase,
  faBusinessTime, faCalendarCheck,
  faCog,
  faCoins,
  faCubes,
  faDiceD6, faDollar,
  faFax, faFile,
  faGear, faHammer,
  faHome,
  faIndustry,
  faList,
  faListCheck,
  faLocationDot, faObjectGroup,
  faPen,
  faPenToSquare,
  faPrint,
  faRefresh,
  faShareFromSquare, faSignal,
  faStore,
  faTableCellsLarge,
  faTags,
  faTruckFast,
  faUser,
  faUserGroup,
  faUsers,
  faUserSecret,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';

const spacing = { marginRight: '0.5rem' };

const breadcrumbMap = {
  admin: {
    href: getRouteURI(ROUTES.ADMIN),
    icon: faUsers,
    message: <FormattedMessage id="admin" defaultMessage="Administration" />,
  },
  banner: {
    href: getRouteURI(ROUTES.ADMIN_BANNER),
    icon: faCommenting,
    message: <FormattedMessage id="banner" defaultMessage="Banner" />,
  },
  captures: {
    href: getRouteURI(ROUTES.CAPTURES),
    icon: faList,
    message: <FormattedMessage id="captures" defaultMessage="Captures" />,
  },
  currencies: {
    href: getRouteURI(ROUTES.CONVERSIONS),
    icon: faDollar,
    message: (
      <FormattedMessage id="manage.currency-conversions" defaultMessage="Currency Conversions" />
    ),
  },
  inventory: {
    icon: faTableCellsLarge,
    message: <FormattedMessage id="inventory" defaultMessage="Inventory" />,
  },
  impersonation: {
    icon: faUserSecret,
    message: <FormattedMessage id="impersonate" defaultMessage="Impersonate" />,
  },
  labels: {
    href: '#/admin/labels',
    icon: faTags,
    message: <FormattedMessage id="field.labels" defaultMessage="Labels" />,
  },
  locations: {
    href: getRouteURI(ROUTES.LOCATIONS),
    icon: faLocationDot,
    message: (
      <FormattedMessage id="organize.locations" defaultMessage="Locations" />
    ),
  },
  organize: {
    icon: faCog,
    message: <FormattedMessage id="organize" defaultMessage="Organize" />,
  },
  assets: {
    icon: faCubes,
    message: <FormattedMessage id="assets" defaultMessage="Assets" />,
  },
  manufacturers: {
    href: getRouteURI(ROUTES.MANUFACTURERS),
    icon: faIndustry,
    message: (
      <FormattedMessage
        id="manage.manufacturers"
        defaultMessage="Manufacturers"
      />
    ),
  },
  materials: {
    href: getRouteURI(ROUTES.MATERIALS),
    icon: faObjectGroup,
    message: (
      <FormattedMessage id="manage.materialTypes" defaultMessage="Material Types" />
    ),
  },
  toolingStockTypes: {
    href: getRouteURI(ROUTES.TOOLING_STOCK_TYPES),
    icon: faHammer,
    message: (
      <FormattedMessage id="record.tooling_stock_types" defaultMessage="Tool Types" />
    ),
  },
  toolingStocks: {
    href: getRouteURI(ROUTES.TOOLING_STOCKS),
    icon: faBoxesStacked,
    message: (
      <FormattedMessage id="record.tooling_stocks" defaultMessage="Tools" />
    ),
  },
  materialStocks: {
    href: getRouteURI(ROUTES.STOCKS),
    icon: faBox,
    message: (
      <FormattedMessage
        id="inventory.materialStocks"
        defaultMessage="Material Stocks"
      />
    ),
  },
  materialLots: {
    href: getRouteURI(ROUTES.MATERIAL_LOTS),
    icon: faBoxOpen,
    message: (
      <FormattedMessage
        id="inventory.materialLots"
        defaultMessage="Material Lots"
      />
    ),
  },
  materialBatches: {
    href: getRouteURI(ROUTES.MATERIAL_BATCHES),
    icon: faArchive,
    message: (
      <FormattedMessage
        id="inventory.materialBatches"
        defaultMessage="Material Batches"
      />
    ),
  },
  permanentContainers: {
    href: getRouteURI(ROUTES.PERMANENT_CONTAINERS),
    icon: faCoins,
    message: (
      <FormattedMessage
        id="inventory.permanentContainers"
        defaultMessage="Permanent Containers"
      />
    ),
  },
  modelLibraries: {
    href: getRouteURI(ROUTES.MODEL_LIBRARY),
    icon: faDiceD6,
    message: <FormattedMessage id="organize.modelLibrary" defaultMessage="Model Library" />,
  },
  buildsLibraries: {
    href: getRouteURI(ROUTES.BUILDS_LIBRARY),
    icon: faArchive,
    message: <FormattedMessage id="organize.buildsLibrary" defaultMessage="Builds Library" />,
  },
  orders: {
    href: getRouteURI(ROUTES.ORDERS),
    icon: faPenToSquare,
    message: <FormattedMessage id="plan.orders" defaultMessage="Orders" />,
  },
  WIPMatrix: {
    icon: faBarsProgress,
    message: <FormattedMessage id="track.workInProgressMatrix" defaultMessage="WIP Matrix" />,
  },
  plan: {
    icon: faListCheck,
    message: <FormattedMessage id="plan" defaultMessage="Plan" />,
  },
  postProcessors: {
    href: getRouteURI(ROUTES.POST_PROCESSORS),
    icon: faFax,
    message: (
      <FormattedMessage
        id="inventory.postProcessors"
        defaultMessage="Post Processors"
      />
    ),
  },
  postProcessorServices: {
    href: getRouteURI(ROUTES.POST_PROCESSOR_SERVICES),
    icon: faBusinessTime,
    message: (
      <FormattedMessage
        id="inventory.postProcessorServices"
        defaultMessage="Post Processor Services"
      />
    ),
  },
  postProcessorTypes: {
    href: getRouteURI(ROUTES.POST_PROCESSOR_TYPES),
    icon: faFax,
    message: (
      <FormattedMessage
        id="manage.postProcessorTypes"
        defaultMessage="Post Processor Types"
      />
    ),
  },
  printers: {
    href: getRouteURI(ROUTES.PRINTERS),
    icon: faPrint,
    message: (
      // Replace "Printers" to "Primary Production" if the feature is enabled.
      <UseNonMfgLanguageFeature
        mfgLanguageComponent={<FormattedMessage id="inventory.printers" defaultMessage="Printers" />}
        nonMfgLanguageComponent={<FormattedMessage id="mfg.printer.productionDevice" defaultMessage="Production Device" />}
      />
    ),
  },
  printerTypes: {
    href: getRouteURI(ROUTES.PRINTER_TYPES),
    icon: faPrint,
    message: (
      <UseNonMfgLanguageFeature
        mfgLanguageComponent={<FormattedMessage id="manage.printerTypes" defaultMessage="Printer Types" />}
        nonMfgLanguageComponent={<FormattedMessage id="mfg.printerTypes.productionDeviceTypes" defaultMessage="Production Device Types" />}
      />
    ),
  },
  prints: {
    href: getRouteURI(ROUTES.PRINTS),
    icon: faList,
    message: <FormattedMessage id="plan.prints" defaultMessage="Prints" />,
  },
  profile: {
    href: getRouteURI(ROUTES.PROFILE),
    icon: faUser,
    message: <FormattedMessage id="myProfile" defaultMessage="My Profile" />,
  },
  pieces: {
    href: getRouteURI(ROUTES.PIECES),
    icon: faWrench,
    message: <FormattedMessage id="pieces" defaultMessage="Pieces" />,
  },
  prepTaskRecords: {
    href: getRouteURI(ROUTES.PREP_TASK_RECORDS),
    icon: faPen,
    message: <FormattedMessage id="tasks" defaultMessage="User Tasks" />,
  },
  recalculation: {
    href: getRouteURI(ROUTES.ADMIN_RECALCULATION),
    icon: faRefresh,
    message: <FormattedMessage id="recalculation_trigger" defaultMessage="Recalculation Trigger" />,
  },
  customFields: {
    href: getRouteURI(ROUTES.ADMIN_CUSTOM_FIELDS),
    icon: faList,
    message: <FormattedMessage id="custom_fields" defaultMessage="Custom Fields" />,
  },
  guidelinesEngine: {
    href: getRouteURI(ROUTES.GUIDELINES_ENGINE),
    icon: faList,
    message: <FormattedMessage id="guidelines_engine" defaultMessage="Guidelines Engine" />,
  },
  newGuideline: {
    href: getRouteURI(ROUTES.GUIDELINES_ENGINE_NEW),
    icon: faList,
    message: <FormattedMessage id="new_guideline" defaultMessage="New Guideline" />,
  },
  materialTests: {
    href: getRouteURI(ROUTES.MATERIAL_TESTS),
    icon: faList,
    message: <FormattedMessage id="materialTests" defaultMessage="Material Tests" />,
  },
  secureRunCheckouts: {
    href: getRouteURI(ROUTES.SECURE_FILE_CHECKOUT),
    icon: faList,
    message: <FormattedMessage id="secureRunCheckouts" defaultMessage="Secure Run Checkouts" />,
  },
  editGuideline: {
    href: getRouteURI(ROUTES.GUIDELINES_ENGINE_EDIT),
    icon: faList,
    message: <FormattedMessage id="edit_guideline" defaultMessage="Edit Guideline" />,
  },
  queues: {
    href: getRouteURI(ROUTES.QUEUES),
    icon: faBarsStaggered,
    message: <FormattedMessage id="track.queues" defaultMessage="Machine Queues" />,
  },
  runs: {
    href: getRouteURI(ROUTES.RUNS),
    icon: faShareFromSquare,
    message: <FormattedMessage id="plan.runs" defaultMessage="Runs" />,
  },
  createRuns: {
    icon: faGear,
    href: getRouteURI(ROUTES.CREATE_RUNS),
    // Route is for internal use only. No need to add translation for now
    message: 'Create Runs',
  },
  shipping: {
    href: getRouteURI(ROUTES.SHIPPINGS),
    icon: faTruckFast,
    message: (
      <FormattedMessage id="shippingPartners" defaultMessage="Shipping Partners" />
    ),
  },
  shipments: {
    href: getRouteURI(ROUTES.SHIPMENTS),
    icon: faBoxesPacking,
    message: <FormattedMessage id="plan.shipments" defaultMessage="Shipments" />,
  },
  workflows: {
    href: getRouteURI(ROUTES.PRODUCTION_WORKFLOW_LIST),
    icon: faArrowDownShortWide,
    message: (
      <FormattedMessage id="workflows" defaultMessage="Workflows" />
    ),
  },
  serviceProviders: {
    href: getRouteURI(ROUTES.SERVICE_PROVIDERS),
    icon: faBriefcase,
    message: (
      <FormattedMessage
        id="organize.serviceProviders"
        defaultMessage="Service Providers"
      />
    ),
  },
  vendorServiceProviders: {
    href: getRouteURI(ROUTES.VENDOR_SERVICE_PROVIDERS),
    icon: faStore,
    message: (
      <FormattedMessage
        id="organize.vendorServiceProviders"
        defaultMessage="Vendor Service Providers"
      />
    ),
  },
  serviceProviderJobs: {
    href: getRouteURI(ROUTES.SERVICE_PROVIDER_JOBS),
    icon: faBusinessTime,
    message: (
      <FormattedMessage
        id="plan.serviceProviderJobs"
        defaultMessage="Service Provider Jobs"
      />
    ),
  },
  users: {
    href: getRouteURI(ROUTES.ADMIN_USERS),
    icon: faUsers,
    message: <FormattedMessage id="users" defaultMessage="Users" />,
  },
  userGroups: {
    href: getRouteURI(ROUTES.ADMIN_USER_GROUPS),
    icon: faUserGroup,
    message: <FormattedMessage id="userGroups" defaultMessage="User Groups" />,
  },
  track: {
    icon: faCalendarCheck,
    message: <FormattedMessage id="track" defaultMessage="Track" />,
  },
  msnavImports: {
    icon: faFile,
    message: <FormattedMessage id="msnavImports" defaultMessage="MSNav Imports" />,
  },
  jeniClusters: {
    href: getRouteURI(ROUTES.JENI_CLUSTERS),
    icon: faPrint,
    message: <FormattedMessage id="jeniClusters" defaultMessage="JENI Clusters" />,
  },
  slicerConfiguration: {
    href: getRouteURI(ROUTES.SLICER_CONFIGURATIONS),
    icon: faSignal,
    message: <FormattedMessage id="slicer.configs" defaultMessage="Slicer Configurations" />,
  },
};

const HomeLink = () => (
  <Breadcrumb.Item href="/#/">
    <FontAwesomeIcon icon={faHome} className="spacer-right" />
    <FormattedMessage id="home" defaultMessage="Home" />
  </Breadcrumb.Item>
);

const BreadcrumbLink = ({ crumb, active }) => {
  let { href, icon, message } = breadcrumbMap[crumb] || { message: crumb, icon: null, href: null };

  if (crumb && typeof crumb === 'object') {
    href = crumb.href;
    message = crumb.message;
    icon = crumb.icon;
  }
  return (
    <Breadcrumb.Item href={href} key={href} active={active || !href}>
      {icon && (<FontAwesomeIcon icon={icon} style={spacing} />)} {message}
    </Breadcrumb.Item>
  );
};

BreadcrumbLink.defaultProps = { active: false };
BreadcrumbLink.propTypes = {
  crumb: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      href: PropTypes.string,
      message: PropTypes.string,
      icon: PropTypes.string,
    }),
  ]).isRequired,
  active: PropTypes.bool,
};

const BreadcrumbNav = ({ breadcrumbs }) => (
  <Breadcrumb>
    <HomeLink />
    {breadcrumbs.map((crumb, index) => (
      <BreadcrumbLink
        crumb={crumb}
        key={(crumb && crumb.href) || crumb}
        active={index === breadcrumbs.length - 1}
      />
    ))}
  </Breadcrumb>
);

BreadcrumbNav.defaultProps = { breadcrumbs: [] };
BreadcrumbNav.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        href: PropTypes.string,
        message: PropTypes.string,
        icon: PropTypes.string,
      }),
    ]),
  ),
};

export default BreadcrumbNav;
