import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import formatNumber from 'rapidfab/utils/numberFormatting';
import LOCALE_MAP from 'rapidfab/constants/localeMap';

const FormattedNumberByLocales = ({ number }) => {
  const { locale } = useSelector(state => state.i18n);
  const formattedLocale = LOCALE_MAP[locale];
  return (
    <div>{formatNumber(number, formattedLocale)}</div>
  );
};

FormattedNumberByLocales.propTypes = {
  number: PropTypes.number.isRequired,
};

export default FormattedNumberByLocales;
