import React from 'react';
import PropTypes from 'prop-types';

import { Container } from 'react-bootstrap';

import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';

import PrinterFormContainer from 'rapidfab/containers/records/PrinterFormContainer';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';

const Printer = ({ name, route, isGeneralMFGLanguageEnabled }) => (
  <Container fluid className="m-b">
    <BreadcrumbNav
      breadcrumbs={['assets', 'printers', route?.uuid ? `${name} (${getShortUUID(route?.uuid)})` : 'New']}
    />

    <h1 className="page-header mb15">
      {name || (isGeneralMFGLanguageEnabled ? 'New Production Device' : 'New Printer')}
    </h1>

    <PrinterFormContainer route={route} />
  </Container>
);

Printer.defaultProps = {
  name: null,
};

Printer.propTypes = {
  name: PropTypes.string,
  route: PropTypes.shape({ uuid: PropTypes.string }).isRequired,
  isGeneralMFGLanguageEnabled: PropTypes.bool.isRequired,
};

export default Printer;
