import React from 'react';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';
import Actions from 'rapidfab/actions';
import { connect } from 'react-redux';
import {
  getLocationFilter,
  getLocations, getSubLocationFilter,
  isFeatureEnabled,
} from 'rapidfab/selectors';
import {
  API_RESOURCES,
  FEATURES,
  LOCATION_FILTER_DEFAULT_VALUES, ROUTES,
  SUB_LOCATION_FILTER_DEFAULT_VALUES,
} from 'rapidfab/constants';
import recordsListOptions from 'rapidfab/utils/recordsListOptions';
import _merge from 'lodash/merge';
import _clone from 'lodash/clone';
import _omit from 'lodash/omit';

/**
 * @param {React.Node} WrappedComponent -
 * The component which will be rendered with all the extra props required for the Record List
 * to work properly (sort/filter/search/etc.)
 *
 * @param {function} getRecordsList - Selector (or custom function)
 * that returns data to be used in the Table (Gridle)
 *
 * @param {string} recordListStoreKeys - Store Key name.
 * Used to get `fetching` state and dispatch list/clear actions
 *
 * @param {Object} [customOptions] - List of custom options in addition to the ones
 * from `recordsListOptions` utils config.
 * Useful for cases when same Store Key is used in different places with different settings.
 * E.g. Orders list use limit 10 on home page and limit 50 on Orders page
 *
 * @param {number} customOptions.defaultLimit - Default number of items per page.
 *   Used in API request `?limit=10`
 * @param {string} customOptions.searchBy - Search key name.
 *   Used in API request `?some_custom_name=search string`. Ignored when multicolumnSearch is true.
 * @param {string} customOptions.defaultSort - Default sort value.
 *   Used in API request `?sort=-created`
 * @param {boolean} customOptions.useLocationFiltering - Is Location filtering required
 *   in the API request. When enabled - current location filter value
 *   will be added to the API request `?location=LOCATION_VALUE`
 * @param {boolean} customOptions.multicolumnSearch - Is multicolumn search used
 *   instead of `searchBy` key. Replaces `searchBy` when enabled.
 *   Used in API request `?multicolumn_search=search string`
 * @param {(object|undefined)} customOptions.staticQueryParams - List of custom Query Params
 *     to be used in each API request
 *
 * @returns {*}
 */
function withRecordsListHandling(
  WrappedComponent,
  getRecordsList,
  recordListStoreKeys,
  customOptions = {},
) {
  class WrappedComponentWithRecordsListHandling extends React.Component {
    constructor(props) {
      super(props);

      const {
        listOptions: {
          defaultLimit,
          defaultSort,
          staticQueryParams,
        },
      } = this.props;
      const initialFilters = staticQueryParams || {};

      this.state = {
        filters: initialFilters,
        customFilters: {},
        sort: defaultSort,
        search: '',
        offset: 0,
        limit: defaultLimit,
        grouping: null,
      };

      // Add more pages when more support for sub-locations is added.
      this.SUB_LOCATIONS_USED_PAGES =
        window.location.href.includes(ROUTES.MATERIAL_LOTS) ||
        window.location.href.includes(ROUTES.MATERIAL_BATCHES) ||
        window.location.href.includes(ROUTES.POST_PROCESSORS);

      this.onPageChange = this.onPageChange.bind(this);
      this.onLimitChange = this.onLimitChange.bind(this);
      this.onFilterUpdate = this.onFilterUpdate.bind(this);
      this.onSort = this.onSort.bind(this);
      this.onSearch = this.onSearch.bind(this);
      this.refreshRecordsList = this.refreshRecordsList.bind(this);
      this.onGroupingUpdate = this.onGroupingUpdate.bind(this);
      this.onCustomFilterUpdate = this.onCustomFilterUpdate.bind(this);
    }

    componentDidMount() {
      this.props.onInitialize(
        this.props.listOptions.useLocationFiltering,
        this.props.locationFilter,
        this.SUB_LOCATIONS_USED_PAGES);
      // No need to wait for `initialize` to finish, since we need just location URI (if any)
      // and not the whole entity to load list of items.
      this.refreshRecordsList(this.SUB_LOCATIONS_USED_PAGES);
    }

    componentDidUpdate(prevProps, prevState) {
      const { offset, limit, filters, sort, search } = this.state;
      const { locationFilter, subLocationFilter } = this.props;

      if (
        limit !== prevState.limit ||
        offset !== prevState.offset ||
        sort !== prevState.sort ||
        search !== prevState.search ||
        !_isEqual(filters, prevState.filters) ||
        locationFilter !== prevProps.locationFilter ||
        subLocationFilter !== prevProps.subLocationFilter
      ) {
        this.refreshRecordsList(this.SUB_LOCATIONS_USED_PAGES);
      }

      // On each locationFilter change, if the filter exists, refresh the subLocations list
      if ((locationFilter && (locationFilter !== prevProps.locationFilter)) && this.SUB_LOCATIONS_USED_PAGES) {
        this.props.refreshSubLocationsList(this.props.locationFilter);
      }
    }

    onPageChange(offset) {
      this.setState({ offset });
    }

    onLimitChange(limit) {
      // Reset offset value to prevent requesting non-existing pages
      this.setState({ limit, offset: 0 });
    }

    onSearch(search) {
      // Reset pagination too when search keyword is changed
      this.setState({ search, offset: 0 });
    }

    onFilterUpdate(updatedFilters) {
      this.setState({ filters: updatedFilters });
    }

    onGroupingUpdate(updatedGrouping) {
      this.setState({ grouping: updatedGrouping });
    }

    /**
     * Passed down to WrappedComponent to handle `customFilters` state change.
    */
    onCustomFilterUpdate(fieldId, value) {
      this.setState({ customFilters: { ...this.state.customOptions, [fieldId]: value } });
    }

    onSort(sort) {
      let sorts = sort.id;
      if (!sort.sortAscending) {
        sorts = `-${sorts}`;
      }
      this.setState({ sort: sorts });
    }

    refreshRecordsList(subLocationAllowedPages) {
      const {
        listOptions: {
          searchBy,
          multicolumnSearch,
          useLocationFiltering,
        },
        locationFilter,
        subLocationFilter,
      } = this.props;

      const { offset, limit, filters, sort, search: searchState } = this.state;
      const queryParams = {};
      if (sort) {
        queryParams.sort = sort;
      }

      const search = {};
      if (searchState !== '') {
        if (multicolumnSearch) {
          queryParams.multicolumn_search = searchState;
        } else {
          search[searchBy] = searchState;
        }
      }

      if (
        useLocationFiltering
        && typeof locationFilter !== 'undefined'
        && locationFilter !== LOCATION_FILTER_DEFAULT_VALUES.ALL
      ) {
        filters.location = locationFilter;

        /* TODO: Temporarily SUB_LOCATIONS_USED_PAGES has the list of pages
                 where we should send sub_location query param to fetch the
                 related sub-locations. Add new pages if more pages support it.
                 Eventually to be removed once all the pages (as locations currently)
                 supported by sub-locations. */

        if (subLocationAllowedPages) {
          // If the sub-location filter is set to some value -> add query param.
          if (subLocationFilter && subLocationFilter !== SUB_LOCATION_FILTER_DEFAULT_VALUES.ALL) {
            filters.sub_location = subLocationFilter;
          } else {
            delete filters.sub_location;
          }
        }
      } else {
        delete filters.location;
        delete filters.sub_location;
      }

      this.props.onPageChange(offset, limit, filters, search, queryParams);
    }

    render() {
      const {
        recordListItems,
        recordListStore,
        locations,
        listOptions: {
          useLocationFiltering,
        },
      } = this.props;
      const { filters, limit, offset, search, sort } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          filters={filters}
          locations={locations}
          limit={limit}
          offset={offset}
          search={search}
          sort={sort}
          onFilterUpdate={this.onFilterUpdate}
          onCustomFilterUpdate={this.onCustomFilterUpdate}
          onGroupingUpdate={this.onGroupingUpdate}
          onPageChange={this.onPageChange}
          onLimitChange={this.onLimitChange}
          onSort={this.onSort}
          onSearch={this.onSearch}
          data={recordListStoreKeys.length > 1 ?
            recordListItems.slice(0, limit) :
            recordListItems}
          listStore={recordListStore}
          showLocationsFilter={useLocationFiltering}
          refreshRecordsList={this.refreshRecordsList}
        />
      );
    }
  }

  WrappedComponentWithRecordsListHandling.propTypes = {
    onInitialize: PropTypes.func.isRequired,
    onPageChange: PropTypes.func.isRequired,
    locationFilter: PropTypes.string,
    recordListItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    recordListStore: PropTypes.shape({}).isRequired,
    fetching: PropTypes.bool.isRequired,
    locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    listOptions: PropTypes.shape({
      defaultLimit: PropTypes.number,
      searchBy: PropTypes.string,
      defaultSort: PropTypes.string,
      useLocationFiltering: PropTypes.bool,
      staticQueryParams: PropTypes.shape({}),
      multicolumnSearch: PropTypes.bool,
    }).isRequired,
    refreshSubLocationsList: PropTypes.func.isRequired,
    subLocationFilter: PropTypes.string,
  };

  WrappedComponentWithRecordsListHandling.defaultProps = {
    locationFilter: LOCATION_FILTER_DEFAULT_VALUES.ALL,
    subLocationFilter: LOCATION_FILTER_DEFAULT_VALUES.UNASSIGNED,
  };

  function mapDispatchToProps(dispatch) {
    const loadList = async (filters, offset, limit, search, queryParams) => {
      await Promise.all(recordListStoreKeys.map(async key => (
        dispatch(Actions.Api.nautilus[key].clear('list'))
      )));
      // Some of resourced does not allow `location` query, add these resources:
      const RESOURCES_SKIP_LOCATION = new Set([API_RESOURCES.LABEL, API_RESOURCES.MSNAV_IMPORT_FILE]);

      Promise.all(recordListStoreKeys.map(async resourceKey => {
        /* If the resource doesn't support location filtering, remove the location filter,
           otherwise send the original filters' data. */
        const requestFilters = RESOURCES_SKIP_LOCATION.has(resourceKey) ?
          _omit({ ...filters }, API_RESOURCES.LOCATION) :
          { ...filters };

        const response = await dispatch(Actions.Api.nautilus[resourceKey].list(
          requestFilters,
          { offset,
            limit },
          search,
          queryParams,
          true,
        ));

        return response?.json?.resources;
      }));
    };
    return {
      onInitialize: (useLocationFiltering, locationFilter, subLocationAllowedPages) => {
        if (useLocationFiltering) {
          // We need to load location only if locations dropdown is needed
          dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list());

          /* Fetch the sub-locations when initializing and the location filter is set,
             also make sure the page supports the Sub-Locations filter */
          if (locationFilter && subLocationAllowedPages) {
            dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].list({ location: locationFilter }));
          }

          if (!subLocationAllowedPages) {
            dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].clear());
          }
        }
      },
      onPageChange: (offset, limit, filters, search, queryParams) =>
        loadList(filters, offset, limit, search, queryParams),
      refreshSubLocationsList: locationFilter => {
        // Clear the options on each refresh
        dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].clear());
        dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].list({ location: locationFilter }));
      },
    };
  }

  function mapStateToProps(state) {
    const recordListStore = _clone(state.ui.nautilus[recordListStoreKeys[0]].list);
    recordListStoreKeys.forEach(key => {
      _merge(recordListStore, state.ui.nautilus[key].list);
    });
    const recordListItems = getRecordsList(state);
    const locationFilter = getLocationFilter(state);
    const subLocationFilter = getSubLocationFilter(state);
    const isDanfossUser = isFeatureEnabled(state, FEATURES.ORDER_BUSINESS_SEGMENT);

    const listOptions = recordsListOptions({
      storeKey: recordListStoreKeys[0],
      isDanfossUser,
    });

    return {
      recordListItems,
      recordListStore,
      locations: getLocations(state),
      locationFilter,
      subLocationFilter,
      fetching: recordListStore.fetching,
      listOptions: {
        ...listOptions,
        ...customOptions,
      },
    };
  }

  return connect(mapStateToProps, mapDispatchToProps)(WrappedComponentWithRecordsListHandling);
}

export default withRecordsListHandling;
