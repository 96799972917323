import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { ROUTES, ZENDESK_MATERIAL_MANAGEMENT_URL } from 'rapidfab/constants';
import { MATERIAL_BATCH_STATUS_MAP } from 'rapidfab/mappings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from 'rc-tooltip';

import Table from 'rapidfab/components/Tables/Table';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const columns = [
  {
    type: 'uuid',
    uid: 'field.id',
    accessor: 'uuid',
    defaultMessage: 'ID',
    resource: 'material-batch',
    route: ROUTES.MATERIAL_BATCH,
  },
  {
    type: 'translatable',
    uid: 'field.status',
    accessor: 'status',
    defaultMessage: 'Status',
    mapping: MATERIAL_BATCH_STATUS_MAP,
  },
  {
    type: 'text',
    uid: 'field.currentQuantity',
    accessor: 'quantity',
    defaultMessage: 'Current quantity',
  },
  {
    type: 'text',
    uid: 'field.fullQuantity',
    accessor: 'full_quantity',
    defaultMessage: 'Full Quantity',
  },
  {
    type: 'time',
    uid: 'created',
    accessor: 'created',
    defaultMessage: 'Created',
  },
];

const MaterialLotInitialBatches = ({
  initialBatches,
  // lot,
}) => {
  const renderMaterialLotInBatchesHeader = () => {
    if (!initialBatches?.length) {
      return (
        <div className="d-flex align-items-center">
          <p style={{ fontSize: '14px' }}>Material Lot Initial Batches</p>
          <Tooltip
            placement="right"
            destroyTooltipOnHide
            mouseEnterDelay={0}
            mouseLeaveDelay={0.1}
            id="initialBatchesTooltip"
            overlayInnerStyle={{ padding: '10px', wordBreak: 'break-word' }}
            overlay={(
              <>
                <p>To create a batch of Material for use in manufacturing,</p>
                <p>please use our QR App to scan the Authentise QR code on the container(s)</p>
                <span>you would like to include in the batch.&nbsp;</span>
                <b><a href={ZENDESK_MATERIAL_MANAGEMENT_URL}>More details</a></b>.
              </>
            )}
          >
            <FontAwesomeIcon icon={faInfoCircle} className="spacer-left" />
          </Tooltip>
        </div>
      );
    }
    return 'Material Lot Initial Batches';
  };

  return (
    <Card bg="dark">
      <Card.Header className="pd-exp inverse">{renderMaterialLotInBatchesHeader()}</Card.Header>
      <div className="card-body-wrapper">
        <Card.Body>
          <Table data={initialBatches} columns={columns} />
        </Card.Body>
      </div>
    </Card>
  );
};

MaterialLotInitialBatches.propTypes = {
  initialBatches: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  // lot: materialLotResourceType.isRequired,
};

export default MaterialLotInitialBatches;
