import _map from 'lodash/map';
import Actions from 'rapidfab/actions';
import CancelOrDeleteModal from 'rapidfab/components/CancelOrDeleteModal';
import NCReviewModalContainer from 'rapidfab/components/records/run/NCReviewModalContainer';
import RemanufactureModal from 'rapidfab/components/records/run/RemanufactureModal';
import ScrapModalContainer from 'rapidfab/components/records/run/ScrapModalContainer';
import BuildPlateLineItemsModeContainer from 'rapidfab/containers/records/run/BuildPlateLineItemsModeContainer';
import WorkChecklistModalContainer from 'rapidfab/containers/records/WorkChecklistModalContainer';
import WorkflowModalContainer from 'rapidfab/containers/records/WorkflowModalContainer';
import { FormattedMessage } from 'rapidfab/i18n';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import React, { useState, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { runType } from 'rapidfab/types';
import { API_RESOURCES, PIECE_BETWEEN_RUN_STATUSES, RUN_OPERATIONS } from 'rapidfab/constants';
import { Card, Row, Col } from 'react-bootstrap';
import RunPrints from 'rapidfab/components/records/run/RunPrints';
import _find from 'lodash/find';
import Loading from 'rapidfab/components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Alert from 'react-s-alert';
import SmoothCollapse from 'react-smooth-collapse';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const imageStyle = {
  display: 'block',
  maxWidth: '100%',
  borderRadius: '5px',
  overflow: 'hidden',
};

const BuildPlate = ({ buildFiles, layerThickness, ...props }) => {
  const buildFileWithContent = _find(buildFiles, buildFile => (buildFile.snapshot_content !== null));
  const snapshotContent = buildFileWithContent && buildFileWithContent.snapshot_content;
  const {
    run,
    gridData,
    pagination,

    onRemanufactureModalSubmit,
    onNCReviewSubmit,
    setTransformTypeForChangeWorkflow,
    runRemanufacture,
    setChangeWorkflowNotes,
    remanufactureNotes,
    onScrapPieceSubmit,
    initializeFieldValues,

    selectedPrints,
    piecesByUri,
    workflowsByUuid,
    isAllPrintsSelected,
    postProcessorsByUri,
    runsByUri,
    setShouldRefetchWorkflows,
    isWorkflowFetching,

    selectedLineItems,
    setSelectedLineItems,
    setSelectedLineItemPiecesCount,
    selectedLineItemPiecesCount,
    allLineItemsSelected,
    ordersList,
    isLineItemsModeEnabled,
    onBuildPlateModeToggle,
    confirmRunTransformationIsLost,
    setConfirmRunTransformationIsLost,

    isRemanufactureModalVisible,
    invertRemanufactureModalStatus,
    handleInputChange,
    remanufactureReason,
    isRemanufactureLoading,
    transformType,
    lineItemSummaryData,
    setLineItemSummaryData,
    lineItemGeneralInfoFetched,
    setLineItemGeneralInfoFetched,
    orders,
  } = props;

  const buildFileStatus = run.status === 'calculating' ? <Loading /> : null;
  const [expanded, setExpanded] = useState(false);

  const [isProcessStepsModalShown, setIsProcessStepsModalShown] = useState(false);
  const [isWorkInstructionsModalShown, setIsWorkInstructionsModalShown] = useState(false);
  const [remanufacturedWorkflowUri, setRemanufacturedWorkflowUri] = useState(null);
  const [pieceWorkflowUri, setPieceWorkflowUri] = useState('');
  const [changeWorflowProcessStepPosition, setChangeWorflowProcessStepPosition] = useState(null);
  const [isNcReviewModalShown, setisNcReviewModalShown] = useState(false);
  const [isScrapModalShown, setIsScrapModalShown] = useState(false);
  const [selectedPiecesList, setSelectedPiecesList] = useState('');

  const [selectedProcessStep, setSelectedProcessStep] = useState('');

  // called when 'Work Instructions' Modal is closed
  const onWorkInstructionsModalClose = () => {
    setIsWorkInstructionsModalShown(false);
    initializeFieldValues();
    setSelectedProcessStep('');
  };

  // called when 'Workflow Change' model is closed
  const onProcessStepsModalClose = () => {
    setIsProcessStepsModalShown(false);
    initializeFieldValues();
  };

  const onProcessStepsModalSubmit = (newProcessSteps, splitWorkflow = false) => {
    onRemanufactureModalSubmit({ processStepURIs: newProcessSteps }, splitWorkflow)
      .then(response => {
        const { custom_workflow: workflowUri } = response.json;
        setRemanufacturedWorkflowUri(workflowUri);
        setIsProcessStepsModalShown(false);
        setIsWorkInstructionsModalShown(true);
      })
      .catch(error => Alert.error(error.message));
  };

  const onMarkingNonConformanceSubmit = (splitSchedules = false) => {
    onNCReviewSubmit(splitSchedules)
      .then(response => {
        if (response?.json) {
          setisNcReviewModalShown(false);
          Alert.success(
            <FormattedMessage
              id="toaster.ncreview.changedSuccessfully"
              defaultMessage="Changed successfully."
            />,
          );
        }
      })
      .catch(error => {
        Alert.error(error.message);
      });
  };

  const onNonConformanceModalOpen = () => {
    const piecesUris = _map(selectedPrints, 'piece');
    setisNcReviewModalShown(true);
    setSelectedPiecesList(piecesUris);
  };

  const onScrapModalOpen = () => {
    const piecesUris = _map(selectedPrints, 'piece');
    setIsScrapModalShown(true);
    setSelectedPiecesList(piecesUris);
  };

  const onLineItemsProductionWorkflow = async dispatch => {
    const firstLineItem = selectedLineItems[0];
    const workflowUri = firstLineItem.workflow;

    const pieceByRunResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.PIECES_BY_RUN].list(
      { line_item: selectedLineItems[0].uri, run: run.uri },
      { limit: 1 },
    ));

    const pieceData = pieceByRunResponse.json?.resources[0];
    const pieceResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.PIECE].get(extractUuid(pieceData.piece)));

    const firstPiece = pieceResponse.json;

    const currentStepPosition = firstPiece.current_step_position;
    const { status } = firstPiece;

    setIsProcessStepsModalShown(true);
    setPieceWorkflowUri(workflowUri);
    setTransformTypeForChangeWorkflow();

    // When we have certain transitional statuses, the "current step" is a position one lower than
    // it would otherwise appear.
    const changeWorkflowProcessStepPosition =
      PIECE_BETWEEN_RUN_STATUSES.includes(status)
        ? currentStepPosition - 1
        : currentStepPosition;

    setChangeWorflowProcessStepPosition(changeWorkflowProcessStepPosition);
  };

  const onChangeProductionWorkflow = () => {
    // since the prints have already been determined to have same workflows and
    // work instructions, we can use the any print or piece from the list of
    // selected prints
    const firstPrint = selectedPrints[0];

    const pieceUris = _map(selectedPrints, 'piece');
    const pieces = _map(pieceUris, uri => piecesByUri[uri]);
    const firstPiece = pieces[0];

    const workflowUuid = firstPrint.workflow;
    const workflowUri = workflowsByUuid[extractUuid(workflowUuid)]?.uri;

    const currentStepPosition = firstPiece.current_step_position;
    const { status } = firstPiece;

    setIsProcessStepsModalShown(true);
    setPieceWorkflowUri(workflowUri);
    setTransformTypeForChangeWorkflow();

    // When we have certain transitional statuses, the "current step" is a position one lower than
    // it would otherwise appear.
    const changeWorkflowProcessStepPosition =
        PIECE_BETWEEN_RUN_STATUSES.includes(status)
          ? currentStepPosition - 1
          : currentStepPosition;

    setChangeWorflowProcessStepPosition(changeWorkflowProcessStepPosition);
  };

  const runPrintsSelected = {
    isWorkInstructionsModalShown,
    isScrapModalShown,
    onNonConformanceModalOpen,
    onScrapModalOpen,
    isWorkflowFetching,
    setShouldRefetchWorkflows,
    lineItemGeneralInfoFetched,
    setLineItemGeneralInfoFetched,
  };

  const lineItemModeSelected = {
    onNonConformanceModalOpen,
    onScrapModalOpen,
    setLineItemGeneralInfoFetched,
    lineItemGeneralInfoFetched,
    isScrapModalShown,
    isWorkInstructionsModalShown,
    setSelectedLineItemPiecesCount,
    selectedLineItemPiecesCount,
    selectedLineItems,
    setSelectedLineItems,
    lineItemSummaryData,
    setLineItemSummaryData,
    orders,
  };

  const lineItemsWithOrdersAndCount = useMemo(() => {
    if (!ordersList?.length || !Object.keys(selectedLineItemPiecesCount)?.length) {
      return [];
    }

    return ordersList.flatMap(order =>
      order.line_items
        .filter(lineItemUri => selectedLineItemPiecesCount[lineItemUri] !== undefined)
        .map(lineItemUri => ({
          line_item: lineItemUri,
          order: order.uri,
          count: selectedLineItemPiecesCount[lineItemUri],
        })),
    );
  }, [ordersList, selectedLineItemPiecesCount]);

  return (
    <Card bg="dark" className="table-responsive m-b">
      <Card.Header
        className="pd-exp inverse d-flex align-items-center position-sticky pointer z-index-1000 justify-content-between"
        onClick={() => setExpanded(previous => !previous)}
      >
        <span>Build Plate Details</span>
        <FontAwesomeIcon size="sm" icon={expanded ? faChevronUp : faChevronDown} />
      </Card.Header>
      <SmoothCollapse expanded={expanded}>
        <div className="card-body-wrapper">
          <Card.Body>
            <Row>
              { buildFiles.length && (snapshotContent && gridData.length > 0) ? (
                <Col sm={12} md={4}>
                  <img src={snapshotContent} alt="snapshot" style={imageStyle} />
                  {/* only display additional build details for printing runs */}
                  {run.operation === RUN_OPERATIONS.PRINTING && (
                    <div className="mt15">
                      <div><b>Layer Thickness: </b>{
                        layerThickness
                          ? `${layerThickness.value} ${layerThickness.units}`
                          : 'Conflict found. Mis-matched layer thickness for prints in run.'
                      }
                      </div>
                      <div><b>Number of Pieces: </b>{pagination?.totalItems}</div>
                    </div>
                  )}
                </Col>
              ) : buildFileStatus}
              <Col sm={12} md={snapshotContent && gridData.length > 0 ? 8 : 12}>
                {isLineItemsModeEnabled ? (
                  <BuildPlateLineItemsModeContainer
                    {...lineItemModeSelected}
                    onLineItemsProductionWorkflow={onLineItemsProductionWorkflow}
                    onBuildPlateModeToggle={onBuildPlateModeToggle}
                    isLineItemsModeEnabled={isLineItemsModeEnabled}
                    runRemanufacture={runRemanufacture}
                    run={run}
                    remanufacturedWorkflowUri={remanufacturedWorkflowUri}
                  />
                ) : (
                  <RunPrints
                    onBuildPlateModeToggle={onBuildPlateModeToggle}
                    onChangeProductionWorkflow={onChangeProductionWorkflow}
                    isLineItemsModeEnabled={isLineItemsModeEnabled}
                    {...runPrintsSelected}
                    {...props}
                  />
                )}
              </Col>
            </Row>
          </Card.Body>
        </div>
      </SmoothCollapse>

      {
        isProcessStepsModalShown && (
          <WorkflowModalContainer
            selectedPiecesList={selectedPrints}
            selectedLineItemsData={lineItemsWithOrdersAndCount}
            workflowURI={pieceWorkflowUri}
            onClose={onProcessStepsModalClose}
            onWorkflowStepsCreateCallback={onProcessStepsModalSubmit}
            currentProcessStepPosition={changeWorflowProcessStepPosition}
            setChangeWorkflowNotes={setChangeWorkflowNotes}
            notes={remanufactureNotes}
            isAllPrintsSelected={isLineItemsModeEnabled ? allLineItemsSelected : isAllPrintsSelected}
            showChangeWorkflowNotes
          />
        )
      }
      {
        isWorkInstructionsModalShown && (
          <WorkChecklistModalContainer
            relatedURI={remanufacturedWorkflowUri}
            workflowURI={remanufacturedWorkflowUri}
            onClose={onWorkInstructionsModalClose}
            selectedProcessStep={selectedProcessStep}
            selectProcessStep={setSelectedProcessStep}
          />
        )
      }

      {isRemanufactureModalVisible && (
        <RemanufactureModal
          selectedPrints={selectedPrints}
          isRemanufactureModalVisible={isRemanufactureModalVisible}
          invertRemanufactureModalStatus={invertRemanufactureModalStatus}
          handleInputChange={handleInputChange}
          transformType={transformType}
          notes={remanufactureNotes}
          reason={remanufactureReason}
          submit={onRemanufactureModalSubmit}
          loading={isRemanufactureLoading}
          isPrinting={run.operation === RUN_OPERATIONS.PRINTING}
        />
      )}

      {
        isNcReviewModalShown && (
          <NCReviewModalContainer
            selectedLineItemsData={lineItemsWithOrdersAndCount}
            selectedPiecesList={_map(selectedPiecesList, uri => piecesByUri[uri])}
            selectedPrints={selectedPrints}
            postProcessorsByUri={postProcessorsByUri}
            run={run}
            runsByUri={runsByUri}
            onClose={() => {
              setisNcReviewModalShown(false);
            }}
            ncreviewSubmitCallback={onMarkingNonConformanceSubmit}
            isAllPrintsSelected={isAllPrintsSelected}
          />
        )
      }
      {
        isScrapModalShown && (
          <ScrapModalContainer
            selectedLineItemsData={lineItemsWithOrdersAndCount}
            selectedPiecesList={_map(selectedPiecesList, uri => piecesByUri[uri])}
            selectedPrints={selectedPrints}
            postProcessorsByUri={postProcessorsByUri}
            run={run}
            runsByUri={runsByUri}
            onClose={() => setIsScrapModalShown(false)}
            onSubmit={onScrapPieceSubmit}
          />
        )
      }

      {confirmRunTransformationIsLost && (
        <CancelOrDeleteModal
          modalType="confirmRunTransformationLost"
          handleConfirm={onBuildPlateModeToggle}
          handleOpen={() => setConfirmRunTransformationIsLost(false)}
        />
      )}

    </Card>
  );
};

BuildPlate.defaultProps = {
  layerThickness: null,
};

// This is proxy component,
// so looks like we have a much more propTypes here ¯\_(ツ)_/¯
BuildPlate.propTypes = {
  buildFiles: PropTypes.arrayOf(PropTypes.shape({
    snapshot_content: PropTypes.string,
    uri: PropTypes.string,
  })).isRequired,
  run: runType.isRequired,
  gridData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  layerThickness: PropTypes.shape({
    units: PropTypes.string,
    value: PropTypes.number,
  }),
  isAllPrintsSelected: PropTypes.bool.isRequired,
  pagination: PropTypes.shape({
    totalItems: PropTypes.number,
  }).isRequired,
  isBuildPlateLoading: PropTypes.bool.isRequired,
  ordersList: PropTypes.arrayOf(PropTypes.shape({
    line_items: PropTypes.arrayOf(PropTypes.string),
    uri: PropTypes.string,
  })).isRequired,
  selectedPrints: PropTypes.arrayOf(PropTypes.shape({
    piece: PropTypes.string,
    workflow: PropTypes.string,
  })).isRequired,
  piecesByUri: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  workflowsByUuid: PropTypes.objectOf(PropTypes.shape({
    uri: PropTypes.string,
  })).isRequired,
  postProcessorsByUri: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  runsByUri: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  setShouldRefetchWorkflows: PropTypes.func.isRequired,
  isWorkflowFetching: PropTypes.bool.isRequired,
  selectedLineItems: PropTypes.arrayOf(PropTypes.shape({
    uri: PropTypes.string,
    workflow: PropTypes.string,
  })).isRequired,
  setSelectedLineItems: PropTypes.func.isRequired,
  setSelectedLineItemPiecesCount: PropTypes.func.isRequired,
  selectedLineItemPiecesCount: PropTypes.objectOf(PropTypes.number).isRequired,
  allLineItemsSelected: PropTypes.bool.isRequired,
  isLineItemsModeEnabled: PropTypes.bool.isRequired,
  onBuildPlateModeToggle: PropTypes.func.isRequired,
  confirmRunTransformationIsLost: PropTypes.bool.isRequired,
  setConfirmRunTransformationIsLost: PropTypes.func.isRequired,
  onRemanufactureModalSubmit: PropTypes.func.isRequired,
  onNCReviewSubmit: PropTypes.func.isRequired,
  setTransformTypeForChangeWorkflow: PropTypes.func.isRequired,
  runRemanufacture: PropTypes.func.isRequired,
  setChangeWorkflowNotes: PropTypes.func.isRequired,
  remanufactureNotes: PropTypes.string.isRequired,
  onScrapPieceSubmit: PropTypes.func.isRequired,
  initializeFieldValues: PropTypes.func.isRequired,
  lineItemSummaryData: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  setLineItemSummaryData: PropTypes.func.isRequired,
  isRemanufactureModalVisible: PropTypes.bool.isRequired,
  invertRemanufactureModalStatus: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  remanufactureReason: PropTypes.string.isRequired,
  isRemanufactureLoading: PropTypes.bool.isRequired,
  transformType: PropTypes.string.isRequired,
  lineItemGeneralInfoFetched: PropTypes.bool.isRequired,
  setLineItemGeneralInfoFetched: PropTypes.func.isRequired,
  orders: PropTypes.objectOf(PropTypes.shape({})).isRequired,
};

export default memo(BuildPlate);
