import _range from 'lodash/range';
import PostProcessorNavbarLinks from 'rapidfab/components/assets/PostProcessorNavbarLinks';
import PostProcessorsCards from 'rapidfab/components/assets/PostProcessorsCards';
import PostProcessorsServicesTable from 'rapidfab/components/assets/PostProcessorServicesTable';
import PostProcessorsTable from 'rapidfab/components/assets/PostProcessorsTable';
import WorkstationTabs from 'rapidfab/components/assets/WorkstationTabs';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import Loading from 'rapidfab/components/Loading';
import Locations from 'rapidfab/components/locations';
import SubLocations from 'rapidfab/components/SubLocations';
import React from 'react';
import PropTypes from 'prop-types';

import {
  Badge,
  Button,
  ButtonToolbar,
  Col,
  Container,
  Dropdown,
  DropdownButton, Form,
  FormControl,
  Row,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { VIEW_MODE_OPTIONS } from 'rapidfab/constants';
import { postProcessorTypeResourceType } from 'rapidfab/types';
import { faList, faThLarge } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FORMATTED_DURATION_TYPES, FormattedOptionalDuration } from 'rapidfab/i18n';
import TimeFormattedHHMMSSWithTooltip from '../TimeFormattedHHMMSSWithTooltip';

const PostProcessors = ({
  data,
  fetching,
  locations,
  locationsByUri,
  postProcessorTypes,
  isServices,
  isServiceProviderRole,
  isGroupQualificationsFeatureEnabled,
  subLocationsByUri,
  isCardsView,
  filterValues,
  viewModeProps,
  pagination,
  filters,
  isDebugModeEnabled,
  hideArchived,
  onArchivedCheckboxChange,
  ...restProps
}) => {
  const columns = [
    {
      type: 'uuid',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      resource: isServices ? 'post-processor-service' : 'post-processor',
    },
    {
      type: 'custom',
      uid: 'field.name',
      accessor: 'name',
      defaultMessage: 'Name',
      isSortable: true,
      Cell: ({ row: { original } }) => (
        <div>
          {original.name}
          {original.archived && (
            <Badge bg="default" className="badge-sm ms-2">
              <FormattedMessage id="field.archived" defaultMessage="Archived" />
            </Badge>
          )}
        </div>
      ),
    },
    {
      type: 'custom',
      uid: 'field.duration',
      accessor: 'duration',
      defaultMessage: 'Duration',
      isSortable: true,
      Cell: ({ row: { original } }) => (

        <TimeFormattedHHMMSSWithTooltip
          timeInSeconds={original.duration}
        >
          <FormattedOptionalDuration
            intervalFormat={FORMATTED_DURATION_TYPES.HH_MM_SS}
            value={original.duration}
          />
        </TimeFormattedHHMMSSWithTooltip>

      ),
    },

    !isServices &&
    {
      type: 'resource',
      uid: 'field.location',
      accessor: 'location',
      defaultMessage: 'Location',
      resource: 'location',
      resources: locationsByUri,
      isSortable: true,
    },
    !isServices &&
    {
      type: 'resource',
      uid: 'field.sub_location',
      accessor: 'sub_location',
      defaultMessage: 'Sub-Location',
      resource: 'sub_location',
      resources: subLocationsByUri,
      isSortable: true,
    },
    !isServices &&
    {
      type: 'resource',
      uid: 'field.postProcessorType',
      accessor: 'post_processor_type',
      defaultMessage: 'Post Processor Type',
      slug: 'post-processor-type',
      resource: 'post_processor_type',
      resources: postProcessorTypes,
      isSortable: true,
    },
    (isServices && !isServiceProviderRole) &&
    {
      type: 'service',
      uid: 'organize.serviceProviders',
      accessor: 'serviceProviders',
      defaultMessage: 'Service Providers',
      isSortable: true,
    },
  ];

  if (isServices) {
    return (
      <PostProcessorsServicesTable
        data={data}
        fetching={fetching}
        locations={locations}
        locationsByUri={locationsByUri}
        postProcessorTypes={postProcessorTypes}
        isServices={isServices}
        isServiceProviderRole={isServiceProviderRole}
        isGroupQualificationsFeatureEnabled={isGroupQualificationsFeatureEnabled}
        subLocationsByUri={subLocationsByUri}
        columns={columns}
        {...restProps}
      />
    );
  }

  const {
    viewMode,
    setViewMode,
  } = viewModeProps ?? {};

  const {
    globalFilter,
    setGlobalFilter,
  } = filters ?? {};

  const {
    filterValue,
    setFilterValue,
  } = filterValues ?? {};

  const {
    activePage,
    setPage,
    pageLimit,
    totalItems,
    nextPage,
    prevPage,
    totalPaginatedPages,
  } = pagination ?? {};

  const renderHeaderView = () => {
    const isListView = !isCardsView;
    return (
      <Row>
        <Col xs={isListView && { span: 12 }} lg={isListView && { span: 10, offset: 1 }} className="d-flex justify-content-between">
          <Col xs={4}>
            <div className="d-flex align-items-center">
              <FormControl
                type="text"
                value={filterValue}
                placeholder="Filter"
                onChange={({ target }) => setFilterValue(target.value)}
              />
              <Button
                className="pull-right spacer-left"
                variant="primary"
                type="submit"
                onClick={() => setGlobalFilter(filterValue)}
                disabled={!globalFilter && !filterValue}
              >
                <FormattedMessage id="button.submit" defaultMessage="Submit" />
              </Button>
            </div>
            <Form.Check
              className="mt15"
              name="archived"
              checked={!!hideArchived}
              onChange={onArchivedCheckboxChange}
              type="checkbox"
              label="Hide Archived"
            />
          </Col>
          <div className="pagination-panel">
            <ButtonToolbar className="pull-right toolbar spacer-left">
              <Button
                variant="default"
                text="dark"
                className={viewMode === VIEW_MODE_OPTIONS.CARDS ? 'btn-view-mode-active' : ''}
                onClick={() => setViewMode(VIEW_MODE_OPTIONS.CARDS)}
              >
                <FontAwesomeIcon icon={faThLarge} size="lg" />
              </Button>
              <Button
                text="dark"
                variant="default"
                className={viewMode === VIEW_MODE_OPTIONS.LIST ? 'btn-view-mode-active' : ''}
                active={viewMode === VIEW_MODE_OPTIONS.LIST}
                onClick={() => setViewMode(VIEW_MODE_OPTIONS.LIST)}
              >
                <FontAwesomeIcon icon={faList} size="lg" />
              </Button>
            </ButtonToolbar>
            <ButtonToolbar className="pull-right">
              <Button
                disabled={activePage === 0 || fetching}
                className="spacer-right"
                onClick={prevPage}
              >Prev
              </Button>
              <DropdownButton className="spacer-right" title={`Page ${activePage + 1}`}>
                {_range(0, totalPaginatedPages).map(value => (
                  <Dropdown.Item
                    active={value === pageLimit}
                    key={value}
                    eventKey={value}
                    onClick={() => setPage(value)}
                  >
                    {value + 1}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              <Button
                disabled={((
                  activePage === (totalPaginatedPages - 1) ||
                  // No need to show the next button if there are no more printers to show
                  totalItems === 0
                )) || fetching}
                onClick={nextPage}
              >Next
              </Button>
            </ButtonToolbar>
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Container fluid>
        <BreadcrumbNav breadcrumbs={['assets', isServices ? 'postProcessorServices' : 'postProcessors']} />

        <Row>
          <Col xs={12} className="mb8">
            <WorkstationTabs activeKey={`${isServices ? 'post-processor-services' : 'post-processors'}`} />
          </Col>
          <Col xs={3}>
            <Locations />
          </Col>
          <Col xs={5}>
            <SubLocations />
          </Col>

          <Col xs={{ span: 4 }}>
            <PostProcessorNavbarLinks
              isDebugModeEnabled={isDebugModeEnabled}
              isServices={isServices}
              isGroupQualificationsFeatureEnabled={
                isGroupQualificationsFeatureEnabled
              }
            />
          </Col>
        </Row>

        <hr />

        <Row>
          <Col xs={12}>
            {fetching ? (
              <Loading />
            ) : (
              <>
                {isCardsView ? (
                  <Container>
                    <PostProcessorsCards
                      fetching={fetching}
                      postProcessorTypes={postProcessorTypes}
                      locations={locations}
                      pagination={pagination}
                      renderHeaderView={renderHeaderView}
                      locationsByUri={locationsByUri}
                      subLocationsByUri={subLocationsByUri}
                      {...restProps}
                    />
                  </Container>
                ) : (
                  <PostProcessorsTable
                    isDebugModeEnabled={isDebugModeEnabled}
                    postProcessors={data}
                    columns={columns}
                    fetching={fetching}
                    pagination={pagination}
                    renderHeaderView={renderHeaderView}
                  />
                )}
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

PostProcessors.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  locationsByUri: PropTypes.objectOf(PropTypes.shape({})),
  postProcessorTypes: PropTypes.objectOf(postProcessorTypeResourceType)
    .isRequired,
  isServices: PropTypes.bool,
  isServiceProviderRole: PropTypes.bool,
  isGroupQualificationsFeatureEnabled: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetching: PropTypes.bool.isRequired,
  pagination: PropTypes.shape({
    activePage: PropTypes.number,
    pageLimit: PropTypes.number,
    setPage: PropTypes.func,
    totalPaginatedPages: PropTypes.number,
    offset: PropTypes.number,
    nextPage: PropTypes.func,
    prevPage: PropTypes.func,
    totalItems: PropTypes.number,
  }).isRequired,
  isCardsView: PropTypes.bool.isRequired,
  subLocationsByUri: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  filterValues: PropTypes.shape({
    filterValue: PropTypes.string,
    setFilterValue: PropTypes.func,
  }).isRequired,
  viewModeProps: PropTypes.shape({
    viewMode: PropTypes.string,
    setViewMode: PropTypes.func,
  }).isRequired,
  filters: PropTypes.shape({
    globalFilter: PropTypes.string,
    setGlobalFilter: PropTypes.func,
  }).isRequired,
  isDebugModeEnabled: PropTypes.bool.isRequired,
  hideArchived: PropTypes.bool.isRequired,
  onArchivedCheckboxChange: PropTypes.func.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      archived: PropTypes.string,
      duration: PropTypes.number.isRequired,
    }),
  }).isRequired,
};

PostProcessors.defaultProps = {
  isServices: false,
  // This prop is Required when isServices=true
  isServiceProviderRole: false,
  // This prop is Required when isServices=false
  locationsByUri: {},
};

export default PostProcessors;
