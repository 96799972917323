/* eslint-disable no-bitwise */
import { faSave } from '@fortawesome/free-regular-svg-icons';
import { faExternalLink, faQuestionCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import Feature from 'rapidfab/components/Feature';
import Loading from 'rapidfab/components/Loading';
import {
  FormControlTextArea,
  FormControlTextCareful,
} from 'rapidfab/components/formTools';
import SelectSingle from 'rapidfab/components/forms/SelectSingle';
import TagsInput from 'rapidfab/components/forms/TagsInput';
import { ALLOWED_DOCUMENT_EXTENSIONS, FEATURES } from 'rapidfab/constants';
import { FormattedMessage } from 'rapidfab/i18n';
import { finalFormInputTypes } from 'rapidfab/types';
import { isURLRegex } from 'rapidfab/utils/uriUtils';
import React, { useState } from 'react';
import {
  Alert,
  Form as BSForm,
  Button,
  Card,
  Col,
  Container,
  FormControl,
  FormGroup,
  FormLabel,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import { Field, Form, FormSpy } from 'react-final-form';
import { hhmmss } from 'rapidfab/utils/timeUtils';
import FileInput from '../records/order/edit/FileInput';

const ResourceLink = ({ href }) => (
  <a href={href}>
    Go <FontAwesomeIcon icon={faExternalLink} />
  </a>
);

ResourceLink.propTypes = { href: PropTypes.string.isRequired };

const DisabledFieldTooltip = () => (
  <OverlayTrigger
    placement="top"
    overlay={(
      <Tooltip>
        <FormattedMessage
          id="disabledFieldTooltip"
          defaultMessage="This field can’t be changed manually. Please contact Authentise Support to change."
        />
      </Tooltip>
    )}
  >
    <FontAwesomeIcon
      icon={faQuestionCircle}
      style={{ marginRight: '10px', marginTop: '12px' }}
    />
  </OverlayTrigger>
);

const EmailFieldExampleFormatTooltip = () => (
  <OverlayTrigger
    placement="top"
    overlay={(
      <Tooltip>
        <FormattedMessage
          id="emailFieldExampleFormatTooltip"
          defaultMessage="Example: hello@world.com, support@test.com"
        />
      </Tooltip>
    )}
  >
    <FontAwesomeIcon icon={faQuestionCircle} className="spacer-left" />
  </OverlayTrigger>
);

const AdminIntakeSettings = ({ bureauIntakeSettings }) => (
  <>
    <Row style={{ margin: '1rem 0' }}>
      <Col xs={4}>
        <FormLabel>Default Workstation Time (hours):</FormLabel>
      </Col>
      <Col xs={3}>
        <Field
          name="default_workstation_time"
          render={() => (
            <FormControlTextCareful
              value={hhmmss(bureauIntakeSettings.default_workstation_time)}
              onChange={() => null}
              disabled
            />
          )}
        />
      </Col>
      <Col xs={2} className="d-sm-none d-md-block">
        <DisabledFieldTooltip />
      </Col>
    </Row>
    <Row style={{ margin: '1rem 0' }}>
      <Col xs={4}>
        <FormLabel>Default Workstation Charge Per:</FormLabel>
      </Col>
      <Col xs={3}>
        <Field
          name="default_workstation_charge_per"
          render={() => (
            <FormControlTextCareful
              value={`${bureauIntakeSettings.default_workstation_charge_per}`}
              onChange={() => null}
              disabled
            />
          )}
        />
      </Col>
      <Col xs={2} className="d-sm-none d-md-block">
        <DisabledFieldTooltip />
      </Col>
    </Row>
    <Row style={{ margin: '1rem 0' }}>
      <Col xs={4}>
        <FormLabel>Default Machine Run Time:</FormLabel>
      </Col>
      <Col xs={3}>
        <Field
          name="default_machine_run_time"
          render={() => (
            <FormControlTextCareful
              value={hhmmss(bureauIntakeSettings.default_machine_run_time)}
              onChange={() => null}
              disabled
            />
          )}
        />
      </Col>
      <Col xs={2} className="d-sm-none d-md-block">
        <DisabledFieldTooltip />
      </Col>
    </Row>
    <Row style={{ margin: '1rem 0' }}>
      <Col xs={4}>
        <FormLabel>Default Machine Charge Per:</FormLabel>
      </Col>
      <Col xs={3}>
        <Field
          name="default_machine_run_charge_per"
          render={() => (
            <FormControlTextCareful
              value={bureauIntakeSettings.default_machine_run_charge_per.toString()}
              onChange={() => null}
              disabled
            />
          )}
        />
      </Col>
      <Col xs={2} className="d-sm-none d-md-block">
        <DisabledFieldTooltip />
      </Col>
    </Row>
    <Row style={{ margin: '1rem 0' }}>
      <Col xs={4}>
        <FormLabel>Default Labor Time:</FormLabel>
      </Col>
      <Col xs={3}>
        <Field
          name="default_labor_time"
          render={() => (
            <FormControlTextCareful
              value={hhmmss(bureauIntakeSettings.default_labor_time)}
              onChange={() => null}
              disabled
            />
          )}
        />
      </Col>
      <Col xs={2} className="d-sm-none d-md-block">
        <DisabledFieldTooltip />
      </Col>
    </Row>
    <Row style={{ margin: '1rem 0' }}>
      <Col xs={4}>
        <FormLabel>Default Labor Charge Per:</FormLabel>
      </Col>
      <Col xs={3}>
        <Field
          name="default_labor_charge_per"
          render={() => (
            <FormControlTextCareful
              value={`${bureauIntakeSettings.default_labor_charge_per}`}
              onChange={() => null}
              disabled
            />
          )}
        />
      </Col>
      <Col xs={2} className="d-sm-none d-md-block">
        <DisabledFieldTooltip />
      </Col>
    </Row>
  </>
);

AdminIntakeSettings.propTypes = {
  bureauIntakeSettings: PropTypes.shape({
    default_workstation_time: PropTypes.number,
    default_workstation_charge_per: PropTypes.number,
    default_machine_run_time: PropTypes.number,
    default_machine_run_charge_per: PropTypes.number,
    default_labor_time: PropTypes.number,
    default_labor_charge_per: PropTypes.number,
  }).isRequired,
};

const AdminSettings = ({
  notifications,
  roles,
  onToggle,
  onFormSubmit,
  initialFormValues,
  isSessionManager,
  submitting,
  loading,
  prepWorkflows,
  bureauIntakeSettings,
  setFormState,
  logoUploadState: [latestBureauLogo, setLatestBureauLogo],
}) => {
  const [showTimeOutField, setShowTimeOutField] = useState(initialFormValues?.contactless_logon_enabled);

  const isEnabledNotification = ([notification, roleBitwiseMask], state) => (
    (state.formState.values[notification] & roleBitwiseMask) === roleBitwiseMask
  );

  const handleChangeHelpdeskType = ([field], state, { changeValue }) => {
    const updatedState = { ...state.formState.values,
      helpdesk_type: field.target.value,
      custom_helpdesk_uri: null };

    changeValue(state, field, () => updatedState);
  };

  const urlFormatValidator = value => {
    if (!isURLRegex(value)) {
      return 'Please enter a valid URL.';
    }

    // eslint-disable-next-line unicorn/no-useless-undefined
    return undefined;
  };

  const notificationsByRole = roleBitwiseMask => notifications.filter(
    ({ show }) => (show & roleBitwiseMask) === roleBitwiseMask,
  );

  if (loading) {
    return <Loading />;
  }
  return (
    <Container>
      <Form
        onSubmit={onFormSubmit}
        mutators={{
          onToggle,
          isEnabledNotification,
          handleChangeHelpdeskType,
        }}
        initialValues={initialFormValues}
        render={({ handleSubmit, form, values }) => (
          <form onSubmit={handleSubmit}>
            <BreadcrumbNav breadcrumbs={['admin', 'Settings']} />

            <div style={{ display: 'flex', alignItems: 'baseline' }} className="page-header">
              <h1>
                <FormattedMessage
                  id="admin.bureauSettings"
                  defaultMessage="Bureau Settings"
                />
              </h1>
              <Button
                variant="primary"
                size="sm"
                className="flexed-pull-right"
                type="submit"
                disabled={!isSessionManager || submitting}
              >
                {submitting ? (
                  <Loading />
                ) : (
                  <span>
                    <FontAwesomeIcon icon={faSave} />{' '}
                    <FormattedMessage id="button.save" defaultMessage="Save" />
                  </span>
                )}
              </Button>
            </div>
            <Card className="m-b" bg="dark">
              <Card.Header className="pd-exp inverse">Email Notifications</Card.Header>
              <div className="card-body-wrapper">
                <Card.Body>
                  <Alert variant="info">
                    Choose who receives email notifications for which events.{' '}
                    <a
                      href="https://authentise.zendesk.com/hc/en-us/articles/115003554806-Alerts"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      See this help article
                    </a>{' '}
                    for more information.
                  </Alert>
                  <Row>
                    <Col md={4} sm={12}>
                      <p className="m-b">
                        The <strong>Customer</strong> should receive the following
                        notifications:
                      </p>

                      {notificationsByRole(roles.CUSTOMER_ROLE).map(notification => {
                        const { id } = notification;
                        return (
                          <FormGroup className="form-group" key={id}>
                            <Field
                              type="checkbox"
                              name={id}
                              render={() => (
                                <BSForm.Check
                                  name={id}
                                  label={notification.label}
                                  checked={form.mutators.isEnabledNotification(id, roles.CUSTOMER_ROLE)}
                                  onChange={event => {
                                    form.mutators.onToggle(
                                      notification.id,
                                      {
                                        roleBitwiseMask: roles.CUSTOMER_ROLE,
                                        isChecked: event.target.checked,
                                      },
                                    );
                                  }}
                                  type="checkbox"
                                />
                              )}
                            />
                          </FormGroup>
                        );
                      })}
                    </Col>
                    <Col md={4} sm={12}>
                      <p className="m-b">
                        The <strong>Owner:</strong> should receive the following
                        notifications:
                      </p>

                      {notificationsByRole(roles.OWNER_ROLE).map(notification => (
                        <FormGroup className="form-group" key={notification.id}>
                          <Field
                            type="checkbox"
                            name={notification.id}
                            render={() => (
                              <BSForm.Check
                                label={notification.label}
                                checked={form.mutators.isEnabledNotification(notification.id, roles.OWNER_ROLE)}
                                onChange={event => {
                                  form.mutators.onToggle(
                                    notification.id,
                                    {
                                      roleBitwiseMask: roles.OWNER_ROLE,
                                      isChecked: event.target.checked,
                                    },
                                  );
                                }}
                                type="checkbox"
                              />
                            )}
                          />
                        </FormGroup>
                      ))}
                    </Col>
                    <Col md={4} sm={12}>
                      <p>
                        The <strong>following email</strong> should receive the following
                        notifications:
                      </p>

                      {notificationsByRole(roles.FOLLOWING_EMAIL).map(notification => (
                        <FormGroup className="form-group" key={notification.id}>
                          <Field
                            type="checkbox"
                            name={notification.id}
                            render={() => (
                              <BSForm.Check
                                label={notification.label}
                                checked={form.mutators.isEnabledNotification(notification.id, roles.FOLLOWING_EMAIL)}
                                onChange={event => {
                                  form.mutators.onToggle(
                                    notification.id,
                                    {
                                      roleBitwiseMask: roles.FOLLOWING_EMAIL,
                                      isChecked: event.target.checked,
                                    },
                                  );
                                }}
                                type="checkbox"
                              />
                            )}
                          />
                        </FormGroup>
                      ))}

                      <FormGroup controlId="customEmail">
                        <FormLabel>
                          Emails:
                          <EmailFieldExampleFormatTooltip />
                        </FormLabel>
                        <Field
                          name="notification_custom_emails"
                          render={props => (
                            <TagsInput
                              tags={props.input.value}
                              onChange={props.input.onChange}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Card.Body>
              </div>
            </Card>

            <Card bg="dark">
              <Card.Header className="pd-exp inverse">Defaults</Card.Header>
              <div className="card-body-wrapper">
                <Card.Body>
                  <Row style={{ margin: '1rem 0' }}>
                    <Col xs={4}>
                      <FormLabel>
                        Bureau Logo:
                      </FormLabel>
                    </Col>
                    <Col xs={3}>
                      {(latestBureauLogo) ?
                        (
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {latestBureauLogo?.name}
                            {' '}
                            <Button
                              size="xs"
                              variant="danger"
                              onClick={() => setLatestBureauLogo(null)}
                              // disabled={isDisabledForm}
                            >
                              <FontAwesomeIcon icon={faTimes} />
                            </Button>
                          </div>
                        ) : (
                          <FileInput
                            defaultStyle={false}
                            onlyFileInput
                            fileType={FileInput.fileTypes.document}
                            handleFileChange={event => setLatestBureauLogo(event.target.files[0])}
                            chooseFileLabel="Choose File"
                            acceptedExtensions={[...ALLOWED_DOCUMENT_EXTENSIONS]}
                          />
                        )}
                    </Col>
                  </Row>

                  <Row style={{ margin: '1rem 0' }}>
                    <Col xs={4}>
                      <FormLabel>
                        Default Order Quote Notes Placeholder:
                      </FormLabel>
                    </Col>
                    <Col xs={3}>
                      <FormControlTextArea
                        name="order_quote_notes_placeholder"
                        initialValue={initialFormValues?.order_quote_notes_placeholder}
                      />
                    </Col>
                  </Row>

                  <Row style={{ margin: '1rem 0' }}>
                    <Col xs={4}>
                      <FormLabel>
                        Default Manufacturing Base Format:
                      </FormLabel>
                    </Col>
                    <Col xs={3}>
                      <Field
                        name="mes_base_format"
                        render={props => {
                          const field = { ...props.input, ...props.meta };
                          return (
                            <FormControlTextCareful
                              {...field}
                              inputRef={props.ref}
                              disabled
                            />
                          );
                        }}
                      />
                    </Col>
                    <Col xs={2} className="d-sm-none d-md-block">
                      <DisabledFieldTooltip />
                    </Col>
                  </Row>

                  <Feature featureName={FEATURES.PREP_WORKFLOW}>
                    <Row style={{ margin: '1rem 0' }}>
                      <Col xs={4}>
                        <FormLabel>
                          Default Prep Workflow
                        </FormLabel>
                      </Col>
                      <Col xs={3}>
                        <Field
                          name="default_prep_workflow"
                          render={props => (
                            <SelectSingle
                              disabled
                              name="defaultPrepWorkflow"
                              data={prepWorkflows}
                              value={props.input.value}
                              handleOnChange={props.input.onChange}
                            />
                          )}
                        />
                      </Col>
                      <Col xs={2} className="d-sm-none d-md-block">
                        <DisabledFieldTooltip />
                      </Col>
                    </Row>
                  </Feature>

                  <Row style={{ margin: '1rem 0' }}>
                    <Col xs={4}>
                      <FormLabel>
                        Default Currency:
                      </FormLabel>
                    </Col>
                    <Col xs={3}>
                      <Field
                        name="default_currency"
                        render={props => {
                          const field = { ...props.input, ...props.meta };
                          return (
                            <FormControlTextCareful
                              {...field}
                              inputRef={props.ref}
                              disabled
                            />
                          );
                        }}
                      />
                    </Col>
                    <Col xs={2} className="d-sm-none d-md-block">
                      <DisabledFieldTooltip />
                    </Col>
                  </Row>

                  <Feature featureName={FEATURES.DESIGN_COST}>
                    <Row style={{ margin: '1rem 0' }}>
                      <Col xs={4}>
                        <FormLabel>
                          Hourly Design Cost
                        </FormLabel>
                      </Col>
                      <Col xs={3}>
                        <Field
                          name="hourly_design_cost"
                          type="number"
                          render={props => {
                            const field = { ...props.input, ...props.meta };
                            return (
                              <FormControlTextCareful
                                {...field}
                                inputRef={props.ref}
                                type={props.input.type}
                              />
                            );
                          }}
                        />
                      </Col>
                    </Row>
                  </Feature>

                  <Feature featureName={FEATURES.AUTOLOGOUT}>
                    <Row style={{ margin: '1rem 0' }}>
                      <Col xs={4}>
                        <FormLabel>
                          Log out automatically after (in minutes)
                        </FormLabel>
                      </Col>
                      <Col xs={3}>
                        <Field
                          name="autologout_after"
                          type="number"
                          render={props => {
                            const field = { ...props.input, ...props.meta };
                            return (
                              <FormControlTextCareful
                                {...field}
                                inputRef={props.ref}
                                type={props.input.type}
                                disabled
                              />
                            );
                          }}
                        />
                      </Col>
                      <Col xs={2} className="d-sm-none d-md-block">
                        <DisabledFieldTooltip />
                      </Col>
                    </Row>
                  </Feature>
                  {bureauIntakeSettings && Object.keys(bureauIntakeSettings).length > 0 && (
                    <AdminIntakeSettings
                      bureauIntakeSettings={bureauIntakeSettings}
                    />
                  )}

                  <Row style={{ margin: '1rem 0' }}>
                    <Col xs={4}>
                      <FormLabel>
                        Allow User Login via QR code:
                      </FormLabel>
                    </Col>
                    <Col xs={3}>
                      <Field
                        name="contactless_logon_enabled"
                        render={props => (
                          <FormControl
                            className="d-flex flex-1"
                            as="select"
                            value={props.input.value}
                            onChange={event => {
                              props.input.onChange(event);
                              setShowTimeOutField(event.target.value === 'true');
                            }}
                          >
                            <option value="true"> Allow</option>
                            <option value="false"> Disallow</option>
                          </FormControl>
                        )}
                      />
                    </Col>
                  </Row>
                  {showTimeOutField && (
                    <Row style={{ margin: '1rem 0' }}>
                      <Col xs={4}>
                        <FormLabel>
                          User Login via QR Timeout
                        </FormLabel>
                      </Col>
                      <Col xs={3}>
                        <Field
                          name="contactless_logon_timeout"
                          type="number"
                          render={props => (
                            <FormControl
                              min={1}
                              {...props.input}
                              required
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  )}

                  <Row style={{ margin: '1rem 0' }}>
                    <Col xs={4}>
                      <FormLabel>
                        Help Desk Type
                      </FormLabel>
                    </Col>
                    <Col xs={3}>
                      <Field
                        name="helpdesk_type"
                        initialValue={values.custom_helpdesk_uri ? 'custom' : 'default'}
                        render={props => (
                          <FormControl
                            className="d-flex flex-1"
                            as="select"
                            defaultValue={props.input.value}
                            onChange={form.mutators.handleChangeHelpdeskType}
                          >
                            <option value="default">Default</option>
                            <option value="custom">Custom</option>
                          </FormControl>
                        )}
                      />
                    </Col>
                  </Row>

                  {(values.helpdesk_type === 'custom' || !!values.custom_helpdesk_uri) && (
                    <Row style={{ margin: '1rem 0' }}>
                      <Col xs={4}>
                        <FormLabel>
                          Custom Help Desk URL
                        </FormLabel>
                      </Col>
                      <Col xs={3}>
                        <Field
                          validate={urlFormatValidator}
                          name="custom_helpdesk_uri"
                          render={props => ([
                            <FormControl
                              min={1}
                              placeholder="help.example.com"
                              {...props.input}
                              required
                            />,
                            props.meta.error && props.meta.touched && <span className="mt-1 text-danger">{props.meta.error}</span>,
                          ])}
                        />
                      </Col>
                    </Row>
                  )}

                  <Row style={{ margin: '1rem 0' }}>
                    <Col xs={4}>
                      <FormLabel>
                        Bureau Barcode Format
                      </FormLabel>
                    </Col>
                    <Col xs={3}>
                      <Field
                        name="barcode_default_format"
                        render={props => (
                          <FormControl
                            className="d-flex flex-1"
                            as="select"
                            value={props.input.value}
                            onChange={props.input.onChange}
                          >
                            <option value="qr">QR</option>
                            <option value="barcode">1-D Barcode</option>
                          </FormControl>
                        )}
                      />
                    </Col>
                  </Row>

                </Card.Body>
              </div>
            </Card>

            <Button
              className="m-t-sm pull-right"
              variant="primary"
              size="sm"
              type="submit"
              disabled={!isSessionManager || submitting}
            >
              {submitting ? (
                <Loading />
              ) : (
                <span>
                  <FontAwesomeIcon icon={faSave} />{' '}
                  <FormattedMessage id="button.save" defaultMessage="Save" />
                </span>
              )}
            </Button>
            <FormSpy
              subscription={{ values: true }}
              onChange={values => setFormState(values)}
            />
          </form>
        )}
      />
    </Container>
  );
};

AdminSettings.propTypes = {
  roles: PropTypes.shape({
    CUSTOMER_ROLE: PropTypes.number,
    OWNER_ROLE: PropTypes.number,
    FOLLOWING_EMAIL: PropTypes.number,
  }).isRequired,
  ref: PropTypes.func.isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }).isRequired,
  isSessionManager: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  notifications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  initialFormValues: PropTypes.shape({
    autologout_after: PropTypes.number.isRequired,
    notification_custom_emails: PropTypes.string.isRequired,
    order_quote_notes_placeholder: PropTypes.string.isRequired,
    mes_base_format: PropTypes.string.isRequired,
    default_prep_workflow: PropTypes.string.isRequired,
    default_currency: PropTypes.string.isRequired,
    contactless_logon_enabled: PropTypes.string.isRequired,
    contactless_logon_timeout: PropTypes.number.isRequired,
    hourly_design_cost: PropTypes.number.isRequired,
  }).isRequired,
  prepWorkflows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  bureauIntakeSettings: PropTypes.shape({
    default_workstation_time: PropTypes.number,
    default_workstation_charge_per: PropTypes.number,
    default_machine_run_time: PropTypes.number,
    default_machine_run_charge_per: PropTypes.number,
    default_labor_time: PropTypes.number,
    default_labor_charge_per: PropTypes.number,
  }).isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  input: finalFormInputTypes.isRequired,
  setFormState: PropTypes.func.isRequired,
  logoUploadState: PropTypes.arrayOf([PropTypes.shape({}), PropTypes.func]).isRequired,
};

export default AdminSettings;
