import { faArrowLeft, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import Loading from 'rapidfab/components/Loading';
import { USER_MODAL_MODES } from 'rapidfab/constants';
import React from 'react';
import { Button, Col, FormControl, FormGroup, FormLabel, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

const AddUser = ({
  fetching,
  isEditing,
  onFormSubmit,
  handleNewUserInputs,
  userInputsProps,
  setUserModalMode,
  isRestrictedUser,
  setDeleteUserWarning,
}) => {
  const { name, email } = userInputsProps;
  return (
    <Row>
      <h5 className="text-white">
        <FormattedMessage
          id="instructionsForAddingUsers"
          defaultMessage="Please enter user's full name and email address here. On submission they will be emailed a link to complete the setup of their account. The link will be valid for 24 hours."
        />
      </h5>
      <form onSubmit={onFormSubmit}>
        <Col lg={12} xs={12}>
          <FormGroup className="form-group" controlId="uxName">
            <FormLabel>
              <FormattedMessage id="field.name" defaultMessage="Name" />: *
            </FormLabel>
            <FormControl
              name="name"
              type="text"
              value={name}
              onChange={handleNewUserInputs}
              required
            />
          </FormGroup>
          <FormGroup className="form-group" controlId="uxEmail">
            <FormLabel>
              <FormattedMessage id="email" defaultMessage="Email" />: *
            </FormLabel>
            <FormControl
              name="email"
              type="email"
              value={email}
              onChange={handleNewUserInputs}
              required
            />
          </FormGroup>
        </Col>
        <Col lg={12} xs={12}>
          {isEditing && (
            <Button
              variant="link"
              className="user-manage-modal-button-delete"
              disabled={isRestrictedUser}
              onClick={() => setDeleteUserWarning(true)}
            >
              <FormattedMessage id="button.delete" defaultMessage="Delete" />
            </Button>
          )}
          <Button variant="link" className="user-manage-modal-button-filled pull-right" type="submit">
            {fetching ? (
              <Loading inline />
            ) : (
              <>
                <FontAwesomeIcon icon={faFloppyDisk} className="spacer-right" />
                <FormattedMessage
                  id={isEditing ? 'updateUser' : 'createAccountAndInvite'}
                  defaultMessage={isEditing ? 'Update User' : 'Create User'}
                />
              </>
            )}
          </Button>
          {isEditing && (
            <Button
              variant="link"
              className="user-manage-modal-button pull-right spacer-right"
              onClick={() => setUserModalMode(USER_MODAL_MODES.VIEW)}
            >
              <FontAwesomeIcon icon={faArrowLeft} className="spacer-right" />
              <FormattedMessage id="back" defaultMessage="Back" />
            </Button>
          )}
        </Col>
      </form>
    </Row>
  );
};

AddUser.propTypes = {
  fetching: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  handleNewUserInputs: PropTypes.func.isRequired,
  userInputsProps: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  setUserModalMode: PropTypes.func.isRequired,
  isRestrictedUser: PropTypes.bool.isRequired,
  setDeleteUserWarning: PropTypes.func.isRequired,
};

export default AddUser;
