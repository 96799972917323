import _isEmpty from 'lodash/isEmpty';
import { weekDays } from 'rapidfab/constants/dates';
import {
  defaultLaborWorkScheduleWeekData,
  getLaborWeekdaysTimeMapping,
  getPostProcessorDurationInSpecialFormat, transformedLaborWorkScheduleWeekData,
} from 'rapidfab/utils/timeUtils';
import utcOffSet from 'rapidfab/utils/utcTimezoneOffSet';
import RCTooltip from 'rc-tooltip';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
} from 'react-bootstrap';
import Loading from 'rapidfab/components/Loading';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { Link } from 'react-router-dom';
import { ROUTES, WORK_SCHEDULE_TYPES } from 'rapidfab/constants';

import _toPairs from 'lodash/toPairs';
import 'rapidfab/styles/componentStyles/resourcesCards.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faList,
  faBarsStaggered,
  faClock, faBusinessTime, faInfoCircle, faQuestionCircle, faFax,
} from '@fortawesome/free-solid-svg-icons';
import 'rapidfab/styles/componentStyles/progressBars.scss';
import LinearProgressBar from 'rapidfab/components/ProgressBars/LinearProgressBar';
import dayjs from 'dayjs';
import { truncateText } from 'rapidfab/utils/stringUtils';
import Tooltip from 'rapidfab/components/Tooltip';
import { FormattedMessage } from 'react-intl';

const PostProcessorsCards = memo(({
  locationsByUri,
  postProcessorTypes,
  groupedPostProcessors,
  findScheduledRuns,
  getPercentage,
  runsFetching,
  scheduledStartDate,
  renderHeaderView,
  isRunActive,
  subLocationsByUri,
  workSchedulesByPostProcessorType,
  isWorkScheduleFetching,
}) => {
  const showTooltipCondition = (type, text) => {
    switch (type) {
      case 'postProcessorType':
        return text?.length >= 25;
      case 'postProcessorName':
        return text?.length >= 21;
      case 'runName':
        return text?.length >= 24;
      default:
        return false;
    }
  };

  const renderPostProcessorWorkSchedule = workSchedule => {
    if (isWorkScheduleFetching) return <Loading />;
    if (_isEmpty(workSchedulesByPostProcessorType)) return null;

    const isNonStop = workSchedule?.type === WORK_SCHEDULE_TYPES.NON_STOP;

    const workScheduleData = () => {
      if (_isEmpty(workSchedule)) {
        return (
          <div>
            <span className="spacer-right">Not set</span>
            <Tooltip
              id="workScheduleNotSet"
              placement="top"
              trigger={(<FontAwesomeIcon className="spacer-right" icon={faQuestionCircle} />)}
            >
              The work schedule has not been configured for this Post Processor Type.
              Please manually set it on the Post Processor Type page if necessary.
            </Tooltip>
          </div>
        );
      }

      if (isNonStop) {
        return (
          <div>
            <span className="spacer-right">24/7</span>
            <Tooltip
              id="workScheduleNonStop"
              placement="top"
              trigger={(<FontAwesomeIcon className="spacer-right" icon={faInfoCircle} />)}
            >
              <FormattedMessage
                id="alwaysOnSchedule"
                defaultMessage="This workstation’s schedule is always on, operated 24/7."
              />
            </Tooltip>
          </div>
        );
      }

      const weekDaysTimeMapping = getLaborWeekdaysTimeMapping(weekDays);
      const weekPayloadData = transformedLaborWorkScheduleWeekData(workSchedule);
      const defaultWeekData = defaultLaborWorkScheduleWeekData(weekDaysTimeMapping, weekPayloadData);

      /* Combine API days, and if it is not all the list of the weekdays
          -> fill in the rest of it by the default data contains of days. */
      const week = { ...weekPayloadData, ...defaultWeekData };
      const workDays = Object.keys(weekPayloadData);
      const laborScheduleTime = week;

      return (
        <>
          <FormattedMessage
            id="status.scheduled"
            defaultMessage="Scheduled"
          />&nbsp;
          <RCTooltip
            placement="right"
            destroyTooltipOnHide
            overlayInnerStyle={{ border: 'none', backgroundColor: '#30343E', padding: 0 }}
            mouseLeaveDelay={0.3}
            overlay={(
              <div className="workScheduleTooltip">
                <div>
                  <p className="workScheduleTooltipTitle">
                    <FormattedMessage
                      id="laborAvailableSchedule"
                      defaultMessage="Labor Available Schedule"
                    />
                  </p>
                  {
                    !!workSchedule?.utc_offset && (
                      <p className="workScheduleTooltipTitleTimezone">Timezone:&nbsp;
                        {Object.keys(utcOffSet).find(key => utcOffSet[key] === workSchedule.utc_offset)}
                      </p>
                    )
                  }
                </div>
                {
                  weekDays.map(day => {
                    const isAvailableWorkday = isNonStop ? true : workDays.includes(day);

                    return (
                      <div className="d-flex justify-content-start align-items-center w-100 mb5" data-cy={day}>
                        <p className="workScheduleDays">{day}:</p>
                        {
                          isAvailableWorkday ?
                            (
                              <div className="d-flex justify-content-center align-items-center">
                                <div className="d-flex align-items-center">
                                  <FontAwesomeIcon className="spacer-right" icon={faClock} />
                                  <p className="workScheduleTooltipTime">{isNonStop ? '00:00' : laborScheduleTime[day]?.start}</p>
                                </div>
                                <span className="spacer-right spacer-left">-</span>
                                <div className="d-flex align-items-center">
                                  <FontAwesomeIcon className="spacer-right" icon={faClock} />
                                  <p className="workScheduleTooltipTime">{isNonStop ? '23:59' : laborScheduleTime[day]?.finish}</p>
                                </div>
                              </div>
                            ) : (
                              <div className="d-flex justify-content-between align-items-center">
                                <i className="workScheduleTooltipTimeOff">
                                  <FormattedMessage
                                    id="notAWorkingDay"
                                    defaultMessage="This is not a working day"
                                  />
                                </i>
                              </div>
                            )
                        }
                      </div>
                    );
                  })
                }
              </div>
            )}
          >
            <FontAwesomeIcon className="spacer-right" icon={faInfoCircle} />
          </RCTooltip>
        </>
      );
    };

    return (
      <div className="mt-2 d-flex align-items-center">
        <FontAwesomeIcon className="spacer-right" icon={faBusinessTime} />
        {workScheduleData()}
      </div>
    );
  };

  const renderPostProcessorCalendarData = (postProcessorDuration, workSchedule) => (
    <div className="ResourceCardBottomViewCard">
      <div>
        <FontAwesomeIcon className="spacer-right" icon={faClock} />
        {postProcessorDuration}
      </div>
      {renderPostProcessorWorkSchedule(workSchedule)}
    </div>
  );

  const renderRunData = (printer, queue) => {
    if (runsFetching) {
      return <Loading />;
    }

    const { earliestRun, latestRunDate } = findScheduledRuns(printer);
    const currentRunPercentage = getPercentage(earliestRun);
    const currentRunScheduledTime = scheduledStartDate(earliestRun);
    const formattedCurrentRunScheduledTime = currentRunScheduledTime ?
      `Scheduled for ${dayjs(currentRunScheduledTime).format('h A, MM/DD')}` :
      'Not Started';
    const formattedDate = latestRunDate ?
      dayjs(latestRunDate).format('h A, MM/DD') :
      'N/A';
    const runName = earliestRun ? earliestRun.name.toLowerCase() : null;
    const runsLength = queue?.length || 0;
    const hasStarted = earliestRun && isRunActive(earliestRun);

    return (
      <div className="ResourceCardBottomViewCard">
        <div className="d-flex align-items-center mb-2">
          <FontAwesomeIcon className="spacer-right" icon={faList} />
          {
            earliestRun?.uri ?
              (showTooltipCondition('runName', runName) ? (
                <Tooltip
                  id="printerNameTooltip"
                  placement="top"
                  trigger={(
                    <Link
                      className="truncate capitalize ResourceCardCurrentRunNameShort"
                      to={getRouteURI(
                        ROUTES.RUN_EDIT,
                        { uuid: extractUuid(earliestRun.uri) },
                        {}, true)}
                    >
                      <span>{runName}
                      </span>
                    </Link>
                  )}
                >
                  <span className="wrap-text">{runName}</span>
                </Tooltip>
              ) : (
                <Link
                  className="truncate capitalize ResourceCardCurrentRunNameShort"
                  to={getRouteURI(
                    ROUTES.RUN_EDIT,
                    { uuid: extractUuid(earliestRun.uri) },
                    {}, true)}
                >
                  <span>{runName}</span>
                </Link>
              )) : (
                <FormattedMessage id="noRunsScheduled" defaultMessage="No Runs Scheduled" />
              )
          }
        </div>
        <div className="mb-2">
          <LinearProgressBar
            active={hasStarted}
            percentage={currentRunPercentage}
            notStartedText={formattedCurrentRunScheduledTime}
          />
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <FontAwesomeIcon className="spacer-right" icon={faBarsStaggered} />
            {
              runsLength ? (
                <div className="d-flex align-items-center">
                  <span>{truncateText(runsLength.toString(), 3)}</span>
                  <span>&nbsp;{runsLength === 1 ? 'Run' : 'Runs'}</span>
                </div>
              ) : <span>Queue Empty</span>
            }
          </div>
          <div className="d-flex align-items-center">
            <FontAwesomeIcon className="spacer-right" icon={faClock} />
            <span className="truncate">{formattedDate}</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {renderHeaderView()}
      <div className="ResourceCardListCards">
        {
          Object.keys(groupedPostProcessors).length ?
            _toPairs(groupedPostProcessors).map(([locationUri, subLocations]) => {
              const location = locationsByUri[locationUri];

              return (
                <>
                  <div className="ResourceCardListLocation">
                    <span className="spacer-right">Location</span>
                    <Link
                      to={getRouteURI(
                        ROUTES.LOCATIONS,
                        null, { uuid: extractUuid(location?.uri) }, true)}
                    >
                      <span className="capitalize">{location?.name}</span>
                    </Link>
                  </div>

                  {_toPairs(subLocations).map(([subLocationUri, postProcessors]) => {
                    const subLocation = subLocationsByUri[subLocationUri];

                    return (
                      <>
                        <div className="ResourceCardListSubLocation">
                          <span className="capitalize">{subLocation?.name}</span>
                        </div>

                        <div className="card-list ResourceCardList mb30">
                          {postProcessors.map(postProcessor => {
                            const postProcessorName = postProcessor.name;
                            const postProcessorType = postProcessorTypes[postProcessor.post_processor_type]?.name;
                            const postProcessorDuration =
                              getPostProcessorDurationInSpecialFormat(postProcessor.duration);
                            const postProcessorTypeWorkSchedule = !_isEmpty(workSchedulesByPostProcessorType)
                              && workSchedulesByPostProcessorType[postProcessor.post_processor_type];

                            return (
                              <div
                                className="card ResourceCard"
                                key={postProcessor.uri}
                              >

                                <Row className="ResourceCardTopView">
                                  <Col xs={5}>
                                    <div className="ResourceCardResources">
                                      <div className="spacer-right">
                                        <FontAwesomeIcon icon={faFax} />
                                      </div>
                                      <div className="ResourceCardResourcesData">
                                        <div className="truncate ResourceCardPrinterName">
                                          {
                                            showTooltipCondition('postProcessorName', postProcessorName) ? (
                                              <Tooltip
                                                id="printerNameTooltip"
                                                placement="top"
                                                trigger={(
                                                  <Link
                                                    to={getRouteURI(
                                                      ROUTES.POST_PROCESSOR_EDIT,
                                                      { uuid: extractUuid(postProcessor.uri) },
                                                      {}, true)}
                                                  >
                                                    <span>{postProcessorName}</span>
                                                  </Link>
                                                )}
                                              >
                                                <span className="wrap-text">{postProcessorName}</span>
                                              </Tooltip>
                                            ) : (
                                              <Link
                                                className="capitalize"
                                                to={getRouteURI(
                                                  ROUTES.POST_PROCESSOR_EDIT,
                                                  { uuid: extractUuid(postProcessor.uri) },
                                                  {}, true)}
                                              >
                                                <span>{postProcessorName}</span>
                                              </Link>
                                            )
                                          }
                                        </div>
                                        <div className="truncate ResourceCardPrinterType">
                                          {
                                            showTooltipCondition('postProcessorType', postProcessorType) ? (
                                              <Tooltip
                                                id="printerNameTooltip"
                                                placement="top"
                                                trigger={(
                                                  <Link
                                                    to={
                                                      getRouteURI(
                                                        ROUTES.POST_PROCESSOR_TYPES,
                                                        null,
                                                        { uuid: extractUuid(postProcessor.post_processor_type) }, true)
                                                    }
                                                  >
                                                    <span className="capitalize">{postProcessorType}</span>
                                                  </Link>
                                                )}
                                              >
                                                <span className="wrap-text">{postProcessorType}</span>
                                              </Tooltip>
                                            ) : (
                                              <Link
                                                to={
                                                  getRouteURI(
                                                    ROUTES.POST_PROCESSOR_TYPES,
                                                    null,
                                                    { uuid: extractUuid(postProcessor.post_processor_type) }, true)
                                                }
                                              >
                                                <span className="capitalize">{postProcessorType}</span>
                                              </Link>
                                            )
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="ResourceCardBottomView">
                                  <Col xs={5}>
                                    {renderPostProcessorCalendarData(
                                      postProcessorDuration,
                                      postProcessorTypeWorkSchedule)}
                                  </Col>
                                  <Col xs={7}>
                                    {renderRunData(postProcessor, postProcessor?.queue)}
                                  </Col>
                                </Row>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    );
                  })}
                </>
              );
            })
            : (
              <h1 className="text-center mt-2">Nothing found</h1>
            )
        }
      </div>
    </>

  );
});

PostProcessorsCards.propTypes = {
  locationsByUri: PropTypes.objectOf(PropTypes.shape({
    name: PropTypes.string,
    uri: PropTypes.string,
  })).isRequired,
  postProcessorTypes: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  groupedPostProcessors: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape({}))).isRequired,
  findScheduledRuns: PropTypes.func.isRequired,
  getPercentage: PropTypes.func.isRequired,
  runsFetching: PropTypes.bool.isRequired,
  scheduledStartDate: PropTypes.oneOfType([
    PropTypes.string, PropTypes.oneOf([null, undefined]),
  ]).isRequired,
  renderHeaderView: PropTypes.func.isRequired,
  isRunActive: PropTypes.func.isRequired,
  subLocationsByUri: PropTypes.objectOf(PropTypes.shape({
    name: PropTypes.string,
  })).isRequired,
  workSchedulesByPostProcessorType: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  isWorkScheduleFetching: PropTypes.bool.isRequired,
};

export default PostProcessorsCards;
