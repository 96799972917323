import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { ROUTES } from 'rapidfab/constants';
import React, { useState, useRef, useEffect } from 'react';
import { Form, FormControl, Row, Col, Button } from 'react-bootstrap';
import Loading from 'rapidfab/components/Loading';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

const LoginForm = ({
  onEmailSubmit,
  loading,
  withPassword,
  ssoURI,
  onSubmit,
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const passwordInputRef = useRef(null);

  const isLoginDisabled = (loading || !email || (withPassword && !password));

  useEffect(() => {
    // Set focus to password input when user sees
    // password input for the first time
    if (withPassword) {
      passwordInputRef.current.focus();
    }
  }, [withPassword]);

  const toSsoLocation = () => {
    if (!ssoURI) {
      return;
    }

    window.location = ssoURI;
  };

  const onLogin = event => {
    event.preventDefault();

    if (!email) {
      return;
    }

    if (!withPassword) {
      // Check for SSO staff
      onEmailSubmit(email);
      return;
    }

    if (!password) {
      return;
    }

    onSubmit(email, password);
  };

  const LoginButtonText = () => {
    if (loading) {
      return <Loading inline />;
    }
    if (withPassword) {
      return <FormattedMessage id="field.signIn" defaultMessage="Sign In" />;
    }
    return <FormattedMessage id="field.continue" defaultMessage="Continue" />;
  };

  return (
    <Form onSubmit={onLogin}>
      <FormControl
        name="email"
        type="text"
        required
        className="mb25"
        autoComplete="username"
        placeholder="Email: *"
        value={email}
        onChange={({ target }) => setEmail(target.value)}
      />

      {
        withPassword && (
          <>
            <FormControl
              name="password"
              type="password"
              required
              placeholder="Password: *"
              autoComplete="current-password"
              value={password}
              onChange={({ target }) => setPassword(target.value)}
              ref={passwordInputRef}
            />
            <div
              className="text-right mt5"
            >
              <Link to={ROUTES.FORGOT_PASSWORD}>
                <FormattedMessage id="forgotPassword" defaultMessage="Forgot Password?" />
              </Link>
            </div>
          </>
        )
      }

      <Row>
        {/* SSO Disabled: [/4/, 4 (LOGIN), /4/]
                SSO Enabled:  [/2/, 4 (LOGIN), 4 (SSO), /2/] */}
        <Col xs={{ span: ssoURI ? 6 : 12 }} md={{ span: 6 }}>
          <Button
            block
            size="sm"
            type="submit"
            variant="outline-warning"
            className="authentise-login-submit-button"
            disabled={isLoginDisabled}
          >
            {!loading && <FontAwesomeIcon icon={faArrowRightLong} className="spacer-right" />}
            <LoginButtonText />
          </Button>
        </Col>
        {ssoURI && (
          <Col xs={6} md={6}>
            <Button
              block
              size="sm"
              onClick={() => toSsoLocation()}
              variant="outline-info"
            >
              <FormattedMessage id="field.signInViaSSO" defaultMessage="Sign In via SSO" />
            </Button>
          </Col>
        )}
      </Row>
    </Form>
  );
};

LoginForm.propTypes = {
  onEmailSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  withPassword: PropTypes.bool.isRequired,
  ssoURI: PropTypes.string,
};

LoginForm.defaultProps = {
  ssoURI: null,
};

export default LoginForm;
