import dayjs from 'dayjs';
import { BATCHING_BY_NUMBER_OF_PARTS, RUN_STATUSES, RUN_UNLOCKED_MAX_PRINTS } from 'rapidfab/constants';

export function isLockedRunType(run) {
  return run.batch_type === BATCHING_BY_NUMBER_OF_PARTS;
}

export function getRunFill(run) {
  if (isLockedRunType(run) && run.prints.length < RUN_UNLOCKED_MAX_PRINTS) {
    return run.prints.length * (100 / RUN_UNLOCKED_MAX_PRINTS); // 5 prints per run
  }

  return null;
}

export default function getRunName(run) {
  const { pieces_locked, name } = run;
  if (isLockedRunType(run)) {
    if (!pieces_locked) {
      const percentFill = getRunFill(run);
      if (percentFill) {
        return `${name} (🔓${percentFill}%)`;
      }
    } else {
      return `${name} 🔒`;
    }
  }
  return name;
}

export const isRunActive = run => {
  if (!run) return false;
  return ((run.status === RUN_STATUSES.IN_PROGRESS) ||
    (run.status === RUN_STATUSES.PAUSED));
};

/*
This method is needed to find the Current Scheduled Run by the earliest estimates.start date,
also find the "next" scheduled run which is the "latest run" which should have the latest
estimates.end date.
*/

export const findScheduledRunsForResource = (resource, runs, runEstimatesKeyedByRunUri) => {
  if (!resource?.uri) {
    return { earliestRun: null, latestRunDate: null };
  }

  // Get all runs for the current resource
  const resourceRuns = runs[resource.uri];

  if (resourceRuns) {
    // Find all the scheduled runs for the current resource
    const scheduledRuns = resourceRuns
      .map(run => {
        // Get run estimates (scheduled_runs API)
        const runEstimate = runEstimatesKeyedByRunUri[run.uri];

        if (runEstimate) {
          // If we have estimates, return the run, its start and end dates in day.js format
          const startDate = dayjs(runEstimate.estimates?.start);
          const endDate = dayjs(runEstimate.estimates?.end);

          return { run, startDate, endDate };
        }
        return null;
      })
      /* Filter array if we have no estimates or the startDate is invalid
         in order to skip the rest of the logic */
      .filter(scheduledRun => scheduledRun &&
        (scheduledRun.startDate.isValid() || scheduledRun.endDate.isValid()));

    /* If we have some scheduled runs, find the earliest and latest runs
     they should not be the same runs as if the resource has only 1 run. */

    if (scheduledRuns.length) {
      const earliestRun = scheduledRuns
        // .slice() - to have a new copy of the array without modifying the original array.
        .slice()
        /* Using sort to find the earliest run by .diff()
           between the start dates and getting the first element. */
        .sort((a, b) => a.startDate.diff(b.startDate))[0].run;

      // If we have only 1 run or the earliest run is the same as the latest run -> we should skip.
      const latestRun = scheduledRuns.length === 1 ? null :
        scheduledRuns.slice().sort((a, b) => b.endDate.diff(a.endDate))[0].run;

      const latestRunDate = latestRun?.uri ?
        scheduledRuns.find(scheduledRun => scheduledRun.run.uri === latestRun.uri)?.endDate :
        null;

      const endDate = latestRunDate && latestRunDate.isValid() ? latestRunDate : null;

      return { earliestRun, latestRunDate: endDate };
    }
  }

  // Return null if we have no scheduled runs for the current resource.

  return { earliestRun: null, latestRunDate: null };
};

export const getPercentageOfTheCurrentScheduledRun = (run, runEstimatesKeyedByRunUri, runActuals) => {
  if (!run) {
    // Has not started yet or no run to show at all
    return 0;
  }

  const runEstimate = runEstimatesKeyedByRunUri[run.uri];
  const actuals = runActuals[run.uri];
  const actualsStartDate = dayjs(actuals?.start_in_progress_time);

  if ((runEstimate && actualsStartDate.isValid()) &&
    isRunActive(run)) {
    const startDate = Date.parse(runEstimate.estimates?.start);
    const endDate = Date.parse(runEstimate.estimates?.end);
    const current = Date.now();
    let percentage = ((current - actualsStartDate) / (endDate - startDate)) * 100;

    if (percentage < 0) {
      percentage = 0;
    } else if (percentage > 100) {
      percentage = 100;
    } else {
      percentage = Math.round(percentage);
    }

    return percentage;
  }

  return 0;
};

export const findCurrentRunStartDate = (run, runEstimatesKeyedByRunUri) => {
  if (!run) {
    return null;
  }

  const runEstimate = runEstimatesKeyedByRunUri[run.uri];

  if (runEstimate) {
    return runEstimate.estimates?.start;
  }

  return null;
};
