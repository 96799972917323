import React from 'react';
import AuthentiseLogo from 'rapidfab/images/authentise-logo-192.png';
import {
  lineItemResourceType,
  materialTypeResourceType,
  modelerResourceType,
  orderType,
  shippingResourceType,
} from 'rapidfab/types';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedMessage } from 'rapidfab/i18n';
import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';
import { getLineItemWorkflowTypeObjectKey } from 'rapidfab/utils/lineItemUtils';
import FormattedLocalizedCost from '../../FormattedLocalizedCost';

const OrderPackingSlipPrint = ({
  order, bureauName, shipping, lineItems, materialsByUri, modelsByUri, isDanfossUser,
}) => {
  const totalValue = _get(order.estimates, 'cost.total', null);
  const notAvailableMessage = <FormattedMessage id="notAvailable" defaultMessage="N/A" />;
  const generalInfoWidths = ['20%', '30%', '20%', '30%'];
  const lineItemsWidths = isDanfossUser ? ['5%', '15%', '10%', '50%', '20%'] : ['5%', '10%', '65%', '20%'];
  return (
    <div className="order-packing-slip">
      <div className="order-packing-slip__general-info">
        <table className="w-full fs-5 mt-3">
          <colgroup>
            {generalInfoWidths.map(w => <col width={w} />)}
          </colgroup>
          <tbody>
            <tr>
              <td><b>Shipping Details:</b></td>
              <td />
              <td><b>Order Name:</b></td>
              <td><b>{order.name}</b></td>
            </tr>
            <tr>
              <td>Shipping Name:</td>
              <td>{order.shipping?.name ?? notAvailableMessage}</td>
              <td>Customer Name:</td>
              <td>{order.customer_name ?? notAvailableMessage}</td>
            </tr>
            <tr>
              <td>Shipping Address:</td>
              <td>{order.shipping?.address ?? notAvailableMessage}</td>
              <td>Order ID:</td>
              <td>{order.uuid}</td>
            </tr>
            <tr>
              <td>Shipping Method:</td>
              <td>{shipping?.name ?? notAvailableMessage}</td>
              <td>Order Date:</td>
              <td>{order.created ? <FormattedDate value={order.created} /> : notAvailableMessage}</td>
            </tr>
            <tr>
              <td>Shipping/Tracking #:</td>
              <td>{order.shipping?.tracking ?? notAvailableMessage}</td>
              <td>Due Date:</td>
              <td>{order.due_date ? <FormattedDate value={order.due_date} /> : notAvailableMessage}</td>
            </tr>
            {isDanfossUser && (
              <tr>
                <td colSpan={2} />
                <td><b>Total Order Value:</b></td>
                <td>
                  <b>{totalValue ? <FormattedLocalizedCost value={totalValue} /> : notAvailableMessage}</b>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="mt-4 mb-2 fs-2 fw-bold">
        {bureauName}
      </div>
      <div className="mb-3 fs-5">
        Order Contents and Packing Slip
      </div>
      <div className="order-packing-slip__line-items">
        {/* eslint-disable-next-line react/no-unknown-property */}
        <table className="table table-bordered w-full fs-5 mt-3" border="1">
          <colgroup>
            {lineItemsWidths.map(w => <col width={w} />)}
          </colgroup>
          <thead>
            <tr>
              <th><b>ID</b></th>
              {isDanfossUser &&
                <th><b>Per Piece Value</b></th>}
              <th><b>Quantity</b></th>
              <th><b>Line Item Details</b></th>
              <th />
            </tr>

          </thead>
          <tbody>
            {_sortBy(lineItems, 'created').map((lineItem, index) => {
              const workflowTypeKey = getLineItemWorkflowTypeObjectKey(lineItem);
              const material = materialsByUri[lineItem[workflowTypeKey]?.materials?.base];
              const image = modelsByUri[lineItem[workflowTypeKey]?.model]?.snapshot_content;
              const { estimates } = lineItem;
              return (
                <tr key={lineItem.uuid}>
                  <td className="align-top">{index + 1}</td>
                  {isDanfossUser && (
                    <td className="align-top">
                      {
                        estimates?.total_cost ?
                          <FormattedLocalizedCost value={estimates.total_cost / lineItem.quantity} /> :
                          notAvailableMessage
                      }
                    </td>
                  )}
                  <td className="align-top">{lineItem.quantity}</td>
                  <td className="align-top">
                    <div>Name: {lineItem.name}</div>
                    <div>Material: {material?.name ?? notAvailableMessage}</div>
                    <div>Line Item Notes: {lineItem.notes ?? notAvailableMessage}</div>
                  </td>
                  <td><img src={image} alt={lineItem.uuid} /></td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="sticker order-packing-slip__sticker">
        <footer>
          <p>
            Powered by <img className="logo" src={AuthentiseLogo} alt="Authentise Logo" /> Authentise
          </p>
        </footer>
      </div>
    </div>
  );
};

OrderPackingSlipPrint.propTypes = {
  order: orderType.isRequired,
  bureauName: PropTypes.string.isRequired,
  shipping: shippingResourceType.isRequired,
  lineItems: PropTypes.arrayOf(lineItemResourceType).isRequired,
  materialsByUri: PropTypes.shape({
    [PropTypes.string]: materialTypeResourceType.isRequired,
  }).isRequired,
  modelsByUri: PropTypes.shape({
    [PropTypes.string]: modelerResourceType.isRequired,
  }).isRequired,
  isDanfossUser: PropTypes.bool.isRequired,
};

export default OrderPackingSlipPrint;
