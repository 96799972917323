import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';

import { WORKFLOW_USAGE_STATES, ROUTES } from 'rapidfab/constants';
import WorkflowTabs from 'rapidfab/components/plan/WorkflowTabs';
import { getRouteURI } from 'rapidfab/utils/uriUtils';

import TableWithSearching from 'rapidfab/components/Tables/TableWithSearching';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const ProductionWorkflows = componentProps => {
  const { specimenWorkflowFeatureEnabled, data, fetching, onSort, isDebugModeEnabled } = componentProps;

  const columns = [
    {
      type: 'uuid',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      resource: 'workflow',
    },
    {
      type: 'text',
      uid: 'field.name',
      accessor: 'name',
      defaultMessage: 'Name',
      isSortable: true,
    },
    specimenWorkflowFeatureEnabled && (
      {
        type: 'workflow',
        uid: 'field.type',
        accessor: 'type',
        defaultMessage: 'Type',
        isSortable: true,
      }
    ),
    {
      type: 'bool',
      uid: 'field.archived',
      accessor: 'usage_state',
      defaultMessage: 'Archived',
      equals: WORKFLOW_USAGE_STATES.ARCHIVED,
    },
  ];

  const NavbarLinks = (
    <Button
      variant="primary"
      size="sm"
      href={getRouteURI(ROUTES.WORKFLOW_CREATE)}
      className="pull-right"
    >
      <FontAwesomeIcon icon={faPlus} />{' '}
      <FormattedMessage
        id="record.workflow.add"
        defaultMessage="Add Workflow"
      />
    </Button>
  );

  return (
    <TableWithSearching
      {...componentProps}
      breadcrumbs={['plan', 'workflows']}
      withBreadcrumbs
      navbar={NavbarLinks}
      subheader={<WorkflowTabs activeKey="production-workflows" />}
      data={data}
      columns={columns}
      isFetching={fetching}
      isFilteringEnabled={false}
      withDefaultPagination={false}
      isManualSoringEnabled
      manualSortingFunc={onSort}
      initialSortedDesc
      initialSortedColumn="updated"
      isDebugModeEnabled={isDebugModeEnabled}
    />
  );
};

ProductionWorkflows.defaultProps = {
  sort: '',
  search: '',
};

ProductionWorkflows.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onFilterUpdate: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  sort: PropTypes.string,
  search: PropTypes.string,
  recordListItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filters: PropTypes.shape({}).isRequired,
  recordListStore: PropTypes.shape({}).isRequired,
  offset: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  specimenWorkflowFeatureEnabled: PropTypes.bool.isRequired,
};

export default ProductionWorkflows;
