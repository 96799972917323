import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, Col, FormControl, FormLabel, Image, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getLineItemsForOrder, getMaterialsByUri, getModelsByUri, getWorkflowsByUri } from 'rapidfab/selectors';
import { API_RESOURCES, ROUTES } from 'rapidfab/constants';
import { LINE_ITEM_STATUS_COLOR_CODE_MAPPING } from 'rapidfab/mappings';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendar,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCube,
  faList,
  faObjectGroup,
} from '@fortawesome/free-solid-svg-icons';
import _sortBy from 'lodash/sortBy';
import _truncate from 'lodash/truncate';
import _chunk from 'lodash/chunk';
import Loading from 'rapidfab/components/Loading';
import dayjs from 'dayjs';
import { getLineItemWorkflowTypeObjectKey } from 'rapidfab/utils/lineItemUtils';
import { lineItemType, modelType, workflowType } from 'rapidfab/types';
import { FormattedDate } from 'rapidfab/i18n';

const MiniCards = ({ lineItem, workflow, material, model, isLoading }) => {
  const scrollToLineItem = (id, event) => {
    event.preventDefault();
    event.stopPropagation();
    // eslint-disable-next-line unicorn/prefer-query-selector
    const element = document.getElementById(id);
    if (!element) return;

    element.parentNode.parentNode.classList.remove('hidden');

    // Store the original border color and border width
    const originalBorderColor = window.getComputedStyle(element).getPropertyValue('border-color');
    const originalBorderWidth = window.getComputedStyle(element).getPropertyValue('border-width');

    element.scrollIntoView();

    // Change the border color to green and increases the thickness to show correct lineitem
    element.style.borderColor = LINE_ITEM_STATUS_COLOR_CODE_MAPPING.complete;
    element.style.borderWidth = 'thick';

    // Set a timeout to change the border color back to the original after 2 seconds
    setTimeout(() => {
      element.style.borderColor = originalBorderColor;
      element.style.borderWidth = originalBorderWidth;
    }, 2000); // (2 seconds)
  };

  const snapshot = model?.snapshot_content;

  return (
    isLoading ? <Loading /> : (
      <Col xl={6} lg={12} className="m-b" md={12} sm={12}>
        <div className="PrintersCardBottomViewCard">
          <Row>
            {snapshot ? (
              <Col lg={3} className="d-flex align-self-center">
                <OverlayTrigger
                  placement="top"
                  overlay={(
                    <Tooltip>
                      <Image className="mb15 rounded-circle" src={snapshot} width={175} />
                    </Tooltip>
                  )}
                >
                  <Image className="mb15 rounded-circle" src={snapshot} width={50} />
                </OverlayTrigger>
              </Col>
            ) : null}
            <Col>
              <Row className="mb-2">
                <Col xs={6} sm={6} lg={9}>
                  <OverlayTrigger
                    placement="top"
                    overlay={(
                      <Tooltip>
                        {lineItem.name}
                      </Tooltip>
                    )}
                  >

                    <span>
                      <FontAwesomeIcon icon={faCube} /> {_truncate(lineItem.name, { length: 10 })}
                    </span>

                  </OverlayTrigger>

                </Col>
                <Col xs={6} sm={6} lg={3} className="d-flex justify-content-end">
                  <OverlayTrigger
                    placement="top"
                    overlay={(
                      <Tooltip>
                        {lineItem.status}
                      </Tooltip>
                    )}
                  >
                    <FontAwesomeIcon
                      icon={faCircle}
                      style={{ color: LINE_ITEM_STATUS_COLOR_CODE_MAPPING[lineItem.status] }}
                    />
                  </OverlayTrigger>

                </Col>
              </Row>
              <Row className="mb-1">
                <Col xs={6} lg={8}>
                  {workflow && (
                    <p className="m-0">
                      <FontAwesomeIcon icon={faList} className="spacer-right" />

                      <OverlayTrigger
                        placement="top"
                        overlay={(
                          <Tooltip>
                            {workflow.name}
                          </Tooltip>
                        )}
                      >
                        <Link to={
                          getRouteURI(
                            ROUTES.WORKFLOW_EDIT,
                            { uuid: extractUuid(lineItem.workflow) },
                            {},
                            true,
                          )
                        }
                        >
                          {_truncate(workflow.name, { length: 10 })}
                        </Link>
                      </OverlayTrigger>

                    </p>
                  )}
                </Col>
                <Col xs={6} lg={4} className="d-flex justify-content-end">
                  <div className="d-flex flex-row justify-content-end">
                    <FontAwesomeIcon icon={faObjectGroup} className="spacer-right" />
                    {material.base && (

                      <OverlayTrigger

                        placement="top"
                        overlay={(
                          <Tooltip>
                            Base Material: {material.base.name}
                          </Tooltip>
                        )}
                      >
                        <div
                          className={material.support && ('spacer-right')}
                          style={{
                            width: 15,
                            height: 15,
                            backgroundColor: material.base.color,
                          }}
                        />
                      </OverlayTrigger>

                    )}
                    {material.support && (
                      <OverlayTrigger
                        placement="top"
                        overlay={(
                          <Tooltip>
                            Support Material:{ material.support.name}
                          </Tooltip>
                        )}
                      >
                        <div
                          style={{
                            width: 15,
                            height: 15,
                            backgroundColor: material.support.color,
                          }}
                        />
                      </OverlayTrigger>
                    )}
                  </div>
                </Col>
              </Row>
              <Row className="mb-1">
                <Col xs={8} lg={8}>

                  <p className="m-0">
                    <FontAwesomeIcon icon={faCalendar} className="spacer-right" />
                    <FormattedDate value={lineItem.created} />
                  </p>

                </Col>
                <Col xs={4} lg={4} className="d-flex justify-content-end">
                  <p className="m-0">
                    Qty: {lineItem.quantity}
                  </p>

                </Col>
              </Row>

            </Col>
            <Row>
              <FontAwesomeIcon
                data-cy="line-item-carousel-navigator-arrow"
                style={{ cursor: 'pointer' }}
                icon={faChevronDown}
                onClick={
                  event => scrollToLineItem(extractUuid(lineItem.uri), event)
                }
              />
            </Row>
          </Row>
        </div>
      </Col>
    )

  );
};

MiniCards.defaultProps = {
  model: null,
  workflow: null,
};

MiniCards.propTypes = {
  lineItem: lineItemType.isRequired,
  workflow: workflowType,
  material: PropTypes.shape({
    support: PropTypes.shape({
      color: PropTypes.string,
      name: PropTypes.string,
    }),
    base: PropTypes.shape({
      color: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
  model: modelType,
  isLoading: PropTypes.bool.isRequired,
};

const LineItemCarouselView = ({ order, bg }) => {
  const lineItems = useSelector(state => getLineItemsForOrder(state, order));
  const [sortedValue, setSortValue] = useState('created');
  const [filterValue, setFilterValue] = useState('');
  const [pageOffSet, setPageOffset] = useState(0);
  const workflowsByUri = useSelector(getWorkflowsByUri);
  const materialsByUri = useSelector(getMaterialsByUri);
  const modelsByUri = useSelector(getModelsByUri);
  const isLoading = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MODEL].list.fetching || state.ui.nautilus[API_RESOURCES.WORKFLOW].list.fetching,
  );

  const NUMBER_OF_CARDS_TO_DISPLAY = 4;

  const handleNextPage = direction => {
    const increment = {
      right: 1,
      left: -1,
    };
    setPageOffset(previous => previous + increment[direction]);
  };

  const getFilterRegex = filter => {
    const isSpecialChar = /[ $()*+.?[\\\]^{|}]+$/;
    const isNumeric = /^\d+$/;
    const characters = [...filter].map(char => {
      if (isSpecialChar.test(char)) {
        return `\\${char}`;
      } if (isNumeric.test(char)) {
        return `[${char}]`;
      }
      return char;
    });
    const expanded = characters.join('');
    return new RegExp(`^.*${expanded}.*$`);
  };

  const filteredLineItems = useMemo(() => {
    const normalizedSearch = filterValue.normalize().toLowerCase();
    const searchRegex = getFilterRegex(normalizedSearch);

    if (!filterValue) return lineItems;

    return lineItems.filter(lineItem => {
      const { name, created, status, notes, custom_field_values, workflow } = lineItem;
      const workflowTypeKey = getLineItemWorkflowTypeObjectKey(lineItem);
      const { layer_thickness, materials } = lineItem[workflowTypeKey] || {};

      const matchesSearchByName = searchRegex.test(name.normalize().toLowerCase());

      const matchesStatus = searchRegex.test(status.normalize().toLowerCase());

      const matchesSearchByWorkflow =
        searchRegex.test(workflowsByUri[workflow].name.normalize().toLowerCase());

      const matchesSearchByBaseMaterial =
        !!materials && searchRegex.test(materialsByUri[materials.base]?.name.normalize().toLowerCase());

      const matchesSearchBySupportMaterial =
       !!materials && searchRegex.test(materialsByUri[materials.support]?.name.normalize().toLowerCase());

      const matchesSearchByCustomFieldValues =
        custom_field_values.some(({ value }) => searchRegex.test(value.normalize().toLowerCase()));

      const matchesSearchByNotes = !!notes && searchRegex.test(notes.normalize().toLowerCase());

      const matchesSearchByLayerThickness = !!layer_thickness && searchRegex.test(
        String(layer_thickness).normalize().toLowerCase(),
      );

      // // Format the created date as 'YYYY-MM' and match against the search term
      const matchesSearchByCreatedMonth = dayjs(created.normalize()).format('MM-YYYY').includes(normalizedSearch);

      // // Format the created date as 'YYYY-MM-DD' and match against the search term
      const matchesSearchByCreatedDate = dayjs(created.normalize()).format('MM-DD-YYYY').includes(normalizedSearch);

      const returnLineItem = matchesSearchByName
          || matchesSearchByLayerThickness
          || matchesSearchByCreatedMonth
          || matchesSearchByCreatedDate
          || matchesStatus
          || matchesSearchByNotes
          || matchesSearchByCustomFieldValues
          || matchesSearchByBaseMaterial
          || matchesSearchBySupportMaterial
          || matchesSearchByWorkflow;

      setPageOffset(0);
      return returnLineItem;
    });
  }, [filterValue, JSON.stringify(lineItems)]);

  const sortedLineItems = useMemo(() => {
    const sortedItems = _sortBy(filteredLineItems, [sortedValue]);
    return _chunk(sortedItems, NUMBER_OF_CARDS_TO_DISPLAY);
  }, [sortedValue, filteredLineItems]);

  if (!lineItems.length) return null;

  return (
    <Card bg={bg} data-cy="line-item-carousel">
      <Card.Header className="pd-exp inverse">
        Line Item Quick View
      </Card.Header>
      <div className="card-body-wrapper">
        <Card.Body>
          <Row className="m-b">
            <Col lg={8} className="m-b">
              <FormLabel>Search</FormLabel>
              <FormControl
                as="input"
                onChange={
                  ({ target }) => setFilterValue(target.value)
                }
              />
            </Col>
            <Col>
              <FormLabel>Sort By</FormLabel>
              <FormControl
                as="select"
                onChange={event => setSortValue(event.target.value)}
              >
                <option value="created">Created (Oldest to Newest)</option>
                <option value="name">Name</option>
                <option value="priority">Priority (Highest to Lowest)</option>
              </FormControl>
            </Col>
          </Row>
          <Row className="m-b">
            {
              pageOffSet > 0 ? (
                <Col lg={1} md={1} sm={1} xs={1} className="d-flex align-self-center" onClick={() => handleNextPage('left')}>
                  <FontAwesomeIcon
                    role="button"
                    icon={faChevronLeft}
                  />
                </Col>
              ) : (
                <Col lg={1} md={1} sm={1} xs={1} className="d-flex align-self-center justify-content-end" />
              )
            }
            <Col>
              <Row>
                {
                  sortedLineItems[pageOffSet]?.map(lineItem => {
                    const workflowTypeKey = getLineItemWorkflowTypeObjectKey(lineItem);
                    return (
                      <MiniCards
                        key={lineItem.uri}
                        lineItem={lineItem}
                        model={modelsByUri[lineItem[workflowTypeKey]?.model]}
                        workflow={workflowsByUri[lineItem.workflow]}
                        material={{
                          base: materialsByUri[lineItem[workflowTypeKey]?.materials.base],
                          support: materialsByUri[lineItem[workflowTypeKey]?.materials.support],
                        }}
                        isLoading={isLoading}
                      />
                    );
                  })
                }
              </Row>
            </Col>
            {
              pageOffSet < Math.floor(sortedLineItems?.length) - 1 ? (
                <Col lg={1} md={1} sm={1} xs={1} className="d-flex align-self-center justify-content-end" onClick={() => handleNextPage('right')}>
                  <FontAwesomeIcon
                    role="button"
                    icon={faChevronRight}
                  />
                </Col>
              ) : (
                <Col lg={1} md={1} sm={1} xs={1} className="d-flex align-self-center justify-content-end" />

              )
            }
          </Row>

        </Card.Body>
      </div>
    </Card>
  );
};

LineItemCarouselView.defaultProps = {
  bg: 'dark',
};

LineItemCarouselView.propTypes = {
  bg: PropTypes.string,
  order: PropTypes.shape({}).isRequired,
};

export default LineItemCarouselView;
