import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _truncate from 'lodash/truncate';
import PropTypes from 'prop-types';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const TruncatedTitle = ({
  title,
  titleClassName,
  icon,
  iconClassName,
  inline,
  maxTextLength,
  tooltipPlacement = 'top',
  style,
}) => {
  if (!title) return null;

  return (
    <p className={`mb0 ${inline ? 'd-inline-block' : ''}`} style={style}>
      {icon && <FontAwesomeIcon className={`spacer-right ${iconClassName}`} icon={icon} />}
      {title.length >= maxTextLength ? (
        <OverlayTrigger placement={tooltipPlacement} overlay={<Tooltip>{title}</Tooltip>}>
          <span className={titleClassName}>
            {_truncate(title, { length: maxTextLength })}
          </span>
        </OverlayTrigger>
      ) : (
        <span className={titleClassName}>
          {title}
        </span>
      )}
    </p>
  );
};

TruncatedTitle.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.shape({}),
  iconClassName: PropTypes.string,
  maxTextLength: PropTypes.number.isRequired,
  tooltipPlacement: PropTypes.string,
  titleClassName: PropTypes.string,
  inline: PropTypes.bool,
  style: PropTypes.shape({}),
};

TruncatedTitle.defaultProps = {
  title: PropTypes.string,
  icon: null,
  titleClassName: '',
  iconClassName: '',
  tooltipPlacement: 'top',
  inline: false,
  style: {},
};

export default TruncatedTitle;
