import { isFeatureEnabled } from 'rapidfab/selectors';
import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import Actions from 'rapidfab/actions';
import { API_RESOURCES, FEATURES, MATERIAL_UNITS } from 'rapidfab/constants';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import Alert from 'rapidfab/utils/alert';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RUN_ACTUALS_MODAL } from 'rapidfab/constants/forms';
import EditRunActualsModal from '../../modals/EditRunActualsModal';
import { convertMassToOtherUnit } from '../../../utils/mathUtils';

const Layout = ({ Table }) => <Table />;
Layout.propTypes = { Table: PropTypes.func.isRequired };

const EditRunActualsModalContainer = ({ handleSubmit, ...props }) => {
  const isPowderWorkflowFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.POWDER_WORKFLOW));
  const dispatch = useDispatch();

  const startDate = dayjs(props.actuals.start)
    .format('YYYY-MM-DD');
  const startTime = dayjs(props.actuals.start)
    .format('HH:mm:ss');
  const endDate = dayjs(props.actuals.end)
    .format('YYYY-MM-DD');
  const endTime = dayjs(props.actuals.end)
    .format('HH:mm:ss');
  const initialValues = { startDate, startTime, endDate, endTime, materialWeight: props.materialWeight };

  const initialFormValues = {};
  Object
    .keys(initialValues)
    .filter(key => RUN_ACTUALS_MODAL.FIELDS.includes(key))
    .forEach(key => {
      initialFormValues[key] = initialValues[key];
    });

  const handleRunActualsSubmit = async payload => {
    try {
      const actualsPayload = { ...payload };
      delete actualsPayload.materialWeight;
      const promises = [
        dispatch(Actions.Api.nautilus[API_RESOURCES.RUN_ACTUALS].put(
          extractUuid(props.actuals.uri),
          actualsPayload,
        )),
      ];

      if (payload.materialWeight && props.materialWeight !== payload.materialWeight) {
        const { units, density } = props;
        const baseMaterialUsed = convertMassToOtherUnit(
          +payload.materialWeight, units, MATERIAL_UNITS.GRAM,
        ) / density;

        const runMaterialPayload = { actual_base_material_used: baseMaterialUsed };
        promises.push(dispatch(Actions.Api.nautilus[API_RESOURCES.RUN_MATERIAL].put(
          extractUuid(props.runUri),
          runMaterialPayload,
        )));
      }

      const [runActualsSubmitResponse] = await Promise.all(promises);
      if (runActualsSubmitResponse.payload) {
        dispatch(Actions.Api.nautilus[API_RESOURCES.RUN_ACTUALS].list(
          null,
          { limit: 5000 },
        ));
        if (payload.materialWeight) {
          dispatch(Actions.Api.nautilus[API_RESOURCES.RUN_MATERIAL].get(
            extractUuid(props.runUri),
            true,
          ));
        }
        Alert.success(
          <FormattedMessage
            id="toaster.run.actualsUpdatedSuccessfully"
            defaultMessage="Runs Actuals updated successfully"
          />,
        );
        props.onHide();
      } else {
        Alert.success(<FormattedMessage
          id="toaster.run.actualsUpdatedUnsuccessfully"
          defaultMessage="Runs Actuals updated unsuccessfully"
        />);
      }
    } catch {
      return null;
    }
    return null;
  };

  const onSubmit = async values => {
    const { startDate, startTime, endDate, endTime, materialWeight } = values;
    const startInProgressTime = dayjs(`${startDate} ${startTime}`).toISOString();
    const endInProgressTime = dayjs(`${endDate} ${endTime}`).toISOString();

    const payload = {
      start_in_progress_time: startInProgressTime,
      end_in_progress_time: endInProgressTime,
      materialWeight,
    };
    // add api request(s) and update actual data on run page (maybe redux state)
    handleRunActualsSubmit(payload);
  };

  return (
    <EditRunActualsModal
      {...props}
      initialValues={initialFormValues}
      onSubmit={onSubmit}
      isPowderWorkflow={isPowderWorkflowFeatureEnabled}
    />
  );
};

EditRunActualsModalContainer.defaultProps = {
  loading: false,
  show: false,
  onHide: () => true,
  actuals: {
    end: null,
    start: null,
    uri: null,
  },
  materialWeight: null,
  units: null,
  density: null,
  runUri: null,
};

EditRunActualsModalContainer.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  actuals: PropTypes.shape({
    end: PropTypes.string,
    start: PropTypes.string,
    uri: PropTypes.string,
  }),
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  materialWeight: PropTypes.oneOfType([PropTypes.number, null]),
  units: PropTypes.string,
  density: PropTypes.number,
  runUri: PropTypes.string,
};

export default EditRunActualsModalContainer;
