import BureauBarcodeOutput from 'rapidfab/components/BureauBarcodeOutput';
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import QRSticker from 'rapidfab/components/qr/_wrapper';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { ROUTES } from 'rapidfab/constants';

const Printer = ({ printer, printerType, location, materials }) => (
  <QRSticker>
    <div>Printer Type: {printerType.name || 'N/A'}</div>
    <div>Printer Name: {printer.name}</div>

    <BureauBarcodeOutput url={printer.uri} />

    <div>Location: {location.name || 'N/A'}</div>
    <div>
      Material Types:{' '}
      {materials.length ? (
        materials.length > 4 ? (
          <>
            <ul className="non-printable">
              {materials.slice(0, 4).map(material => (
                <li key={material.uri}>{material.name}</li>
              ))}
              {materials.length > 5 && (
                <li>
                  Plus {materials.length - 4} more.{' '}
                  <Link to={getRouteURI(ROUTES.PRINTER_TYPES, null, { uuid: extractUuid(printerType.uri) }, true)}>
                    View all here
                  </Link>
                </li>
              )}
            </ul>
            <div className="only-printable">
              {materials.length}
              <br />
              For a full list of material types please navigate to the printer
              type page in Flows
            </div>
          </>
        ) : (
          <div>
            {materials.map(material => (
              <p key={material.uri}>{material.name}</p>
            ))}
          </div>
        )
      ) : (
        'N/A'
      )}
    </div>
  </QRSticker>
);

Printer.propTypes = {
  printer: PropTypes.shape({
    name: PropTypes.string.isRequired,
    uri: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  printerType: PropTypes.shape({
    name: PropTypes.string,
    uri: PropTypes.string,
  }).isRequired,
  materials: PropTypes.arrayOf({
    name: PropTypes.string,
  }).isRequired,
};

export default Printer;
