import { OrderQuoteContextProvider } from 'rapidfab/context/OrderQuoteContext';
import React, { useEffect, memo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import { ROUTES, PAGINATION_IGNORE_DEFAULT_LIMIT, API_RESOURCES, FEATURES } from 'rapidfab/constants';

import EditOrder from 'rapidfab/components/records/order/edit/EditOrder';
import Loading from 'rapidfab/components/Loading';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import _map from 'lodash/map';
import { loadLineItemQuotes } from 'rapidfab/dispatchers/lineItem';
import { useNavigate } from 'react-router-dom';
import {
  getFeatures,
} from 'rapidfab/selectors';
import { isFeatureEnabled } from 'rapidfab/utils/featureFlagUtils';
import BulkLineItemActionContextProvider, { BulkLineItemActionContext } from 'rapidfab/context/BulkLineItemActionContext';
import LineItemBulkActionModal from 'rapidfab/components/modals/LineItemBulkActionModal';
import { useModal } from 'rapidfab/hooks';
import OrderPackingSlipPrintContainer from './OrderPackingSlipPrintContainer';

const OrderContainer = () => {
  const routeUUID = useSelector(Selectors.getRouteUUID);
  const bureau = useSelector(Selectors.getBureauUri);
  const isRestrictedUser = useSelector(Selectors.isCurrentUserRestricted);
  const order = useSelector(state => Selectors.getUUIDResource(state, routeUUID));
  const features = useSelector(getFeatures);
  const isNetFabbEnabled = isFeatureEnabled(features, FEATURES.NETFABB);

  const isGuidelineEngineFeatureEnabled = useSelector(state => Selectors.isFeatureEnabled(
    state,
    FEATURES.GUIDELINES_ENGINE,
  ));

  const isOrderQuoteFeatureEnabled = useSelector(state => Selectors.isFeatureEnabled(state, FEATURES.ORDER_QUOTE));
  const isLineItemWorkStepQuoteDetailsFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.LINE_ITEM_WORK_STEP_QUOTE_DETAILS));

  const lineItems = useSelector(state => Selectors.getLineItemsForOrder(state, order));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [expandMode, setExpandMode] = useState(null);
  const [openLineItemBulkActionModal, WrappedLineItemBulkActionModal] = useModal(LineItemBulkActionModal);

  const onInitialize = () => {
    // Fetch order and related resources
    if (isNetFabbEnabled) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].get(routeUUID));
      // Dispatch is being disabled due to OAuth Refresh Failed error until we fix this feature in the future.
      // Ticket https://app.shortcut.com/authentise/story/63727/fix-oauth-refresh-failed-pop-up for reference
      // .then(() => {
      //   dispatch(Actions.Api.nautilus[API_RESOURCES.OAUTH_TOKEN_AUTODESK_FORGE].get(''));
      // });
    } else {
      dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].get(routeUUID));
    }

    // Fetch resource options for input selections
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].list({ bureau }));
    dispatch(Actions.Api.nautilus[API_RESOURCES.SERVICE_PROVIDER].list({}, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER_TYPE].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.INFILL_STRATEGY].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.SUPPORT_STRATEGY].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.SHIPPING].list());

    if (isGuidelineEngineFeatureEnabled) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.PROCESS_STEP].list());
    }
  };

  useEffect(() => onInitialize(routeUUID), [routeUUID]);

  useEffect(() => {
    if (order?.quote_required && lineItems?.length) {
      // Load all Line Item Quote details when `Order Quote Required` checkbox was set
      loadLineItemQuotes(dispatch, _map(lineItems, 'uri'));
    }
  }, [order?.quote_required, JSON.stringify(_map(lineItems, 'uri'))]);
  const loading = !order || !routeUUID;

  // For restricted user it's another endpoint and another form
  if (!loading && isRestrictedUser) {
    navigate(getRouteURI(ROUTES.REVIEW_RESTRICTED_ORDER, { uuid: routeUUID }, {}, true));
  }
  if (loading) return <Loading />;
  return (
    <OrderQuoteContextProvider
      orderQuoteFFEnabled={isOrderQuoteFeatureEnabled}
      workstepQuoteDetailsFFEnabled={isLineItemWorkStepQuoteDetailsFeatureEnabled}
    >
      <BulkLineItemActionContextProvider>
        <BulkLineItemActionContext.Consumer>
          {({ bulkActionMarkedLineItems }) => (
            <>
              <WrappedLineItemBulkActionModal
                expandModeState={[expandMode, setExpandMode]}
                bulkActionMarkedLineItems={bulkActionMarkedLineItems}
                id="line-item-bulk-action-modal"
              />
              <div>
                <OrderPackingSlipPrintContainer orderUuid={order.uuid} />
                <div className="hide-on-print">
                  <EditOrder
                    expandModeState={[expandMode, setExpandMode]}
                    handleLineItemBulkActionModal={openLineItemBulkActionModal}
                    orderQuoteFFEnabled={isOrderQuoteFeatureEnabled}
                    workstepQuoteDetailsFFEnabled={isLineItemWorkStepQuoteDetailsFeatureEnabled}
                  />
                </div>
              </div>
            </>
          )}
        </BulkLineItemActionContext.Consumer>
      </BulkLineItemActionContextProvider>
    </OrderQuoteContextProvider>
  );
};

export default memo(OrderContainer);
