import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { FEATURES } from 'rapidfab/constants';
import { FormattedDate, FormattedLength, FormattedMessage } from 'rapidfab/i18n';
import { Draggable } from 'react-drag-and-drop';
import Feature from 'rapidfab/components/Feature';
import PriorityLabel from 'rapidfab/components/records/run/PriorityLabel';
import ModalThreeScene from 'rapidfab/components/ModalThreeScene';
import { lineItemResourceType } from 'rapidfab/types';
import { getLineItemWorkflowTypeObjectKey } from 'rapidfab/utils/lineItemUtils';
import { ExportControlWarningIcon } from 'rapidfab/components/ExportControl';

export default class RunLineItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event, lineItem) {
    const { setFirstItem, onShiftToggle } = this.props;
    if (event.shiftKey) {
      onShiftToggle(lineItem.pieces);
    } else {
      setFirstItem(lineItem.pieces);
    }
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { lineItem, selected, pieces, labelRelationshipsForLineItemPieces } = this.props;
    const workflowTypeKey = getLineItemWorkflowTypeObjectKey(lineItem);
    const { model, materials } = lineItem[workflowTypeKey];
    return (
      <Draggable type="buildplatelineitem" data={lineItem.uri}>
        <tr
          onClick={event => this.handleClick(event, lineItem)}
          className={selected ? 'selected-row ' : null}
        >
          <td className="hidden">{lineItem.uuid}</td>
          <td className="render-column">
            <ModalThreeScene
              snapshot={model.snapshot_content}
              model={model.content}
              unit={model.user_unit}
              fileUnit={model.file_unit}
              size={model.size}
              volume={model.volume_mm}
              showMfgOrientationPanel={false}
            />
            <span className="printItemCount">x{pieces.length}</span>
          </td>
          <td>
            <a href={`#/records/order/${extractUuid(lineItem.order)}`}>
              {lineItem.order_name}{' '}{`(${lineItem.name})`}{' '}
            </a>
            {labelRelationshipsForLineItemPieces.length > 0 && (
              <ExportControlWarningIcon
                isLineItem
                exportControlLabel={labelRelationshipsForLineItemPieces[0].label}
              />
            )}
          </td>
          <td>
            <FormattedLength
              length={model.size.x}
              roundPrecision={0}
              lengthUnits={model.file_unit}
            />{' × '}
            <FormattedLength
              length={model.size.y}
              roundPrecision={0}
              lengthUnits={model.file_unit}
            />{' × '}
            <FormattedLength
              length={model.size.z}
              roundPrecision={0}
              lengthUnits={model.file_unit}
            />
          </td>
          <td>
            <a
              href={`#/inventory/materials?uuid=${materials.base.uuid}`}
            >
              {materials.base.name}
            </a>
          </td>
          <td>
            {lineItem[workflowTypeKey].layer_thickness}
          </td>
          <td>
            {lineItem.order_due_date ? (
              <FormattedDate value={lineItem.order_due_date} />
            ) : (
              <FormattedMessage id="notAvailable" defaultMessage="N/A" />
            )}
          </td>
          <Feature featureName={FEATURES.PRIORITY}>
            <td>
              <PriorityLabel value={lineItem.priority} />
            </td>
          </Feature>
        </tr>
      </Draggable>
    );
  }
}

RunLineItem.propTypes = {
  lineItem: lineItemResourceType.isRequired,
  pieces: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selected: PropTypes.bool.isRequired,
  onShiftToggle: PropTypes.func.isRequired,
  setFirstItem: PropTypes.func.isRequired,
  labelRelationshipsForLineItemPieces: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.shape({}),
  })).isRequired,
};
