import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import {
  API_RESOURCES,
  COMMENT_RELATED_TABLE_NAMES,
  FEATURES,
  PAGINATION_IGNORE_DEFAULT_LIMIT,
  USER_ROLES,
} from 'rapidfab/constants';

import Comments from 'rapidfab/components/comments/Comments';
import { uniq } from 'lodash/array';
import { getCurrentUserRole, getLabels } from 'rapidfab/selectors';
import { useLocation } from 'react-router-dom';
import _compact from 'lodash/compact';
import _map from 'lodash/map';

const CommentsContainer = ({ resourceUUID, resourceTableName, bgInverse, modelLibrary, resourceType }) => {
  const location = useLocation();
  const resource = useSelector(state =>
    Selectors.getUUIDResource(state, resourceUUID));
  const comments = useSelector(state =>
    Selectors.getCommentsForResource(state, resource));
  const usersByUsername = useSelector(Selectors.getUsersByUsername);
  const usersByUri = useSelector(Selectors.getUsersByUri);
  const isLoading = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.COMMENT].list.fetching ||
    state.ui.nautilus[API_RESOURCES.USERS].list.fetching ||
    state.ui.nautilus[API_RESOURCES.THREADS_MESSAGES].list.fetching);
  const labels = useSelector(getLabels);
  const isSubmitting = useSelector(state => state.ui.nautilus[API_RESOURCES.COMMENT].post.fetching
    || state.ui.nautilus[API_RESOURCES.THREADS_MESSAGES].list.fetching,
  );
  const usersFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.USERS].list.fetching);
  const isThreadsCommentsInFlowsFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.THREADS_COMMENTS_IN_FLOWS));

  const threadsMessages = useSelector(state =>
    Selectors.getThreadsMessagesForResource(state, resourceUUID));

  const [usersFetched, setUsersFetched] = useState(false);

  const currentUserRole = useSelector(getCurrentUserRole);
  const isManager = currentUserRole === USER_ROLES.MANAGER;
  const isModelLibraryRoute = location.pathname.includes(API_RESOURCES.MODEL_LIBRARY);

  const dispatch = useDispatch();

  useEffect(() => {
    const missingAuthors = threadsMessages
      .map(message => message.user_uri)
      .filter(uri => usersByUri[uri] === undefined);

    if (!missingAuthors.length) return;
    dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list({
      uri: missingAuthors,
    }));
  }, [threadsMessages.length]);

  // eslint-disable-next-line no-shadow
  const listComments = currentResourceUUID => Actions.Api.nautilus[API_RESOURCES.COMMENT].list({
    related_table_name: resourceTableName,
    related_uuid: currentResourceUUID,
  }, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT });

  // eslint-disable-next-line no-shadow
  const onInitialize = currentResourceUUID => {
    // Initialise threads comments.
    if (isThreadsCommentsInFlowsFeatureEnabled) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.THREADS_MESSAGES].list({
        record_uuid: currentResourceUUID,
      }));
    } else {
      // Otherwise, initialise regular comments.
      dispatch(listComments(currentResourceUUID)).then(response => {
        const comments = response?.json?.resources || [];
        const usersByComments = uniq(_compact(_map(comments, 'user')));
        const usersByAssignedComment = uniq(comments.map(({ comment_action }) => (comment_action.assignee_user)));
        if (usersByComments.length) {
          dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list({
            uri: [...usersByComments, ...usersByAssignedComment],
          }));
        }
      });
    }
  };

  const extractLabelIds = comment => {
    /* The regex should match the following patterns of the text Label output:
           * [6cd42d](#Test-Label) <- with the spaces between words replaced with "-"
           * [43dwd7](#testlabel) <- no spaces between words */

    const labelRegex = /\[([\w-]+)]\(#.*?\)/g;
    const matchArray = [...comment.matchAll(labelRegex)];
    return [...new Set(matchArray.map(matchString => matchString[1]))];
  };

  const handleCreateNewLabel = labelName => {
    const payload = {
      color: '#e6e337', // default new label color for Label in Comments
      name: labelName,
    };

    return dispatch(Actions.Api.nautilus[API_RESOURCES.LABEL].post(payload));
  };

  const getNewLabelsUris = async labelNames => Promise.all(
    labelNames.map(labelName =>
      handleCreateNewLabel(labelName).then(response => response?.headers?.location),
    ),
  );

  const getFilteredLabels = (labels, labelsList, modelLibrary) => labels
    .filter(label => labelsList.includes(label.id) && !modelLibrary.labels.includes(label.uri))
    .map(label => label.uri);

  const checkIfLabelsAlreadyExists = (labels, labelsCommentsList) => {
    // Extract the name field from each label
    const labelIds = new Set(labels.map(label => label.id));

    // Return the items from labelsCommentsList that do not exist in labelNames
    return labelsCommentsList.filter(labelComment => !labelIds.has(labelComment));
  };

  const filterLabelIds = (labelListIds, labels, modelLibrary) => {
    if (!labelListIds.length) {
      return [];
    }
    /* If we have labelListIds -> [6cd42d, 43dwd7] then we can filter the labels to:
       * Find the relevant ones by ID
       * Make sure such labels do not exist in the Model Library already
       -> Otherwise - we will return [] */

    return getFilteredLabels(labels, labelListIds, modelLibrary);
  };

  const dispatchLabelsToModelLibrary = (labelsToSend, modelLibrary, resourceUUID) => {
    if (!labelsToSend.length) {
      return null;
    }

    // If we need to push new label to the Model Library on Comment Save:
    const payload = {
      labels: modelLibrary.labels ? [...modelLibrary.labels, ...labelsToSend] : labelsToSend,
    };

    return dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL_LIBRARY].put(resourceUUID, payload));
  };

  const handlePushLabelsToModelLibrary = async comment => {
    if (!isModelLibraryRoute && !modelLibrary) {
      return null;
    }

    const labelListIds = extractLabelIds(comment);
    const labelsToSend = filterLabelIds(labelListIds, labels, modelLibrary);

    const labelsToBeCreated = checkIfLabelsAlreadyExists(labels, labelListIds);

    if (labelListIds.length && labelsToBeCreated.length) {
      // Such label(s) do(es) not exist, and we need to create a new one

      const newLabelsUris = await getNewLabelsUris(labelsToBeCreated);
      return dispatchLabelsToModelLibrary([...labelsToSend, ...newLabelsUris], modelLibrary, resourceUUID);
    }
    return dispatchLabelsToModelLibrary(labelsToSend, modelLibrary, resourceUUID);
  };

  const fetchAllUsers = React.useCallback(() => {
    if (!usersFetched && !usersFetching) {
      return dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list(
        (isManager ? { archived: false } : {}), { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
      ))
        .then(response => {
          setUsersFetched(true);
          return response?.json?.resources;
        });
    }
    return usersByUsername;
  }, [usersByUsername, usersFetched, usersFetching]);

  const onSubmit = async payload => {
    await dispatch(Actions.Api.nautilus[API_RESOURCES.COMMENT].post({
      related_table_name: resourceTableName,
      related_uuid: resourceUUID,
      ...payload,
    }));
    dispatch(listComments(resourceUUID));
  };

  useEffect(() => {
    onInitialize(resourceUUID);
  }, []);

  const selected = {
    threadsMessages,
    resourceTableName,
    resourceUUID,
    isThreadsCommentsInFlowsFeatureEnabled,
  };

  return (
    <Comments
      {...selected}
      bgInverse={bgInverse}
      isLoading={isLoading}
      isSubmitting={isSubmitting}
      comments={comments}
      usersByUsername={usersByUsername}
      onSubmit={payload => onSubmit(payload)}
      fetchAllUsers={fetchAllUsers}
      labels={labels}
      isModelLibraryRoute={isModelLibraryRoute}
      handlePushLabelsToModelLibrary={handlePushLabelsToModelLibrary}
      resourceType={resourceType}
    />
  );
};

CommentsContainer.defaultProps = {
  modelLibrary: {},
};

CommentsContainer.propTypes = {
  bgInverse: PropTypes.bool.isRequired,
  resourceUUID: PropTypes.string.isRequired,
  resourceType: PropTypes.string.isRequired,
  usersByUsername: PropTypes.shape({}).isRequired,
  // Eslint does not see usage of props inside of `mapDispatchToProps`
  // eslint-disable-next-line react/no-unused-prop-types
  resourceTableName: PropTypes.oneOf(
    Object.values(COMMENT_RELATED_TABLE_NAMES),
  ).isRequired,
  modelLibrary: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default CommentsContainer;
