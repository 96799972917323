import PropTypes from 'prop-types';
import Loading from 'rapidfab/components/Loading';
import LocationModal from 'rapidfab/components/modals/LocationModal';
import React, { useCallback, useEffect, useState } from 'react';
import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import {
  API_RESOURCES,
  FEATURES,
  LOCATION_NOTIFICATION_SETTING_BOOLS, LOCATION_TABS,
  ROUTES,
} from 'rapidfab/constants';
import userSort from 'rapidfab/utils/userSort';
import countries from 'i18n-iso-countries';
import countriesEn from 'i18n-iso-countries/langs/en.json';
import _pick from 'lodash/pick';
import _isArray from 'lodash/isArray';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Alert from 'rapidfab/utils/alert-new';

import { LOCATION_CONTAINER } from 'rapidfab/constants/forms';
import { FormattedMessage } from 'react-intl';
import { isFeatureEnabled } from 'rapidfab/selectors';

const locationSettingsPutFields = [
  ...Object.values(LOCATION_NOTIFICATION_SETTING_BOOLS),
  'uri',
];

countries.registerLocale(countriesEn);

function getCountryList() {
  const allCountries = Object.keys(countries.getAlpha3Codes()).map(code => ({
    code,
    name: countries.getName(code, 'en'),
  }));

  allCountries.sort((a, b) => a.name.localeCompare(b.name));

  return allCountries;
}

const resourcesToFetch = [
  {
    resource: API_RESOURCES.MATERIAL_LOT,
  },
  {
    resource: API_RESOURCES.MATERIAL_BATCH,
  },
];

export const allLocationSettingsEnabled = (settings, keys) => {
  if (!settings) return false;
  return keys.every(key => settings[key] === true);
};

const LocationModalContainer = props => {
  const [updatedLocationSettings, setUpdatedLocationSettings] = useState({});
  const [searchParams] = useSearchParams();
  const uuid = searchParams.get('uuid');
  const location = useSelector(state => Selectors.getUUIDResource(state, uuid));
  const subLocations = useSelector(state => Selectors.getSubLocationsForLocation(state, location?.uri));
  const subLocationsByUri = useSelector(Selectors.getSubLocationsByUri);
  const users = useSelector(state => Selectors.getUsers(state).sort(userSort));
  const locationSettings = useSelector(state => Selectors.getLocationSettingsForLocation(state, location));
  const sensorDataForSensorResources = useSelector(Selectors.getSensorDataForSensorResources);
  const bureau = useSelector(Selectors.getBureau);
  const fetching = useSelector(state => state.ui.nautilus[API_RESOURCES.LOCATION].list.fetching);

  const locationSettingsInitial = Object.fromEntries(
    Object.values(LOCATION_NOTIFICATION_SETTING_BOOLS).map(key => [key, false]),
  );

  const locationSettingsFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.LOCATION_SETTINGS].list.fetching,
  );
  const deleting = useSelector(state => state.ui.nautilus[API_RESOURCES.LOCATION].delete.fetching);
  const usersFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.USERS].list.fetching);
  const isSubmitting = useSelector(state => state.ui.nautilus[API_RESOURCES.LOCATION].post.fetching
    || state.ui.nautilus[API_RESOURCES.LOCATION].put.fetching
    || state.ui.nautilus[API_RESOURCES.LOCATION].delete.fetching
    || state.ui.nautilus[API_RESOURCES.LOCATION_SETTINGS].put.fetching);
  const subLocationsFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.SUB_LOCATION].list.fetching);
  const materialResourcesFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MATERIAL_BATCH].list.fetching ||
    state.ui.nautilus[API_RESOURCES.MATERIAL_LOT].list.fetching);
  const subLocationsSubmitting = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.SUB_LOCATION].post.fetching ||
    state.ui.nautilus[API_RESOURCES.SUB_LOCATION].put.fetching);
  const subLocationDeleting = useSelector(state => state.ui.nautilus[API_RESOURCES.SUB_LOCATION].delete.fetching);
  const isMaterialManagementFeatureEnabled = useSelector(state =>
    isFeatureEnabled(state, FEATURES.MATERIAL_MANAGEMENT));
  const isSensorFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.SENSORS));
  const navigate = useNavigate();

  const initialValues = {
    ...location,
  };

  const initialFormValues = {};
  Object
    .keys(initialValues)
    .filter(key => LOCATION_CONTAINER.FIELDS.includes(key))
    .forEach(key => {
      initialFormValues[key] = location[key];
    });

  const [allEmailNotificationsActive, setAllEmailNotificationsActive] = useState(
    location ? allLocationSettingsEnabled(locationSettings, Object.values(LOCATION_NOTIFICATION_SETTING_BOOLS)) : false,
  );

  const [activeTab, setActiveTab] = useState(LOCATION_TABS.DETAILS);
  const [resourcesCountState, setResourcesCountState] = useState({});
  const [addSubLocationModal, setAddSubLocationModal] = useState(false);

  const dispatch = useDispatch();

  const toggleAllLocationSettings = () => {
    const shouldActivate = !allEmailNotificationsActive;
    const newLocationSettingsValues = Object.fromEntries(
      Object.keys(updatedLocationSettings).map(key => [key, shouldActivate]),
    );
    setUpdatedLocationSettings(newLocationSettingsValues);
    setAllEmailNotificationsActive(shouldActivate);
  };

  const handleEmailNotificationsModeSetting = () => {
    setAllEmailNotificationsActive(previous => !previous);
    toggleAllLocationSettings();
  };

  const selected = {
    uuid,
    locationUri: location?.uri,
    initialFormValues,
    users,
    bureauGroup: bureau?.group,
    bureauUri: bureau?.uri,
    countries: getCountryList(),
    locationSettings,
    isSubmitting,
    subLocations,
    subLocationsFetching,
    subLocationsByUri,
    materialResourcesFetching,
    subLocationsSubmitting,
    subLocationDeleting,
    isMaterialManagementFeatureEnabled,
    isSensorFeatureEnabled,
    sensorDataForSensorResources,
    fetching,
    deleting,
    allEmailNotificationsActive,
    usersFetching,
    locationSettingsFetching,
    activeTab,
    resourcesCountState,
  };

  const redirectToNewLocation = uri => {
    navigate(getRouteURI(ROUTES.LOCATIONS,
      null,
      { uuid: extractUuid(uri) },
      true), { replace: true });
  };

  const handleInitializeSensorData = async subLocationUris => {
    if (!subLocationUris.length) {
      return;
    }

    const sensorResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.SENSOR]
      .list({ sub_location: subLocationUris }));

    const sensorUris = _map(sensorResponse.json?.resources, 'uri');

    if (!sensorUris.length) {
      return;
    }

    await dispatch(Actions.Api.nautilus[API_RESOURCES.SENSOR_DATA].list({
      sensor: sensorUris,
    }));
  };

  const onInitialize = (currentUuid, bureauGroup) => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].clear('list'));
    if (!users?.length) {
      dispatch(
        Actions.Api.nautilus[API_RESOURCES.USERS].list({
          group: bureauGroup,
        }),
      );
    }
    if (currentUuid) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].get(currentUuid))
        .then(locationResponse => {
          const { uri } = locationResponse.json;
          dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION_SETTINGS].list({ location: uri }));
          dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].list({ location: uri }))
            .then(subLocationResponse => {
              const { resources: subLocationResources } = subLocationResponse?.json || [];
              const subLocationUris = _map(subLocationResources, 'uri');

              if (isSensorFeatureEnabled) {
                handleInitializeSensorData(subLocationUris);
              }
            });
        });
    }
  };
  const onSave = async (locationPayload, locationSettingsPayload) => {
    const modifiedLocationPayload = locationPayload;

    // The API requires an array, but we only have a single selection for now.
    // This isn't great, but it's better than other options until we can
    // integrate a proper multi-select input for this.
    if (!_isArray(modifiedLocationPayload.countries_served)) {
      modifiedLocationPayload.countries_served = [modifiedLocationPayload.countries_served];
    }

    Object.keys(modifiedLocationPayload).forEach(key => {
      if (modifiedLocationPayload[key] == null) {
        delete modifiedLocationPayload[key];
      }
    });

    let locationSettingsUuid = locationSettingsPayload.uuid;

    if (modifiedLocationPayload.uuid) {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION]
        .put(modifiedLocationPayload.uuid, modifiedLocationPayload))
        .then(() => {
          Alert.success(
            <FormattedMessage
              id="toaster.location.updated"
              defaultMessage="Location {uuid} successfully updated."
              values={{ uuid: modifiedLocationPayload.uuid }}
            />,
          );
        });
    } else {
      const locationResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION]
        .post(modifiedLocationPayload));
      locationSettingsUuid = extractUuid(locationResponse.headers.locationSettings);
      Alert.success(
        <FormattedMessage
          id="toaster.location.created"
          defaultMessage="Location successfully created."
        />,
      );
      redirectToNewLocation(locationResponse.headers.location);
    }

    const updatedLocationSettingsPayload = _pick(locationSettingsPayload, locationSettingsPutFields);
    // Send location settings PUT request only if there is anything to send.
    if (!_isEmpty(updatedLocationSettingsPayload)) {
      // We never create `location-settings` manually. It is always done on the backend.
      // So, we only need `update` here
      await dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION_SETTINGS].put(
        locationSettingsUuid, updatedLocationSettingsPayload,
      ));
    }
  };

  const onSaveSubLocationName = (uuid, name) => {
    if (uuid && name) {
      return dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].put(uuid, { name }));
    }

    return null;
  };

  const onArchiveSubLocation = async (uuid, hasResources, sensorUuid) => {
    if (uuid) {
      if (sensorUuid) {
        await dispatch(Actions.Api.nautilus[API_RESOURCES.SENSOR].delete(sensorUuid));
      }
      await dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].delete(uuid))
        .then(() => {
          if (hasResources) {
            Alert.success(
              <FormattedMessage
                id="toaster.subLocation.archived"
                defaultMessage="Sub-Location successfully archived."
              />,
            );
          } else {
            Alert.success(
              <FormattedMessage
                id="toaster.subLocation.deleted"
                defaultMessage="Sub-Location successfully deleted."
              />,
            );
          }
        });
    }
  };

  const onUnmount = () => {
    // get rid of pesky lingering errors
    dispatch(Actions.UI.clearUIState(['nautilus.location']));
  };

  const onDelete = currentUUID => {
    if (currentUUID) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].delete(currentUUID))
        .then(() => Alert.success(
          <FormattedMessage
            id="toaster.location.deleted"
            defaultMessage="Location {uuid} successfully deleted."
            values={{ uuid: currentUUID }}
          />,
        ))
        .then(() => navigate(getRouteURI(ROUTES.LOCATIONS, {}, {}, true)));
    }
  };

  const refreshLocationSettings = () => {
    const locationSettingsValues = location ? _pick(
      locationSettings, Object.values(LOCATION_NOTIFICATION_SETTING_BOOLS),
    ) : locationSettingsInitial;
    setUpdatedLocationSettings(locationSettingsValues);
  };

  useEffect(() => {
    onInitialize(uuid, selected.bureauGroup);
    refreshLocationSettings();

    return () => {
      onUnmount();
    };
  }, [uuid]);

  useEffect(() => {
    refreshLocationSettings();
  }, [locationSettings]);

  const onSubmit = payload => {
    const { bureauUri } = selected;
    const locationPayload = {
      ...payload,
      bureau: bureauUri,
    };
    const locationSettingsPayload = {
      ...locationSettings,
      ...updatedLocationSettings,
      bureau: bureauUri,
    };

    onSave(locationPayload, locationSettingsPayload);
  };

  const onChangeLocationSettings = (key, value) => {
    const newLocationSettingsValues = { ...updatedLocationSettings, [key]: value };
    setUpdatedLocationSettings(newLocationSettingsValues);
    setAllEmailNotificationsActive(
      allLocationSettingsEnabled(newLocationSettingsValues, Object.values(LOCATION_NOTIFICATION_SETTING_BOOLS)));
  };

  /*
    This method will send the API call to the related resource and filter it
    either by the sub_location uri or location uri (if "All" tab is active).
    As the result, it will return the "count" of the resources stored in the DB.
    It is needed to show the number in the Resources Table if Sub-Locations component. */
  const handleFetchRelatedResource = (fetchAll, resource, uri) => {
    if (uri) {
      return dispatch(Actions.Api.nautilus[resource].list(
        fetchAll ?
          { location: uri } :
          { sub_location: uri }, {}, {}, {}, true))
        .then(resourceResponse => resourceResponse?.json?.meta?.count || 0);
    }
    return null;
  };

  const handleAddSubLocation = values => {
    const payload = {
      ...values,
      location: location?.uri,
    };

    return dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].post(payload))
      .then(() => Alert.success(
        <FormattedMessage
          id="toaster.subLocation.created"
          defaultMessage="Sub-Location successfully created."
        />,
      ))
      .catch(error => Alert.error(error));
  };

  const handleSetLocationFilters = useCallback((locationUri, subLocationUri) => {
    if (locationUri && !subLocationUri) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].clear('list'));
      dispatch(Actions.LocationFilter.setSubLocation(null));
      return dispatch(Actions.LocationFilter.setLocation(locationUri));
    }

    if (locationUri && subLocationUri) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].clear('list'));
      dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].clear('list'));

      dispatch(Actions.LocationFilter.setSubLocation(subLocationUri));
      return dispatch(Actions.LocationFilter.setLocation(locationUri));
    }

    return null;
  }, [dispatch]);

  const handleCloseModal = () => {
    if (props.isVisible) {
      return props.hideModal();
    }
    return navigate(getRouteURI(ROUTES.LOCATIONS, null, null, true), { replace: true });
  };

  const renderResourceCount = (resource, locationUri) => {
    if (materialResourcesFetching) {
      return <Loading inline />;
    }

    if (resourcesCountState?.[locationUri]) {
      return resourcesCountState[locationUri][resource];
    }

    return null;
  };

  /* This method will decide whether we should actually send an API call
   to get the Resources Count. If we already stored this number, we will skip. */
  const shouldFetchResource = useCallback((resourceName, relatedLocationUri) => {
    // Check if we already have the resources by the related location (or sub.) URI.
    const resourcesCountKey = resourcesCountState?.[relatedLocationUri];
    // Check if we have the number of resources for the current resource name.
    const count = resourcesCountKey?.[resourceName];

    /* If we don't have the resources by URI, or we do not have the count
       for the resource name, we will send an API call. */
    return !resourcesCountKey || !count;
  }, [resourcesCountState]);

  const fetchAndUpdateResourceCount = useCallback(
    async (resourceName, relatedLocationUri) => {
      if (!relatedLocationUri) return;

      /* Based on the conditions:

         - If we are on the "All" tab or not.
         - Which resource API call we should send.
         - Which URI we are passing (location, or sub_location.

         We will send the appropriate API call and get the number of resources
         to show in the Resources Table.
         */
      const resourceCount = await handleFetchRelatedResource(
        activeTab === LOCATION_TABS.DETAILS,
        resourceName,
        relatedLocationUri,
      );

      /* Then we will store this number by the following schema:

         {
           [URI]: {
              [resourceName]: [resourceCount],
           }
         }

        This way, we will be able to get this number stored on every
        Tab change, and we will not need to call the same API call again.

         */

      setResourcesCountState(previous => ({
        ...previous,
        [relatedLocationUri]: {
          ...previous[relatedLocationUri],
          [resourceName]: resourceCount,
        },
      }));
    },
    [activeTab, handleFetchRelatedResource],
  );

  useEffect(() => {
    if (isMaterialManagementFeatureEnabled) {
      // Go through all the resources and send the API call if needed.
      resourcesToFetch.forEach(({ resource: resourceName }) => {
        if (shouldFetchResource(resourceName, location?.uri)) {
          fetchAndUpdateResourceCount(resourceName, location?.uri);
        }
      });
    }
  }, [
    isMaterialManagementFeatureEnabled,
    activeTab,
    location?.uri]);

  const dispatched = {
    onSubmit,
    onDelete,
    onSaveSubLocationName,
    onArchiveSubLocation,
    handleFetchRelatedResource,
    handleAddSubLocation,
    handleSetLocationFilters,
    handleCloseModal,
    handleEmailNotificationsModeSetting,
    setActiveTab,
    renderResourceCount,
    shouldFetchResource,
    fetchAndUpdateResourceCount,
    setAddSubLocationModal,
    addSubLocationModal,
  };

  return (
    <LocationModal
      updatedLocationSettings={updatedLocationSettings}
      onChangeLocationSettings={onChangeLocationSettings}
      {...props}
      {...selected}
      {...dispatched}
    />
  );
};

LocationModalContainer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default LocationModalContainer;
