import React from 'react';
import PropTypes from 'prop-types';
import { Card, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import './modalStyle.css';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const NoModelUpload = () => (
  <Card>
    <div className="text-center">
      <FormattedMessage id="record.uploadWithoutDesign" defaultMessage="Upload Without Design" />
    </div>
  </Card>
);

const Loading = () => (
  <Card>
    <div className="text-center">
      <FontAwesomeIcon icon={faSpinner} spin />
      <span style={{ marginRight: '0.5em' }} />
      <FormattedMessage
        id="loading.thumbnail"
        defaultMessage="Rendering Thumbnail…"
      />
    </div>
  </Card>
);

const NoSnapshot = () => (
  <Card>
    <div className="text-center">
      <FormattedMessage
        id="record.snapshot.none"
        defaultMessage="No Snapshot"
      />
    </div>
  </Card>
);

const Error = () => (
  <Card>
    <div className="text-center">
      <FormattedMessage id="status.error" defaultMessage="Error" />
    </div>
  </Card>
);

class ModalLightboxModel extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false };
    this.openModal = this.openModal.bind(this);
  }

  openModal(event) {
    this.setState({ isOpen: true });

    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  }

  render() {
    const { isOpen } = this.state;
    const { snapshot, customBtnClass, customImgClass, piecesCount } = this.props;

    if (!snapshot) {
      return <NoSnapshot />;
    }

    switch (snapshot) {
      case 'ERROR':
        return <Error />;

      case 'NO_MODEL_UPLOAD':
        return <NoModelUpload />;

      case 'LOADING':
        return <Loading />;

      case 'NO_SNAPSHOT':
        return <NoSnapshot />;

      default:
        return (
          <div>
            <button
              type="button"
              className={`modalStyle ${customBtnClass || ''}`}
              onClick={this.openModal}
            >
              {/* <Thumbnail src={snapshot} /> */}
              {piecesCount && (
                <div className="snapshotThumbnailPiecesCount">
                  x{piecesCount}
                </div>
              )}
              <Image src={snapshot} thumbnail className={customImgClass || ''} />
            </button>

            {isOpen && (
              <Lightbox
                mainSrc={snapshot}
                onCloseRequest={() => this.setState({ isOpen: false })}
              />
            )}
          </div>
        );
    }
  }
}

ModalLightboxModel.propTypes = {
  snapshot: PropTypes.string.isRequired,
  customBtnClass: PropTypes.string,
  customImgClass: PropTypes.string,
  piecesCount: PropTypes.number,
};

ModalLightboxModel.defaultProps = {
  customBtnClass: '',
  customImgClass: '',
  piecesCount: null,
};

export default ModalLightboxModel;
