import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ROUTES } from 'rapidfab/constants';
import { STOCK_STATUS_MAP } from 'rapidfab/mappings';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { faExternalLink, faPlusCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const MaterialTypeStockPanelHeader = ({
  onOpenModal,
  materialManagementEnabled,
}) => (
  <Row className="d-flex align-items-center">
    <Col xs={6}>Material Stocks</Col>
    <Col xs={6} className="d-flex justify-content-end">
      {materialManagementEnabled ? (
        <OverlayTrigger
          placement="left"
          overlay={(
            <Tooltip>
              <FormattedMessage
                id="record.material.stockPanelMMTooltip"
                defaultMessage="Order or Create Material Lots in your Inventory to add them to your Stocks"
              />
            </Tooltip>
          )}
        >
          <FontAwesomeIcon icon={faQuestionCircle} size="lg" />
        </OverlayTrigger>
      ) : (
        <FontAwesomeIcon icon={faPlusCircle} size="lg" onClick={onOpenModal} />
      )}
    </Col>
  </Row>
);
MaterialTypeStockPanelHeader.propTypes = {
  onOpenModal: PropTypes.func.isRequired,
  materialManagementEnabled: PropTypes.bool.isRequired,
};

const MaterialTypeStockPanel = ({
  stocks,
  locationsByUri,
  materialsByUri,
  onOpenModal,
  isCustomDarkCardStyle,
  materialManagementEnabled,
}) => {
  const stockMaterials = stocks.map(stock => {
    if (!materialsByUri[stock.material]) {
      return null;
    }
    return materialsByUri[stock.material];
  });

  const getUnits = useMemo(() => {
    const stockMaterialUnits = stockMaterials.map(material => material.units);
    const units = new Set(stockMaterialUnits);
    const unitsAreConsistent = units.size === 1;

    if (!unitsAreConsistent) return null;

    return [...units][0];
  }, [stockMaterials]);

  return (
    <Card
      bg={`${isCustomDarkCardStyle ? '' : 'dark'}`}
      className={`m-b ${isCustomDarkCardStyle ? 'custom-darken-modal--card' : ''}`}
    >
      <Card.Header className={`${isCustomDarkCardStyle ? 'pd-exp custom-darken-modal--card-header' : 'pd-exp inverse'}`}>
        <MaterialTypeStockPanelHeader
          onOpenModal={onOpenModal}
          materialManagementEnabled={materialManagementEnabled}
        />
      </Card.Header>
      <div className={`${isCustomDarkCardStyle ? '' : 'card-body-wrapper'}`}>
        <ListGroup fill>
          <ListGroupItem key="header">
            <Row>
              <Col xs={3}>
                <b>
                  <FormattedMessage
                    id="field.location"
                    defaultMessage="Location"
                  />
                </b>
              </Col>
              <Col xs={3}>
                <b>
                  <FormattedMessage id="field.status" defaultMessage="Status" />
                </b>
              </Col>
              <Col xs={3}>
                <b>
                  <FormattedMessage
                    id="field.quantity"
                    defaultMessage="Quantity"
                  />{' '}
                  {getUnits === null || getUnits === 'cm3' ?
                    <FormattedMessage id="field.units.cm3" defaultMessage="cm³" />
                    :
                    <p>({getUnits}s)</p>}
                </b>
              </Col>
              <Col xs={3} />
            </Row>
          </ListGroupItem>
          {!stocks.length ? (
            <div className="mt15 ml15 mb15">No stocks for this material</div>
          ) : (
            stocks.map(stock => {
              const location = locationsByUri[stock?.location];

              if (!location) return null;

              return (
                <ListGroupItem key={stock.uri}>
                  <Row>
                    <Col xs={3}>{location.name}</Col>
                    <Col xs={3}>
                      {stock.status ? (
                        <FormattedMessage {...STOCK_STATUS_MAP[stock.status]} />
                      ) : (
                        <FormattedMessage
                          id="notAvailable"
                          defaultMessage="N/A"
                        />
                      )}
                    </Col>
                    <Col xs={3}>{stock.quantity}</Col>
                    <Col xs={3}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={getRouteURI(ROUTES.STOCK_EDIT, {
                          uuid: stock.uuid,
                        })}
                      >
                        <FontAwesomeIcon icon={faExternalLink} />
                      </a>
                    </Col>
                  </Row>
                </ListGroupItem>
              );
            })
          )}
        </ListGroup>
      </div>
    </Card>
  );
};

MaterialTypeStockPanel.defaultProps = {
  isCustomDarkCardStyle: false,
};

MaterialTypeStockPanel.propTypes = {
  stocks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  locationsByUri: PropTypes.objectOf(PropTypes.shape({
    name: PropTypes.string,
  })).isRequired,
  materialsByUri: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  onOpenModal: PropTypes.func.isRequired,
  materialManagementEnabled: PropTypes.bool.isRequired,
  isCustomDarkCardStyle: PropTypes.bool,
};

export default MaterialTypeStockPanel;
