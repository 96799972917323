export const MATERIAL_LOT_STATUSES = {
  RECEIVED: 'received',
  IN_TESTING: 'in_testing',
  AVAILABLE: 'available',
  IN_USE: 'in_use',
  DONE: 'done',
  SCRAPPED: 'scrapped',
  RETURN_TO_SUPPLIER: 'return_to_supplier',
  ON_ORDER: 'on_order',
  IN_STORAGE: 'in_storage',
};

export const STOCK_STATUSES = {
  IN_USE: 'in-use',
  AVAILABLE: 'available',
  EXHAUSTED: 'exhausted',
};

// START OF Order related Statuses

export const BOEING_CUSTOM_ORDER_STATUS = {
  PENDING: 'Pending',
  IN_PROGRESS: 'In Progress',
  COMPLETE: 'Complete',
};

export const ORDER_STATUS = {
  CALCULATING_ESTIMATES: 'calculating_estimates',
  CANCELLED: 'cancelled',
  CLONING: 'cloning',
  CONFIRMED: 'confirmed',
  COMPLETE: 'complete',
  ERROR: 'error',
  IN_PREPARATION: 'in-preparation',
  NEW: 'new',
  PENDING: 'pending',
  PROCESSING: 'processing',
  POST_PROCESSING: 'post-processing',
  PRINTING: 'printing',
  SHIPPING: 'shipping',
};

export const ORDER_DELETABLE_STATUSES = [
  ORDER_STATUS.NEW,
  ORDER_STATUS.CALCULATING_ESTIMATES,
  ORDER_STATUS.PENDING,
  ORDER_STATUS.CANCELLED,
];

export const ORDER_UNLOCKED_STATUSES = [
  ...ORDER_DELETABLE_STATUSES,
  ORDER_STATUS.IN_PREPARATION,
  ORDER_STATUS.CLONING,
];

export const ORDER_END_STATUSES = [
  ORDER_STATUS.COMPLETE,
  ORDER_STATUS.CANCELLED,
  ORDER_STATUS.ERROR,
];

// END OF Order related Statuses

// START OF Line Item related Statuses

// Similar to ORDER_STATUS, but missing a few fields
export const LINE_ITEM_STATUS = {
  NEW: 'new',
  CALCULATING_ESTIMATES: 'calculating_estimates',
  PENDING: 'pending',
  IN_PREPARATION: 'in-preparation',
  CONFIRMED: 'confirmed',
  PRINTING: 'printing',
  CANCELLED: 'cancelled',
  COMPLETE: 'complete',
  POST_PROCESSING: 'post-processing',
  SHIPPING: 'shipping',
  ERROR: 'error',
};

export const DOWNTIME_STATUS = {
  SCHEDULED: 'scheduled',
  COMPLETE: 'complete',
};

// Before Preparation stage is Design Stage, except IN_PREPARATION
// This list might be changed in future (e.g. if any new status is added into Design Stage)
export const LINE_ITEM_BEFORE_PREPARATION_STAGE_STATUSES = [
  LINE_ITEM_STATUS.NEW,
  LINE_ITEM_STATUS.CALCULATING_ESTIMATES,
  LINE_ITEM_STATUS.PENDING,
];

export const LINE_ITEM_DESIGN_STAGE_STATUSES = [
  ...LINE_ITEM_BEFORE_PREPARATION_STAGE_STATUSES,
  LINE_ITEM_STATUS.IN_PREPARATION,
];

export const LINE_ITEM_PRODUCTION_STAGE_STATUSES = [
  LINE_ITEM_STATUS.CONFIRMED,
  LINE_ITEM_STATUS.PRINTING,
  LINE_ITEM_STATUS.COMPLETE,
  LINE_ITEM_STATUS.POST_PROCESSING,
  LINE_ITEM_STATUS.SHIPPING,
];

export const LINE_ITEM_ERROR_STATUSES = [
  LINE_ITEM_STATUS.CANCELLED,
  LINE_ITEM_STATUS.ERROR,
];

// END OF Line Item related Statuses

export const BUILD_FILE_STATUSES = {
  PENDING: 'pending',
  PROCESSING: 'processing',
  COMPLETE: 'complete',
  ERROR: 'error',
};

export const CASTOR_COSTING_FAILED_STATUSES = {
  PROJECT_UP_FAILED: 'project_up_failed',
  FILES_UP_FAILED: 'files_up_failed',
  FAILED: 'failed',
};

export const CASTOR_COSTING_STATUSES = {
  NEW: 'new',
  PENDING: 'pending',
  PROJECT_UP: 'project_up',
  FILES_UP: 'files_up',
  PROCESSING: 'processing',
  UP_TO_DATE: 'up_to_date',
  REFETCH_NEEDED: 'refetch_needed',
  ...CASTOR_COSTING_FAILED_STATUSES,
};

export const MODEL_STATUSES = {
  NOT_UPLOADED: 'not-uploaded',
  CONVERTING: 'converting',
  PROCESSING: 'processing',
  PROCESSED: 'processed',
  EXTERNAL_PROCESS: 'external-process',
  ERROR: 'error',
  REPAIRED: 'repaired',
  IRREPARABLE: 'irreparable',
  INOPERABLE: 'inoperable',
  FILE_TOO_LARGE: 'file-too-large',
};

export const ZVERSE_CONVERSION_STATUSES = {
  NEW: 'new',
  PENDING: 'pending', // Backend started calling ZVerse to do work, no result yet
  SUBMITTED: 'submitted', // Backend submitted conversion, to ZVerse
  FILE_UP: 'file-up', // Backend sent file to ZVerse, waiting on review
  PROCESSING: 'processing',
  ESTIMATE_COMPLETED: 'estimate-completed', // Backend has estimated loaded from ZVerse (user can contact ZVerse directly)
  APPROVAL_REQUESTED: 'approval-requested', // User approved Conversion to be started in our UI
  APPROVAL_FAILED: 'approval-failed', // User approval request failed
  CONVERSION_APPROVED: 'conversion-approved', // User approval request successfully received. Waiting for conversion
  CONVERSION_COMPLETED: 'conversion-completed', // Conversion completed on ZVerse side (Not supported on the Backend yet)
  COMPLETE: 'complete', // Conversion result is loaded into our system from ZVerse (User did this manually via our UI)
  ERROR: 'error',
};

export const EXPERT_CONVERSION_STATUSES = {
  NEW: 'new',
  PENDING: 'pending',
  SUBMITTED: 'submitted',
  COMPLETE: 'complete',
  ERROR: 'error',
};

export const MODEL_CONVERSION_STATUSES = {
  NEW: 'new', // conversion is created on the Nautilus, but transaction to conversion server not running yet
  PENDING: 'pending', // conversion request message sent from Nautilus to conversion server, waiting for conversion
  DOWNLOADING: 'downloading', // File is downloading by conversion server (uploading to conversion server)
  REPAIRING: 'repairing', // Not supported at the moment (for future use)
  PROCESSING: 'processing', // converting to STL from native cad
  UPLOADING: 'uploading', // Backend is pushing finished STL back to s3
  COMPLETE: 'complete', // conversion completed successfully
  COMPLETE_WITHOUT_UPLOAD: 'complete-without-upload', // Custom status for Zverse integration. Not used for now
  FAILED: 'failed', // Failed conversion (error state)
};

export const MODELER_STATUSES = {
  IDLE: 'idle',
  OFFLINE: 'offline',
  ERROR: 'error',
  PRINTING: 'printing',
  MAINTENANCE: 'maintenance',
  PRINT_FINISHED: 'print_finished',
  READY_TO_PRINT: 'ready_to_print',
  UNKNOWN: 'unknown',
  NOT_CONNECTED: 'not_connected',
};

export const WORK_SCHEDULE_TYPES = {
  NON_STOP: 'non_stop',
  CALENDAR: 'calendar',
};

export const WIP_MATRIX_TIMING_STATUSES = {
  ON_TIME: 'on-time',
  BEHIND: 'behind',
  LATE: 'late',
  UNKNOWN: 'unknown',
};

export const MSNAV_IMPORT_FILE_STATUSES = {
  UPLOADED: 'uploaded',
  NOT_UPLOADED: 'not-uploaded',
  ERROR: 'error',
  PROCESSING: 'processing',
  PROCESSED: 'processed',
};

export const DDW_PAYMENT_STATUSES = {
  READY: 'ready',
  COMPLETE: 'complete',
};

export const SECURE_FILE_CHECKOUT_STATUSES = {
  CHECKING_OUT: 'checking_out',
  CHECKED_OUT: 'checked_out',
  PROCESSING: 'processing',
  PROCESSED: 'processed',
  CHECKING_IN: 'checking_in',
  COMPLETED: 'completed',
  ERROR: 'error',
  CORRECTED: 'corrected',
  FAILED: 'failed',
};

export const CREATE_RUN_EVENT_STREAM_STATUSES = {
  RUN: 'nautilus.run',
  RUN_UPDATED: 'nautilus.run.updated',
  CREATE_RUNS: 'nautilus.create-runs',
  CREATE_RUNS_UPDATED: 'nautilus.create-runs.updated',
};
export const ZIP_FILE_STATUSES = {
  PENDING: 'pending',
  PROCESSING: 'processing',
  COMPLETED: 'completed',
  DELETED: 'deleted',
  ERROR: 'error',
};
