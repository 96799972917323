import { faClose, faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import Constants, { API_RESOURCES, FILE_EXTENSIONS, PRINTER_FORM_COMMAND_TYPES, PRINTER_FORM_COMMAND_VIEW_PASSWORD } from 'rapidfab/constants';
import { useModal } from 'rapidfab/hooks';
import { FormattedMessage } from 'rapidfab/i18n';
import Alert from 'rapidfab/utils/alert';
import React, { useEffect, useState } from 'react';
import { Button, Card, FormControl, FormGroup, FormLabel, Modal } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import FileInput from './order/edit/FileInput';

const CommandViewUnlockWarningModal = ({ show, onHide, unlockCommandView }) => {
  const [passwordIncorrect, setPasswordIncorrect] = useState(false);
  const fieldRequiredValidator = value => (value ? undefined : 'Please enter a password.');

  const validateAndHandleSubmit = formValues => {
    setPasswordIncorrect(false);
    const { password } = formValues;
    if (password === PRINTER_FORM_COMMAND_VIEW_PASSWORD) {
      unlockCommandView();
      // Intended to save the user from having to re-enter the password immediately with a 5 minute buffer.
      localStorage.setItem('timestempJENICommandViewLastUnlock', moment().toISOString());
      onHide();
    } else {
      setPasswordIncorrect(true);
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Form
        onSubmit={validateAndHandleSubmit}
        render={({ handleSubmit, values }) => (
          <form id="jeniCommandViewUnlockWarningForm" onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              Warning
            </Modal.Header>
            <Modal.Body>
              <p>
                <FormattedMessage
                  id="jeniCluster.commandView.directInteractionWarning"
                  defaultMessage="Warning: You are directly interacting with printers, existing print runs will be affected. Please enter the password to proceed."
                />
              </p>
              <Field
                validate={fieldRequiredValidator}
                name="password"
                render={({ input, meta }) => (
                  <>
                    <FormControl
                      autoFocus
                      {...input}
                      type="password"
                      placeholder="Password"
                      maxLength={50}
                      required
                    />
                    {meta.error && meta.touched && <span className="mt-1 text-danger">{meta.error}</span>}
                    {values?.password?.length && !meta.dirtySinceLastSubmit && passwordIncorrect &&
                      <span className="mt-1 text-danger">Incorrect password.</span>}
                  </>
                )}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={onHide} type="button">
                Cancel
              </Button>
              <Button onClick={handleSubmit} type="submit">
                Continue
              </Button>
            </Modal.Footer>
          </form>
        )}
      />
    </Modal>
  );
};

CommandViewUnlockWarningModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  unlockCommandView: PropTypes.func.isRequired,
};

const FileUploadView = ({
  fileState: [selectedFile, setSelectedFile],
}) => {
  const SelectedFileView = (
    <Card className="m-b mt-1">
      <Card.Body>
        <div id="selected-file-body-wrapper" className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-itesm-center">
            <FontAwesomeIcon className="spacer-right" icon={faFile} />
            <small>{selectedFile?.name}</small>
          </div>
          <Button
            className="pull-right"
            size="xs"
            bg="danger"
            variant="danger"
            onClick={() => setSelectedFile(null)}
          >
            <FontAwesomeIcon icon={faClose} />
          </Button>
        </div>
      </Card.Body>
    </Card>
  );

  return (
    <div>
      <FileInput
        acceptedExtensions={[FILE_EXTENSIONS.ZIP]}
        chooseFileLabel="Choose file"
        handleFileChange={event => setSelectedFile(event.target.files[0])}
      />
      <div className="m-t">
        <b>Selected Definition:</b>
        <div>{
          selectedFile?.name ?
            SelectedFileView : <p>(none)</p>
        }
        </div>
      </div>
    </div>
  );
};

FileUploadView.propTypes = {
  fileState: PropTypes.arrayOf(
    PropTypes.func,
  ).isRequired,
};

// TODO (JENI): Refactor this to make it general, a lot of this code is copied from `PrinterFormCommandView.jsx`
const JeniClusterCommandView = ({ jeniCluster, className = null }) => {
  const dispatch = useDispatch();

  const [openUnlockWarningPromptModal, WrappedUnlockWarningPromptModal] = useModal(CommandViewUnlockWarningModal);

  const isJeniCommandFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.DOCUMENT].post.fetching ||
    state.ui.nautilus[API_RESOURCES.MODELER_COMMAND].post.fetching ||
    state.uploadModel.uploading,
  );

  const commandConfigurationInitialState = {
    filePickerVisible: false,
    jobIdFieldVisible: false,
  };

  const [isViewEnabled, setIsViewEnabled] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [enteredJobId, setEnteredJobId] = useState(null);
  const [commandType, setCommandType] = useState('');
  const [printingPresetName, setPrintingPresetName] = useState(null);
  const [commandConfigurationState, setCommandConfigurationState] = useState(commandConfigurationInitialState);

  useEffect(() => {
    // Reset state when enabled/disabled.
    setCommandType('');
    setCommandConfigurationState(commandConfigurationInitialState);
  }, [isViewEnabled]);

  useEffect(() => {
    setCommandConfigurationState(commandConfigurationInitialState);

    if (commandType === PRINTER_FORM_COMMAND_TYPES.START_JOB) {
      setCommandConfigurationState({ ...commandConfigurationInitialState, filePickerVisible: true });
    } else if (commandType === PRINTER_FORM_COMMAND_TYPES.CANCEL_JOB) {
      setCommandConfigurationState({ ...commandConfigurationInitialState, jobIdFieldVisible: true });
    } else {
      /* ... */
    }
  }, [commandType]);

  const handleUploadSelectedFile = async () => {
    // Empty payload for now, `/machine-job-file/` API signature doesn't require any fields to be sent (POST).
    const payload = {};

    const documentPostResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.MACHINE_JOB_FILE].post(payload));
    const uploadLocation = documentPostResponse?.headers.uploadLocation;

    await dispatch(Actions.UploadModel.upload(uploadLocation, selectedFile));

    return {
      documentPostResponse,
    };
  };

  let payload = {};

  const handleSendCommand = async () => {
    if (commandType === PRINTER_FORM_COMMAND_TYPES.START_JOB) {
      // Upload the file to `/document`
      const { documentPostResponse } = await handleUploadSelectedFile();

      payload = {
        machine_job_file: documentPostResponse.headers.location,
        modeler: jeniCluster?.modeler,
        type: PRINTER_FORM_COMMAND_TYPES.START_JOB,
        printing_preset: printingPresetName,
      };

      // POST uploaded file to `/modeler-command/`
      const sendCommandResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.MODELER_COMMAND].post(payload));

      if (sendCommandResponse.type === Constants.RESOURCE_POST_SUCCESS) {
        Alert.success(<FormattedMessage
          id="toaster.jeniCluster.command.created"
          defaultMessage="Created command successfully."
        />);
        setSelectedFile(null);
      }
    } else if (commandType === PRINTER_FORM_COMMAND_TYPES.CANCEL_JOB) {
      // Not built in yet.
    }
  };

  const toggleViewEnabled = () => {
    // Check for temporary quick-unlock data, if it exists, set view to true.
    const quickUnlock = localStorage.getItem('timestempJENICommandViewLastUnlock');
    const quickUnlockExpired = moment(quickUnlock).add(5, 'minutes').isBefore(moment());
    if (!isViewEnabled && quickUnlock && !quickUnlockExpired) {
      setIsViewEnabled(true);
      return;
    }

    // If view is not enabled, clicking to toggle to -> true
    if (!isViewEnabled) {
      openUnlockWarningPromptModal({
        unlockCommandView: () => setIsViewEnabled(true),
      });
    } else {
      // View is already enabled, toggle straight to -> false
      setIsViewEnabled(false);
    }
  };

  const sendCommandButtonDisabled =
      !isViewEnabled ||
      isJeniCommandFetching ||
      (commandType === '') ||
      (commandType === PRINTER_FORM_COMMAND_TYPES.START_JOB && !selectedFile) ||
      (commandType === PRINTER_FORM_COMMAND_TYPES.CANCEL_JOB && _isEmpty(enteredJobId));

  return ([
    <WrappedUnlockWarningPromptModal id="commandViewUnlockWarningModal" />,
    <Card className={className} bg="dark">
      <Card.Header className="pd-exp inverse">
        <div className="d-flex align-items-center justify-content-between">
          <FormattedMessage
            id="jeniCluster.commands"
            defaultMessage="JENI Commands"
          />
          <Button
            onClick={toggleViewEnabled}
            size="xs"
            variant={isViewEnabled ? 'danger' : 'success'}
            bg={isViewEnabled ? 'danger' : 'success'}
          >
            {isViewEnabled ? 'Disable' : 'Enable'}
          </Button>
        </div>
      </Card.Header>
      <div className="card-body-wrapper">
        <Card.Body>
          <FormGroup className="form-group" controlId="commandType">
            <FormLabel>
              Command Type:
            </FormLabel>
            <FormControl
              disabled={!isViewEnabled}
              name="selectCommand"
              onChange={event => setCommandType(event.target.value)}
              as="select"
              value={commandType}
            >
              <option id={null} value="">
                Select a command
              </option>
              <option id={PRINTER_FORM_COMMAND_TYPES.START_JOB} value={PRINTER_FORM_COMMAND_TYPES.START_JOB}>
                Start Job
              </option>
              <option id={PRINTER_FORM_COMMAND_TYPES.CANCEL_JOB} value={PRINTER_FORM_COMMAND_TYPES.CANCEL_JOB}>
                Cancel Job
              </option>
            </FormControl>
          </FormGroup>
          {commandType === PRINTER_FORM_COMMAND_TYPES.START_JOB && (
            <FormGroup className="form-group" controlId="printingPresetName">
              <FormLabel>
                Printing Preset Name:
              </FormLabel>
              <FormControl
                disabled={!isViewEnabled}
                name="printingPresetName"
                placeholder="Printing Preset"
                onChange={event => setPrintingPresetName(event.target.value)}
                maxLength={250}
                as="input"
              />
            </FormGroup>
          )}
          {commandConfigurationState.filePickerVisible &&
            <FileUploadView fileState={[selectedFile, setSelectedFile]} />}
          {commandConfigurationState.jobIdFieldVisible && (
            <FormGroup className="form-group" controlId="jobId">
              <FormLabel>
                Job ID:
              </FormLabel>
              <FormControl
                name="jobId"
                onChange={event => setEnteredJobId(event.target.value)}
                placeholder="Enter Job ID"
                as="input"
              />
            </FormGroup>
          )}
          <Button
            className="pull-right mb15 mt15"
            variant="success"
            onClick={handleSendCommand}
            disabled={sendCommandButtonDisabled}
          >
            <FormattedMessage
              id="jeniCluster.command.sendCommand"
              defaultMessage="Send Command"
            />
          </Button>
        </Card.Body>
      </div>
    </Card>,
  ]);
};

JeniClusterCommandView.propTypes = {
  printer: PropTypes.shape({
    modeler: PropTypes.string,
  }).isRequired,
};

export default JeniClusterCommandView;
