import UserModalWrapper from 'rapidfab/components/modals/UserModalWrapper';
import { USER_MODAL_MODES } from 'rapidfab/constants';
import React from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Container, Row, FormControl, FormLabel } from 'react-bootstrap';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import { FormattedMessage } from 'react-intl';
import UsersContainer from 'rapidfab/containers/admin/UsersContainer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUsers } from '@fortawesome/free-solid-svg-icons';

const AdminUsers = ({
  filter,
  handleFilterChange,
  locations,
  roleFilter,
  handleRoleFilterChange,
  isSessionManager,
  isSelfRegistrationAllowedFeatureEnabled,
  csrfToken,
  newUserInputProps,
  userModalModeProps,
  isRestrictedUser,
  handleDeleteUser,
  handleCloseModal,
}) => {
  const {
    handleNewUserInputs,
    newUserName,
    newUserEmail,
    deleteUserWarning,
    setDeleteUserWarning,
    isDeletingUser,
  } = newUserInputProps;

  const {
    setUserModalMode,
    userModalMode,
    currentUserPreview,
    setCurrentUserPreview,
  } = userModalModeProps;

  return (
    <>
      <Container className="p-b" fluid>
        <BreadcrumbNav breadcrumbs={['admin', 'users']} />

        <div className="page-header">
          <h1>
            <FontAwesomeIcon icon={faUsers} className="spacer-right" />
            <FormattedMessage
              id="users"
              defaultMessage="Users"
            />
          </h1>
        </div>

        <Row className="align-items-center" id="users-table">
          <Col xs={3}>
            <FormLabel>Search</FormLabel>{' '}
            <FormControl
              type="text"
              value={filter}
              onChange={event => handleFilterChange(event.target.value)}
            />
          </Col>
          <Col xs={3}>
            <FormLabel>Role</FormLabel>{' '}
            <FormControl
              as="select"
              type="select"
              value={roleFilter}
              onChange={event => handleRoleFilterChange(event.target.value)}
            >
              <option value="">Any</option>
              <option value="manager">Manager</option>
              <option value="global-user">Global</option>
              <option value="restricted">Restricted</option>
              {isSelfRegistrationAllowedFeatureEnabled && (
                <option value="self-registered-guest">Guest</option>
              )}
              {locations.map(location => (
                <option key={location.uri} value={location.uri}>Location: {location.name}</option>
              ))}
            </FormControl>
          </Col>
          <Col xs={6}>
            <div className="pull-right">
              <Button
                variant="primary"
                disabled={!isSessionManager}
                onClick={() => setUserModalMode(USER_MODAL_MODES.ADD)}
              >
                <FontAwesomeIcon icon={faPlus} className="spacer-right" />
                <FormattedMessage id="addNewUser" defaultMessage="Add New User" />
              </Button>
            </div>
          </Col>
        </Row>
      </Container>

      <UsersContainer
        filter={filter}
        roleFilter={roleFilter}
        multiSelect={false}
        setCurrentUserPreview={setCurrentUserPreview}
        currentUserPreview={currentUserPreview}
        setUserModalMode={setUserModalMode}
      />

      <UserModalWrapper
        handleCloseModal={handleCloseModal}
        currentUserPreview={currentUserPreview}
        handleNewUserInputs={handleNewUserInputs}
        userName={newUserName}
        userEmail={newUserEmail}
        setUserModalMode={setUserModalMode}
        userModalMode={userModalMode}
        isRestrictedUser={isRestrictedUser}
        isSessionManager={isSessionManager}
        csrfToken={csrfToken}
        handleDeleteUser={handleDeleteUser}
        deleteUserWarning={deleteUserWarning}
        isDeletingUser={isDeletingUser}
        setDeleteUserWarning={setDeleteUserWarning}
      />
    </>
  );
};

AdminUsers.propTypes = {
  filter: PropTypes.string.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  roleFilter: PropTypes.string.isRequired,
  handleRoleFilterChange: PropTypes.func.isRequired,
  isSessionManager: PropTypes.bool.isRequired,
  isSelfRegistrationAllowedFeatureEnabled: PropTypes.bool.isRequired,
  csrfToken: PropTypes.string.isRequired,
  newUserInputProps: PropTypes.shape({
    handleNewUserInputs: PropTypes.func.isRequired,
    newUserName: PropTypes.string.isRequired,
    newUserEmail: PropTypes.string.isRequired,
    deleteUserWarning: PropTypes.bool.isRequired,
    setDeleteUserWarning: PropTypes.func.isRequired,
    isDeletingUser: PropTypes.bool.isRequired,
  }).isRequired,
  userModalModeProps: PropTypes.shape({
    setUserModalMode: PropTypes.func.isRequired,
    userModalMode: PropTypes.string.isRequired,
    currentUserPreview: PropTypes.shape({}),
    setCurrentUserPreview: PropTypes.func.isRequired,
  }).isRequired,
  isRestrictedUser: PropTypes.bool.isRequired,
  handleDeleteUser: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

export default AdminUsers;
