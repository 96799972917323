import React, { memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ResourceReadOnlyView from 'rapidfab/components/ResourceReadOnlyView/ResourceReadOnlyView';
import ResourceReadOnlyViewRow, {
  RESOURCE_READ_ONLY_VIEW_FIELD_TYPES,
} from 'rapidfab/components/ResourceReadOnlyView/ResourceReadOnlyViewRow';
import { ROUTES } from 'rapidfab/constants';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { extractUuid, getShortUUID } from 'rapidfab/utils/uuidUtils';
import { Link } from 'react-router-dom';
import { faCubes, faExternalLink, faObjectGroup } from '@fortawesome/free-solid-svg-icons';
import PieChart from '../../ChartPie';

const LotDetailsPanelHeader = () => (
  <Row className="d-flex align-items-center">
    <Col xs={6}>
      Lot Details
    </Col>
  </Row>
);

const LotDetails = props => {
  const { lot, batch } = props;

  const [selectedSegment, setSelectedSegment] = useState(0);
  const currentBatchMaterial = batch?.materials[selectedSegment];

  const materials = useMemo(() => ([...batch?.materials]), [batch?.materials]);

  return (
    <Card bg="dark" className="mb15">
      <Card.Header className="pd-exp inverse">
        <LotDetailsPanelHeader />
      </Card.Header>
      <div className="card-body-wrapper">
        <Card.Body>
          <Row>
            <Col xs={6} sm={3}>
              <PieChart
                materials={materials}
                onSegmentClick={(_, element) => {
                  setSelectedSegment(element.index);
                }}
              />
            </Col>
            <Col xs={6} sm={9}>
              <Button
                variant="light"
                style={{ width: '100%' }}
              >
                <p>{lot?.material_name}</p>
              </Button>
              <Row>
                <Col xs={6} sm={6}>
                  <Button
                    variant="light"
                    style={{ width: '100%', margin: '10px 0' }}
                  >
                    <Link
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                      to={getRouteURI(ROUTES.MATERIAL_LOT,
                        { uuid: extractUuid(currentBatchMaterial?.material_lot_uri) },
                        {},
                        true)}
                    >
                      <FontAwesomeIcon icon={faObjectGroup} size="lg" />
                      <p>Material Lot ({getShortUUID(lot?.uuid)})</p>
                      <FontAwesomeIcon icon={faExternalLink} size="lg" />
                    </Link>
                  </Button>
                  <ResourceReadOnlyView entity={currentBatchMaterial}>
                    <p><strong>Name:</strong> {lot?.name}</p>
                    <ResourceReadOnlyViewRow name="percentage" />
                  </ResourceReadOnlyView>
                  <ResourceReadOnlyView entity={lot}>
                    <ResourceReadOnlyViewRow
                      name="actual_delivery_date"
                      type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.DATE}
                    />
                    <ResourceReadOnlyViewRow name="delivery_id" />
                    <ResourceReadOnlyViewRow
                      name="expiration_date"
                      type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.DATE}
                    />
                    <ResourceReadOnlyViewRow
                      name="usage_cycles_limit"
                      type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.DATE}
                    />
                  </ResourceReadOnlyView>
                </Col>
                <Col xs={6} sm={6}>
                  <Button
                    variant="light"
                    style={{
                      margin: '10px 0',
                      width: '100%',
                    }}
                  >
                    <a
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                      href={getRouteURI(ROUTES.MATERIAL_BATCH, { uuid: batch?.is_initial_batch ?
                        batch?.uuid :
                        extractUuid(batch?.initial_batch) })}
                    >
                      <FontAwesomeIcon icon={faCubes} size="lg" />
                      <p>
                        Material Batch
                        ({getShortUUID(batch?.is_initial_batch ? batch?.uuid : extractUuid(batch?.initial_batch))})
                      </p>
                      <FontAwesomeIcon icon={faExternalLink} size="lg" />
                    </a>
                  </Button>
                  <ResourceReadOnlyView entity={batch}>
                    <p><strong>Quantity:</strong> {batch?.quantity} {currentBatchMaterial?.units}</p>
                    <p><strong>Number of Containers:</strong> {batch?.containers?.length}</p>
                    <ResourceReadOnlyViewRow name="usage_cycles" />
                  </ResourceReadOnlyView>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </div>
    </Card>
  );
};

LotDetails.propTypes = {
  batch: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    is_initial_batch: PropTypes.bool,
    initial_batch: PropTypes.string,
    quantity: PropTypes.number.isRequired,
    containers: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
    materials: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
  }).isRequired,
  lot: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
    material_name: PropTypes.string,
  }).isRequired,
  material: PropTypes.shape({
    materials: PropTypes.arrayOf(PropTypes.shape({
      material_name: PropTypes.string,
    })),
  }).isRequired,
};

export default memo(LotDetails);
