import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import Tooltip from 'rapidfab/components/Tooltip';
import React from 'react';
import Toggle from 'react-toggle';

const BuildPlateModeToggle = ({
  onBuildPlateModeToggle,
  isLineItemsModeEnabled,
  hasSelectedItems,
  lineItemsMode,
}) => (
  <div className="d-flex justify-content-end align-items-center">
    <span
      className="toggleTitle font-weight-500"
      role="button"
      onClick={onBuildPlateModeToggle}
      tabIndex={0}
    >
      Pieces
    </span>
    <Toggle
      checked={isLineItemsModeEnabled}
      icons={false}
      onChange={onBuildPlateModeToggle}
      className="toggleSliderDark"
    />
    <span
      className="toggleTitle font-weight-500"
      role="button"
      onClick={onBuildPlateModeToggle}
      tabIndex={0}
    >
      Line Items
    </span>
    {hasSelectedItems && (
      <Tooltip
        id="accessDisallowedReason"
        placement="top"
        trigger={(<FontAwesomeIcon icon={faInfoCircle} className="spacer-left" />)}
      >
        Please complete the Run Production Change for the {lineItemsMode ? 'Line Items' : 'Pieces'} before changing
        modes.
      </Tooltip>
    )}
  </div>
);

BuildPlateModeToggle.propTypes = {
  onBuildPlateModeToggle: PropTypes.func.isRequired,
  isLineItemsModeEnabled: PropTypes.bool.isRequired,
  hasSelectedItems: PropTypes.bool.isRequired,
  lineItemsMode: PropTypes.bool.isRequired,
};

export default BuildPlateModeToggle;
