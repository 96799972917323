import PropTypes from 'prop-types';
import usePrevious from 'rapidfab/hooks';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _filter from 'lodash/filter';
import _isEqual from 'lodash/isEqual';
import _map from 'lodash/map';
import _uniq from 'lodash/uniq';
import _isEmpty from 'lodash/isEmpty';
import Actions from 'rapidfab/actions';
import Loading from 'rapidfab/components/Loading';
import RunRecordForm from 'rapidfab/components/records/run/RunRecordForm';
import {
  API_RESOURCES,
  BUILD_FILE_STATUSES,
  BUILD_PACKER_TYPES,
  FEATURES,
  FILE_EXTENSIONS,
  PAGINATION_IGNORE_DEFAULT_LIMIT,
  ROUTES, SECURE_FILE_CHECKOUT_STATUSES,
} from 'rapidfab/constants';
import {
  getBuildFilesForRun,
  getPiecesByUri,
  getPostProcessorsForRun,
  getPrintersForRun,
  getRouteUUID,
  getRouteUUIDResource,
  getRunActualsForRun,
  getRunPrints,
  getSpecificWorkstationUrisForPrints,
  getUUIDResource,
  getUploadModel,
  isFeatureEnabled,
} from 'rapidfab/selectors';
import { buildFileResourceType, runResourceType } from 'rapidfab/types';
import Alert from 'rapidfab/utils/alert';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { FormattedMessage } from 'react-intl';
import * as Selectors from '../../../selectors';

const RunRecordFormContainer = props => {
  const uuid = useSelector(getRouteUUID);
  const run = useSelector(getRouteUUIDResource);
  const uploadModel = useSelector(getUploadModel);

  const isMaterialManagementFeatureEnabled = useSelector(state =>
    isFeatureEnabled(state, FEATURES.MATERIAL_MANAGEMENT));
  const runActuals = useSelector(state => getRunActualsForRun(state, run));
  const materialBatchUri = isMaterialManagementFeatureEnabled && runActuals && runActuals?.additive.material_batch;

  const printerType = useSelector(state => getUUIDResource(state, extractUuid(run?.printer_type)));

  const postProcessorType = useSelector(state => getUUIDResource(state, extractUuid(run?.post_processor_type)));

  const prints = useSelector(state => getRunPrints(state, run));
  const printsWorkstationUris = useSelector(state => getSpecificWorkstationUrisForPrints(state, prints));

  const isUserManagedPrinterType = run?.batch_type === BUILD_PACKER_TYPES.USER_MANAGED;
  const isPressToPrintFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.PRESS_TO_PRINT));

  let printers = useSelector(state => getPrintersForRun(state, run));
  if (printsWorkstationUris.length) {
    // If there are Printers used as workstation in prints production workflow steps
    // Limit list to those ones only
    printers = _filter(
      printers,
      printer => printsWorkstationUris.includes(printer.uri),
    );
  }

  let postProcessors = useSelector(state => getPostProcessorsForRun(state, run));
  if (printsWorkstationUris.length) {
    // If there are Post processors used as workstation in prints production workflow steps
    // Limit list to those ones only
    postProcessors = _filter(
      postProcessors,
      postProcessor => printsWorkstationUris.includes(postProcessor.uri),
    );
  }

  const buildFiles = useSelector(state => getBuildFilesForRun(state, run));
  const materialBatch = useSelector(state =>
    (materialBatchUri ? getUUIDResource(state, extractUuid(materialBatchUri)) : null));
  const latestBuildFileEvent = useSelector(state => Selectors.getStateEventStreamEvents(state, 'build_file'));

  const isGeneralMFGLanguageEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.GENERAL_MFG_LANGUAGE));
  const isSaving = useSelector(
    state => state.ui.nautilus[API_RESOURCES.RUN].put.fetching
      || state.ui.nautilus[API_RESOURCES.SECURE_FILE_CHECKOUT].post.fetching,
  );
  const isFetching = useSelector(
    state => state.ui.nautilus[API_RESOURCES.RUN].get.fetching
      || state.ui.nautilus[API_RESOURCES.SECURE_FILE_CHECKOUT].list.fetching
      || state.ui.nautilus[API_RESOURCES.SECURE_CHECKOUT_DIRECTORY].list.fetching,
  );
  const piecesByUri = useSelector(state => getPiecesByUri(state));
  const isPowderWorkflowFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.POWDER_WORKFLOW));
  const isSlicingFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.AUTO_CREATE_SLICE));
  const manuallySlicedBuildFile = useSelector(state => Selectors.getManuallySlicedBuildFileForRun(state, run.uri));

  const isCertifiedBuildsFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.CERTIFIED_BUILDS));

  const isSecureFileCheckoutFeatureEnabled = useSelector(
    state => isFeatureEnabled(state, FEATURES.SECURE_FILE_CHECKOUT),
  );

  const secureFileCheckout = useSelector(state => Selectors.getLastSecureFileCheckoutForRun(state, uuid));
  const lastSecureCheckoutDirectory = useSelector(
    state => Selectors.getSecureCheckoutDirectoryByUri(state, secureFileCheckout?.secure_checkout_directory),
  );
  const secureCheckoutDirectories = useSelector(
    state => (
      !secureFileCheckout || [
        SECURE_FILE_CHECKOUT_STATUSES.COMPLETED, SECURE_FILE_CHECKOUT_STATUSES.ERROR,
      ].includes(secureFileCheckout?.status) ?
        Selectors.getAvailableSecureCheckoutDirectories(state) : Selectors.getSecureCheckoutDirectories(state)),
  );
  const isDisabledForm = !!secureFileCheckout && ![
    SECURE_FILE_CHECKOUT_STATUSES.COMPLETED, SECURE_FILE_CHECKOUT_STATUSES.ERROR,
  ].includes(secureFileCheckout.status);

  const customFieldsByUri = useSelector(Selectors.getCustomFieldsByUri);
  const customRunFieldReferences = useSelector(Selectors.getCustomRunFieldReferences);

  const complete = {
    runOperation: run.operation,
    materialBatchUri,
    materialBatch,
    created: run.created,
    initialName: run.name,
    initialPrinter: run.printer,
    initialPostProcessor: run.post_processor,
    initialNotes: run.notes,
    initialStatus: run.status,
    initialLocked: run.pieces_locked,
    errorMessage: run.error,
    uploadModel,
    printerType: run.printer_type,
    printerTypeName: printerType && printerType.name,
    postProcessorType: run.post_processor_type,
    postProcessorTypeName: postProcessorType && postProcessorType.name,
    priority: run.priority,
    shipping: run.shipping,
    initialSecureRunCheckout: lastSecureCheckoutDirectory?.uri || null,
    initialCustomFields: run.custom_field_values,
  };

  // Form Data
  const {
    initialName,
    initialNotes,
    initialStatus,
    initialLocked,
    initialPrinter,
    initialPostProcessor,
    shipping,
    initialSecureRunCheckout,
    initialCustomFields,
  } = complete;

  const buildCustomFieldsObjectByUri = customFields => {
    if (_isEmpty(customFields)) {
      return {};
    }
    const result = {};
    customFields.forEach(({ custom_field, value }) => {
      result[custom_field] = { custom_field, value };
    });

    return result;
  };

  const [name, setName] = useState(initialName || null);
  const [notes, setNotes] = useState(initialNotes || null);
  const [status, setStatus] = useState(initialStatus || null);
  const [locked, setLocked] = useState(initialLocked || false);
  const [printer, setPrinter] = useState(initialPrinter || null);
  const [postProcessor, setPostProcessor] = useState(initialPostProcessor || null);
  const [secureRunCheckout, setSecureRunCheckout] = useState(initialSecureRunCheckout || null);
  const [modelUpload, setModelUpload] = useState(null);
  const [isModelUploading, setIsModelUploading] = useState(false);
  const [postProcessorData, setPostProcessorData] = useState(null);
  const [shippingData, setShippingData] = useState(null);
  const [customFieldValues, setCustomFieldValues] = useState(buildCustomFieldsObjectByUri(initialCustomFields));
  const [
    addCertifiedBuildToLibraryConfirmationModalState,
    setAddCertifiedBuildToLibraryConfirmationModalState,
  ] = useState({
    show: false,
  });
  const [buildFileDoesExistInBuildLibrary, setBuildFileDoesExistInBuildLibrary] = useState(false);
  const [existingBuildFiles, setExistingBuildFiles] = useState([]);
  const [manuallySlicedBuildFileUpload, setManuallySlicedBuildFileUpload] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    // When build file is updated on the event stream, fetch to refresh UI state.
    if (latestBuildFileEvent?.payload.run === run?.uri &&
      latestBuildFileEvent?.payload.status === BUILD_FILE_STATUSES.COMPLETE &&
      latestBuildFileEvent?.uuid) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.BUILD_FILE]
        .get(extractUuid(latestBuildFileEvent?.payload.uri)));
    }
  }, [latestBuildFileEvent]);

  useEffect(() => {
    setName(initialName || null);
    setNotes(initialNotes || null);
    setStatus(initialStatus || null);
    setLocked(initialLocked || false);
    setPrinter(initialPrinter || null);
    setPostProcessor(initialPostProcessor || null);
    setSecureRunCheckout(initialSecureRunCheckout || null);

    // need to set custom fields here
  }, [
    initialLocked,
    initialName,
    initialNotes,
    initialPostProcessor,
    initialPrinter,
    initialStatus,
    initialSecureRunCheckout,
  ]);

  const loadMaterialBatch = () => {
    if (materialBatchUri) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_BATCH].get(extractUuid(materialBatchUri)));
    }
  };

  const onInitialize = () => {
    const {
      uri: runURI,
      post_processor_type: currentPostProcessorType,
      printer_type: currentPrinterType,
      shipping: currentShipping,
      post_processor: currentPostProcessor,
      printer: currentPrinter,
    } = run;

    dispatch(Actions.Api.nautilus[API_RESOURCES.BUILD_FILE].list({
      archived: null,
      run: runURI,
    })).then(currentBuildFiles => {
      const buildFiles = currentBuildFiles?.json?.resources || [];
      const layoutUris = _uniq(_map(buildFiles, 'layout')).filter(Boolean);
      layoutUris.forEach(layoutUri => {
        // TODO Layout is only GET endpoint, so we can't to LIST with uri filter for now
        //  It's always 1-2 layouts
        dispatch(Actions.Api.nautilus[API_RESOURCES.LAYOUT].get(extractUuid(layoutUri)));
      });

      if (isSlicingFeatureEnabled && buildFiles.length) {
        // Filter on format .gcode.
        dispatch(Actions.Api.nautilus[API_RESOURCES.BUILD_FILE].list({
          format: FILE_EXTENSIONS.GCODE,
          run: run.uri,
        }));
        // Filter on format: .zip.
        dispatch(Actions.Api.nautilus[API_RESOURCES.BUILD_FILE].list({
          format: FILE_EXTENSIONS.ZIP,
          run: run.uri,
        }));
      }
    });

    if (currentShipping) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.SHIPPING].get(
        extractUuid(currentShipping),
      )).then(currentShippingData => setShippingData(currentShippingData?.json));
    }

    if (currentPostProcessorType) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR].list({
        post_processor_type: currentPostProcessorType,
      }));

      dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR].get(
        extractUuid(currentPostProcessor),
      )).then(currentPostProcessorData => setPostProcessorData(currentPostProcessorData?.json));

      dispatch(
        Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].get(
          extractUuid(currentPostProcessorType),
        ),
      );
    }

    if (currentPrinterType) {
      dispatch(
        Actions.Api.nautilus[API_RESOURCES.PRINTER_TYPE].get(
          extractUuid(currentPrinterType),
        ),
      );

      dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].get(
        extractUuid(currentPrinter),
      ));

      dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].list({
        printer_type: currentPrinterType,
      }));
    }

    loadMaterialBatch();
  };

  const manuallySlicedBuildFileState = {
    manuallySlicedBuildFileUpload,
    onManuallySlicedBuildFileChange: event =>
      setManuallySlicedBuildFileUpload(event.target.files[0]),
    handleRemoveManuallySlicedBuildFile: () =>
      setManuallySlicedBuildFileUpload(null),
  };

  const loadRunActuals = () => {
    const { runURI } = props;
    // Check for runUri to exist, just in case (anyway, it should be always set here)
    if (runURI) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.RUN_ACTUALS].list({ run: runURI }));
    }
  };

  const reLoadRun = () => {
    if (run) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.RUN].get(run.uuid))
        .then(() => {
          setStatus(run.status);
        });
    }
  };

  useEffect(() => {
    onInitialize();
  }, [uuid]);

  const previousStatus = usePrevious(status);
  const previousMaterialBatchURI = usePrevious(materialBatchUri);
  const previousUploadModelUploading = usePrevious(uploadModel.uploading);

  useEffect(() => {
    if (previousStatus !== status && !buildFiles.length) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.BUILD_FILE].list({
        archived: null,
        run: run.uri,
      }));
    }
  }, [status]);

  useEffect(() => {
    if (materialBatchUri && previousMaterialBatchURI !== materialBatchUri) {
      // In case material batch uri was set initially or via event stream
      // Or changed, even though, `change` is not possible logically at the moment
      loadMaterialBatch();
    }
  }, [materialBatchUri]);

  useEffect(() => {
    if (isModelUploading && uploadModel.uploading !== previousUploadModelUploading) {
      setIsModelUploading(uploadModel.uploading);
      setModelUpload(null);
    }
  }, [uploadModel.uploading]);

  const previousRun = usePrevious(run);
  useEffect(() => {
    if (!_isEqual(run, previousRun)) {
      reLoadRun();
    }
  }, [run]);

  useEffect(() => {
    if (!isPowderWorkflowFeatureEnabled) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.BUILD_FILE_LIBRARY].list(
        {},
        { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
        {},
        {},
        false,
      ))
        .then(response => {
          const resources = response?.json?.resources || [];
          const foundBuildFiles = resources.filter(({ run_template }) => run_template === run.uri);
          setExistingBuildFiles(foundBuildFiles);
          if (foundBuildFiles.length) {
            setBuildFileDoesExistInBuildLibrary(true);
          }
        });
    }
  }, [buildFileDoesExistInBuildLibrary]);

  const handleDelete = () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.RUN].delete(uuid))
      .then(() => {
        window.location.hash = getRouteURI(ROUTES.RUNS);
      });
  };

  const handleFileChange = event => {
    setModelUpload(event.target.files[0]);
  };

  const handleFileRemove = () => {
    setModelUpload(null);
  };

  const handleInputChange = event => {
    const { type, checked, name } = event.target;
    const value = type === 'checkbox' ? checked : event.target.value;
    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'status':
        setStatus(value);
        break;
      case 'notes':
        setNotes(value);
        break;
      case 'printer':
        setPrinter(value);
        break;
      case 'postProcessor':
        setPostProcessor(value);
        break;
      case 'pieces_locked':
        setLocked(value);
        break;
      case 'secureRunCheckout':
        setSecureRunCheckout(value);
        break;
      case 'custom_field_values':
        setCustomFieldValues(previous => (
          { ...previous, [event.target.uri]: { custom_field: event.target.uri, value } }));
        break;
      default:
        break;
    }
  };

  const handleCustomInputChange = (customEventName, { value, customFieldReferenceUri }) => {
    const { type, uri } = customFieldsByUri[customFieldReferenceUri];
    const customSyntheticEvent = { target: { name: customEventName, uri, type, ...(type === 'checkbox' ? { checked: value } : { value }) } };
    handleInputChange(customSyntheticEvent);
  };

  const handleAddBuildFileToCertifiedBuildsLibrary = certifiedBuildName => {
    if (!buildFiles || !buildFiles.length) {
      Alert.error(
        <FormattedMessage
          id="toaster.error.buildFile.notFound"
          defaultMessage="Build file could not be found."
        />);
      return;
    }

    const { uri } = buildFiles[0];

    const payload = {
      build_file_template: uri,
      name: certifiedBuildName,
    };

    dispatch(Actions.Api.nautilus[API_RESOURCES.BUILD_FILE_LIBRARY].post(payload))
      .then(() => Alert.success(
        <FormattedMessage
          id="toaster.buildFileLibrary.addedToCertifiedBuildsLibrary"
          defaultMessage="Successfully added to Certified Builds library"
        />,
      ))
      .then(() => setBuildFileDoesExistInBuildLibrary(true));
  };

  const handleSubmit = async () => {
    if (secureRunCheckout && initialSecureRunCheckout !== secureRunCheckout) {
      await dispatch(
        Actions.Api.nautilus[API_RESOURCES.SECURE_FILE_CHECKOUT].post({
          secure_checkout_directory: secureRunCheckout,
          related_table_name: 'run',
          resource_uuid: uuid,
        }),
      );
      dispatch(Actions.Api.nautilus[API_RESOURCES.SECURE_FILE_CHECKOUT].list(
        { related_table_name: 'run', resource_uuid: uuid },
      ));
      dispatch(Actions.Api.nautilus[API_RESOURCES.SECURE_CHECKOUT_DIRECTORY].list());
    }

    const payload = {
      name,
      notes,
      pieces_locked: locked,
      printer: printer || initialPrinter,
      post_processor: postProcessor || initialPostProcessor,
      shipping,
      custom_field_values: customFieldValues,
      ...(status !== initialStatus ? { status } : null),
    };

    if (!_isEmpty(customFieldValues)) {
      payload.custom_field_values = Object.values(customFieldValues);
    } else {
      // use default values from custom forms data
      const customFieldsWithDefaultValues = _filter(customRunFieldReferences, 'default_value');
      payload.custom_field_values = customFieldsWithDefaultValues.map(
        ({ default_value, uri }) => ({ custom_field: uri, value: default_value }));
    }

    if (modelUpload) {
      const type = modelUpload.name.toLowerCase().split('.').pop();
      dispatch(Actions.UploadModel.uploadProgress(0));
      dispatch(
        Actions.Api.nautilus[API_RESOURCES.BUILD_FILE].post({
          run: run.uri,
          name: modelUpload.name,
          format: type,
        }),
      )
        .then(args => {
          const { uploadLocation } = args.headers;
          setIsModelUploading(true);
          return dispatch(
            Actions.UploadModel.upload(uploadLocation, modelUpload),
          );
        });
    }

    // If a replacing manually sliced build file is uploaded
    if (manuallySlicedBuildFileUpload) {
      const type = manuallySlicedBuildFileUpload.name.toLowerCase().split('.').pop();
      dispatch(Actions.Api.nautilus[API_RESOURCES.BUILD_FILE].post({
        run: run.uri,
        name: manuallySlicedBuildFileUpload?.name,
        format: type,
      }))
        .then(response => {
          setManuallySlicedBuildFileUpload(null);
          const { uploadLocation } = response.headers;
          return dispatch(
            Actions.UploadModel.upload(uploadLocation, manuallySlicedBuildFileUpload),
          );
        });
    }

    dispatch(Actions.Api.nautilus[API_RESOURCES.RUN].put(uuid, payload)).then(() => {
      // Load run actuals on status change, in case new object is created
      loadRunActuals();
    });
  };

  const selected = {
    isSaving,
    isFetching,
    uuid,
    printers,
    postProcessors,
    run,
    buildFiles,
    ...(run
      ? complete
      : null),
    isGeneralMFGLanguageEnabled,
    piecesByUri,
    isCertifiedBuildsFeatureEnabled,
    isUserManagedPrinterType,
    manuallySlicedBuildFile,
    manuallySlicedBuildFileState,
    isSlicingFeatureEnabled,
    isPressToPrintFeatureEnabled,
    isSecureFileCheckoutFeatureEnabled,
    secureCheckoutDirectories,
    lastSecureCheckoutDirectory,
    customRunFieldReferences,
  };

  if (isFetching) {
    return <Loading />;
  }

  return (
    <RunRecordForm
      {...props}
      {...selected}
      name={name}
      operation={run.operation}
      notes={notes}
      status={status}
      pieces_locked={locked}
      secureRunCheckout={secureRunCheckout}
      printer={printer}
      postProcessorData={postProcessorData}
      postProcessor={postProcessor}
      isModelUploading={isModelUploading}
      modelUpload={modelUpload}
      printerData={printers[0]}
      shippingData={shippingData}
      handleDelete={handleDelete}
      handleInputChange={handleInputChange}
      handleSubmit={handleSubmit}
      handleFileChange={handleFileChange}
      handleFileRemove={handleFileRemove}
      addCertifiedBuildToLibraryConfirmationModalState={addCertifiedBuildToLibraryConfirmationModalState}
      setAddCertifiedBuildToLibraryConfirmationModalState={setAddCertifiedBuildToLibraryConfirmationModalState}
      prints={prints}
      handleAddBuildFileToCertifiedBuildsLibrary={handleAddBuildFileToCertifiedBuildsLibrary}
      buildFileDoesExistInBuildLibrary={buildFileDoesExistInBuildLibrary}
      existingBuildFiles={existingBuildFiles}
      isDisabledForm={isDisabledForm}
      customFieldValues={customFieldValues}
      handleCustomInputChange={handleCustomInputChange}
    />
  );
};

RunRecordFormContainer.defaultProps = {
  initialName: null,
  initialNotes: null,
  initialStatus: null,
  initialLocked: null,
  initialPrinter: null,
  initialPostProcessor: null,
  errorMessage: null,
  printerType: null,
  postProcessorType: null,
  uuid: null,
  runOperation: null,
  materialBatchUri: null,
  materialBatch: null,
  shipping: null,
  buildFiles: [],
};

RunRecordFormContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  initialName: PropTypes.string,
  initialNotes: PropTypes.string,
  initialStatus: PropTypes.string,
  initialLocked: PropTypes.bool,
  initialPrinter: PropTypes.string,
  initialPostProcessor: PropTypes.string,
  errorMessage: PropTypes.string,
  printerType: PropTypes.string,
  postProcessorType: PropTypes.string,
  uuid: PropTypes.string,
  runURI: PropTypes.string.isRequired,
  runOperation: PropTypes.string,
  materialBatchUri: PropTypes.string,
  materialBatch: PropTypes.shape({}),
  uploadModel: PropTypes.shape({
    uploading: PropTypes.bool,
  }).isRequired,
  shipping: PropTypes.string,
  run: runResourceType.isRequired,
  buildFiles: PropTypes.arrayOf(buildFileResourceType),
  isFetching: PropTypes.bool.isRequired,
};

export default RunRecordFormContainer;
