import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar, Dropdown, DropdownButton, Button } from 'react-bootstrap';
import _join from 'lodash/join';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _isEqual from 'lodash/isEqual';
import { Droppable } from 'react-drag-and-drop';
import { PRINT_STATUSES, FEATURES } from 'rapidfab/constants';
import Loading from 'rapidfab/components/Loading';
import Feature from 'rapidfab/components/Feature';
import SelectPiecesPanelHeader from 'rapidfab/components/records/run/SelectPiecesPanelHeader';
import DebugModeDataPanel from 'rapidfab/components/DebugMode/DebugModeDataPanel';
import RunPieceItem from './RunPieceItem';

class PieceList extends Component {
  constructor(props) {
    super(props);

    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.handleSearchValue = this.handleSearchValue.bind(this);
    this.onShiftToggle = this.onShiftToggle.bind(this);
    this.onShiftUp = this.onShiftUp.bind(this);
    this.onShiftDown = this.onShiftDown.bind(this);
    this.onPageLimitSelect = this.onPageLimitSelect.bind(this);
    this.handleSelectPage = this.handleSelectPage.bind(this);
    this.setFirstItem = this.setFirstItem.bind(this);
    this.getSortDirection = this.getSortDirection.bind(this);

    this.handleOrdering = this.handleOrdering.bind(this);
    this.handleNextPage = this.handleNextPage.bind(this);
    this.handlePrevPage = this.handlePrevPage.bind(this);

    this.state = {
      shiftPressed: false,
      shiftStart: props.shiftStart,
      shiftEnd: null,
      searchValue: '',
      pageLimitValues: [10, 20, 50],
      pageLimit: 10,
      pageSorting: 'order_due_date',
      pageSortingDirection: 'asc',
      activePage: 1,
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onShiftDown, false);
    document.addEventListener('keyup', this.onShiftUp, false);

    const { selectedPrinter } = this.props;

    if (selectedPrinter) {
      // Loading pieces when printer is selected
      this.refreshPieces();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      selectedPrinter,
      selectedLocations,
      selectedMaterials,
      selectedWorkflows,
      searchQuery,
      layerThicknessSearch,
    } = this.props;

    const { activePage, pageLimit, pageSorting, pageSortingDirection } = this.state;

    // Printer has been changed, update all prints related to printer
    if (
      selectedPrinter !== null &&
      (selectedPrinter !== prevProps.selectedPrinter)
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ activePage: 1 }, () => this.refreshPieces());
    }

    if (
      !_isEqual(selectedLocations, prevProps.selectedLocations) ||
      !_isEqual(selectedMaterials, prevProps.selectedMaterials) ||
      !_isEqual(selectedWorkflows, prevProps.selectedWorkflows) ||
      !_isEqual(layerThicknessSearch, prevProps.layerThicknessSearch)
    ) {
      this.refreshPieces();
    }

    if (
      searchQuery !== prevProps.searchQuery
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        searchValue: searchQuery,
        activePage: 1,
      }, () => this.refreshPieces());
    }

    if (activePage !== prevState.activePage) {
      this.refreshPieces();
    }

    if (pageLimit !== prevState.pageLimit) {
      this.refreshPieces();
    }

    if (pageSorting !== prevState.pageSorting) {
      this.refreshPieces();
    }

    if (pageSortingDirection !== prevState.pageSortingDirection) {
      this.refreshPieces();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onShiftDown, false);
    document.removeEventListener('keyup', this.onShiftUp, false);
  }

  handleSearchSubmit() {
    this.refreshPieces();
  }

  handleSearchValue(event) {
    const { value } = event.target;

    this.setState({
      searchValue: value,
      activePage: 1,
    });
  }

  handleOrdering(key) {
    let { pageSorting, pageSortingDirection } = this.state;
    if (pageSorting === key) {
      pageSortingDirection = pageSortingDirection === 'asc' ? 'desc' : 'asc';
    } else {
      pageSorting = key;
      pageSortingDirection = 'asc';
    }

    this.setState({ pageSorting, pageSortingDirection });
  }

  handleSelectPage(eventKey) {
    this.setState(
      {
        activePage: eventKey,
      },
    );
  }

  handleNextPage() {
    this.setState({
      activePage: this.state.activePage + 1,
    });
  }

  handlePrevPage() {
    this.setState({
      activePage: Math.max(this.state.activePage - 1, 1),
    });
  }

  onShiftDown(event) {
    if (event.keyCode === 16 && !this.state.shiftPressed) {
      this.setState({ shiftPressed: true, shiftEnd: null });
    }
  }

  onShiftUp(event) {
    if (event.keyCode === 16 && this.state.shiftPressed) {
      this.setState({
        shiftPressed: false,
      });
    }
  }

  onShiftToggle(piece) {
    const { pieces, onSelectGroup } = this.props;
    let piecesArray = [];

    if (this.state.shiftStart !== null) {
      const indexEnd = pieces.findIndex(x => x.uuid === piece.uuid);

      const shiftStart = Math.min(this.state.shiftStart, indexEnd);
      const shiftEnd = Math.max(this.state.shiftEnd, indexEnd);
      piecesArray = pieces.slice(shiftStart, shiftEnd + 1);

      this.setState({ shiftStart: null }, () => {
        onSelectGroup(piecesArray);
      });
    }
  }

  onPageLimitSelect(eventKey) {
    this.setState({ pageLimit: eventKey, activePage: 1 });
  }

  getSortDirection(name) {
    const { pageSorting, pageSortingDirection } = this.state;
    if (!pageSorting || (name !== pageSorting)) {
      return null;
    }

    if (pageSortingDirection === 'asc') {
      // "Up" symbol
      return ' ↑';
    }
    // "Down" symbol
    return ' ↓';
  }

  setFirstItem(piece) {
    const { pieces, onSelect, selected, selectedPrinter } = this.props;

    if (selectedPrinter !== null) {
      const { shiftStart } = this.state;
      let indexStart = pieces.findIndex(x => x.uuid === piece.uuid);
      const itemIsSelected = selected.findIndex(x => x.uuid === piece.uuid);

      onSelect(pieces[indexStart]);

      if (itemIsSelected > -1 || indexStart === shiftStart) {
        indexStart = null;
      }

      this.setState({ shiftStart: indexStart });
    }
  }

  refreshPieces() {
    const {
      selectedPrinter,
      filterItems,
      filterName,
      selectedMaterials,
      selectedWorkflows,
      layerThicknessSearch,
    } = this.props;
    // This method operates over prints to load pieces
    // so, all filters are related with "print" service
    const { activePage, pageLimit, searchValue, pageSorting, pageSortingDirection } = this.state;

    // fetch only unsigned Prints
    const filters = {
      run: null,
    };

    if (selectedPrinter !== undefined && selectedPrinter !== null) {
      filters.printer = selectedPrinter.uuid;
      filters.filter_by_line_item_status = true;
      filters.status = PRINT_STATUSES.CREATED;
    }

    if (selectedMaterials.length > 0) {
      filters.base_material = _map(selectedMaterials, 'uuid');
    }

    if (selectedWorkflows.length > 0) {
      filters.workflow = _map(selectedWorkflows, 'uri');
    }

    if (layerThicknessSearch) {
      filters.layer_thickness = layerThicknessSearch;
    }

    const filtersByFields = {};

    if (filterName && filterItems) {
      filtersByFields[filterName] = filterItems;
    }

    _forEach(filtersByFields, (data, key) => {
      if (data && data.length > 0) {
        const filterURLs = data.map(element => element.uri);
        const filterString = _join(filterURLs, ',');

        if (filterString) {
          filters[key] = filterString;
        }
      }
    });

    const offsetPage = (activePage - 1) * pageLimit;
    const pageParams = {
      limit: pageLimit,
      offset: offsetPage,
    };

    const searchParams = {};

    if (searchValue !== '') {
      searchParams.name = searchValue;
    }

    const queryParams = {};
    if (pageSorting) {
      queryParams.sort = `${pageSortingDirection === 'desc' ? '-' : ''}${pageSorting}`;
    }

    this.props.onLoadPieces(filters, pageParams, searchParams, queryParams);
  }

  render() {
    const {
      selected,
      onSelect,
      selectedPrinter,
      onDeactivate,
      fetching,
      printListStore,
      showSearch,
      toggleChecked,
      onToggleChange,
      isDebugModeEnabled,
      labelRelationships,
    } = this.props;

    const { pageLimitValues, pageLimit, searchValue } = this.state;
    let pieces = [...this.props.pieces];

    const isAvailableNextLink = (printListStore.links && printListStore.links.next !== null);
    const isAvailablePreviousLink = (printListStore.links && printListStore.links.prev !== null);
    const isPrinterSelected = selectedPrinter !== null;

    if (!isPrinterSelected) {
      // There are probably already cached data from previous request
      // which shouldn't be here
      // Let's override prints with empty value
      pieces = [];
    }

    return (
      <>
        <SelectPiecesPanelHeader
          showSearch={showSearch}
          toggleChecked={toggleChecked}
          onToggleChange={onToggleChange}
          fetching={this.props.initialFetching}
          selectLabel="Select Pieces"
          handleSearchSubmit={this.handleSearchSubmit}
          handleSearchValue={this.handleSearchValue}
          searchValue={searchValue}
        />

        <div className="vm-panel-body panel-body-bordered">
          <div className="table-responsive">
            <Droppable types={['buildplatepiece', 'buildplatelineitem']} onDrop={onDeactivate}>
              <table
                className="table table-fixed table-hover m-b-0 no-outer-border"
                id="printsTable"
              >
                <thead>
                  <tr>
                    <td>Render</td>
                    {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
                    <td
                      className="sorting-td"
                      onClick={() => {
                        this.handleOrdering('name');
                      }}
                    >
                      Order Name
                      { this.getSortDirection('name') }
                    </td>
                    <td
                      className="sorting-td"
                      onClick={() => {
                        this.handleOrdering('size');
                      }}
                    >
                      Size
                      { this.getSortDirection('size') }
                    </td>
                    <td
                      className="sorting-td"
                      onClick={() => {
                        this.handleOrdering('material_name');
                      }}
                    >
                      Material
                      { this.getSortDirection('material_name') }
                    </td>
                    <td>Layer Thickness</td>
                    <td
                      className="sorting-td"
                      onClick={() => {
                        this.handleOrdering('order_due_date');
                      }}
                    >
                      Due Date
                      { this.getSortDirection('order_due_date') }
                    </td>
                    <Feature featureName={FEATURES.PRIORITY}>
                      <td
                        className="sorting-td"
                        onClick={() => {
                          this.handleOrdering('piece_priority');
                        }}
                      >
                        Priority
                        { this.getSortDirection('piece_priority') }
                      </td>
                    </Feature>
                    {/* eslint-enable jsx-a11y/no-noninteractive-element-interactions */}
                  </tr>
                </thead>
                <tbody style={{ height: 'auto', maxHeight: 'inherit' }}>
                  {
                    fetching && <tr><td><Loading /></td></tr>
                  }
                  {(!fetching && !pieces.length) && (
                    <tr>
                      <td>
                        {
                          !isPrinterSelected ?
                            'Select Printer to list available Pieces'
                            :
                            'No results found'
                        }
                      </td>
                    </tr>
                  )}

                  {
                    !fetching && pieces.map(piece => {
                      const labelRelationshipForCurrentPiece =
                        _find(labelRelationships, { target_uri: piece.uri });

                      return (
                        <>
                          <RunPieceItem
                            key={piece.uuid}
                            selected={
                              !!selected.some(select => select.uri === piece.uri)
                            }
                            piece={piece}
                            onSelect={onSelect}
                            onShiftToggle={this.onShiftToggle}
                            setFirstItem={this.setFirstItem}
                            labelRelationshipForCurrentPiece={labelRelationshipForCurrentPiece}
                          />
                          {
                            isDebugModeEnabled && (
                              <DebugModeDataPanel data={piece} />
                            )
                          }
                        </>
                      );
                    })
                  }
                </tbody>
              </table>
            </Droppable>
          </div>
        </div>

        { isPrinterSelected && (
          <div className="pagination-panel">
            <ButtonToolbar className="pull-left">
              <DropdownButton title={pageLimit} id="pageLimitSelector-PrintsTab">
                {pageLimitValues.map(value => (
                  <Dropdown.Item
                    active={value === pageLimit}
                    key={value}
                    eventKey={value}
                    onClick={() => this.onPageLimitSelect(value)}
                  >
                    {value}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </ButtonToolbar>

            <ButtonToolbar className="pull-right">
              <Button
                className="spacer-right"
                disabled={!isAvailablePreviousLink}
                onClick={this.handlePrevPage}
                size="sm"
              >Prev
              </Button>
              <Button
                disabled={!isAvailableNextLink}
                size="sm"
                onClick={this.handleNextPage}
              >Next
              </Button>
            </ButtonToolbar>
          </div>
        )}
      </>
    );
  }
}

PieceList.defaultProps = {
  onToggleChange: () => true,
  fetching: false,
  selected: [],
  selectedPrinter: null,
  selectedLocations: [],
  selectedMaterials: [],
  selectedWorkflows: [],
  layerThicknessSearch: '',
  filterName: null,
  filterItems: [],
  searchQuery: null,
  showSearch: false,
  toggleChecked: false,
  shiftStart: null,
};

PieceList.propTypes = {
  onDeactivate: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onToggleChange: PropTypes.func,
  onLoadPieces: PropTypes.func.isRequired,
  onSelectGroup: PropTypes.func.isRequired,
  pieces: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selected: PropTypes.arrayOf(PropTypes.shape({})),
  printListStore: PropTypes.shape({
    links: PropTypes.shape({
      next: PropTypes.string,
      prev: PropTypes.string,
    }),
  }).isRequired,
  filterName: PropTypes.string,
  filterItems: PropTypes.arrayOf(PropTypes.shape({})),
  fetching: PropTypes.bool,
  showSearch: PropTypes.bool,
  toggleChecked: PropTypes.bool,
  searchQuery: PropTypes.string,
  selectedPrinter: PropTypes.shape({
    uuid: PropTypes.string,
  }),
  selectedLocations: PropTypes.arrayOf(PropTypes.shape({})),
  selectedMaterials: PropTypes.arrayOf(PropTypes.shape({})),
  selectedWorkflows: PropTypes.arrayOf(PropTypes.shape({})),
  layerThicknessSearch: PropTypes.string,
  isDebugModeEnabled: PropTypes.bool.isRequired,
  shiftStart: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]),
  initialFetching: PropTypes.bool.isRequired,
  labelRelationships: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default PieceList;
