import React from 'react';
import PropTypes from 'prop-types';

const LinearProgressBar = ({ active, percentage = 0, notStartedText }) => {
  const percentageData = percentage &&
  (
    percentage > 0 ||
    active
  ) ? `${percentage}%` : notStartedText;

  return (
    <div className="LinearProgressBar">
      <div className="LinearProgressBarLine" style={{ width: `${percentage}%` }} />
      <span className="LinearProgressBarPercentage">{percentageData}</span>
    </div>
  );
};

LinearProgressBar.defaultProps = {
  notStartedText: 'Not Started',
  active: false,
};

LinearProgressBar.propTypes = {
  percentage: PropTypes.number.isRequired,
  notStartedText: PropTypes.string,
  active: PropTypes.bool,
};
export default LinearProgressBar;
