import SubLocationCard from 'rapidfab/components/organize/SubLocationCard';
import React, { memo, useEffect, useMemo, useState } from 'react';
import {
  faStore,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import _sum from 'lodash/sum';
import PropTypes from 'prop-types';
import ConfirmationModal from 'rapidfab/components/ConfirmationModal';
import Loading from 'rapidfab/components/Loading';
import SensorDataLineChart from 'rapidfab/components/SensorDataLineChart';
import SubLocationsAddModal from 'rapidfab/components/records/SubLocationsAddModal';
import { API_RESOURCES } from 'rapidfab/constants';
import { SUB_LOCATIONS_FIELDS_MODAL } from 'rapidfab/constants/forms';
import EditSubLocationModalContainer from 'rapidfab/containers/modals/EditSubLocationModalContainer';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import {
  Col,
  Nav,
  NavItem,
  NavLink, OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import Alert from 'react-s-alert';

/* Here we can add all the resources we would like to fetch
   to show their count in the Resource Table view. As many resources
   you will add here, as many API calls will be sent to fetch the related
   resources counts stored in the DB to show in the Table.

   So all you need to do to get more data -> just put the new resource here.
   The rest will be covered automatically. */

const resourcesToFetch = [
  {
    resource: API_RESOURCES.MATERIAL_LOT,
  },
  {
    resource: API_RESOURCES.MATERIAL_BATCH,
  },
];

const SubLocations = memo(({
  locationUri,
  subLocations,
  subLocationsFetching,
  subLocationsByUri,
  onSaveSubLocationName,
  onArchiveSubLocation,
  handleAddSubLocation,
  materialResourcesFetching,
  subLocationsSubmitting,
  subLocationDeleting,
  handleSetLocationFilters,
  isMaterialManagementFeatureEnabled,
  sensorDataForSensorResources,
  isSensorFeatureEnabled,
  shouldFetchResource,
  fetchAndUpdateResourceCount,
  renderResourceCount,
  addSubLocationModal,
  setAddSubLocationModal,
  resourcesCountState,
}) => {
  const [tab, setTab] = useState(null);
  const [selectedSubLocation, setSelectedSubLocation] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [editSubLocationModal, setEditSubLocationModal] = useState(false);

  const selectedSubLocationSensorData = _find(sensorDataForSensorResources,
    { sub_location: selectedSubLocation?.uri });
  // Check which URI we should pass to the API call (All tab or Sub-Location tab).
  // const relatedLocationUri = selectedSubLocation?.uri;
  const relatedLocationUri = useMemo(() => selectedSubLocation?.uri, [selectedSubLocation?.uri]);

  /* In order to render either Archive or Delete button for the sub-location
     action, we need to check if there are some resources related to this sub-location.
     If we have more than 0 -> we will need to show the Archive button. If 0 ->
     we will show the Delete button. The method above works to get this number. */
  const handleCheckRelatedResources = subLocationUri =>
    (resourcesCountState[subLocationUri] ? _sum(Object.values(resourcesCountState[subLocationUri])) : 0);

  const hasRelatedResources = handleCheckRelatedResources(selectedSubLocation?.uri);

  useEffect(() => {
    if (isMaterialManagementFeatureEnabled) {
      // Go through all the resources and send the API call if needed.
      resourcesToFetch.forEach(({ resource: resourceName }) => {
        if (shouldFetchResource(resourceName, relatedLocationUri)) {
          fetchAndUpdateResourceCount(resourceName, relatedLocationUri);
        }
      });
    }
  }, [
    isMaterialManagementFeatureEnabled,
    tab,
    relatedLocationUri]);

  const onSelectSubLocationTab = selectedTab => {
    setTab(selectedTab);
    setSelectedSubLocation(subLocationsByUri[selectedTab]);
  };

  useEffect(() => {
    // If we have sub-locations -> select the first one by default.
    onSelectSubLocationTab(subLocations[0]?.uri);
  }, []);

  const handleChangeSubLocationName = payload => {
    if (!editSubLocationModal) {
      return setEditSubLocationModal(previous => !previous);
    }

    const subLocationSameName = (payload?.name && subLocationsByUri[tab])
      && (payload.name === subLocationsByUri[tab].name);

    if (subLocationSameName) {
      return setEditSubLocationModal(previous => !previous);
    }

    return onSaveSubLocationName(selectedSubLocation.uuid, payload.name)
      .then(response => {
        setSelectedSubLocation(previous => ({
          ...previous,
          name: payload.name,
        }));
        setEditSubLocationModal(!response);
      });
  };

  const handleArchiveSubLocation = async () => {
    try {
      await onArchiveSubLocation(
        selectedSubLocation.uuid,
        hasRelatedResources,
        selectedSubLocationSensorData && extractUuid(selectedSubLocationSensorData.uri),
      );

      const updatedSubLocations = subLocations.filter(({ uri }) => uri !== selectedSubLocation.uri);
      const previousTab = subLocations.findIndex(
        ({ uri }) => uri === selectedSubLocation.uri,
      ) - 1;

      const tabToSet = previousTab >= 0 ? updatedSubLocations[previousTab]?.uri : updatedSubLocations[0]?.uri;
      onSelectSubLocationTab(tabToSet);
    } catch (error) {
      Alert.error(error.message);
    }
  };

  const onCloseAddSubLocationModal = () => setAddSubLocationModal(false);
  const onCloseEditSubLocationModal = () => setEditSubLocationModal(false);

  const onAddSubLocation = async values => {
    setSubmitting(true);
    const payload = {
      ...values,
      location: locationUri,
    };

    SUB_LOCATIONS_FIELDS_MODAL.STRING_FIELDS.forEach(
      fieldName => {
        const field = _get(payload, fieldName);
        if (field === null) {
          _set(payload, fieldName, '');
        }
      },
    );

    if (!payload.location) {
      setSubmitting(false);
      return;
    }

    handleAddSubLocation(payload).then(() => {
      setSubmitting(false);
      onCloseAddSubLocationModal();
    });
  };

  const handleCloseArchiveModal = () =>
    setShowConfirmationModal(false);
  const handleConfirmArchive = () => handleArchiveSubLocation()
    .then(handleCloseArchiveModal);

  return (
    <>
      <div className="location-modal-tabs location-modal-sub-location-tabs">
        <Nav
          variant="tabs"
          activeKey={tab}
          onSelect={onSelectSubLocationTab}
        >
          {
            subLocationsFetching ?
              <Loading inline style={{ padding: '7px 15px' }} /> :
              subLocations.map(({ name, uri }) => (
                <NavItem key={uri}>
                  <NavLink eventKey={uri} className="d-flex align-items-center location-modal-tab location-modal-sub-location-tab">
                    <FontAwesomeIcon icon={faStore} className="spacer-right" />
                    {
                      name.length >= 20 ? (
                        <OverlayTrigger
                          placement="top"
                          overlay={(
                            <Tooltip>
                              <span>{name}</span>
                            </Tooltip>
                          )}
                        >
                          <span className="subLocationsTruncatedName">{name}</span>
                        </OverlayTrigger>
                      )
                        : (<span className="subLocationsTruncatedName">{name}</span>)
                    }
                  </NavLink>
                </NavItem>
              ))
          }
        </Nav>
      </div>
      <Row className="custom-darken-modal-custom-gutter">
        <Col xs={5} sm={5}>
          <SubLocationCard
            subLocation={selectedSubLocation}
            isMaterialManagementFeatureEnabled={isMaterialManagementFeatureEnabled}
            batchesCount={renderResourceCount(API_RESOURCES.MATERIAL_BATCH, relatedLocationUri)}
            lotsCount={renderResourceCount(API_RESOURCES.MATERIAL_LOT, relatedLocationUri)}
            setEditSubLocationModal={setEditSubLocationModal}
            materialResourcesFetching={materialResourcesFetching}
            hasRelatedResources={hasRelatedResources}
            setShowConfirmationModal={setShowConfirmationModal}
            subLocationDeleting={subLocationDeleting}
            handleSetLocationFilters={handleSetLocationFilters}
            locationUri={locationUri}
            selectedSubLocationSensorData={selectedSubLocationSensorData}
          />
        </Col>
        <Col xs={7} sm={7}>
          {!_isEmpty(selectedSubLocationSensorData?.sensorData)
          && isSensorFeatureEnabled
          && (
            <div className="sensorDataChartWrapper">
              <SensorDataLineChart
                data={selectedSubLocationSensorData?.sensorData}
                selectedSubLocation={selectedSubLocation}
              />
            </div>
          )}
        </Col>
      </Row>
      {
        addSubLocationModal && (
          <SubLocationsAddModal
            onClose={onCloseAddSubLocationModal}
            onSubmit={onAddSubLocation}
            isAdding={subLocationsSubmitting || submitting}
          />
        )
      }
      {
        editSubLocationModal && (
          <EditSubLocationModalContainer
            onClose={onCloseEditSubLocationModal}
            onSubmit={handleChangeSubLocationName}
            isAdding={subLocationsSubmitting || submitting}
            subLocation={selectedSubLocation}
          />
        )
      }
      {
        showConfirmationModal && !selectedSubLocationSensorData && (
          <ConfirmationModal
            handleCancel={handleCloseArchiveModal}
            handleConfirm={handleConfirmArchive}
            confirmButtonContent={hasRelatedResources ?
              <FormattedMessage id="button.archive" defaultMessage="Archive" /> :
              <FormattedMessage id="button.delete" defaultMessage="Delete" />}
            message={hasRelatedResources ?
              <FormattedMessage id="message.archiveSubLocation" defaultMessage="Are you sure you want to archive this sub-location?" /> :
              <FormattedMessage id="message.deleteSubLocation" defaultMessage="Are you sure you want to delete this sub-location?" />}
          />
        )
      }
      {
        showConfirmationModal && selectedSubLocationSensorData && (
          <ConfirmationModal
            handleCancel={handleCloseArchiveModal}
            handleConfirm={handleConfirmArchive}
            confirmButtonContent={<FormattedMessage id="button.delete" defaultMessage="Delete" />}
            message={(
              <FormattedMessage
                id="message.deleteSubLocationWithSensor"
                defaultMessage="This sub-location has a sensor {sensorSerialNumber} and sensor data. Do you want to delete those records?"
                values={{ sensorSerialNumber: selectedSubLocationSensorData.serial_number }}
              />
            )}
          />
        )
      }
    </>
  );
});

SubLocations.propTypes = {
  subLocations: PropTypes.arrayOf(PropTypes.shape({
    uri: PropTypes.string,
  })).isRequired,
  subLocationsFetching: PropTypes.bool.isRequired,
  subLocationsByUri: PropTypes.shape({}).isRequired,
  onSaveSubLocationName: PropTypes.func.isRequired,
  onArchiveSubLocation: PropTypes.func.isRequired,
  locationUri: PropTypes.string.isRequired,
  handleAddSubLocation: PropTypes.func.isRequired,
  materialResourcesFetching: PropTypes.bool.isRequired,
  subLocationsSubmitting: PropTypes.bool.isRequired,
  subLocationDeleting: PropTypes.bool.isRequired,
  handleSetLocationFilters: PropTypes.func.isRequired,
  isMaterialManagementFeatureEnabled: PropTypes.bool.isRequired,
  sensorDataForSensorResources: PropTypes.shape({}).isRequired,
  isSensorFeatureEnabled: PropTypes.bool.isRequired,
  shouldFetchResource: PropTypes.func.isRequired,
  fetchAndUpdateResourceCount: PropTypes.func.isRequired,
  renderResourceCount: PropTypes.func.isRequired,
  addSubLocationModal: PropTypes.bool.isRequired,
  setAddSubLocationModal: PropTypes.func.isRequired,
  resourcesCountState: PropTypes.shape({}).isRequired,
};

export default SubLocations;
