import { faClose, faPencil, faUserPen, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import ConfirmationModal from 'rapidfab/components/ConfirmationModal';
import Loading from 'rapidfab/components/Loading';
import { ProfileAvatar } from 'rapidfab/components/navbar/ProfileAvatar';
import { USER_MODAL_MODES } from 'rapidfab/constants';
import CreateNewUserContainer from 'rapidfab/containers/admin/CreateNewUserContainer';
import ManageUserContainer from 'rapidfab/containers/admin/ManageUserContainer';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

const UserModalWrapper = ({
  userName,
  userEmail,
  currentUserPreview,
  isSessionManager,
  userModalMode,
  setUserModalMode,
  csrfToken,
  handleNewUserInputs,
  isRestrictedUser,
  handleDeleteUser,
  deleteUserWarning,
  setDeleteUserWarning,
  handleCloseModal,
  isDeletingUser,
}) => {
  const renderUserProfile = (userName, userEmail) => (
    <div className="user-manage-modal-info">
      <ProfileAvatar userName={userName} size="lg" customClassName="user-manage-modal-profile-avatar" />
      <div className="user-manage-modal-info-content">
        <div className="d-flex align-items-center justify-content-center">
          <div className="user-manage-modal-info-name">{userName}</div>
          {userModalMode === USER_MODAL_MODES.VIEW && (
            <Button
              variant="link"
              className="user-manage-modal-button user-manage-modal-edit-button spacer-left"
              disabled={!isSessionManager}
              onClick={() => setUserModalMode(USER_MODAL_MODES.EDIT)}
              type="submit"
            >
              <FontAwesomeIcon icon={faPencil} />
            </Button>
          )}
        </div>

        <div className="user-manage-modal-info-email">{userEmail}</div>
      </div>
    </div>
  );

  const renderUserInfoLayout = () => {
    if (userModalMode === USER_MODAL_MODES.ADD && !userName) {
      return (
        <div className="user-manage-modal-create-user">
          <FontAwesomeIcon icon={faUserPlus} className="spacer-right" />
          <FormattedMessage id="record.user.new" defaultMessage="New User" />
        </div>
      );
    }

    if (userModalMode === USER_MODAL_MODES.EDIT && (!userName && !userEmail)) {
      return (
        <div className="user-manage-modal-create-user">
          <FontAwesomeIcon icon={faUserPen} className="spacer-right" />
          <FormattedMessage id="updateUser" defaultMessage="Update User" />
        </div>
      );
    }

    return renderUserProfile(userName, userEmail);
  };

  return (
    <Modal
      size="md"
      className="user-modal"
      show={!!userModalMode}
      onHide={handleCloseModal}
      scrollable
      backdrop="static"
      dialogClassName="custom-darken-modal user-manage-modal"
    >
      <Modal.Header className="user-manage-modal-header">
        <FontAwesomeIcon
          icon={faClose}
          onClick={handleCloseModal}
          tabIndex={0}
          role="button"
          className="custom-darken-modal-button custom-darken-modal user-manage-modal-header-icon"
        />
        {renderUserInfoLayout()}
      </Modal.Header>
      <Modal.Body className="custom-darken-modal--body-scroll user-manage-modal-body">

        {(userModalMode === USER_MODAL_MODES.ADD ||
          (userModalMode === USER_MODAL_MODES.EDIT && currentUserPreview)) && (
          <CreateNewUserContainer
            csrfToken={csrfToken}
            userInputsProps={{
              name: userName,
              email: userEmail,
            }}
            setUserModalMode={setUserModalMode}
            user={currentUserPreview}
            handleCloseModal={handleCloseModal}
            handleNewUserInputs={handleNewUserInputs}
            isRestrictedUser={isRestrictedUser}
            setDeleteUserWarning={setDeleteUserWarning}
          />
        )}

        {userModalMode === 'view' && currentUserPreview && (
          <ManageUserContainer
            user={currentUserPreview}
            csrfToken={csrfToken}
          />
        )}

        {deleteUserWarning && (
          <ConfirmationModal
            handleCancel={() => setDeleteUserWarning(false)}
            handleConfirm={() => handleDeleteUser(currentUserPreview)}
            message={`Are you sure you want to delete ${userName}?`}
            isDarken
            confirmButtonContent={isDeletingUser ? <Loading inline /> : <FormattedMessage id="button.delete" defaultMessage="Delete" />}
          />
        )}

      </Modal.Body>
    </Modal>
  );
};

UserModalWrapper.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
  currentUserPreview: PropTypes.shape({}).isRequired,
  handleNewUserInputs: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  setUserModalMode: PropTypes.func.isRequired,
  userModalMode: PropTypes.string.isRequired,
  isRestrictedUser: PropTypes.bool.isRequired,
  isSessionManager: PropTypes.bool.isRequired,
  csrfToken: PropTypes.string.isRequired,
  handleDeleteUser: PropTypes.func.isRequired,
  deleteUserWarning: PropTypes.bool.isRequired,
  setDeleteUserWarning: PropTypes.func.isRequired,
  isDeletingUser: PropTypes.bool.isRequired,
};

export default UserModalWrapper;
