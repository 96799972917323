import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { getUUIDResource, getMaterialsForPrinterType, getRouteUUID } from 'rapidfab/selectors';
import Actions from 'rapidfab/actions';

import Loading from 'rapidfab/components/Loading';
import QrPrinter from 'rapidfab/components/qr/Printer';
import { extractUuid, getShortUUID } from 'rapidfab/utils/uuidUtils';
import { Col, Container, Row } from 'react-bootstrap';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { API_RESOURCES, ROUTES } from 'rapidfab/constants';

const PrinterContainer = () => {
  const dispatch = useDispatch();
  const uuid = useSelector(state => getRouteUUID(state));
  const printer = useSelector(state => getUUIDResource(state, uuid));
  const isLoading = useSelector(state => !printer
    || state.ui.nautilus[API_RESOURCES.PRINTER].get.fetching
    || state.ui.nautilus[API_RESOURCES.LOCATION].get.fetching
    || state.ui.nautilus[API_RESOURCES.PRINTER_TYPE].get.fetching
    || state.ui.nautilus[API_RESOURCES.MATERIAL].list.fetching,
  );

  const location = useSelector(state => printer && getUUIDResource(state, extractUuid(printer.location)));

  const printerType = useSelector(state => printer
    && getUUIDResource(state, extractUuid(printer.printer_type)));

  const materials = useSelector(state => getMaterialsForPrinterType(state, printerType));

  const onInitialize = async printerUuid => {
    const result = await dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].get(printerUuid));
    const { location: locationUri, printer_type: printerTypeUri } = result.json;
    const [printerTypeResult] = await Promise.all([
      dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER_TYPE].get(extractUuid(printerTypeUri))),
      dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].get(extractUuid(locationUri))),
    ]);
    const { materials: currentMaterials } = printerTypeResult.json;
    await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].list({ uri: currentMaterials }));
  };

  useEffect(() => {
    if (uuid) {
      onInitialize(uuid).then(() => window.print());
    }
  }, [uuid]);

  return (
    <Container fluid>
      <Row>
        <Col lg={12}>
          {isLoading
            ? (<Loading />)
            : (
              <>
                <div className="hide-on-print">
                  <BreadcrumbNav
                    breadcrumbs={[
                      'printers',
                      {
                        message: `${printer.name} (${getShortUUID(printer.uuid)})`,
                        href: getRouteURI(ROUTES.PRINTER_EDIT, { uuid: printer.uuid }),
                      },
                      `Print ${printer.name} QR Code`,
                    ]}
                  />
                </div>

                <QrPrinter
                  printer={printer}
                  location={location || {}}
                  printerType={printerType || {}}
                  materials={materials || []}
                />
              </>
            )}
        </Col>
      </Row>
    </Container>
  );
};

PrinterContainer.propTypes = {
  printer: PropTypes.shape({
    name: PropTypes.string,
    uuid: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({}).isRequired,
  printerType: PropTypes.shape({}).isRequired,
};

export default PrinterContainer;
